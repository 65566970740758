import React from 'react';
import PropTypes from 'prop-types';
import Cookies from '../../cookies';
import './style.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from '../../actions/report';
import * as answerActions from '../../actions/answer';
import * as flowActions from '../../actions/flow';
import UserSession from '../../services/user-session';
import ReportService from '../../services/report-service';
import AnswerService from '../../services/answers-service';
import { findNextStepPageInFlow } from '../../components/utils/flow-utils';
import LoaderComponent from '../loader';

class ReportComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionPage: 'list',
      details: null,
    };
    this.reportContentTimeoutId = null;
    this.reportContentLoaded = false;
    this.advanceHandler = this.advanceHandler.bind(this);
  }

  componentDidMount() {
    const { flow } = this.props.flow;
    const user = UserSession.getUserData();
    const userId = user ? user.user_id : null;
    const content = this.props.item.content[0];
    this.props.actions.clearReport();

    // if we have a logged in user and the report has already been viewed, advance the user in the flow
    // a user should only see the report in the onboarding app web ONCE
    if (
      (userId && ReportService.getReportViewed(flow.flow_id, userId)) ||
      UserSession.getEmailedReport(flow.flow_id, content.report_id)
    ) {
      return setTimeout(this.advanceHandler.bind(this), 10);
    }

    this.checkForCustomCTA();
    this.getReportContent();
  }

  componentDidUpdate() {
    this.checkForCustomCTA();
    this.getReportContent(true);
  }

  /**
   * @description Loads the report content and checks whether it should send email or not
   * @param {Boolean} reUseContent - flag if should re-generate the report or not
   */
  getReportContent(reUseContent = false) {
    const unsavedAnswers = AnswerService.checkUnsavedAnswersExist();
    const user = UserSession.getUserData();
    const userId = user ? user.user_id : null;

    if (userId && unsavedAnswers) {
      clearTimeout(this.reportContentTimeoutId);
      this.reportContentTimeoutId = setTimeout(
        this.getReportContent.bind(this),
        100
      );
    } else {
      const { savingAnswers, flow } = this.props.flow;
      const report =
        (userId || reUseContent) && this.props.reportReducer.report
          ? this.props.reportReducer.report
          : null;
      const content = this.props.item.content[0];
      if (!savingAnswers && !report && !this.reportContentLoaded) {
        const reportId = content.report_id;
        const flow_id = flow ? flow.flow_id : null;
        const organization_id = flow ? flow.organization_id : null;

        const sendEmail = userId
          ? !ReportService.getReportEmailSentForUserId(userId)
          : false;
        const sl_user_flow = Cookies.get('sl-user-flow');
        const product_slug = Cookies.get('product_slug');
        const organization_slug = Cookies.get('organization_slug');
        let email_button_link = '';

        if (sl_user_flow) {
          email_button_link = sl_user_flow.replace(/^\/|\/$/g, '');
        } else if (product_slug && organization_slug) {
          email_button_link = `${product_slug}/${organization_slug}`;
        } else {
          email_button_link = window.location.href.split('/');
          email_button_link = `${email_button_link[3]}/${email_button_link[4]}`;
        }

        // Get report contents
        // eslint-disable-next-line max-len
        const actionFunc = userId
          ? this.props.actions.getReportContentsWithUserId
          : this.props.actions.getReportContentsWithoutUserId;

        const params = userId
          ? [
              reportId,
              userId,
              sendEmail,
              email_button_link,
              flow_id,
              organization_id,
            ]
          : [reportId];

        actionFunc.apply(this, params);
        this.reportContentLoaded = true;
        ReportService.markReportAsEmailSent(userId);
        ReportService.markReportAsViewed(flow_id, userId);
      }
    }
  }

  /** Checks if we have custom CTAs and binds them */
  checkForCustomCTA() {
    const { report } = this.props.reportReducer;
    const template = report && report.html ? report.html : report;
    if (template) {
      const hasCustomCTA = template.indexOf('data-sl-next') > -1;
      if (hasCustomCTA) {
        this.attachListener();
      }

      const hasScrollToSelector =
        template.indexOf('data-sl-scroll-to-selector') > -1;
      if (hasScrollToSelector) {
        this.attachScrollListener();
      }
    }
  }

  scrollIntoView(selector) {
    document.querySelector(selector).scrollIntoView({ behavior: 'smooth' });
  }

  attachScrollListener() {
    const buttons = Array.from(
      document.querySelectorAll('[data-sl-scroll-to-selector]')
    );
    buttons.forEach(button => {
      if (button.tagName === 'A') {
        button.setAttribute('href', 'javascript:void(0)');
      }
      const selector = button.getAttribute('data-sl-scroll-to-selector');
      button.removeEventListener(
        'click',
        this.scrollIntoView.bind(this, selector),
        true
      );
      button.addEventListener(
        'click',
        this.scrollIntoView.bind(this, selector),
        true
      );
    });
  }

  attachListener() {
    const buttons = Array.from(document.querySelectorAll('[data-sl-next]'));
    buttons.forEach(button => {
      if (button.tagName === 'A') {
        button.setAttribute('href', 'javascript:void(0)');
      }
      button.removeEventListener('click', this.advanceHandler, true);
      button.addEventListener('click', this.advanceHandler, true);
    });
  }

  /**
   * Advance to next step/page in flow
   */
  advanceHandler() {
    const { pages, currentPage, flow, currentStep } = this.props.flow;
    const next = findNextStepPageInFlow({ currentStep, currentPage, flow });
    if (next.isJump) {
      // Jump to a certain step & page
      this.props.flowActions.jumpTo(
        null,
        currentStep,
        currentPage,
        next.step,
        next.page,
        flow,
        true
      );
    } else {
      // OR Move to next Step or Next Page
      this.props.flowActions.next(
        null,
        currentPage === pages.length - 1 ? 'step' : 'page',
        currentStep,
        currentPage,
        flow
      );
    }
  }

  render() {
    const reportContents = this.props.reportReducer.report;
    let body;
    let template =
      reportContents && reportContents.html
        ? reportContents.html
        : reportContents;
    if (!reportContents || !this.reportContentLoaded) {
      return <LoaderComponent />;
    }

    // the report template HTML is generated at the service level
    // the same HTML is then being sent as an email AND as a response to the onboarding app web
    // minor adjustments have to be made to the html in order for it to render correctly in the onboarding app web
    // without introducing iframes. i.e. remove body tags and remove the tailor your program button)
    if (template) {
      template = template.replace(
        "span type='button'",
        'input class="sl-button" type="button"'
      );
      template = template.replace(
        'a class="sl-tailor" style="',
        'a class="sl-tailor" style="display:none !important;'
      );
      const bStart = template.indexOf('<body');
      const bEnd = template.indexOf('</body');
      body = template.slice(bStart + 6, bEnd);
    }
    return <div dangerouslySetInnerHTML={{ __html: body }} />;
  }
}

ReportComponent.propTypes = {
  reportReducer: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  answerActions: PropTypes.object.isRequired,
  flowActions: PropTypes.object.isRequired,
  flow: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  reportReducer: state.reportReducer,
  flow: state.flowReducer,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  answerActions: bindActionCreators(answerActions, dispatch),
  flowActions: bindActionCreators(flowActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportComponent);
