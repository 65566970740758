import { InteractivePasswordInput } from '../themed.password.component';
import {
  TooltipProvider,
  TooltipWithTrigger,
} from '../../../../tooltip/tooltip.component';
import { passwordErrorStyle } from '../themed.password.styles';
import ErrorMessage from '../../../../errors/error-message/error-message.component';
import { FunctionComponent, useEffect, useState } from 'react';
import { AdditionalDataProps } from '../themed.password.container';
import {
  passwordBlockPaddingStyle,
  passwordHelpTextStyle,
} from './password-block.styles';

export type InteractivePasswordBlockProps = {
  id: number;
  name: string;
  onChange: (value: string, isDisabled?: boolean) => void;
  type: string;
  maxLength: number;
  minLength: number;
  disabled?: boolean;
  value: string;
  additionalData: AdditionalDataProps;
  label: string;
  errorText?: string;
  placeholder: string;
  fieldError?: string;
  stateError: boolean;
  inputValidationChanged: (param: string, param2: boolean) => void;
};

export const CONFIRM_PASSWORD = 'Confirm password';
export const PASSWORD_HELPER_TEXT = 'Password guidelines';
export const CONFIRM_PASSWORD_FIELD_NAME = 'confirm-password';
export const PASSWORDS_DONT_MATCH_ERROR =
  'Passwords do not match.  Please try again!';

export const InteractivePasswordBlock: FunctionComponent<
  InteractivePasswordBlockProps
> = ({
  id,
  name,
  onChange,
  type,
  maxLength,
  minLength,
  disabled,
  value,
  additionalData,
  label,
  errorText,
  placeholder,
  fieldError,
  stateError,
  inputValidationChanged,
}) => {
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
  const [passwordsDoNotMatchError, setPasswordsDoNotMatchError] = useState('');
  const error = !!fieldError || stateError || !!passwordsDoNotMatchError;
  const errorTextToDisplay =
    fieldError || errorText || passwordsDoNotMatchError;

  const onConfirmPasswordChange = (value: string) => {
    setConfirmPasswordValue(value);
  };

  useEffect(() => {
    if (value !== '') {
      const doPasswordsMatch = value === confirmPasswordValue;
      inputValidationChanged(CONFIRM_PASSWORD_FIELD_NAME, !doPasswordsMatch);

      if (doPasswordsMatch) {
        setPasswordsDoNotMatchError('');
      } else {
        setPasswordsDoNotMatchError(PASSWORDS_DONT_MATCH_ERROR);
      }
    } else if (confirmPasswordValue === '' || value === '') {
      // If either password input is 'cleared', remove the error
      setPasswordsDoNotMatchError('');
    }
  }, [value, confirmPasswordValue, inputValidationChanged]);

  return (
    <div css={passwordBlockPaddingStyle}>
      <InteractivePasswordInput
        id={id}
        name={name}
        type={type}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        onChange={onChange}
        value={value || ''}
        label={label}
        error={error}
        placeholder={placeholder}
        required={additionalData.required}
      />

      <InteractivePasswordInput
        id={CONFIRM_PASSWORD_FIELD_NAME}
        name={CONFIRM_PASSWORD_FIELD_NAME}
        type={type}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        onChange={onConfirmPasswordChange}
        value={confirmPasswordValue}
        label={CONFIRM_PASSWORD}
        error={error}
        placeholder={CONFIRM_PASSWORD}
        required={additionalData.required}
      />

      <TooltipProvider>
        <TooltipWithTrigger
          side={'top'}
          align={'start'}
          content={additionalData.help_text}
        >
          <div tabIndex={0} css={passwordHelpTextStyle}>
            {PASSWORD_HELPER_TEXT}
          </div>
        </TooltipWithTrigger>
      </TooltipProvider>

      {error && !!errorTextToDisplay && (
        <ErrorMessage
          errorMessage={errorTextToDisplay}
          css={passwordErrorStyle}
        />
      )}
    </div>
  );
};
