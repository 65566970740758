import { FC } from 'react';
import './style.scss';
import InputButton from '../../../input-button/themed.input-button.component';

export type InteractiveInputButtonProps = {
  loading: boolean;
  label: string;
  allRequiredInputsHaveValue: boolean;
  type: 'button' | 'submit' | 'reset' | undefined;
  onFormAttemptSubmit: () => void;
};

// This wrapper is used for passing down renamed props specific to the interactive input button

const InteractiveInputButton: FC<InteractiveInputButtonProps> = props => {
  const {
    loading,
    label,
    allRequiredInputsHaveValue,
    type,
    onFormAttemptSubmit,
  } = props;

  return (
    <InputButton
      loading={loading}
      value={label}
      disabled={!allRequiredInputsHaveValue}
      type={type}
      onClick={onFormAttemptSubmit}
    />
  );
};

export default InteractiveInputButton;
