import { Fragment } from 'react';
import UserSession from '../../../../services/user-session';
import interactiveConfig from '../../../triggers/triggers-config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Dispatch } from 'redux';

import * as interactiveV2Actions from '../../../../actions/interactive-v2';
import InteractiveComponentV2Service from '../../../../services/interactive-component-v2';

import InteractiveInputComponent from '../interactive-input';
import { InteractiveInputText } from './themed.interactive-input-text.component';
import { InteractiveInputProps } from '../interactive-input/interactive-input.types';

class InteractiveTextInputContainer extends InteractiveInputComponent {
  constructor(props: InteractiveInputProps) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this._triggerNotifiers();
  }

  componentDidUpdate() {
    super.componentDidUpdate();
    this._triggerNotifiers();
  }

  _isInputRequired() {
    const { field_name } = this.props;
    const { additionalData } = this.state;

    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(field_name) > -1
    ) {
      const option = this.getOptionByField('two_step_check');

      if (option.value) {
        return false;
      }
    }

    return additionalData.required;
  }

  static _getPredefinedValue(props: InteractiveInputProps) {
    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMAIL.indexOf(props.field_name) > -1
    ) {
      const tempData = UserSession.getTempUserData() || {};
      return (
        tempData.email ||
        InteractiveComponentV2Service.getUserValues(props.field_name) ||
        ''
      );
    } else {
      return InteractiveInputComponent._getPredefinedValue(props);
    }
  }

  _shouldHideEmailAfterSso() {
    const { field_name, usedSSO } = this.props;

    return (
      interactiveConfig.INPUT_FIELD_NAMES.EMAIL.indexOf(field_name) > -1 &&
      usedSSO
    );
  }

  _isOneStepCheckEmployeeId() {
    const { field_name } = this.props;

    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(field_name) > -1
    ) {
      const option = this.getOptionByField('two_step_check');
      return !option.value;
    }

    return false;
  }

  _shouldRenderEmployeeId() {
    const { field_name, interactiveComponentError } = this.props;

    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(field_name) > -1
    ) {
      const employeeIdentifierNeeded =
        interactiveComponentError.employee_identifier_needed;
      return this._isOneStepCheckEmployeeId() || !!employeeIdentifierNeeded;
    }

    return false;
  }

  _triggerNotifiers() {
    const { interactiveV2Actions } = this.props;

    return this._shouldRenderEmployeeId()
      ? interactiveV2Actions.notifyEmployeeIdVisible(true)
      : interactiveV2Actions.notifyEmployeeIdVisible(false);
  }

  _isTwoStepCheckEmployeeId() {
    const { field_name } = this.props;
    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(field_name) > -1
    ) {
      const option = this.getOptionByField('two_step_check');
      return option.value;
    }

    return false;
  }

  _isNoValidationInput() {
    const parentResult = super._isNoValidationInput();
    const isHiddenEmployeeId =
      !this._shouldRenderEmployeeId() &&
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(
        this.props.field_name
      ) > -1;
    const isOptionalZipCode = this._isOptionalZipCode();

    return parentResult || isHiddenEmployeeId || isOptionalZipCode;
  }

  _isOptionalZipCode() {
    const { field_name, interactiveV2Reducer } = this.props;
    if (interactiveConfig.INPUT_FIELD_NAMES.ZIP_CODE.indexOf(field_name) > -1) {
      return (
        interactiveV2Reducer?.showingEmployeeId &&
        this._isTwoStepCheckEmployeeId()
      );
    }

    return false;
  }

  render() {
    const {
      fieldError,
      field_name,
      isDisabled,
      id,
      type,
      max_length,
      min_length,
      label,
      options,
    } = this.props;
    const { error, errorText, value, isReadOnly, additionalData } = this.state;
    return (
      <Fragment>
        {(!this._isTwoStepCheckEmployeeId() ||
          this._shouldRenderEmployeeId()) &&
          !this._shouldHideEmailAfterSso() && (
            <InteractiveInputText
              isInputRequired={this._isInputRequired()}
              onChange={this.onChange.bind(this)}
              fieldError={fieldError}
              field_name={field_name}
              isDisabled={isDisabled}
              id={id.toString()}
              type={type}
              max_length={max_length}
              min_length={min_length}
              label={label}
              error={error}
              errorText={errorText}
              value={value}
              isReadOnly={isReadOnly}
              additionalData={additionalData}
              placeholder={
                options.find(option => option.field === 'placeholder')?.value ||
                label
              }
            />
          )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  interactiveV2Actions: bindActionCreators(interactiveV2Actions, dispatch),
});

export default connect(null, mapDispatchToProps)(InteractiveTextInputContainer);
