import moment from 'moment';
import React from 'react';
import interactiveConfig from '../../../triggers/triggers-config';
import InteractiveInputComponent from '../interactive-input';
import InputDate from '../../../input-date/input-date.component';

class InteractiveDateInputComponent extends InteractiveInputComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      date: null,
    };
  }

  onChange(value) {
    // hack because this is the only component not sending back an event and can't change the component because of
    // backwards compatibility
    const data =
      value && typeof value === 'object' && value.target
        ? value
        : { target: { value: value } };
    data.target.value = moment(data.target.value).format(
      interactiveConfig.BIRTHDAY_DATE_FORMAT
    );
    super.onChange(data);
  }

  static getDerivedStateFromProps(props, state) {
    // const x = super.getDerivedStateFromProps(props, state);

    if (!InteractiveDateInputComponent._dateCorrectFormat(state.value)) {
      return {
        // ...state,
        // ...(x && {x}),
        value:
          InteractiveDateInputComponent._formatDateValue(state.value) ||
          undefined,
      };
    }

    return null;
  }

  componentDidUpdate() {
    if (this.state.value && this.state.value !== this.state.date) {
      // Set date to date prop value when available, needed to keep past InputDate values selected
      this.setState({ date: this.state.value });
    }
    super.componentDidUpdate();
  }

  validateData() {
    super.validateData();
  }

  render() {
    const error = !!this.props.fieldError || this.state.error;
    const errorText = this.props.fieldError || this.state.errorText;

    return (
      <div
        className="sl-interactive--date"
        data-disabled={this.props.isDisabled}
      >
        <input
          type="hidden"
          name={this.props.field_name}
          value={this.state.value}
        />
        {this.getLabelHTML()}
        <InputDate
          key={this.state.date}
          disabled={this.props.isDisabled}
          onChange={this.onChange.bind(this)}
          selectedValue={this.state.date}
          formatting={'month-day-year'}
          short={true}
          details=""
          error={error}
          errorText={errorText}
        />
        {this.getHelpText()}
      </div>
    );
  }

  static _dateCorrectFormat(value) {
    return (
      !value ||
      moment.utc(value, interactiveConfig.BIRTHDAY_DATE_VALID_FORMAT).isValid()
    );
  }

  static _formatDateValue(value) {
    const momentObject = moment.utc(
      value,
      interactiveConfig.BIRTHDAY_DATE_VALID_FORMAT
    );
    return momentObject.isValid()
      ? momentObject.toISOString().split('T')[0]
      : null;
  }
}

// InteractiveDateInputComponent.propTypes = InteractiveInputComponent.propTypes;

export default InteractiveDateInputComponent;
