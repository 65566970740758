import { css } from '@emotion/react';

export const modalHeaderStyle = () =>
  css({
    textAlign: 'right',
    padding: '24px 18px 20px',
    fontSize: '24px',
    fontWeight: 'bold',
    span: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  });

export const modalContent = () =>
  css({
    padding: '38px 54px 82px 34px',
    h2: {
      fontSize: '24px',
      fontWieght: 'bold',
      marginBottom: '32px',
    },
    p: {
      fontSize: '18px',
    },
  });
