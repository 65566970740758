// This is a model of what question data comes back as from the initial api call to retrieve flow data
// This is a WIP as we learn more of how the data is structured

import { EntityTypes } from '../constants/entity-types';
import { QuestionTypes } from '../constants/question-types';

export interface Question {
  // this should just extend PageEntity, pretty sure
  id: number;
  type: QuestionTypes;
  title?: string;
  is_core_question: boolean;
  validation: QuestionValidation;
  semantic_id: string;
  question_name: string;
  contains_mapped_questions: boolean;
  default_answer_keys: string[] | null; // is array of question-content-answer id's
  content: QuestionContent[];
  conditions: unknown[];
  display_name: string;
  primary_key: number;
  need_fit_bit_login?: boolean;
  entity_type?: EntityTypes; // should always be 'question' for questions
}

export const STRING_RESPONSE_TYPES = [
  QuestionTypes.NUMBER,
  QuestionTypes.DATE,
  QuestionTypes.TEXT,
];
export interface HiddenQuestion extends Question {
  defaultAnswerValue?: string;
  defaultAnswerKey?: string;
  step: number;
  page: number;
}

export interface QuestionValidation {
  maximumAnswers?: number;
  maximumNumber?: number | string;
  maximumNumberText?: number | string;
  minimumAnswers?: number;
}

export interface QuestionContent {
  id: number;
  post_question_text: string;
  pre_question_text: string;
  placeholder: string;
  post_answer_text: string;
  lang: string;
  show_none_of_the_above: string;
  tooltip: string;
  title: string;
  answers: QuestionContentAnswer[] | MappedQuestionContentAnswer[];
  question_id: number;
  skip_able_text: unknown;
  max_value: unknown;
  min_value: unknown;
  max_visible_answers?: number | null;
  display_name: string;
  primary_key: number;
}

export function isMappedQuestion(
  item: MappedQuestionContentAnswer | QuestionContentAnswer
): item is MappedQuestionContentAnswer {
  return (item as MappedQuestionContentAnswer).selected !== undefined;
}

export interface QuestionContentAnswer {
  id: number | string | boolean | null;
  score: number | string | null;
  text: string;
}

export interface MappedQuestionContentAnswer {
  text: string;
  selected: MappedQuestionSelection[];
  not_selected: MappedQuestionSelection[];
  id: string;
  score: string;
}

export interface MappedQuestionSelection {
  question_id: number;
  answer_key: string[];
  mapped_child_question: Question;
}
