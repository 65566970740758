import { AnyAction } from 'redux';
import * as ACTIONS from '../constants/action-types';
import { checkCondition } from '../components/triggers/conditions';
import { InteractiveSignupRequests } from '../constants/interact-signup';

export type InteractiveActionResponse = {
  body?: {
    field_errors?: any;
    alerts?: unknown[];
  };
  error?: string;
  message?: string;
  eligibility?: any;
};

export type InteractiveV2State = {
  shouldValidate: boolean;
  actionResponse: InteractiveActionResponse;
  disableAllExceptEmployeeId: boolean;
  showingEmployeeId: boolean;
  actionMethod?: InteractiveSignupRequests;
  actionParameterRenaming: unknown;
  loading?: boolean;
  passed?: boolean;
};

export const INITIAL_STATE: InteractiveV2State = {
  shouldValidate: false,
  actionResponse: {
    body: {},
  },
  disableAllExceptEmployeeId: false,
  showingEmployeeId: false,
  actionMethod: undefined,
  actionParameterRenaming: {},
};

export default function interactiveV2Reducer(
  state: InteractiveV2State = INITIAL_STATE,
  action: AnyAction
): InteractiveV2State {
  let passed;
  switch (action.type) {
    case ACTIONS.INTERACTIVE_V2_INPUTS_VALIDATE:
      return {
        ...state,
        shouldValidate: true,
      };

    case ACTIONS.INTERACTIVE_V2_SHOWING_EMPLOYEE_ID:
      return {
        ...state,
        showingEmployeeId: action.data,
      };

    case ACTIONS.INTERACTIVE_V2_DISABLE_FIELDS_EXCEPT_EMPLOYEE_ID:
      return {
        ...state,
        disableAllExceptEmployeeId: action.data,
      };

    case ACTIONS.INTERACTIVE_V2_ACTION_CALLBACK:
      passed = checkCondition(action.data, action.method);
      return {
        ...state,
        loading: false,
        shouldValidate: false,
        passed: passed,
        actionResponse: action.data,
        actionMethod: action.method,
      };
    case ACTIONS.INTERACTIVE_V2_SET_PASSED_PROP_TO_FALSE:
      // Resets this Interactive V2 Component back to its initial state,
      // including that it is not passed.
      return INITIAL_STATE;

    default:
      return state;
  }
}
