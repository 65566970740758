import { RootState } from '../store/store';
import { User } from '../types/user.types';

export const selectUserReducer = (state: RootState): User =>
  state.userReducer || {};
export const selectUserReducerUserDetails = (state: RootState) =>
  selectUserReducer(state).user;
export const selectUserReducerUserFitBitToken = (state: RootState) =>
  selectUserReducerUserDetails(state)?.fit_bit_token;
export const selectUserReducerIsUserAuthenticated = (state: RootState) =>
  selectUserReducerUserDetails(state)?.is_authenticated;
