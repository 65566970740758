import { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import UserSession from '../../services/user-session';
import { getUrlParamsWithSeparator } from '../../helpers/utm-params';
import ConditionalRender from '../../components/conditional-render';
import { onEnterOrSpace } from '../../components/utils/util-functions';
import { User } from '../../types/user.types';
import { ClientFlow } from '../../types/flow.types';
import {
  generateHeaderPageTrackerText,
  shouldShowHeaderPageTracker,
  getFullIdpLogoutUrl,
} from './header.utils';
import { getProductByUrl, getProductHomePage } from '../../helpers/product';
import { RootState } from '../../store/store';
import { SL_HEADER, HEADER_ELEMENT } from '../../constants/class-names';
import { SLEEPIO_BLUE_LOGO } from '../../constants/logos';

import './style.scss';

type HeaderContainerOwnProps = {
  onRef?: () => void;
};

type HeaderContainerStoreProps = {
  flow: ClientFlow;
  user: User;
};

type HeaderContainerProps = HeaderContainerOwnProps & HeaderContainerStoreProps;

export class HeaderContainer extends Component<HeaderContainerProps> {
  constructor(props: HeaderContainerProps) {
    super(props);
  }

  logOut(): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    UserSession.logout().then(resData => {
      if (resData?.idp_logout_uri) {
        const idpLogoutUrl = getFullIdpLogoutUrl(
          resData.idp_logout_uri,
          '/login-component/login'
        );
        window.location.assign(idpLogoutUrl);
      } else {
        window.location.assign(window.location.pathname);
      }
    });
  }

  onEnterOrSpaceOrCtrPlusEnter(
    event: React.KeyboardEvent<HTMLAnchorElement>,
    url: string | URL
  ): void {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
    if (
      (event.keyCode === 13 && !event.metaKey && !event.ctrlKey) ||
      event.keyCode === 32
    ) {
      window.location.assign(url);
    }
  }

  render() {
    const displayLogin = this.props.user.user
      ? !this.props.user.user.is_authenticated
      : true;

    const loginUrl = `/login-component/login?product_stream_id=${window.product_stream_id}`;
    const productName = getProductByUrl();
    const utm = getUrlParamsWithSeparator();
    const homePage = `${getProductHomePage(productName)}${utm}`;
    const showHeader =
      productName === 'sleepio' && shouldShowHeaderPageTracker(this.props.flow);
    const headerPageTracker = showHeader
      ? generateHeaderPageTrackerText(this.props.flow)
      : '';

    return (
      <header className={classNames(SL_HEADER, HEADER_ELEMENT)}>
        <div className="sl-header__content">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <a href={homePage} className="sl-header__logo" ref={this.logo}>
            <img src={SLEEPIO_BLUE_LOGO} alt="Sleepio Logo" />
          </a>

          <ConditionalRender if={showHeader && headerPageTracker}>
            <p className="sl-header__tracker">{headerPageTracker}</p>
          </ConditionalRender>

          <ConditionalRender if={displayLogin}>
            <a
              tabIndex={0}
              className="sl-header__login"
              href={loginUrl}
              onKeyDown={e => this.onEnterOrSpaceOrCtrPlusEnter(e, loginUrl)}
            >
              Log in
            </a>
          </ConditionalRender>

          <ConditionalRender if={!displayLogin}>
            <a
              tabIndex={0}
              className="sl-header__login"
              onClick={this.logOut.bind(this)}
              onKeyDown={e => onEnterOrSpace(e, this.logOut.bind(this))}
            >
              Log Out
            </a>
          </ConditionalRender>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state: RootState): HeaderContainerStoreProps => ({
  flow: state.flowReducer,
  user: state.userReducer,
});

export default connect(mapStateToProps)(HeaderContainer);
