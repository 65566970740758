import Cookies from '../cookies';

const SIGNUP_REDIRECT_STORAGE_KEY = 'sl-signup-redirect';
const COOKIE_UUID = 'sl-user-uuid';
const OLD_COOKIE_UUID = 'sl-user-old-uuid';
const COOKIE_SESSION_ID = 'sl-session-id';
const USER_FLOW = 'sl-user-flow';
const UUID_CHANGE_EVENT_SENT = 'sl-uuid-change-event-sent';

export function setUuidChangeEventSent(value: boolean) {
  localStorage.setItem(UUID_CHANGE_EVENT_SENT, JSON.stringify(value));
}

export function getUuidChangeEventSent() {
  return JSON.parse(localStorage.getItem(UUID_CHANGE_EVENT_SENT) || 'null');
}

export function getSignupRedirect() {
  return JSON.parse(
    localStorage.getItem(SIGNUP_REDIRECT_STORAGE_KEY) || 'null'
  );
}

export function removeSignupRedirect() {
  localStorage.removeItem(SIGNUP_REDIRECT_STORAGE_KEY);
}

export function setUserFlow(value: string) {
  return Cookies.set(USER_FLOW, value);
}

export function getUserUuid() {
  return Cookies.get(COOKIE_UUID);
}

export function getUserOldUuid() {
  return Cookies.get(OLD_COOKIE_UUID);
}

export function getUserSessionId() {
  return Cookies.get(COOKIE_SESSION_ID);
}
