export default class BaseValidator {
  MESSAGES = {
    REQUIRED: 'This field is required',
    EMAIL: 'Invalid email format',
    MESSAGE_FITBIT_SLEEP:
      'This seems too early based on when you normally get into bed.',
    MESSAGE_FITBIT_WAKE:
      'This seems too early based on when you normally wake up for the last time.',
    MESSAGE_FITBIT_ASLEEP: 'This time is too early given your answers.',

    CUSTOM_ERRORS: {
      data_storing: 'You must agree to the Privacy Policy and Terms',
      doctor_acknowledgement: 'Please acknowledge the statement above',
    },
    isValidNameErrorMessage:
      'This entry contains characters we are unable to process',
    isValidEmailErrorMessage: 'Please enter a valid email',
    isValidPasswordErrorMessage: 'Please enter a valid password',
    isValidZipCodeErrorMessage: 'Please enter a valid zip code',
    isValidPostalCodeErrorMessage: 'Please enter a valid postal code',
    isValidPhoneNumberErrorMessage: 'Please enter a valid phone number',
  };
}
