export const getSelectedValueForQuestion = function (
  question,
  answers,
  content,
  defaultAnswers
) {
  let selectedAnswers = null;
  // Get already-selected value(s), if any
  answers.forEach(answer => {
    if (content.question_id === answer.question_id) {
      selectedAnswers = answer.values;
    }
  });
  const isSingle =
    question.type.indexOf('single') > -1 ||
    question.type.indexOf('limit box') > -1 ||
    question.type.indexOf('box') > -1;
  if (selectedAnswers === null && defaultAnswers) {
    if (isSingle) {
      selectedAnswers = content.answers.find(a => a.id === defaultAnswers[0]);
    }

    if (
      question.type.indexOf('select') > -1 ||
      question.type.indexOf('radio') > -1
    ) {
      selectedAnswers = content.answers.filter(a => a.id === defaultAnswers[0]);
      selectedAnswers = selectedAnswers.length ? selectedAnswers[0] : null;
    }

    if (
      question.type.indexOf('number') > -1 ||
      question.type.indexOf('text') > -1 ||
      question.type.indexOf('date') > -1
    ) {
      selectedAnswers = defaultAnswers[0];
    }

    if (question.type.indexOf('multi') > -1) {
      selectedAnswers = [];
      defaultAnswers.forEach(answer => {
        selectedAnswers = selectedAnswers.concat(
          content.answers.filter(a => a.id === answer)
        );
      });
      if (defaultAnswers.indexOf('none') > -1) {
        selectedAnswers = ['none'];
      }
      if (!selectedAnswers.length) {
        selectedAnswers = null;
      }
    }
  }

  return selectedAnswers;
};
