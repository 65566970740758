// api to login with authenticated otp
type otpPayloadType = {
  otp_session_token: string;
  email: string;
  product_id: number;
  otp: string;
  device_platform: string;
};

const loginWithOtp = async (payload: otpPayloadType) => {
  const url = '/api/service_method_proxy/GeneralLogin/1/login_with_otp';
  const params = {
    method: 'POST',
    body: JSON.stringify(payload),
  };
  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .catch(err => {
      throw Error('logging in with one-time-password' + err);
    });
};

export default loginWithOtp;
