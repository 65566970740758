import React from 'react';

import ConditionalRender from '../components/conditional-render';

import Email from '../components/inputs/email';
import Submit from '../components/buttons/submit';
import GenericNewAuthenticationPage from '../components/page';

import Header from '../components/header';

import './style.scss';
import GaService from '../services/ga';

// The forgot password page which extends the GenericNewAuthenticationPage
export default class GenericForgotPasswordComponent extends GenericNewAuthenticationPage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = `Forgot Password - ${this.state.productNameCapitalized}`;
    GaService.initialize().then(() => GaService.forgotPasswordLoadEvent());
    this.expiredResetPassword =
      window.localStorage.getItem(
        'sl-new-authentication-reset-password-expired'
      ) === 'true';
    window.localStorage.removeItem(
      'sl-new-authentication-reset-password-expired'
    );
  }

  // calling the action is a component specific action
  // calls specific url
  // sends google analytics events as needed when response comes back on action ajax call
  async callAction() {
    const payload = {
      email: this.state.email,
      product_id: this._getProductId(),
    };
    const response = await super.callAction(
      payload,
      '/api/service_method_proxy/GeneralLogin/1/forgot_password'
    );

    if (
      response.result &&
      ['success', 'redirect'].indexOf(response.result.status) > -1
    ) {
      GaService.initialize().then(() => GaService.forgotPasswordSuccessful());
    } else {
      GaService.initialize().then(() => GaService.forgotPasswordUnsuccessful());
    }

    this.setState({
      forgotSuccess: true,
      forgotError: false,
      submitting: false,
      shouldValidate: false,
    });
  }

  forgotSuccessState() {
    this.setState({
      forgotSuccess: false,
    });
  }

  render() {
    return (
      <div className={`${this.state.product} login-component-container`}>
        <Header product={this.state.product} variant={'login'} />
        <div id="sl-forgot-page">
          <ConditionalRender if={!this.state.forgotSuccess}>
            <form
              id="sl-forgot"
              name="sl-forgot"
              onSubmit={this.onFormAttemptSubmit.bind(this)}
            >
              <h1>
                {this.expiredResetPassword
                  ? 'Link expired'
                  : 'Reset your password'}
              </h1>
              <p>
                {this.expiredResetPassword
                  ? 'Enter your email and we’ll send you a new link to reset your password.'
                  : 'Enter the email associated with your account and we’ll send you a link to reset your password.'}
              </p>
              <Email
                type="text"
                id="sl-login-email"
                name="sl-login-email"
                required={true}
                label="Email"
                placeholder="Email"
                shouldValidate={this.state.shouldValidate}
                disabled={this.state.submitting}
                error={
                  (this.errorFields &&
                    this.errorFields.indexOf('email') > -1) ||
                  this.state.forgotError
                }
                onChange={value => this.onInputValueChange('email', value)}
                value={this.state.email}
                onValidate={this.inputValidationChanged.bind(this, 'email')}
              />

              <ConditionalRender if={this.state.forgotError}>
                <div className="sl-forgot--error">
                  <p>Something went wrong. Please try again.</p>
                </div>
              </ConditionalRender>

              <Submit
                value="Reset password"
                loading={this.state.submitting}
                disabled={this.state.submitting}
              />
            </form>
          </ConditionalRender>

          <ConditionalRender if={this.state.forgotSuccess}>
            <div id="sl-forgot-success">
              <h1>Check your inbox</h1>

              <p>
                We’ve just sent you an email with a link to reset your password.
                Contact
                <a id="contact-email" href="mailto:hello@sleepio.com">
                  {' '}
                  hello@sleepio.com{' '}
                </a>
                with any questions.
              </p>
            </div>
          </ConditionalRender>

          {this.renderFooter(this.state.forgotSuccess === false)}
        </div>
      </div>
    );
  }
}
