import ApiActions from '../actions/api';
import { GET_ANSWERS_FOR_USER } from '../constants/action-types';

/**
 * Get logged in user info
 * @param {String} id identifier for the user
 * @param {String} flowId identifier for the flow
 * @param {String} organizationId identifier for the organization
 * @param {Function} [cb] callback
 * @returns {Function}
 */
export function getAnswersForUser({ id, flowId, organizationId, cb }) {
  const query = `user_id=${id}&flow_id=${flowId}&organization_id=${organizationId}&product_id=${window.product_id}`;
  return function (dispatch) {
    // eslint-disable-next-line max-len
    ApiActions.get({
      entity: 'Answer',
      method:
        'find_with_user_id_and_product_id_and_flow_id_and_organization_id',
      query: query,
    })
      .then(res => {
        if (cb) {
          cb(res.result);
        }
        return dispatch({ type: GET_ANSWERS_FOR_USER, data: { res } });
      })
      .catch(() => {
        return dispatch({ type: GET_ANSWERS_FOR_USER });
      });
  };
}
