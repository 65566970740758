import './style.scss';

import React from 'react';
import InteractiveInputComponent from '../interactive-input';
import FacebookSSOComponent from '../../../sso/facebook';
import GoogleSSOComponent from '../../../sso/google';
import VirginPulseSSOComponent from '../../../sso/virgin-pulse';
import ConditionalRender from '../../../conditional-render';
import interactiveConfig from '../../../triggers/triggers-config';

class InteractiveSsoComponent extends InteractiveInputComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  componentDidUpdate() {
    super.componentDidUpdate();
  }

  validateData() {
    super.validateData();
  }

  render() {
    return (
      <div className="sl-interactive--sso">
        <ConditionalRender
          if={
            interactiveConfig.INPUT_FIELD_NAMES.FACEBOOK.indexOf(
              this.props.field_name
            ) > -1 && !this.props.usedSSO
          }
        >
          <FacebookSSOComponent mode={'signup'} />
        </ConditionalRender>
        <ConditionalRender
          if={
            interactiveConfig.INPUT_FIELD_NAMES.GOOGLE.indexOf(
              this.props.field_name
            ) > -1 && !this.props.usedSSO
          }
        >
          <GoogleSSOComponent mode={'signup'} />
        </ConditionalRender>
        <ConditionalRender
          if={
            interactiveConfig.INPUT_FIELD_NAMES.VIRGIN_PULSE.indexOf(
              this.props.field_name
            ) > -1 && !this.props.usedSSO
          }
        >
          <VirginPulseSSOComponent mode={'signup'} />
        </ConditionalRender>
      </div>
    );
  }
}

export default InteractiveSsoComponent;
