import { css } from '@emotion/react';
import { BHTheme } from '../../types/bh-themes.types';

export const wrapperStyle = () =>
  css({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
  });

export const buttonDefaultStyle = (theme: BHTheme) =>
  css({
    border: 'none',
    borderRadius: '88px',
    color: theme.button.color.default,
    backgroundColor: theme.button.backgroundColor.default,
    fontSize: theme.button.fontSize.DESKTOP,
    padding: `0 ${theme.padding.EXTRA_LARGE}`,
    minWidth: theme.button.width.DESKTOP,
    maxWidth: theme.button.width.MAXWIDTH,
    height: theme.button.height.DESKTOP,
    lineHeight: '24px',
    whiteSpace: 'nowrap',

    [`@media (max-width: ${theme.screenWidth.TABLET})`]: {
      minWidth: theme.button.width.MOBILE,
      height: theme.button.height.MOBILE,
      fontSize: theme.button.fontSize.MOBILE,
      lineHeight: '16px',
    },

    '&:hover': {
      backgroundColor: theme.button.backgroundColor.hover,
    },
    '&:active': {
      backgroundColor: theme.button.backgroundColor.active,
    },
    '&:disabled': {
      color: theme.button.color.disabled,
      backgroundColor: theme.button.backgroundColor.disabled,
    },
  });
