import Cookies from '../cookies';

export const FLOW_STORAGE_KEY = 'sl-flow-progress';
export const FLOW_MAX_PROGRESS_STORAGE_KEY = 'sl-flow-max-progress';
export const FLOW_LINKED_TO_PLATGEN = 'linked_to_platgen';
export const FLOW_LINKED_TO_PLATGEN_LOGIN = 'is_platform_user';
export const FLOW_POST_EVENT_FLOW_ID = 'post_event_flow_id';

class FlowService {
  static getCurrentFlowURL() {
    const sl_user_flow = Cookies.get('sl-user-flow');
    const product_slug = Cookies.get('product_slug');
    const organization_slug = Cookies.get('organization_slug');
    let link = '';
    if (sl_user_flow) {
      link = sl_user_flow;
    } else if (product_slug && organization_slug) {
      link = `/${product_slug}/${organization_slug}`;
    }

    return link;
  }

  static setMaxProgress(currentMaxStep, currentMaxPage) {
    localStorage.setItem(
      FLOW_MAX_PROGRESS_STORAGE_KEY,
      JSON.stringify({ currentMaxStep, currentMaxPage })
    );
  }

  /**
   * Sets local progress when advancing through the flow
   * @param {String | Number | null} flowId ID of the flow
   * @param {Number} currentStep idx of current step
   * @param {Number} currentPage idx of current page
   */
  static setLocalProgress(flowId, currentStep, currentPage) {
    const { currentMaxStep = 0, currentMaxPage = 0 } =
      FlowService.loadMaxProgress();

    if (
      currentStep > currentMaxStep ||
      (currentStep === currentMaxStep && currentPage > currentMaxPage)
    ) {
      FlowService.setMaxProgress(currentStep, currentPage);
    }

    const cookieFlowId = Cookies.get('flow_id');
    if (!cookieFlowId) {
      Cookies.set('flow_id', flowId);
    }

    localStorage.setItem(
      FLOW_STORAGE_KEY,
      JSON.stringify({ flowId, currentStep, currentPage })
    );
  }

  /**
   * Get the local progress of the flow.
   *
   * If no local progress is found, put the user on the zeroth step.
   *
   * @returns {*}
   */
  static loadLocalProgress() {
    const json = JSON.parse(localStorage.getItem(FLOW_STORAGE_KEY));
    if (json) {
      return {
        flowId: json.flowId, // note: only used by hasProgressForFlow
        currentStep: json.currentStep,
        currentPage: json.currentPage,
      };
    } else {
      return { currentStep: 0, currentPage: 0 };
    }
  }

  static loadMaxProgress() {
    const json = JSON.parse(
      localStorage.getItem(FLOW_MAX_PROGRESS_STORAGE_KEY)
    );
    if (json) {
      return {
        flowId: json.flowId,
        currentMaxStep: json.currentMaxStep,
        currentMaxPage: json.currentMaxPage,
      };
    } else {
      return {};
    }
  }

  /** Clears the local flow progress */
  static removeLocalProgress() {
    localStorage.removeItem(FLOW_STORAGE_KEY);
  }

  /**
   * Gets the flow id of the current flow
   * @returns {Number|null}
   */
  static getFlowId() {
    const json = JSON.parse(localStorage.getItem(FLOW_STORAGE_KEY));
    return json ? json.flowId : null;
  }

  static isPostFlow() {
    return !!FlowService.getParsedPostFlowIdFromCookie();
  }

  static getPostFlowIdFromCookie() {
    return Cookies.get(FLOW_POST_EVENT_FLOW_ID);
  }

  static getParsedPostFlowIdFromCookie() {
    return parseInt(FlowService.getPostFlowIdFromCookie(), 10);
  }

  /**
       Gets the Platgen flag that was set by Onboarding
       @returns {boolean}
     */
  static getPlatgenFlag() {
    const value = Cookies.get(FLOW_LINKED_TO_PLATGEN);
    if (typeof value === 'undefined') {
      return false;
    }
    return value.toLowerCase() === 'true';
  }

  /**
       Gets the Platgen flag that was set by login.
       If this function returns null, it means that this flag was never set,
       for instance, if this user signed up on their first time through the
       flow.
       @returns {boolean|null}
     */
  static getPlatgenFlagFromLogin() {
    const value = Cookies.get(FLOW_LINKED_TO_PLATGEN_LOGIN);
    if (typeof value === 'undefined') {
      return null;
    }
    // Turn "True|1:5ec023:c001c13" into true; slice off the signature.
    return value.split('|')[0].toLowerCase() === 'true';
  }

  static checkIfFlowChanged(flowId) {
    const flowStorageItem = localStorage.getItem(FLOW_STORAGE_KEY);

    if (flowStorageItem && flowId) {
      const currentFlow = JSON.parse(flowStorageItem).flowId;
      if (currentFlow !== flowId) {
        return true;
      }
    }
    return false;
  }
}

export default FlowService;
