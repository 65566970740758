import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import ConditionalRender from '../conditional-render';
import Actions from '../../actions/api';
import { getProductByUrl, getProductId } from '../../../../helpers/product';
import { isDevHostname } from '../../../../helpers/isDevHostname';
import { getDevicePlatform } from '../../../../helpers/devicePlatform';
import { logError } from '../../../../analytics/logger/logger';

// New Auth Page component
// Each page on the new authentication mechanism (login, reset password, forgot password, etc) will extend this page
class GenericNewAuthenticationPage extends React.Component {
  constructor(props) {
    super(props);
    const productName = getProductByUrl();
    this.state = {
      submitting: false,
      shouldValidate: false,
      product: productName,
      productNameCapitalized:
        productName.charAt(0).toUpperCase() + productName.slice(1),
      forgotError: false,
      resetError: false,
      email: '',
      password: '',
      confirmPassword: '',
      pageLoading: false,
      forgotSuccess: false,
      resetSuccess: false,
      passwordsNoMatch: false,
      shouldShowNHS: false,
      nhsError: false,
    };

    this.errorFields = null;
    this._handleURL();
    document.body.setAttribute('data-new-authentication-component', 'true');
  }

  async handleAuth0Response(code, state) {
    const loginFields = {
      product_id: Number(window.product_id),
      oidc_code: code,
      oidc_state: state,
      device_platform: getDevicePlatform(),
      oidc_session_token: localStorage.getItem('oidcSessionToken'),
    };

    const requestUrl = `/api/service_method_proxy/GeneralLogin/1/login_with_oidc_auth_code`;
    try {
      const data = await Actions.doRequest(requestUrl, loginFields, 'POST');
      /**
       * response status could always be 200, even if there is an error.
       * Here we check if the response > result > status has either
       * a 'redirect' or 'error'.
       * Based on status, we do check then execute 1 of the 2 options.
       * IF a new status is returned, we do nothing letting FE know
       * that an update is required.
       */
      if (data.result.status === 'redirect') {
        window.location.assign(data.result.redirect);
      } else {
        this.setState({
          isAuth0Loading: false,
          nhsError: true,
        });
        this.props.history.replace(window.location.pathname);
      }
    } catch (e) {
      logError(e);
      this.setState({
        isAuth0Loading: false,
        nhsError: true,
      });
      this.props.history.replace(window.location.pathname);
    }
  }

  componentDidMount() {
    Actions.doRequest(
      '/api/service_method_proxy/ClientGateway/1/get_feature_flags'
    ).then(data => {
      if (data?.result?.sleepio_nhs_login_enabled) {
        this.setState({ shouldShowNHS: true });
      }
    });
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const state = params.get('state');
      const error = params.get('error');
      if (code && state) {
        this.setState({ isAuth0Loading: true });
        this.handleAuth0Response(code, state);
      } else if (error) {
        logError(error);
        this.setState({
          isAuth0Loading: false,
          nhsError: true,
        });
      } else {
        this._handleURL();
        this.props.history.replace(window.location.pathname);
      }
    }
  }

  componentDidUpdate() {
    // call submit if no errors on page
    if (
      this.state.submitting &&
      this.errorFields &&
      !this.errorFields.length &&
      !this.state.shouldValidate
    ) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.callAction('email').then(ignore => {});
    }
  }

  renderFooter(showNeedHelp = true) {
    // For Reset Password & Check you email views
    // The "Need help?" is not part of the Figma designs.
    return (
      <div
        id="sl-new-footer"
        className={'flex ' + (showNeedHelp ? '' : 'sl-new-footer-short')}
      >
        <div className="flex column">
          <ConditionalRender if={showNeedHelp}>
            <div className="sl-new-footer--contact">
              Need help? Contact{' '}
              <a
                href="mailto:hello@sleepio.com"
                target="_blank"
                rel="noreferrer"
              >
                hello@sleepio.com
              </a>
            </div>
          </ConditionalRender>

          <div>&copy; {new Date().getFullYear()} Big Health</div>
          <div>
            <span className="product-name">
              {this.state.product}&trade;&nbsp;
            </span>
            Onboarding v{this._getProductVersion()}
          </div>
        </div>
      </div>
    );
  }

  // check errors and check if should submit after an input value has changed
  inputValidationChanged(fieldName, isValid) {
    const errorFields = this.errorFields || [];
    const foundAtIndex = errorFields.indexOf(fieldName);
    if (!isValid && foundAtIndex === -1) {
      errorFields.push(fieldName);
    }

    if (isValid && foundAtIndex > -1) {
      errorFields.splice(foundAtIndex, 1);
    }

    const submitting = this.state.submitting && !errorFields.length;

    if (
      errorFields !== this.state.errorFields ||
      submitting !== this.state.submitting
    ) {
      this.errorFields = errorFields;
      this.setState({ submitting: submitting, shouldValidate: false });
    }
  }

  onFormAttemptSubmit(event) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    event ? event.preventDefault() : () => {};
    this.setState({
      submitting: true,
      shouldValidate: true,
      forgotError: false,
      loginError: false,
      otpError: false,
      otpErrorMessage: '',
      nhsError: false,
    });
  }

  onInputValueChange(name, value) {
    if (this.errorFields) {
      const foundAtIndex = this.errorFields.indexOf(name);
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      foundAtIndex > -1 ? this.errorFields.splice(foundAtIndex, 1) : () => {};
    }
    this.setState({
      [name]: value,
    });
  }

  // call the actual submit action for the form on this page
  callAction(payload, url, idp_name, next) {
    return Actions.doRequest(url, payload, 'POST');
  }

  // make sure that product id, next url, google analytics id exist in local storage
  // if said values are found in the url, they will be saved in local storage
  // we use local storage instead of solely relying on the url since these values get cleaned from the url right after
  // the page load. in case of a refresh, they would be empty.

  _handleURL() {
    const localDataURL = window.localStorage.getItem(
      'sl-new-authentication-load-url'
    );
    // For IE compatibility, create URLSearchParams with empty string.
    this.urlData = new URLSearchParams(
      this.props.location.search || localDataURL || ''
    );
    window.localStorage.setItem(
      'sl-new-authentication-load-url',
      `?${this.urlData.toString()}`
    );
    if (window.product_id) {
      window.localStorage.setItem(
        'sl-new-authentication-product-id',
        window.product_id
      );
    }
    if (window.app_version) {
      window.localStorage.setItem(
        'sl-new-authentication-app-version',
        window.app_version
      );
    }

    if (window.ga_id) {
      window.localStorage.setItem('sl-new-authentication-ga-id', window.ga_id);
    }

    if (this.urlData.get('token')) {
      window.localStorage.setItem(
        'sl-new-authentication-reset-token',
        this.urlData.get('token')
      );
    }
    if (this.urlData.get('feat_show_nhs')) {
      this.setState({ shouldShowNHS: true });
    }
  }

  _getProductId() {
    return getProductId(this.state.product);
  }

  _getProductVersion() {
    const appVersion = localStorage.getItem(
      'sl-new-authentication-app-version'
    );
    if (!appVersion) {
      const errorMessage = '_getProductVersion(): App version is unset';
      if (isDevHostname(window?.location?.hostname)) {
        // Allow render to continue, but throw error
        setTimeout(() => {
          throw Error(errorMessage);
        });
      } else {
        console.error(errorMessage);
      }
    }
    return appVersion;
  }
}

GenericNewAuthenticationPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.any,
};

export default GenericNewAuthenticationPage;
