import { css } from '@emotion/react';
import { MIDDLE_BLUE } from '../../styles/colors';
import { BHTheme } from '../../types/bh-themes.types';

// This constant is for making sure that the loader component will always layer over the snap engage button on the bottom of the screen.
// Snap Engage is a 3rd party vendor service that has a help button that has a z-index of 999998.
export const loaderZIndex = 999999;

export const loaderStyle = (theme: BHTheme) =>
  css({
    width: '54px',
    height: '54px',
    paddingLeft: theme.padding.MEDIUM,
  });

export const loaderContainerStyle = () =>
  css({
    width: '100%',
    height: '100vh',
    backgroundColor: MIDDLE_BLUE,
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: loaderZIndex,
  });

export const loaderGroupStyle = () =>
  css({
    position: 'absolute',
    right: '0',
    left: '0',
    top: '30%',
    textAlign: 'center',
  });

export const loaderStarsStyle = () =>
  css({
    position: 'absolute',
  });
