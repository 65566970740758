import { InteractiveComponentAction } from './interactive-component-v2.types';
import FlowService from '../../services/flow-service';

/**
 * shouldShowSignupWithExistingUser
 * This function figures out when an interactive component should show the button to signup with an existing account.
 * We don't always want to show the button cause the interactive component can be used to just check eligibility
 */
export const shouldShowSignupWithExistingUser = (
  action: InteractiveComponentAction
) => {
  return Boolean(
    window.feature_switch_multi_product_signup &&
      FlowService.getPlatgenFlag() &&
      (action.method === 'create' ||
        action.method === 'check_eligibility_for_organization_and_sign_up')
  );
};
