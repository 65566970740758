import AnswerService from '../../../services/answers-service';
import getConfig from '../../../config';

export const ensureGenderOptionsExist = function (flowSteps) {
  const genderSemanticIds = getConfig().semanticIds.genderQuestion || [];
  let existingGenderOptions = AnswerService.getGenderQuestionOptions();

  if (!existingGenderOptions || !existingGenderOptions.length) {
    const genderQuestion = findGenderQuestionInFlowBySemanticIds(
      genderSemanticIds,
      flowSteps
    );
    existingGenderOptions = getGenderOptionsFromQuestion(genderQuestion);
    AnswerService.saveGenderQuestionOptions(existingGenderOptions);
  }
};

function findGenderQuestionInFlowBySemanticIds(semanticIds = [], flowSteps) {
  let foundQuestion = {};
  flowSteps.forEach(step => {
    step.pages.forEach(page => {
      const result = page.entity.find(
        entity => semanticIds.indexOf(entity.semantic_id) > -1
      );
      foundQuestion = result ? result : foundQuestion;
    });
  });

  return foundQuestion;
}

function getGenderOptionsFromQuestion(genderQuestion = {}) {
  const content = genderQuestion.content || [];
  const langContent = content.find(c => c.lang === 'en') || {};
  const answers = langContent.answers || [];

  return answers.map((answer, idx) => {
    return { ...answer, idx };
  });
}
