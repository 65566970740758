import { useTheme } from '@emotion/react';
import {
  FunctionComponent,
  useCallback,
  useMemo,
  Fragment,
  KeyboardEventHandler,
} from 'react';
import { isEventEnterKey, isEventSpaceKey } from '../../helpers/event-helpers';
import {
  isMappedQuestion,
  MappedQuestionContentAnswer,
  QuestionContent,
  QuestionContentAnswer,
} from '../../types/questions.types';
import CheckboxInput from '../checkbox/themed-checkbox.component';
import QuestionGroupComponent from '../question-group-component/question-group.component';
import { checkRowLabel } from './style';

export type CheckRowProps = {
  referenceAnswer: QuestionContentAnswer | MappedQuestionContentAnswer;
  label?: string;
  question_id: QuestionContent['question_id'];
  isMapped: boolean;
  isChecked: boolean;
  onChange: (
    toggledAnswer: QuestionContentAnswer,
    activeDecendentId?: string
  ) => void;
  belongsTo: string;
  actions: any;
  onAnswer?: () => void; // question-mapped.component only, this needs to go away
};

export const CheckRow: FunctionComponent<CheckRowProps> = ({
  referenceAnswer,
  label,
  question_id,
  isChecked,
  onChange,
  isMapped,
  belongsTo,
  actions,
  onAnswer,
}) => {
  const theme = useTheme();
  const htmlFor = `checkrow-${question_id}_${referenceAnswer.id}`;

  const style = useMemo(() => {
    if (!isChecked) {
      return {
        border: theme.borders.defaultBorder,
      };
    }

    return {
      border: theme.borders.defaultActiveBorder,
      // below accounts for active border being 1px bigger. We should use a different strategy like explicit border-width settings if this pattern ever changes.
      padding: `calc(${theme.padding.MEDIUM} - 1px)`,
    };
  }, [isChecked, theme]);

  const _onChange = useCallback(() => {
    onChange(referenceAnswer, htmlFor);
  }, [htmlFor, referenceAnswer, onChange]);

  const _onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (isEventEnterKey(event) || isEventSpaceKey(event)) {
        onChange(referenceAnswer, htmlFor);
      }
    },
    [htmlFor, referenceAnswer, onChange]
  );

  return (
    <div
      tabIndex={0}
      css={checkRowLabel}
      style={style}
      onKeyDown={_onKeyDown}
      aria-checked={isChecked}
      onClick={_onChange}
    >
      <CheckboxInput
        tabIndex={-1}
        id={htmlFor}
        name={htmlFor}
        isChecked={isChecked}
        onChange={_onChange}
        onKeyDown={_onKeyDown}
      />
      <label
        css={{ cursor: 'pointer' }}
        onClick={event => event.stopPropagation()}
        htmlFor={htmlFor}
      >
        {label ?? referenceAnswer.text}
      </label>

      {onAnswer && isMapped && isMappedQuestion(referenceAnswer) && (
        <Fragment>
          <QuestionGroupComponent
            hidden
            belongsTo={belongsTo}
            content={referenceAnswer.selected}
            actions={actions}
            onAnswer={onAnswer}
            selected={isChecked}
          />
          <QuestionGroupComponent
            hidden
            belongsTo={belongsTo}
            content={referenceAnswer.not_selected}
            actions={actions}
            onAnswer={onAnswer}
            selected={!isChecked}
          />
        </Fragment>
      )}
    </div>
  );
};

export default CheckRow;
