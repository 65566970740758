import { memo } from 'react';
import { messageStyle, wrapperStyle } from './style';

export type ErrorMessagePropType = {
  errorMessage?: string;
  className?: HTMLDivElement['className'];
};

export const ErrorMessage_TEST_ID = 'error-message-component';
const ErrorMessage = ({ errorMessage, className }: ErrorMessagePropType) => {
  if (!errorMessage) {
    return null;
  }
  return (
    <div
      className={className}
      css={wrapperStyle}
      data-testid={ErrorMessage_TEST_ID}
    >
      <p css={messageStyle}>{errorMessage}</p>
    </div>
  );
};

export default memo(ErrorMessage);
