import { useAppSelector } from '../../hooks/use-app-react-redux';
import { getProductDisplayName } from '../../helpers/product';
import { SerializedStyles } from '@emotion/react';
import { FunctionComponent } from 'react';
import { BHTheme } from '../../types/bh-themes.types';

export const COPYRIGHT = (year: number) => `${year} Big Health.`;
export const PRODUCT_VERSION = (productName: string, version: string) =>
  `${productName} Onboarding Version ${version}`;

type FooterV2Props = {
  id?: string;
  style?: (theme: BHTheme) => SerializedStyles;
};

export const FooterV2: FunctionComponent<FooterV2Props> = ({ id, style }) => {
  const year = new Date().getFullYear();
  const productName = getProductDisplayName();
  const version = useAppSelector(state => state.appReducer.version);

  return (
    <div id={id ? id : ''} css={style}>
      <div data-product={productName}>
        <div>&copy; {COPYRIGHT(year)}</div>
        <div>{PRODUCT_VERSION(productName, version)}</div>
      </div>
    </div>
  );
};
