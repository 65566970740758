import {
  INTERACTIVE_V2_INPUTS_VALIDATE,
  INTERACTIVE_V2_SHOWING_EMPLOYEE_ID,
  INTERACTIVE_V2_ACTION_CALLBACK,
  INTERACTIVE_V2_DISABLE_FIELDS_EXCEPT_EMPLOYEE_ID,
  INTERACTIVE_V2_SET_PASSED_PROP_TO_FALSE,
} from '../constants/action-types';
import ApiActions from './api';
import { Dispatch } from 'redux';

export function setPassedPropToFalse() {
  return function (dispatch: Dispatch) {
    return dispatch({ type: INTERACTIVE_V2_SET_PASSED_PROP_TO_FALSE });
  };
}

export function validateInteractiveInputs() {
  return function (dispatch: Dispatch) {
    return dispatch({ type: INTERACTIVE_V2_INPUTS_VALIDATE });
  };
}

export function notifyEmployeeIdVisible(value: boolean) {
  return function (dispatch: Dispatch) {
    return dispatch({
      type: INTERACTIVE_V2_SHOWING_EMPLOYEE_ID,
      data: !!value,
    });
  };
}

export function notifyEmployeeIdDisableFields(value: string) {
  return function (dispatch: Dispatch) {
    return dispatch({
      type: INTERACTIVE_V2_DISABLE_FIELDS_EXCEPT_EMPLOYEE_ID,
      data: !!value,
    });
  };
}

export function callInteractiveAction(
  entity: string,
  method: string,
  data: any,
  serviceVersion: string,
  requestPath: string
) {
  return function (dispatch: Dispatch) {
    // if request path does not hit the /api/ - which in turn hits the services - then make the request directly to said path
    // since it means it's an endpoint on the webapp
    if (requestPath.indexOf('/api/') === -1) {
      return ApiActions.makeRequest({
        request: {
          url: `${requestPath}/`,
          method: 'POST',
          body: JSON.stringify(data),
        },
        callback: (data: any) => {
          const result = data?.result || data?.response;
          // eslint-disable-next-line max-len
          return dispatch({
            type: INTERACTIVE_V2_ACTION_CALLBACK,
            data: result,
            method: method,
            serviceVersion: serviceVersion,
          });
        },
      }).catch(error => {
        console.log(error);
      });
    }

    return ApiActions.post({
      entity,
      method,
      data,
      callback: false,
      serviceVersion,
    })
      .then(data => {
        const result = data.result;
        // eslint-disable-next-line max-len
        return dispatch({
          type: INTERACTIVE_V2_ACTION_CALLBACK,
          data: result,
          method: method,
          serviceVersion: serviceVersion,
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
}
