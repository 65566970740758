/** from existing "unthemed" styles */
export const ERROR = '#fd717f';
export const LIGHT_BLUE = '#29c3e5';
export const DARK_BLUE = '#003366';
export const INTERACTIVE_V2_LIGHT_BLUE = '#ade0f9';
export const CTA_GREEN = '#4db400';

/** from theming Figma */
export const SLEEPIO_NAVY = '#163258';
export const PALE_GRAY = '#F7F6F5';
export const MIDDLE_BLUE = '#A5CBFF';
export const SALMON = '#FF8E75';
export const SALMON_DARK = '#FF7152';
export const SALMON_LIGHT = 'rgba(255, 142, 117, 0.8)'; // not a hex, might have to deal with opacity issues depending on bg
export const WHITE = '#FFFFFF';
export const SLEEP_SCORE_GREEN = '#19AB46';
export const SLEEP_SCORE_RED = '#FD5A3A';
export const SLEEP_SCORE_YELLOW = '#F8A500';
export const DISABLED_GRAY = 'rgba(0, 0, 0, 0.4)'; // not a hex, might have to deal with opacity issues depending on bg
export const SLATE_GRAY = '#6A717D';
export const PERIWINKLE = '#BCC4FF';
export const INPUT_OUTLINE_GRAY = '#DFE1E6';
export const OFF_WHITE = '#FBFCFD';
export const THEMED_ERROR_RED = '#B9230F';
export const THEMED_ERROR_LIGHT_RED = '#F6E0DD';
export const SLEEPIO_LIGHT_BLUE = '#D4E6FF';

/** New colors, not from theme or from unthemed styles */
export const THEMED_ICON = '#8896A9';

/** Daylight colors */
// https://www.figma.com/file/CQl30xVIupzYnpQPgKGmEt/Daylight-2.0-Design-System?node-id=3%3A83&t=GHMrEYVgCOGNaZpP-0
export const DAYLIGHT_TEXT_COLOR = '#363334';
export const DAYLIGHT_LINK_BLUE = '#2467B0';
