import { Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const questionProgressTrackerStyle = (theme: Theme) =>
  css({
    padding: theme.padding.MEDIUM,
    textAlign: 'center',
    fontSize: theme.fonts.fontSizes.MEDIUM,
    color: theme.fonts.fontColors.PRIMARY,
    fontWeight: theme.fonts.fontWeights.REGULAR,
  });
