import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import interactiveConfig from '../triggers/triggers-config';
import * as interactiveV2Actions from '../../actions/interactive-v2';
import { getUserInfo } from '../../actions/user';
import InteractiveComponentV2Service from '../../services/interactive-component-v2';
import InteractiveInputFactoryComponent from './factory';
import FlowService from '../../services/flow-service';
import UserSession from '../../services/user-session';
import {
  getRequestTypeForActions,
  onSuccessFollowUpRequests,
} from '../triggers/requests';
import { checkCondition } from '../triggers/conditions';
import { ensureGenderOptionsExist } from './utils/gender-options';
import { doRequest } from '../triggers/follow-ups';
import { buildActionPayload } from '../triggers/payload';
import { getAdditionalData } from './utils/inputs';
import { StoreState } from '../../store/configureStore';
import {
  InteractiveComponentInput,
  InteractiveComponentItem,
} from './interactive-component-v2.types';
import { showExistingSignup } from '../../actions/flow';
import {
  InteractiveContent,
  InteractiveContentProps,
} from './interactive-content/interactive-content.component';
import { ThemedInteractiveContent } from './interactive-content/themed.interactive-content.component';

export type InteractiveComponentV2Props = {
  item: InteractiveComponentItem;
  error?: string;
  advance: (
    event:
      | Event
      | { initiatingStepAndPage: { currentStep: number; currentPage: number } },
    isSkipped: boolean
  ) => void;
  showSignupWithExistingAccount?: () => void;
} & PropsFromRedux &
  RouteComponentProps;

type InteractiveComponentV2State = {
  loading: boolean;
  submitting: boolean;
  errorFields?: unknown[] | null;
  passed: boolean;
  componentError: { error?: string };
  componentFieldErrors: any;
  disabledSubmitButton: boolean;
  componentAlerts: any[];
  usedSSO: boolean;
  usedSAML: boolean;
  parameterRenaming: any;
  parameterValueChange: any;
  allRequiredInputsHaveValue: boolean;
  error?: string;
  revalidateInputs?: boolean;
};

export const withInteractiveLogic = (
  WrappedComponent: FunctionComponent<InteractiveContentProps>
) => {
  return class InteractiveComponentV2 extends React.Component<
    InteractiveComponentV2Props,
    InteractiveComponentV2State
  > {
    scrollToErrorTimeout: any;
    requiredInputsWithoutValue: any[];
    /**
     * Interactive v2 component which renders an entity with the interactive-v2-input type
     * @param {Object} props - React Component properties
     */
    constructor(props: InteractiveComponentV2Props) {
      super(props);

      // temp user date will be populated in local storage in case of an SSO signup
      const tempUserData = UserSession.getTempUserData() || {};

      // user data represents existing values in local storage
      const userData = UserSession.getUserData() || {};

      this.scrollToErrorTimeout = null;

      this.state = {
        loading: false,
        submitting: false,
        errorFields: null,
        passed: false,
        componentError: {},
        componentFieldErrors: {},
        disabledSubmitButton: true,
        componentAlerts: [],
        usedSSO:
          !!Object.keys(tempUserData).length &&
          ['test', 'evive', 'rally'].indexOf(tempUserData.idp_name) === -1,
        usedSAML:
          (!!Object.keys(tempUserData).length &&
            ['test', 'evive', 'rally'].indexOf(tempUserData.idp_name) > -1) ||
          ['test', 'evive', 'rally'].indexOf(userData.idp_name) > -1,
        parameterRenaming: interactiveConfig.REQUESTS.PARAMETER_RENAMING,
        parameterValueChange: interactiveConfig.REQUESTS.PARAMETER_VALUE_CHANGE,
        allRequiredInputsHaveValue: false,
      };

      // initialize list of all requiredInputsWithoutValue
      const content = props.item.content.find(c => c.lang === 'en');
      const requiredSubmittableInputs =
        content?.inputs?.filter(input => {
          return (
            interactiveConfig.NO_SUBMIT_INPUT_TYPES.indexOf(input.type) ===
              -1 &&
            interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(
              input.field_name
            ) === -1 &&
            getAdditionalData(input).required
          );
        }) || [];
      this.requiredInputsWithoutValue = requiredSubmittableInputs.map(
        input => input.field_name
      );

      // eslint-disable-next-line react/prop-types
      InteractiveComponentV2Service.setInteractiveComponentId(props.item.id);
      ensureGenderOptionsExist(this.props.flow.flow.steps);
    }

    // get the current component state based on incoming props
    static getDerivedStateFromProps(
      props: InteractiveComponentV2Props,
      state: InteractiveComponentV2State
    ) {
      const actionResponse = props.actionResponse.body || {};
      const componentError = props.actionResponse.body || {
        error: props.actionResponse.error && props.actionResponse.message,
      };
      const componentFieldErrors = actionResponse.field_errors
        ? actionResponse.field_errors
        : {};
      const componentAlerts = actionResponse.alerts
        ? actionResponse.alerts
        : [];
      // eslint-disable-next-line react/prop-types
      const passed = props.passed;

      if (
        componentError !== state.componentError ||
        componentFieldErrors !== state.componentFieldErrors ||
        componentAlerts !== state.componentAlerts ||
        passed !== state.passed
      ) {
        return {
          componentError,
          componentFieldErrors,
          componentAlerts,
          passed,
          // eslint-disable-next-line react/prop-types
          revalidateInputs:
            !Object.keys(componentFieldErrors).length &&
            (state.error || props.error), // trigger
          // validation on inputs if no errors came back to clear the state-errors per input component
        };
      }

      return null;
    }

    componentDidMount() {
      // on component mount, check if sso was used. if so, attempt to submit the form
      // (sso is currently done via a redirect, so the component will mount again when redirected from SSO provider
      if (this.state.usedSSO) {
        return this.onFormAttemptSubmit();
      }
    }

    componentDidUpdate() {
      const { user } = this.props.user;
      let passed = false;

      // if sso was used, ignore the password and remove it from the required inputs list
      if (this.state.usedSSO) {
        const passwordFoundAtIndex = this.requiredInputsWithoutValue.indexOf(
          interactiveConfig.INPUT_FIELD_NAMES.PASSWORD[0]
        );
        passwordFoundAtIndex !== -1
          ? this.requiredInputsWithoutValue.splice(passwordFoundAtIndex, 1)
          : // eslint-disable-next-line @typescript-eslint/no-empty-function
            () => {};
      }

      if (this.props.user.userInitialized) {
        const actionMethods = this.props.actionMethod
          ? [this.props.actionMethod]
          : this.props.item.action.map(a => a.method);
        // user may not have to fill in any data - check first

        passed = !!actionMethods.length;
        const eligibility = this.props.actionResponse.eligibility
          ? this.props.actionResponse.eligibility
          : this.props.actionResponse;

        // check if the conditions are all fulfilled
        actionMethods.forEach(method => {
          passed =
            passed && checkCondition({ ...user, ...eligibility }, method);
        });
      }

      if (passed && !this.state.loading) {
        this.setState({ loading: true });

        // HACK: To prevent 'moveOn' below from lostupdating the hash, we
        // save it here, and do a Compare and Swap operation in advance.
        const initiatingStepAndPage = FlowService.loadLocalProgress();

        this._onPassSuccess().then(() => {
          const moveOn = () => {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            if (!this.state.usedSAML) {
              UserSession.removeTempUserData();
            }
            this.props.interactiveV2Actions.setPassedPropToFalse();

            this.props.advance(
              {
                initiatingStepAndPage,
              },
              false
            );
            // WARNING: Can't call setState on an unmounted component.
            setTimeout(() => this.setState({ loading: false }), 10);
          };

          // in case of sso, wait a bit before continuing
          this.state.usedSSO ? setTimeout(moveOn, 1000) : moveOn();
        });
      }

      // if the state is marked as submitting (clicking on Sign up button) and there are no errors after
      // the component validated, call the interactive-v2 component action
      if (
        this.state.submitting &&
        this.state.errorFields &&
        !this.state.errorFields.length
      ) {
        this.setState({ submitting: false });
        this.callComponentAction();
      }

      if (this.state.revalidateInputs) {
        this.props.interactiveV2Actions.validateInteractiveInputs();
      }
    }

    callComponentAction() {
      // @todo: ENR-1156 this needs to be refactored to handle a case for each type of action
      if (!this.state.loading) {
        this.setState({ loading: true });
        let path;
        let entity;
        let method;

        const action = this.props.item.action[0];
        const request = getRequestTypeForActions(action);
        const content = this.props.item.content.find(c => c.lang === 'en');

        // get the list of inputs that have value (excludes buttons, static components...
        const inputs =
          content?.inputs?.filter(
            i => interactiveConfig.NO_SUBMIT_INPUT_TYPES.indexOf(i.type) === -1
          ) || [];

        // build the payload to send on the action call
        const payload = buildActionPayload(
          inputs,
          action,
          this.state.parameterRenaming,
          this.state.parameterValueChange
        );
        switch (request.type) {
          case 'form_post':
            path = `${request.path}?next=${window.location.pathname}`;
            console.log(path);
            // make form-post here
            break;
          case 'ajax':
            entity = request.service;
            method = request.method;

            // call the action ...
            // @todo this type is getting mangled do to the fact that we are using thunk and we likely will need
            //  to type out the entirety of reducers and actions and pull the type directly from the store
            (
              this.props.interactiveV2Actions.callInteractiveAction(
                entity,
                method,
                payload,
                action.version,
                request.path
              ) as unknown as Promise<void>
            )
              .then(() => {
                // ... then get the user info from the server to make sure all information is up-to-date
                this.props.getUserInfo({
                  cb: () => this.setState({ loading: false }),
                });
              })
              .catch(() => this.setState({ loading: false }));
            break;
          default:
            break;
        }
      }
    }

    // when validating, each input calls this function with the fieldName and a boolean flag if there is an error or not
    // we want each individual input to handle the validation instead of having all the logic crammed in the parent
    // component
    inputValidationChanged(fieldName: string, hasErrors: boolean) {
      const errorFields = this.state.errorFields || [];
      const foundAtIndex = errorFields.indexOf(fieldName);
      if (hasErrors && foundAtIndex === -1) {
        errorFields.push(fieldName);
      }

      if (!hasErrors && foundAtIndex > -1) {
        errorFields.splice(foundAtIndex, 1);
      }

      const submitting = this.state.submitting && !errorFields.length;

      if (errorFields.length === 0) {
        this.setState({ disabledSubmitButton: true });
      } else {
        this.setState({ disabledSubmitButton: false });
      }

      if (
        errorFields !== this.state.errorFields ||
        submitting !== this.state.submitting
      ) {
        this.setState({ errorFields: errorFields, submitting: submitting });
      }
    }

    onFormAttemptSubmit(event?: Event) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      event ? event.preventDefault() : () => {};
      this.setState({ submitting: true });

      // when there is an attempt to submit the form, trigger a validation for all the inputs
      this.props.interactiveV2Actions.validateInteractiveInputs();
    }

    render() {
      const content = this.props.item.content.find(c => c.lang === 'en');
      const headerText = content?.header_help_text;
      const instructionsHeaderCvsStyle = content?.instructions_header_cvs_style;
      const { inputComponents, fallbackAlerts } =
        this._getInputComponentsAndFallbackAlerts();

      return (
        <WrappedComponent
          item={this.props.item}
          name={this.props.item.content?.find(c => c.lang === 'en')?.name}
          headerText={headerText}
          dataCvsStyle={instructionsHeaderCvsStyle}
          inputComponents={inputComponents}
          fallbackAlerts={fallbackAlerts}
          componentAlerts={this.state.componentAlerts}
          componentError={this.state.componentError}
          errorFields={this.state.errorFields}
          onShowExistingSignupClick={() => this.props.showExistingSignup(true)}
        />
      );
    }

    _getInputComponentsAndFallbackAlerts() {
      // render each individual input component
      // also pass any error that may have come from the eligibility service

      const content = this.props.item.content.find(c => c.lang === 'en');
      const inputs = content?.inputs || [];
      let alerts = JSON.parse(JSON.stringify(this.state.componentAlerts));
      const inputComponents = inputs.map(input => {
        const error = this._getComponentFieldError(input.field_name);
        const componentAlerts = this._getComponentAlerts(alerts, input);
        alerts = alerts.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (alert: any) =>
            !componentAlerts.find((a: any) => a.type === alert.type)
        );

        const isDisabled =
          interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(
            input.field_name
          ) === -1 && this.props.disableAllExceptEmployeeId;

        return (
          <InteractiveInputFactoryComponent
            {...input}
            interactiveComponentError={this.state.componentError}
            fieldError={error}
            isDisabled={isDisabled}
            alerts={componentAlerts}
            shouldValidate={
              this.props.shouldValidate &&
              interactiveConfig.NO_VALIDATION_INPUT_TYPES.indexOf(
                input.type
              ) === -1
            }
            key={input.id}
            usedSSO={this.state.usedSSO}
            usedSAML={this.state.usedSAML}
            allRequiredInputsHaveValue={
              this.state.allRequiredInputsHaveValue &&
              this.state.disabledSubmitButton
            }
            requiredInputValueStateChanged={this._requiredInputValueStateChanged.bind(
              this,
              input.field_name
            )}
            loading={
              interactiveConfig.INPUT_FIELD_NAMES.SUBMIT.indexOf(input.type) >
              -1
                ? this.state.loading
                : false
            }
            onFormAttemptSubmit={this.onFormAttemptSubmit.bind(this)}
            inputValidationChanged={this.inputValidationChanged.bind(this)}
          />
        );
      });

      return { inputComponents, fallbackAlerts: alerts };
    }

    _getComponentAlerts(alerts: any[], input: InteractiveComponentInput) {
      // get alerts shown as part of static components
      return alerts.filter(alert => {
        const usedForErrors = !!input.options.find(
          o => o.field === 'used_for_error' && o.value
        );
        // eslint-disable-next-line max-len
        return (
          interactiveConfig.INPUT_FIELD_NAMES.STATIC.indexOf(input.type) > -1 &&
          alert.type === input.static.semantic_id &&
          usedForErrors
        );
      });
    }

    _getFallbackAlerts(fieldNames: string) {
      // get individual input errors that come back from the eligibility service cluster
      return this.state.componentAlerts.filter(
        alert => fieldNames.indexOf(alert.type) === -1
      );
    }

    _getComponentFieldError(fieldName: string) {
      Object.keys(this.state.parameterRenaming).forEach(key => {
        if (key === fieldName) {
          // @todo - this is kind of weird, field name is expected to be a string but has the capacity to be set as an object
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          fieldName = this.state.parameterRenaming[key];
        }
      });

      if (Object.keys(this.state.componentFieldErrors).length) {
        const errors = this.state.componentFieldErrors[fieldName];
        return errors ? errors[0] : undefined;
      }
    }

    _requiredInputValueStateChanged(fieldName: string, hasValue = false) {
      const foundAtIndex = this.requiredInputsWithoutValue.indexOf(fieldName);
      if (!hasValue && foundAtIndex === -1) {
        this.requiredInputsWithoutValue.push(fieldName);
      }

      if (hasValue && foundAtIndex > -1) {
        this.requiredInputsWithoutValue.splice(foundAtIndex, 1);
      }

      let submitting = this.state.submitting;
      if (this.requiredInputsWithoutValue) {
        submitting = false;
      }

      this.setState({
        allRequiredInputsHaveValue: !this.requiredInputsWithoutValue.length,
        submitting: submitting,
      });
    }

    _onPassSuccess() {
      return new Promise<void>(resolve => {
        if (this.props.actionMethod) {
          const followUpRequests =
            onSuccessFollowUpRequests[this.props.actionMethod];
          if (Object.keys(followUpRequests || {}).length) {
            return doRequest(this.props, followUpRequests)
              .then(() => resolve())
              .catch(error => console.log(error));
          }
        }
        return resolve();
      });
    }
  };
};

const mapStateToProps = (state: StoreState) => ({
  user: state.userReducer,
  validation: state.validationReducer,
  flow: state.flowReducer,
  ...state.interactiveV2Reducer,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  interactiveV2Actions: bindActionCreators(interactiveV2Actions, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
  showExistingSignup: bindActionCreators(showExistingSignup, dispatch),
});

type PropsFromRedux = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export const InteractiveComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withInteractiveLogic(InteractiveContent))
);

export const ThemedInteractiveComponent = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withInteractiveLogic(ThemedInteractiveContent))
);
