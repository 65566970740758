import { Fragment, FunctionComponent, useState, useEffect } from 'react';
import interactiveConfig from '../../../../triggers/triggers-config';

import InputRadio from '../../../../input-radio';
import { QuestionContentAnswer } from '../../../../../types/questions.types';

export type GenderOptionProps = {
  id: string;
  items: QuestionContentAnswer[];
  onChange: (arg0: { target: { value: string | undefined } }) => void;
  error: boolean;
  errorText: string;
  value: string;
  includeUnknown: boolean;
  isDisabled: boolean;
  synonyms: any;
};

const GenderOption: FunctionComponent<GenderOptionProps> = ({
  id,
  value,
  items,
  error,
  errorText,
  isDisabled,
  onChange,
  includeUnknown,
}) => {
  // update gender selection when user selects gender
  function genderChanged(itemId: string) {
    setSelectedGender(deriveSelectedGenderObject(itemId));
    onChange({ target: { value: itemId } });
  }

  // massage item data to include 'other' gender option when required
  function _adjustNeededItems(items: QuestionContentAnswer[]) {
    // determine if `items` array includes a gender option of 'other'
    const itemsIncludeUnknown = !!items.find(
      item => item.id === interactiveConfig.GENDER_OPTION_UNKNOWN_ID
    );

    // return the gender option containing 'other'
    const unknownGenderDefaultOption =
      interactiveConfig.DEFAULT_GENDER_OPTIONS.find(
        option => option.id === interactiveConfig.GENDER_OPTION_UNKNOWN_ID
      );

    // if `items` array includes 'other' gender option and `includeUnknown` is false, filter out the option containing 'other'
    items =
      itemsIncludeUnknown && !includeUnknown
        ? items.filter(
            item => item.id !== interactiveConfig.GENDER_OPTION_UNKNOWN_ID
          )
        : items;

    // add `other` gender option if the `items` array DOES NOT include an 'other' gender option and the props request an 'other' gender
    items =
      !itemsIncludeUnknown &&
      includeUnknown &&
      unknownGenderDefaultOption !== undefined
        ? items.concat([unknownGenderDefaultOption])
        : items;

    return items;
  }

  const _items = _adjustNeededItems(items);

  const [selectedGender, setSelectedGender] = useState(
    deriveSelectedGenderObject(value)
  );

  useEffect(() => {
    setSelectedGender(deriveSelectedGenderObject(value));
  }, [value]);

  // find gender object selected in list of genders
  function deriveSelectedGenderObject(itemId: string) {
    const selectedGender = _items.find(item => item.id === itemId);
    return selectedGender || null;
  }

  return (
    <Fragment>
      <InputRadio
        id={id}
        disabled={isDisabled}
        selectedValue={selectedGender}
        items={items}
        handleRadioSelect={genderChanged}
        oneLine={false}
        details={undefined}
        isChecked={false}
      />
      {error && <div className="sl-input-text__error">{errorText}</div>}
    </Fragment>
  );
};

export default GenderOption;
