import OrganizationService from '../../services/org-service';
import * as SignupService from '../../services/signup-service';
import InteractiveV2Service from '../../services/interactive-component-v2';
import UserSession from '../../services/user-session';
import FlowService from '../../services/flow-service';
import { generateEligibilityForProduct } from '../../helpers/eligibility';

export const getStorageParameter = function (paramName) {
  const temporaryData = UserSession.getTempUserData();

  switch (paramName) {
    case 'uuid':
      return SignupService.getUserUuid();
    case 'organization_id':
      return OrganizationService.getOrgId();
    case 'interactive_id':
      return InteractiveV2Service.getInteractiveComponentId();
    case 'product_id':
      return window.product_id;
    case 'flow_id':
    case 'onboarding_flow_id':
      // The paramName of `onboarding_flow_id` was established under TP-149.
      // We've added an additional mapping to FlowService.getFlowId() here
      // to remain consistent with the related param `onboarding_vanity`.
      return FlowService.getFlowId();
    case 'onboarding_vanity':
      // url is of the form `/${product_slug}/${organization_slug}`;
      // We only want the organization_slug
      return FlowService.getCurrentFlowURL().split('/').slice(-1)[0];
    case 'temporary_user_data':
      return temporaryData ? temporaryData : undefined;
    case 'eligibility_for_product':
      return FlowService.getPlatgenFlag()
        ? generateEligibilityForProduct()
        : undefined;
    default:
      return undefined;
  }
};
