import { QuestionTypes } from '../../constants/question-types';

export const QUESTION_TYPES_TO_SHOW_SKIP = [
  QuestionTypes.TEXT,
  QuestionTypes.NUMBER,
  QuestionTypes.ONELINE_TEXT,
  QuestionTypes.ONELINE_NUMBER,
];

// TODO: to delete once themed text input is live, pls refer to https://bighealth.atlassian.net/browse/ENR-2331
export const SL_CONTAINER_TEXT_INPUT_ERROR_CLASSNAME =
  'sl-container-text-input--has-error';

export const FITBIT_QUESTION_TO_SHOW = 'during_night_wake';
