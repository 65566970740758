import { css } from '@emotion/react';
import {
  DESKTOP_QUESTION_MAX_WIDTH,
  DESKTOP_TITLE_MAX_WIDTH,
} from '../../styles/breakpoints';
import { BHTheme } from '../../types/bh-themes.types';
import { SLEEPIO_LIGHT_BLUE } from '../../styles/colors';

const defaultQuestionStyles = (theme: BHTheme) =>
  css({
    color: theme.fonts.fontColors.PRIMARY,
    textAlign: 'center',
    marginTop: theme.padding.LARGE,
    fontSize: theme.fonts.fontSizes.SMALL,
    lineHeight: 1.2,

    a: theme.links.defaultStyle,
  });

export const questionTitleWrapperStyle = css({
  maxWidth: DESKTOP_TITLE_MAX_WIDTH,
  margin: 'auto',
});

export const ttdQuestionTitleWrapperStyle = css({
  alignItems: 'center',
  backgroundColor: SLEEPIO_LIGHT_BLUE,
});

export const questionTitleStyle = (theme: BHTheme) =>
  css(defaultQuestionStyles(theme), {
    fontSize: theme.fonts.fontSizes.TITLE,
  });

export const ttdQuestionTitleStyle = (theme: BHTheme) =>
  css(defaultQuestionStyles(theme), {
    fontSize: theme.fonts.fontSizes.TITLE,
    backgroundColor: SLEEPIO_LIGHT_BLUE,
    paddingTop: 20,
    width: '100%',
  });

export const questionPreTitleStyle = (theme: BHTheme) =>
  css(defaultQuestionStyles(theme), {
    marginTop: 'inherit',
  });

export const questionPostTitleStyle = (theme: BHTheme) =>
  css(defaultQuestionStyles(theme));

export const ttdQuestionPostTitleStyle = (theme: BHTheme) =>
  css(defaultQuestionStyles(theme), {
    backgroundColor: SLEEPIO_LIGHT_BLUE,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  });

export const questionPostAnswerStyle = (theme: BHTheme) =>
  css(defaultQuestionStyles(theme), questionTitleWrapperStyle, {
    marginTop: theme.padding.EXTRA_LARGE,
  });

export const questionWrapperStyle = (theme: BHTheme) =>
  css({
    maxWidth: DESKTOP_QUESTION_MAX_WIDTH,
    margin: `${theme.padding.EXTRA_LARGE} auto 0`,
  });

export const questionWrapperStyleWithoutMargin = () =>
  css({
    maxWidth: DESKTOP_QUESTION_MAX_WIDTH,
    margin: 'auto',
  });

export const textToDownloadTitleStyle = (theme: BHTheme) =>
  css({
    fontSize: theme.fonts.fontSizes.HEADLINE,
    lineHeight: '1.35em',
    color: theme.fonts.fontColors.PRIMARY,
    textAlign: 'center',
    backgroundColor: '#F7F6F5',
    width: '100%',
    justifyContent: 'center',
    paddingTop: 88,
    paddingBottom: 22,
  });
