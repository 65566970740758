import * as EventService from '../../services/event-service';
// we need to import in this way because of an es6 conflict, this might change in the future
import GaService from '../../services/ga-service';
import OrgService from '../../services/org-service';
import AnswersServices from '../../services/answers-service';
import FlowService from '../../services/flow-service';
import * as SignupService from '../../services/signup-service';
import ReportService from '../../services/report-service';
import * as UserSession from '../../services/user-session';

const localServices = {
  EventService: EventService,
  OrgService: OrgService,
  GaService: GaService,
  AnswersService: AnswersServices,
  FlowService: FlowService,
  SignupService: SignupService,
  ReportService: ReportService,
  UserService: UserSession,
};

export const getLocalService = function (serviceName) {
  return localServices[serviceName];
};
