import { FC, Fragment, memo } from 'react';
import { useAppSelector } from '../../../hooks/use-app-react-redux';
import selectors from '../../../selectors';
import FactoryComponent from '../../factory-component';
import { PageContentProps } from '../flow-page.types';
import { isPrimaryColor } from '../../../helpers/is-primary-color';
import CookieConsentComponent from '../../cookie-consent-component/cookie-consent-component';

/**
 * This provides a separate display FC that can be used by react during reconciliation to prevent
 * any needless re-renders of the flow components down the line. It also encapsulates what goes into making
 * a component from the Factory, for the most part. Some further refactoring of the factory may or may not eliminate
 * the need for some of the props here, ie, things like advance or onAnswer or fitBitData could come from
 * redux/thunks later and be pulled in lower in the tree when necessary, rather than passed through here.
 */
const PageContent: FC<PageContentProps> = ({
  content,
  isNextButtonDisabled,
  fitBitData,
  isValidated,
  answers,
  advance,
  onAnswer,
  validateAnswers,
}) => {
  const { currentPage } = useAppSelector(
    selectors.selectFlowReducerCurrentStepPage
  );
  const validator = useAppSelector(
    selectors.selectFlowReducerCurrentStepValidator
  );
  const continueBtnLoading = useAppSelector(
    selectors.selectFlowReducerContinueButtonLoading
  );
  return (
    <Fragment>
      <CookieConsentComponent />
      {content.map((entity, index) => {
        return (
          <div
            key={`${currentPage}-${index}`}
            data-index={index}
            data-semantic-id={entity.semantic_id}
            data-testid="page-content"
            css={isPrimaryColor(entity.semantic_id, index)}
          >
            <FactoryComponent
              type={entity.entity_type}
              item={entity}
              fitBitData={fitBitData}
              isChecked={isValidated}
              answers={answers}
              advance={advance}
              answerValidator={validator}
              onAnswer={onAnswer}
              nextButtonDisabled={isNextButtonDisabled}
              continueBtnLoading={continueBtnLoading}
              validateAnswersOnPage={validateAnswers}
            />
          </div>
        );
      })}
    </Fragment>
  );
};

export default memo(PageContent);
