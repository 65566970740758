import React from 'react';
import './style.scss';
import Actions from '../../../actions/api';
import getConfig from '../../../config';
import PropTypes from 'prop-types';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mappedFitBitData } from '../../../actions/login';
import { onEnterOrSpace } from '../../../components/utils/util-functions';

const FITBIT_REDIRECT_LINK = `${
  getConfig().returnURL || window.location.origin
}/fitbit_redirect`;
const FITBIT_PATH = window.location.pathname;

class FitBitSSOComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  /**
   * @description After the component mounts bind the next action to any dynamically injected Next CTA
   * @returns {Promise}
   */
  componentDidMount() {
    /** After the component has mounted, check if is fitBit login and get access token based on url code */
    const fitBitCode = localStorage['fit_bit_code'];
    if (fitBitCode) {
      this.setState({ loading: true });
      localStorage.removeItem('fit_bit_code');
      const json = {
        request: {
          serverUri: `/auth/fitbit_token/?fitbit_code=${fitBitCode}&fitbit_redirect_uri=${FITBIT_REDIRECT_LINK}`,
          method: 'GET',
        },
        callback: this.getFitBitData.bind(this),
      };
      return Actions.makeRequest(json);
    }
  }

  /**
   * @description get fitBit sleep data
   * @param {Object} res response from fitBit
   * @returns {Promise}
   */
  getFitBitData(res) {
    if (res && res.errors) {
      return Promise.resolve();
    }
    const accessToken = res.response.access_token;
    // const date = new Date().toISOString().slice(0, -5);

    const date = new Date();
    date.setDate(date.getDate() - 7);
    const lastWeek = date.toISOString().slice(0, -5);

    // save token to user details
    const json = {
      request: {
        serverUri: `https://api.fitbit.com/1.2/user/-/sleep/list.json?afterDate=${lastWeek}&offset=0&limit=100&sort=asc`,
        method: 'GET',
        url: '',
        credentials: 'omit',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
      callback: this.fitBitLogged.bind(this, accessToken),
    };

    return Actions.makeRequest(json);
  }

  fitBitLogged(token, res) {
    if (res) {
      this.props.actions.mappedFitBitData(
        res,
        token,
        this.autoFillData.bind(this)
      );
    }
  }

  autoFillData(res) {
    const autoFillData = [];
    this.setState({ loading: false });
    const fitBitArray = res.data;
    const lastWeekData = fitBitArray.slice(Math.max(fitBitArray.length - 7, 0));
    if (res.data.length > 0) {
      for (const key in res.mapping) {
        // eslint-disable-next-line no-prototype-builtins
        if (res.mapping.hasOwnProperty(key)) {
          const elem = {};
          let totalDays = 0;
          elem.semantic = res.mapping[key];
          elem.name = key;
          let isToday;
          let unixFitBitData;
          if (
            key === 'time_out_of_bed' ||
            key === 'time_lights_out' ||
            key === 'time_wake' ||
            key === 'time_into_bed'
          ) {
            let averageHour = 0;
            lastWeekData.forEach(item => {
              if (item[key]) {
                const today = moment(new Date()).format('YYYY-MM-DD');
                const tomorrow = moment(new Date())
                  .add(1, 'days')
                  .format('YYYY-MM-DD');
                isToday = moment(item[key]).format('A');
                const time = moment(item[key]).format('HH:mm');

                // since we need only hours and minutes we can consider all in the same night
                const currentDay = isToday === 'PM' ? today : tomorrow;
                unixFitBitData = moment(
                  `${currentDay} ${time}`,
                  'YYYY-MM-DD HH:mm'
                ).unix();
                averageHour += unixFitBitData;
                totalDays++;
              }
            });
            const averageUnixHour = Math.round(averageHour / totalDays) * 1000;
            isToday = moment(averageUnixHour).format('A') === 'PM';

            // remove one day if average is on the next day
            const averageFinalTime = isToday
              ? averageUnixHour
              : averageUnixHour - 24 * 60 * 60 * 1000;
            elem.value =
              totalDays > 0
                ? moment(averageFinalTime).format('YYYY-MM-DD HH:mm')
                : null;
          } else if (key === 'times_awake_at_night') {
            let averageTimes = 0;
            lastWeekData.forEach(item => {
              if (item[key] || item[key] === 0) {
                averageTimes += item[key];
                totalDays++;
              }
            });
            const intvalue = Math.round(averageTimes / totalDays);
            elem.value =
              totalDays > 0 ? (intvalue < 8 ? intvalue : '8+') : null;
          } else {
            // for minutes_lights_out_to_asleep and minutes_awake_at_night
            let averageMinutes = 0;
            lastWeekData.forEach(item => {
              if (item[key] || item[key] === 0) {
                averageMinutes += item[key];
                totalDays++;
              }
            });
            elem.value =
              totalDays > 0 ? Math.round(averageMinutes / totalDays) : null;
          }
          if (elem.semantic === 'during_night_wake') {
            localStorage.setItem('during_night_wake', elem.value);
          }
          autoFillData.push(elem);
        }
      }
    }
    this.props.mapData(autoFillData);
  }

  render() {
    return (
      <a
        tabIndex={0}
        id="sl-fitbit-sso"
        className={'sl-fitbit-sso'}
        onClick={fitBitRedirect.bind(this)}
        onKeyDown={e => onEnterOrSpace(e, fitBitRedirect)}
      >
        <span className={'sl-fitbit-sso__inner'}>
          {this.state.loading ? 'Mapping ...' : 'Sync with FitBit'}
        </span>
      </a>
    );
  }
}

function fitBitRedirect() {
  // window.location = `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${FITBIT_CLIENT_ID}&redirect_uri=${FITBIT_REDIRECT_LINK}&fitbit_auth&scope=activity%20nutrition%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight`;
  window.location = `/auth/fitbit_redirect?redirect_uri=${FITBIT_REDIRECT_LINK}`;
  localStorage.setItem('current-path', FITBIT_PATH);
}

FitBitSSOComponent.propTypes = {
  actions: PropTypes.object.isRequired,
  fitBitCode: PropTypes.string,
  mapData: PropTypes.func,
  flow: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ mappedFitBitData }, dispatch) };
}

const mapStateToProps = state => ({ flow: state.flowReducer });
export default connect(mapStateToProps, mapDispatchToProps)(FitBitSSOComponent);
