import { css } from '@emotion/react';
import {
  SLATE_GRAY,
  WHITE,
  SLEEPIO_NAVY,
  SALMON,
} from '../../../../styles/colors';

export const MFAVerificationStyles = (isDisabled: boolean) => {
  // this is a horrible way to switch button styles but work around required due to globally applied styles ../input/text/styles.scss
  const buttonStyle = isDisabled
    ? css({
        ['input[type=submit]']: {
          backgroundColor: SLATE_GRAY,
          color: WHITE,
        },
      })
    : css({
        ['input[type=submit]']: {
          backgroundColor: `${SALMON} !important`,
          color: SLEEPIO_NAVY,
          '&:hover': {
            cursor: 'pointer',
          },
        },
      });

  return css(
    {
      margin: '80px auto', // account for position fixed header
      maxWidth: '350px',
      padding: '60px 24px',
      textAlign: 'center',
      p: {
        fontSize: '20px',
        lineHeight: '27px',
        marginBottom: '32px',
        maxWidth: '360px',
        color: SLEEPIO_NAVY,
      },
      buttonStyle,
      a: {
        fontSize: '20px',
        color: SLEEPIO_NAVY,
        textDecoration: 'none',
        fontWeight: 'normal',
      },
      'input[type=submit]': {
        margin: '32px 0px 32px 0px',
        borderRadius: '32px',
      },
    },
    buttonStyle
  );
};

// styles being passed to OTP input node_module
export const otpContainerStyle = () => {
  return {
    display: 'flex',
    gap: '8px',
  };
};

export const otpInputStyle = () => {
  return {
    width: '100%',
    flexGrow: '1',
    height: '70px',
    borderRadius: '8px',
    textAlign: 'center',
  };
};
