import InteractiveComponentV2Service from '../../services/interactive-component-v2';
import UserApiActions from '../../actions/user-api';
import ApiActions from '../../actions/api';
import EventService from '../../services/event-service';

import interactiveConfig from './triggers-config';

import { getLocalService } from './get-local-service';
import { getStorageParameter } from './parameters';
import { InteractiveComponentV2Props } from '../interactive-component-v2/interactive-component-v2.component';
import { InteractiveComponentItem } from '../interactive-component-v2/interactive-component-v2.types';

const _updateUserInfoCallback = function () {
  const data = InteractiveComponentV2Service.getUserInputAll();
  // eslint-disable-next-line max-len
  const paramNames = [
    'id',
    'phone_number',
    'email',
    'first_name',
    'last_name',
    'idp_name',
    'idp_id',
    'employee_id',
    'uuid',
    'product_id',
    'organization_id',
  ];
  const payload: { [key: string]: any } = {};

  paramNames.forEach(p => {
    payload[p] =
      data[p] ||
      InteractiveComponentV2Service.getUserValues(p) ||
      getStorageParameter(p);
  });

  EventService.accountUpdatedEvent(payload);
};

export const doRequest = function (
  props: InteractiveComponentV2Props,
  followUpRequests: { [key: string]: any }
) {
  return new Promise(resolve => {
    const serviceNames = Object.keys(followUpRequests);
    const promiseArray: Promise<void>[] = [];

    const content = props.item.content.find(c => c.lang === 'en');
    const inputs =
      content?.inputs.filter(
        i => interactiveConfig.NO_SUBMIT_INPUT_TYPES.indexOf(i.type) === -1
      ) || [];
    const data = InteractiveComponentV2Service.getUserInputAll();

    serviceNames.forEach(serviceName => {
      const requests = followUpRequests[serviceName];

      requests.forEach((request: any) => {
        // eslint-disable-next-line max-len
        let paramNames = inputs
          .map(i => i.field_name)
          .filter(
            p => request.fields.indexOf(p) > -1 || request.fields === '*'
          );
        paramNames = paramNames.concat(request.hidden_inputs); // add the hidden_inputs to the list of parameters
        const payload: { [key: string]: any } = {};

        paramNames.forEach(p => {
          if (request.omitted_params.indexOf(p) > -1) {
            return;
          }

          payload[p] =
            data[p] ||
            InteractiveComponentV2Service.getUserValues(p) ||
            getStorageParameter(p);
        });

        if (request.version === 'local') {
          let service = getLocalService(serviceName);
          // eslint-disable-next-line max-len
          // we use import with '*' for EventService because an es6 conflict and here we have to access 'default' property
          // if better solution will be found this need to change
          if (
            service &&
            !service[request.method] &&
            service['default'] &&
            service['default'][request.method]
          ) {
            service = service['default'];
          }
          service[request.method].call(service, payload);
        } else if (request.method === 'update_user_info') {
          promiseArray.push(
            UserApiActions.post({
              action: 'UserInfo',
              redirectUri: '',
              data: payload,
              callback: _updateUserInfoCallback,
            })
          );
        } else {
          promiseArray.push(
            ApiActions.post({
              entity: serviceName,
              method: request.method,
              data: payload,
            })
          );
        }
      });
    });

    Promise.all(promiseArray)
      .then(() => resolve(undefined))
      .catch(error => console.log(error));
  });
};

export const updateUserInfo = (item: InteractiveComponentItem) => {
  const content = item.content.find(c => c.lang === 'en');
  const inputs =
    content?.inputs.filter(
      i => interactiveConfig.NO_SUBMIT_INPUT_TYPES.indexOf(i.type) === -1
    ) || [];
  const data = InteractiveComponentV2Service.getUserInputAll();
  const request =
    interactiveConfig.REQUESTS.ON_REQUEST_SUCCESS_FOLLOW_UP.create.User[0];
  // eslint-disable-next-line max-len
  let paramNames = inputs
    .map(i => i.field_name)
    .filter(p => request.fields.indexOf(p) > -1 || request.fields === '*');
  paramNames = paramNames.concat(request.hidden_inputs); // add the hidden_inputs to the list of parameters
  const payload: { [key: string]: any } = {};

  paramNames.forEach(p => {
    if (request.omitted_params.indexOf(p) > -1) {
      return;
    }

    payload[p] =
      data[p] ||
      InteractiveComponentV2Service.getUserValues(p) ||
      getStorageParameter(p);
  });

  UserApiActions.post({
    action: 'UserInfo',
    redirectUri: '',
    data: payload,
    callback: _updateUserInfoCallback,
  });
};
