import { logError } from './analytics/logger/logger';

let instance = null;

class Cookies {
  constructor() {
    instance = instance || null;
    return instance;
  }

  get(name, retries = 0) {
    try {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      let returnedValue;
      if (parts.length === 2) {
        returnedValue = parts.pop().split(';').shift();
      }

      if (
        (name.indexOf('sl-user-uuid') > -1 ||
          name.indexOf('sl-session-id') > -1) &&
        !returnedValue
      ) {
        handleCookieError(`Empty cookie ${name}`);
      }
      return returnedValue;
    } catch (error) {
      handleCookieError(`Error when getting cookie: ${error}`);
    }
  }

  set(name, value, days = 14) {
    if (!value && name.indexOf('utm') === -1) {
      handleCookieError(`Setting cookie ${name} to ${value}`);
    }
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toString()}`;
  }

  remove(name) {
    if (
      name.indexOf('sl-user-uuid') > -1 ||
      name.indexOf('sl-session-id') > -1
    ) {
      handleCookieError(`Removing cookie ${name}`);
    }
    this.set(name, '', -1);
  }
}

const handleCookieError = function (message) {
  logError(message, {
    team: 'onboarding-app-web-client',
    service: `onboarding-app-web-client-${window.ENV}`,
    stackTrace: getStackTrace(),
  });
};

const getStackTrace = function () {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if (!isIE11) {
    let obj = {};
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(obj, getStackTrace);
      return obj.stack;
    } else {
      try {
        return Error().stack;
      } catch (err) {
        return 'Could not get stack trace';
      }
    }
  } else {
    return 'This is IE 11 no stack trace available';
  }
};

export default new Cookies();
