import { useState } from 'react';
import CountryCodeSelect from '../country-code-select/themed.country-code-select';
import './text-to-download.scss';
import { PhoneNumber } from '../phone-number/themed.phone-number.component';
import InputButton from '../input-button/input-button.component';
import classNames from 'classnames';
import { CountryCode } from '../country-code-select/country-codes.types';
import enable_text_to_download_campaign from './text-to-download-api';
import {
  textToDownloadCountryPhoneWrapperStyle,
  textToDownloadStyle,
  textToDownloadSubmitStyle,
  textToDownloadSubmitSuccessStyle,
} from './text-to-download-style';

/* SMS consent is to be used later when we start supporting the feature */
// const smsConsentText = `It's ok to text me several tips and encouragements each week.`;

const unneededValidCharactersRegex = /[()-\s]/gi;

const tenNumericalDigitsRegex = /(^[0-9]{10}$)/;

const isValidPhoneNumber = ({ phoneNumber }: { phoneNumber: string }) => {
  if (phoneNumber) {
    // Strip out valid but not needed characters such as parentheses, dashes, spaces
    const strippedPhoneNumber = phoneNumber.replaceAll(
      unneededValidCharactersRegex,
      ''
    );
    // Ensure we have a 10 digit number
    return (
      strippedPhoneNumber && strippedPhoneNumber.match(tenNumericalDigitsRegex)
    );
  }

  return false;
};
// Note: This is only temporary until we support UK numbers
const US_COUNTRY_CODE: CountryCode = '1';

export default function TextToDownload() {
  /* SMS consent is to be used later when we start supporting the feature */
  // const [isSmsConsented, setIsSmsConsented] = useState(false);
  const [countryCode, setCountryCode] = useState<CountryCode>(US_COUNTRY_CODE);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCountryCodeError, setCountryCodeError] = useState(false);
  const [isPhoneNumberError, setPhoneNumberError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = () => {
    if (!countryCode) {
      setCountryCodeError(true);
    }

    if (!isValidPhoneNumber({ phoneNumber })) {
      setPhoneNumberError(true);
    }

    if (isValidPhoneNumber({ phoneNumber }) && countryCode) {
      setCountryCodeError(false);
      setPhoneNumberError(false);
      setIsLoading(true);
      const phone_number = `+${countryCode}${phoneNumber.replaceAll(
        unneededValidCharactersRegex,
        ''
      )}`;
      enable_text_to_download_campaign(phone_number)
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then(() => {
          setIsSuccess(true);
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <div css={textToDownloadStyle}>
      <div>
        <div
          css={textToDownloadCountryPhoneWrapperStyle}
          style={{ marginBottom: '24px' }}
        >
          <CountryCodeSelect
            currentSelection={countryCode}
            setCountryCode={setCountryCode}
            isError={isCountryCodeError}
          />

          <PhoneNumber
            countryCode={countryCode}
            currentValue={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            isError={isPhoneNumberError}
          />
        </div>
      </div>
      <div css={{ height: 16, width: 16 }} />
      <InputButton
        className={classNames('themed_ttd__submit', {
          'themed_ttd__submit--success': isSuccess,
        })}
        css={[
          isSuccess
            ? textToDownloadSubmitSuccessStyle
            : textToDownloadSubmitStyle,
        ]}
        loading={isLoading}
        value={isSuccess ? 'Sent' : 'Send'}
        onClick={onSubmit}
        type="submit"
        showIcon={isSuccess}
      />
    </div>
  );
}
