import React from 'react';

import NextButton from './next-button.component';
import PageContent from './content.component';
import { FlowPageContainerProps, FlowPageProps } from '../flow-page.types';
import * as CLASSES from '../../../constants/class-names';

/**
 * FlowPageProps are props that come from the container (IE, redux state)
 * FlowPageContainerProps are intended to be props that are passed directly into the Container and then
 * passed onwards to this component. Some of those might go away with further refactoring.
 */
export const FlowPage: React.FC<FlowPageProps & FlowPageContainerProps> = ({
  currentStep,
  currentPage,
  shouldDisplaySignupExistingUserInFlow,
  Header,
  ProgressBar,
  Footer,
  FitBitElement,
  content,
  isNextButtonDisabled,
  fitBitData,
  isValidated,
  answers,
  advance,
  onContinue,
  onAnswer,
  validateAnswers,
  isNextButtonVisible,
  showSkippableText,
}) => {
  return (
    <div
      id={CLASSES.SL_FLOW}
      data-step={currentStep}
      data-page={currentPage}
      className={CLASSES.SL_FLOW}
    >
      {Header && <Header />}
      {ProgressBar && <ProgressBar />}
      <div className={CLASSES.SL_DYNAMIC_CONTENT}>
        {/* this is used for dynamic backgrounds*/}
      </div>
      <div
        className={
          shouldDisplaySignupExistingUserInFlow
            ? `${CLASSES.MULTIPRODUCT_CONTENT} ${CLASSES.MULTIPRODUCT_BACKGROUND}`
            : CLASSES.SL_CONTENT
        }
        role="main"
      >
        <div
          className={
            shouldDisplaySignupExistingUserInFlow
              ? `${CLASSES.MULTIPRODUCT_BACKGROUND} ${CLASSES.MULTIPRODUCT_INNER_CONTENT}`
              : CLASSES.SL_INNER_CONTENT
          }
        >
          <div className={CLASSES.SL_PAGE}>
            {!!FitBitElement && FitBitElement}
            <PageContent
              content={content}
              isNextButtonDisabled={isNextButtonDisabled}
              fitBitData={fitBitData}
              isValidated={isValidated}
              answers={answers}
              advance={advance}
              onAnswer={onAnswer}
              validateAnswers={validateAnswers}
            />
            <NextButton
              isVisible={isNextButtonVisible}
              isDisabled={isNextButtonDisabled}
              isValidated={isValidated}
              showSkippableText={showSkippableText}
              advance={advance}
              onContinue={onContinue}
              validateAnswers={validateAnswers}
            />
          </div>
        </div>
      </div>
      {Footer && <Footer />}
    </div>
  );
};

export default React.memo(FlowPage);
