export enum QuestionTypes {
  AUTOCOMPLETE = 'autocomplete',
  BOX = 'box',
  CARD = 'card',
  DATE = 'date',
  LIMIT_BOX = 'limit box',
  MULTI = 'multi',
  NUMBER = 'number',
  ONELINE_DATE = 'oneLine date',
  ONELINE_NUMBER = 'oneLine number',
  ONELINE_RADIO = 'oneLine radio',
  ONELINE_SELECT = 'oneLine select',
  ONELINE_TEXT = 'oneLine text',
  RADIO = 'radio',
  SELECT = 'select',
  SIDE_BY_SIDE = 'side-by-side',
  SINGLE = 'single',
  TEXT = 'text',
  TEXT_TO_DOWNLOAD = 'text-to-download'
}

const ONELINE_QUESTION_TYPES = [
  QuestionTypes.ONELINE_DATE,
  QuestionTypes.ONELINE_NUMBER,
  QuestionTypes.ONELINE_RADIO,
  QuestionTypes.ONELINE_SELECT,
  QuestionTypes.ONELINE_TEXT,
];

export const isOneLineQuestionType = (
  questionType?: QuestionTypes
): boolean => {
  return !!questionType && ONELINE_QUESTION_TYPES.includes(questionType);
};
