import './style.scss';
import React, { Fragment } from 'react';
import InteractiveInputComponent from '../interactive-input';
import InputCheckbox from '../../../input-checkbox';
import InteractiveComponentV2Service from '../../../../services/interactive-component-v2';

import interactiveConfig from '../../../triggers/triggers-config';
import { noop } from '../../../../props/empty';

class InteractiveCheckboxInputComponent extends InteractiveInputComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  onChange(event) {
    super.onChange(event);
  }

  toggle(event) {
    if (event.target.nodeName !== 'A') {
      this.setState({ value: !this.state.value });
      this.onChange({ target: { value: !this.state.value } });
    }
  }

  componentDidUpdate() {
    super.componentDidUpdate();
  }

  validateData() {
    super.validateData();
  }

  getLabelHTML() {
    let label = this.props.label;
    const hiddenCheckbox =
      interactiveConfig.INPUT_FIELD_NAMES.CLINICAL_GOVERNANCE.indexOf(
        this.props.field_name
      ) > -1;

    Object.keys(this.state.additionalData).forEach(key => {
      if (key.indexOf('template_') === 0) {
        const stringToReplace = `{% ${key.replace('template_', '')} %}`;
        let valueToReplaceWith = '';

        const configFieldNames = interactiveConfig.INPUT_FIELD_NAMES;
        const fieldNamesWithTemplates = configFieldNames.DATA_STORING.concat(
          configFieldNames.CLINICAL_GOVERNANCE
        ).concat(configFieldNames.DOCTOR_ACKNOWLEDGEMENT);

        if (fieldNamesWithTemplates.indexOf(this.props.field_name) > -1) {
          const aux = `${key.replace('template_', '')}`;
          if (key.indexOf(aux) > -1) {
            valueToReplaceWith = `
                            <a target="_blank" href="${
                              this.state.additionalData[`${aux}_url`]
                            }">
                                ${this.state.additionalData[key]}
                            </a>`;
          }
        } else {
          valueToReplaceWith = this.state.additionalData[key];
        }

        label = label.replace(stringToReplace, valueToReplaceWith);
      }
    });

    return (
      <Fragment>
        <label
          data-small-font={this.state.additionalData.use_small_font}
          dangerouslySetInnerHTML={{ __html: label }}
          className="sl-interactive--field-label"
          htmlFor={this.props.id}
          onClick={hiddenCheckbox ? noop : this.toggle.bind(this)}
        />
      </Fragment>
    );
  }

  static _getPredefinedValue(props) {
    if (
      interactiveConfig.INPUT_FIELD_NAMES.CLINICAL_GOVERNANCE.indexOf(
        props.field_name
      ) > -1
    ) {
      return true;
    }

    const existingValue =
      InteractiveComponentV2Service.getUserValues(props.field_name) || '';
    return existingValue
      ? existingValue === true || existingValue === 'on'
      : false;
  }

  render() {
    const hiddenCheckbox =
      interactiveConfig.INPUT_FIELD_NAMES.CLINICAL_GOVERNANCE.indexOf(
        this.props.field_name
      ) > -1;
    const error = !!this.props.fieldError || this.state.error;
    const errorText = this.props.fieldError || this.state.errorText;
    return (
      <div className="sl-interactive--checkbox" data-hidden={hiddenCheckbox}>
        <InputCheckbox
          error={error}
          errorText={errorText}
          isChecked={this.state.value || false}
          onChange={this.onChange.bind(this)}
          disabled={this.props.isDisabled}
        />
        {this.getLabelHTML()}
        {this.getHelpText()}
      </div>
    );
  }
}

// InteractiveCheckboxInputComponent.propTypes = InteractiveInputComponent.propTypes;

export default InteractiveCheckboxInputComponent;
