import InputOptions from '../input-options';
import generateId from '../utils/generate-id';
import ConditionalRender from '../conditional-render';
import InputSelect from '../input-select';
import RadioInput from '../input-radio';
import {
  MappedQuestionContentAnswer,
  QuestionContent,
} from '../../types/questions.types';
import { QuestionTitle } from '../question-title/question-title.component';
import { QuestionProps } from '../question-component/question.types';
import { iOSSafari } from '../question-component/question.helpers';
import { withQuestionLogic } from '../question-component';

// A mapped question component extends the question component in order to support the same radio buttons, dropdowns, etc
// with the difference being that answers in the mapped question component are not simply values, but are made of
// question-group-components which in turn are made of questions.
export const MappedQuestionComponent = ({
  isChecked,
  item,
  selectedAnswers,
  handleInputSelect,
  handleChoiceAnswer,
  handleRadioSelect,
  onAnswer,
  actions,
}: QuestionProps) => {
  const question = item;
  const details = question.content[0];
  const content = JSON.parse(
    JSON.stringify(item.content.find((c: QuestionContent) => c.lang === 'en'))
  );
  const oneLine = question.type.toLowerCase().indexOf('oneline') > -1;
  content.answers = content.answers.map((an: MappedQuestionContentAnswer) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    an.id = an.id !== undefined ? an.id : an.key;
    return an;
  });

  const dynamicId = generateId();

  const definedSelectedAnswers =
    selectedAnswers === null || selectedAnswers === undefined
      ? []
      : selectedAnswers;

  const arrayOfAnswers =
    definedSelectedAnswers && Array.isArray(definedSelectedAnswers)
      ? definedSelectedAnswers
      : [definedSelectedAnswers];
  const checkBoxAnswerType =
    question.type === 'single' ||
    question.type === 'multi' ||
    question.type === 'box' ||
    question.type === 'limit box';

  const isFullLine = question.type === 'oneLine radio';

  return (
    <div className="sl-question">
      <QuestionTitle
        title={details.title}
        dynamicId={dynamicId}
        post_question_text={details.post_question_text}
        pre_question_text={details.pre_question_text}
        className={`${isFullLine ? 'sl-page-title-area--full' : ''}`}
      />

      <ConditionalRender if={checkBoxAnswerType}>
        <InputOptions
          type={question.type}
          isMapped={true}
          isChecked={isChecked}
          inputId={dynamicId}
          validation={question.validation}
          details={content}
          onAnswer={onAnswer}
          actions={actions}
          selectedAnswers={arrayOfAnswers}
          onChange={handleChoiceAnswer}
        />
      </ConditionalRender>

      <ConditionalRender
        if={question.type === 'select' || question.type === 'oneLine select'}
      >
        <div className="sl-question-answer">
          <InputSelect
            onChange={() => handleInputSelect(content, '')}
            isChecked={isChecked}
            inputId={dynamicId}
            isMapped={true}
            details={content}
            type="default"
            onAnswer={onAnswer}
            actions={actions}
            answers={content.answers}
            value={definedSelectedAnswers}
          >
            {/* value == null - only 2 = operator, we want to catch both null AND undefined, nothing else! */}

            <ConditionalRender
              if={definedSelectedAnswers === null || iOSSafari}
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
              {/* @ts-ignore*/}
              <option key="0" value={null}>
                Please Select
              </option>
            </ConditionalRender>

            {content.answers.map(
              (answer: MappedQuestionContentAnswer, idx: number) => (
                <option key={idx + 1} value={answer.id}>
                  {answer.text}
                </option>
              )
            )}
          </InputSelect>
        </div>
      </ConditionalRender>

      <ConditionalRender
        if={question.type === 'radio' || question.type === 'oneLine radio'}
      >
        <div
          key={question.id}
          className={`sl-question-answer ${
            isFullLine ? 'sl-question-answer--full' : ''
          }`}
        >
          <RadioInput
            isChecked={isChecked}
            items={content.answers}
            key={`${content.question_id}-${definedSelectedAnswers}`}
            id={content.question_id}
            isMapped={true}
            selectedValue={definedSelectedAnswers}
            oneLine={oneLine}
            details={content}
            onAnswer={onAnswer}
            actions={actions}
            handleRadioSelect={() => handleRadioSelect(content, '')}
          />
        </div>
      </ConditionalRender>

      <ConditionalRender if={details.post_answer_text}>
        <div
          className="sl-page-post-answer"
          dangerouslySetInnerHTML={{ __html: details.post_answer_text }}
        />
      </ConditionalRender>
    </div>
  );
};

export default withQuestionLogic(MappedQuestionComponent);
