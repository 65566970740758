import InteractiveComponentV2Service from '../../services/interactive-component-v2';
import UserService from '../../services/user-session';

import { getStorageParameter } from './parameters';
import OrganizationService from '../../services/org-service';
import FlowService from '../../services/flow-service';
import AnswerService from '../../services/answers-service';
import {
  InteractiveComponentAction,
  InteractiveComponentInput,
} from '../interactive-component-v2/interactive-component-v2.types';

// @todo Taber - remove reliance on global variables set on the window object
export const buildGenericDataToCheckConditionsAgain = function () {
  (window as any).done_requests = (window as any).done_requests || 0;
  return {
    has_pending_requests:
      (window as any).outgoing_requests - (window as any).done_requests > 0,
    post_test: FlowService.isPostFlow(),
    flow_id: FlowService.getFlowId(),
    organization_id: OrganizationService.getOrgId(),
    product_id: (window as any).product_id,
    user: UserService.getUserData(),
  };
};

// @todo this should be replaced with a solutions that does not depend on configuration
// This requires too much cognitive overhead to figure out what is happening.
// In short, this loops through a configuration object to determine what information to add and omit to send to the user service
export const buildActionPayload = function (
  inputs: InteractiveComponentInput[],
  action: InteractiveComponentAction,
  parameterRenaming: any,
  parameterValueChange: any
) {
  const data = InteractiveComponentV2Service.getUserInputAll();
  const payload: any = {};
  let paramNames = inputs.map(i => i.field_name); // get all the inputs field names

  // eslint-disable-next-line max-len
  paramNames = paramNames.filter(
    p =>
      action.params === null ||
      (action.params instanceof Array && action.params.indexOf(p) > -1)
  ); // filter out the ones not in params
  if (action.hidden_inputs) {
    paramNames = paramNames.concat(action.hidden_inputs); // add the hidden_inputs to the list of parameters\
  }
  paramNames.forEach(p => {
    if (action.omitted_params && action.omitted_params.indexOf(p) > -1) {
      return;
    }
    let paramName = parameterRenaming[p] ? parameterRenaming[p] : p;
    // eslint-disable-next-line max-len
    paramName = parameterRenaming[`_$method_${action.method}`]
      ? parameterRenaming[`_$method_${action.method}`][p] || paramName
      : paramName;

    payload[paramName] =
      data[p] !== undefined
        ? data[p]
        : InteractiveComponentV2Service.getUserValues(p);
    payload[paramName] =
      payload[paramName] !== undefined
        ? payload[paramName]
        : getStorageParameter(p);
    // eslint-disable-next-line max-len
    payload[paramName] =
      parameterValueChange[p] && parameterValueChange[p][payload[paramName]]
        ? parameterValueChange[p][payload[paramName]]
        : payload[paramName];
  });

  const question_answers = action.question_answers
    ? [...action.question_answers]
    : [];
  question_answers.forEach(semantic_id => {
    payload.answers = payload.answers || {};
    const storedAnswer =
      AnswerService.getAnswerBySemantic(semantic_id, true) || {};
    if (storedAnswer) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      payload.answers[semantic_id] = storedAnswer.values;
    }
  });

  if (action.default_values) {
    for (const property in action.default_values) {
      // eslint-disable-next-line no-prototype-builtins
      if (action.default_values.hasOwnProperty(property)) {
        payload[property] =
          payload[property] || action.default_values[property];
      }
    }
  }
  return payload;
};
