import { css } from '@emotion/react';
import { BHTheme } from '../../types/bh-themes.types';

const sharedInputCheckboxStyles = (theme: BHTheme) =>
  css({
    fontSize: theme.fonts.fontSizes.STANDARD,
    color: theme.inputs.checkbox.textColor,
  });

export const inputCheckboxStyles = (theme: BHTheme) =>
  css(
    {
      display: 'flex',
      flexWrap: 'wrap',
    },
    sharedInputCheckboxStyles(theme)
  );

export const inputCheckboxStylesHidden = (theme: BHTheme) =>
  css(
    {
      display: 'none',
    },
    sharedInputCheckboxStyles(theme)
  );

export const errorStyles = (theme: BHTheme) =>
  css({
    paddingLeft: theme.padding.EXTRA_LARGE,
    paddingTop: 0,
    flex: '0 0 100%',
  });
