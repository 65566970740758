const INITIAL_STATE = {
  environment: window.ENV,
  productId: window.product_id,
  productName: window.product_name,
  privacyPolicyURL: window.privacyPolicyUrl,
  termsOfServiceURL: window.termsOfServiceUrl,
  version: window.app_version,
  footerLogoIcons: Array.isArray(window.footer_logo_icons)
    ? window.footer_logo_icons
    : [],
};

export default function appReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state;
  }
}
