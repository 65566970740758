import { useAppSelector } from '../../hooks/use-app-react-redux';
import { FC } from 'react';
import {
  darkFooterLinkStyles,
  darkFooterStyles,
  lightFooterLinkStyles,
  lightFooterStyles,
} from './styles';
import { FOOTER_ELEMENT } from '../../constants/class-names';

export type FooterProps = {
  footerTheme?: 'dark' | 'light' | undefined;
};
const Vr = (props: {
  height?: string;
  color?: string;
  width?: string;
  margin?: string;
}) => (
  <span
    style={{
      height: props?.height || '100%',
      padding: props?.width || '0.5px',
      backgroundColor: props?.color || 'grey',
      margin: props?.margin || '0 0.5em',
    }}
  />
);

export const Footer: FC<FooterProps> = ({ footerTheme = 'light' }) => {
  const appInfo = useAppSelector(state => state.appReducer);
  const year = new Date().getFullYear();
  const productName =
    appInfo.productName.charAt(0).toUpperCase() + appInfo.productName.slice(1);

  return (
    <footer
      css={footerTheme === 'light' ? lightFooterStyles : darkFooterStyles}
      className={FOOTER_ELEMENT}
      style={{ zIndex: 1 } /* Ensure clickable inon Sleepio landing page */}
    >
      <div data-product={appInfo.productName}>
        <div>&copy; {year} Big Health</div>
        <div>
          {productName} Onboarding Version {appInfo.version}
        </div>
        <div style={{ marginTop: '1em' }}>
          <a
            href="//info.sleepio.com/privacy"
            className={FOOTER_ELEMENT}
            css={
              footerTheme === 'light'
                ? lightFooterLinkStyles
                : darkFooterLinkStyles
            }
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <Vr />
          <a
            href="//info.sleepio.com/terms"
            className={FOOTER_ELEMENT}
            css={
              footerTheme === 'light'
                ? lightFooterLinkStyles
                : darkFooterLinkStyles
            }
            target="_blank"
            rel="noreferrer"
          >
            Terms of Use
          </a>
          <Vr />
          <a
            href="#"
            className={FOOTER_ELEMENT}
            onClick={() => window.CC.showSettings()}
            css={
              footerTheme === 'light'
                ? lightFooterLinkStyles
                : darkFooterLinkStyles
            }
          >
            Cookie preferences
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
