import React from 'react';
import FooterContainer from '../../containers/footer';
import './style.scss';
import Header from '../../containers/header/header.container';
import ConditionalRender from '../conditional-render';
import sleepio from './img/group-5.svg';
import daylight from './img/group-9.svg';
import PropTypes from 'prop-types';

const AutologinRedirectPage = props => {
  const productName = props.match.params.product;
  document
    .getElementsByTagName('body')[0]
    .setAttribute('data-product', productName);

  return (
    <div className="sl-empty-layout">
      <Header displayLogin={false} displayLogout={false} />
      <div className={`sl-token-reset-page ${productName}`}>
        <div className={'sl-token-reset-page__inner'}>
          <h3>This link has expired.</h3>

          <ConditionalRender if={productName === 'sleepio'}>
            <img src={sleepio} className={'top'} />
            <h4>
              We&apos;ve sent you a new one! Please check your email for a new
              link to take the Sleep Test and receive your new Sleep Score.
            </h4>
          </ConditionalRender>
          <ConditionalRender if={productName === 'daylight'}>
            <img src={daylight} className={'top'} />
            <h4>
              We&apos;ve sent you a new one! Please check your email for a new
              link to this feedback form.
            </h4>
          </ConditionalRender>
        </div>
      </div>
      <FooterContainer />
    </div>
  );
};

AutologinRedirectPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AutologinRedirectPage;
