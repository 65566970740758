import {
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import ErrorMessageComponent from '../errors/error-message/error-message.component';
import { HandleInputDate } from '../question-component/question.types';
import {
  INVALID_MONTH,
  INVALID_DAY,
  currentYear,
  INVALID_YEAR,
  INVALID_AGE,
  INVALID_OTHER,
  MONTH,
  MONTH_PLACEHOLDER,
  DATE,
  DAY_PLACEHOLDER,
  YEAR,
  currentDate,
  YEAR_PLACEHOLDER,
} from './constants';
import { formatDate, isUserLegalAge } from './input-date.utils';
import { dateWrapper, errorStyle, onelineDate } from './style';

export type DateInputProps = {
  inputId?: string;
  onChange: HandleInputDate;
  value: string | null; // selectedAnswers
};

type NumberChangeEvent = (event: ChangeEvent<HTMLInputElement>) => void;

const _validateMonth = (month: DMYNumbers['month']) => {
  if (!isInteger(month) || month < 1 || month > 12) {
    return INVALID_MONTH;
  }
  return null;
};

const _validateDay = (day: DMYNumbers['day']) => {
  if (!isInteger(day) || day < 1 || day > 31) {
    return INVALID_DAY;
  }
  return null;
};

const _validateYear = (year: DMYNumbers['year']) => {
  if (!isInteger(year) || year < 1900 || year > currentYear) {
    return INVALID_YEAR;
  }
  return null;
};

const DateInput: FunctionComponent<DateInputProps> = ({
  onChange,
  value,
  inputId,
}) => {
  // value is a date string. Parse for initial state
  const valueDateRaw = Date.parse(value || '');
  const parsedDate = new Date(valueDateRaw);
  const [month, setMonth] = useState<number | null>(
    parsedDate.getMonth() + 1 || null
  );
  const [day, setDay] = useState<number | null>(parsedDate.getDate() || null);
  const [year, setYear] = useState<number | null>(
    parsedDate.getFullYear() || null
  );
  const [error, setError] = useState<string | null>(null);
  const [monthError, setMonthError] = useState<string | null>(null);
  const [dateError, setDateError] = useState<string | null>(null);
  const [yearError, setYearError] = useState<string | null>(null);

  // componentDidMount
  useEffect(() => {
    const isValid = validateDate(day, month, year);
    onChange(value, !isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _onBlur =
    (
      validationFn:
        | typeof _validateMonth
        | typeof _validateDay
        | typeof _validateYear,
      setStateFn:
        | typeof setMonthError
        | typeof setDateError
        | typeof setYearError
    ) =>
    (
      validationArgs:
        | DMYNumbers['month']
        | DMYNumbers['day']
        | DMYNumbers['year']
    ) => {
      const errorMessage = validationFn(validationArgs);
      setStateFn(errorMessage);
    };

  const validateDate = (
    day: DMYNumbers['day'],
    month: DMYNumbers['month'],
    year: DMYNumbers['year']
  ) => {
    const individualValidation =
      _validateMonth(month) || _validateDay(day) || _validateYear(year);
    if (
      individualValidation ||
      !(isInteger(day) && isInteger(month) && isInteger(year))
    ) {
      // do not do other validations yet
      return false;
    }

    // NOTE: month needs to be input - 1 because of JS date API
    const dateToCheck = new Date(year, month - 1, day);
    if (!isUserLegalAge(dateToCheck.toString())) {
      setError(INVALID_AGE);
      return false;
    }

    // check that datestring actually matches
    // this block exists because new Date(1991, 1, 31) (which would be February 31) returns March 3, which is obviously not lining up with user expectation
    if (
      dateToCheck.getDate() !== day ||
      dateToCheck.getMonth() !== month - 1 ||
      dateToCheck.getFullYear() !== year
    ) {
      setError(INVALID_OTHER);
      return false;
    }

    setError(null);
    return true;
  };

  const _onChange = useCallback(
    (
      day: DMYNumbers['day'],
      month: DMYNumbers['month'],
      year: DMYNumbers['year']
    ) => {
      const isValid = validateDate(day, month, year);
      if (isValid && isInteger(day) && isInteger(month) && isInteger(year)) {
        const newValue = formatDate(day, month - 1, year, true); // -1 accounts for zero-indexed month
        onChange(newValue, false);
        return;
      }
      onChange(value, true);
    },
    [onChange, value]
  );

  const _onChangeMonth: NumberChangeEvent = useCallback(
    ({ target: { valueAsNumber } }) => {
      setMonth(valueAsNumber);
      _onChange(day, valueAsNumber, year);
    },
    [_onChange, day, year]
  );
  const _onChangeDay: NumberChangeEvent = useCallback(
    ({ target: { valueAsNumber } }) => {
      setDay(valueAsNumber);
      _onChange(valueAsNumber, month, year);
    },
    [_onChange, month, year]
  );
  const _onChangeYear: NumberChangeEvent = useCallback(
    ({ target: { valueAsNumber } }) => {
      setYear(valueAsNumber);
      _onChange(day, month, valueAsNumber);
    },
    [_onChange, day, month]
  );

  const mainErrorMessage = monthError || dateError || yearError || error;

  // formatting is hard-coded to MM/DD/YYYY order until product reqs demand otherwise
  return (
    <div css={dateWrapper} aria-labelledby={`${inputId}_title`}>
      <div css={onelineDate}>
        <div>
          <label htmlFor="birth-month">{MONTH}</label>
          <input
            type="number"
            id="birth-month"
            min={1}
            max={12}
            tabIndex={0}
            onChange={_onChangeMonth}
            value={month || ''}
            placeholder={MONTH_PLACEHOLDER}
            onBlur={() => _onBlur(_validateMonth, setMonthError)(month)}
          />
        </div>
        <div>
          <label htmlFor="birth-day">{DATE} </label>
          <input
            type="number"
            id="birth-day"
            min={1}
            max={31}
            tabIndex={0}
            onChange={_onChangeDay}
            value={day || ''}
            placeholder={DAY_PLACEHOLDER}
            onBlur={() => _onBlur(_validateDay, setDateError)(day)}
          />
        </div>
        <div>
          <label htmlFor="birth-year">{YEAR}</label>
          <input
            type="number"
            id="birth-year"
            min={1900}
            max={currentDate.getFullYear()}
            tabIndex={0}
            onChange={_onChangeYear}
            value={year || ''}
            placeholder={YEAR_PLACEHOLDER}
            onBlur={() => _onBlur(_validateYear, setYearError)(year)}
          />
        </div>
      </div>

      {mainErrorMessage && (
        <ErrorMessageComponent
          css={errorStyle}
          errorMessage={mainErrorMessage}
        />
      )}
    </div>
  );
};

export default DateInput;

type DMYNumbers = {
  day: number | null;
  month: number | null;
  year: number | null;
};

const isInteger = (arg: number | null | undefined): arg is number => {
  return Number.isInteger(arg);
};
