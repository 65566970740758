import { FunctionComponent } from 'react';
import {
  DAYLIGHT_ORANGE_LOGO,
  SLEEPIO_WHITE_LOGO,
} from '../../constants/logos';
import { MULTIPRODUCT_HEADER } from '../../constants/class-names';

import './multi-product-header.style.scss';

export const MultiProductHeader: FunctionComponent = () => {
  return (
    <header className={MULTIPRODUCT_HEADER}>
      <div className="multi-product-header__content">
        <div className="multi-product-header__logo-container">
          <img
            className="multi-product-header__logo multi-product-header__logo__sleepio"
            src={SLEEPIO_WHITE_LOGO}
            alt="Sleepio"
          />
          <img
            className="multi-product-header__logo"
            src={DAYLIGHT_ORANGE_LOGO}
            alt="Daylight"
          />
        </div>
        <div className="multi-product-header__divider"></div>
        <div className="multi-product-header__subheader">
          <span>by</span>
          <span className="multi-product-header__brand">&nbsp;Big Health</span>
        </div>
      </div>
    </header>
  );
};
