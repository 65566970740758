import {
  SIGNUP_USER,
  LOGIN_USER,
  GET_USER_INFO,
  LOGIN_ERROR,
  UNSUBSCRIBE_USER,
  URL_DETAILS,
  TEMP_USER_DATA,
  UPDATE_EMAIL,
  UPDATE_PASSWORD,
  SET_COUNTRY,
} from '../constants/action-types';
import ApiActions from './api.js';
import EventService from '../services/event-service';
import FlowService from '../services/flow-service';
import * as SignupService from '../services/signup-service';
import UserApiActions from '../actions/user-api';
import UserSession from '../services/user-session';

/**
 * Get logged in user info
 * @returns {Function}
 */
export function getUserInfo({ cb }) {
  return function (dispatch) {
    UserApiActions.get({ action: 'Me' })
      .then(res => {
        if (res.is_authenticated) {
          const oldUuid = SignupService.getUserOldUuid();
          const uuid = SignupService.getUserUuid();
          const userData = UserSession.getUserData() || {};

          userData.idp_name = res.idp_name;
          userData.idp_id = res.idp_id;
          userData.product_ids = res.product_ids || [];
          UserSession.setUserData({ ...res, ...userData });

          const uuidChangeEventSend = SignupService.getUuidChangeEventSent();

          if (
            !uuidChangeEventSend &&
            oldUuid &&
            uuid !== oldUuid &&
            !FlowService.isPostFlow()
          ) {
            EventService.uuidChangedOnLoginEvent();
            SignupService.setUuidChangeEventSent(true);
          }
        } else {
          const userData = UserSession.getUserData() || {};
          if (userData.user_id) {
            localStorage.clear();
            window.location = window.location.pathname;
          }
        }
        // Set user data in local storage
        UserSession.setUserData(res);
        if (cb) {
          cb(res);
        }
        return dispatch({ type: GET_USER_INFO, data: res });
      })
      .catch(res => {
        console.log(res);
        return dispatch({ type: GET_USER_INFO });
      });
  };
}

export function unsubscribeByUuidAndProductSlug(uuid, productSlug) {
  let query = `uuid=${uuid}&product_slug=${productSlug}`;
  return function (dispatch) {
    ApiActions.get({
      entity: 'User',
      method: 'unsubscribe_with_uuid_and_product_slug',
      query,
    })
      .then(res => {
        return dispatch({ type: UNSUBSCRIBE_USER, data: res });
      })
      .catch(() => {
        return dispatch({ type: UNSUBSCRIBE_USER });
      });
  };
}

/** Log out the user
 * @param {Function=} callback logout success
 * @returns {Function}
 * */
export function logoutUser(callback) {
  let logoutResponse = {};
  return UserApiActions.get({ action: 'Logout' })
    .then(resData => {
      const allLocalStorageKeys = Object.entries(localStorage).map(
        entry => entry[0]
      );
      SignupService.setUuidChangeEventSent(false);
      allLocalStorageKeys.forEach(key => {
        if (
          key.indexOf('sent-report-') !== 0 &&
          key.indexOf('sl-report-viewed-') !== 0
        ) {
          localStorage.removeItem(key);
        }
      });
      // prevent internet explorer to auto login
      document.execCommand('ClearAuthenticationCache', false);
      EventService.logoutUser();

      logoutResponse = resData;
    })
    .then(typeof callback === 'function' && callback)
    .then(() => {
      return logoutResponse;
    });
}

/**
 * Login user
 * @param {Object} data user data
 * @param {String} redirectUri redirect uri
 * @returns {Function}
 */
export function loginUser(data, redirectUri) {
  return function (dispatch) {
    UserApiActions.post({ action: 'Login', redirectUri, data })
      .then(res => {
        return dispatch({ type: LOGIN_USER, data: res });
      })
      .catch(() => {
        return dispatch({ type: LOGIN_USER });
      });
  };
}

/**
 * Signup a user
 * @param {Object} data user data
 * @param {String} redirectUri redirect uri
 * @param {Function} cb callback
 * @returns {Function}
 */
export function signupUser(data, redirectUri, cb) {
  return function (dispatch) {
    UserApiActions.post({ action: 'Signup', redirectUri, data })
      .then(res => {
        dispatch({ type: SIGNUP_USER, data: res });
        cb();
      })
      .catch(() => {
        return dispatch({ type: SIGNUP_USER });
      });
  };
}

export function loginError(error) {
  return { type: LOGIN_ERROR, data: error };
}

export function urlDetails(details) {
  return { type: URL_DETAILS, data: JSON.parse(details) };
}

export function tempUserData(temp_user_data) {
  return { type: TEMP_USER_DATA, data: temp_user_data };
}

export function onChangeEmail(email) {
  return { type: UPDATE_EMAIL, value: email };
}

export function onChangePassword(password) {
  return { type: UPDATE_PASSWORD, value: password };
}

export function onChangeCountry(country) {
  return { type: SET_COUNTRY, value: country };
}
