import { modalStyles, overlayStyles } from './modal.styles';
import ReactDom from 'react-dom';
import { Fragment } from 'react';
import NeedHelpModal from './modal-content/need-help/need-help-modal.component';

type ModalProps = {
  open: boolean;
  onClose: () => void;
  modalContent: 'need-help-modal'; // add modal types here as you need them
};

const Modal = ({ open, onClose, modalContent }: ModalProps) => {
  if (!open) {
    return null;
  }

  return ReactDom.createPortal(
    <Fragment>
      <div css={overlayStyles} onClick={onClose}></div>
      <div css={modalStyles}>
        {/* add additional modals below as needed below */}
        {/* modal content can be added in ./modal-content/ */}
        {modalContent === 'need-help-modal' && (
          <NeedHelpModal onClose={onClose} />
        )}
      </div>
    </Fragment>,
    document.getElementById('modal-portal')!
  );
};

export default Modal;
