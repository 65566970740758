import Text from '../text';
import { IsValidEmail } from '../../../../../helpers/validation';

class Email extends Text {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      error: '',
    };
  }

  isValid() {
    if (!this._hasValue()) {
      this.setState({ error: 'Please enter your email' });
      return false;
    }

    if (!this._isEmail()) {
      this.setState({ error: 'Please enter a valid email' });
      return false;
    }

    this.setState({ error: '' });
    return true;
  }

  _isEmail() {
    return IsValidEmail(this.props.value);
  }

  _hasValue() {
    return this.props.value;
  }
}

export default Email;
