import { Answer } from '../../types/answers.types';
import { PageEntity, StepPage } from '../../types/flow.types';
import {
  getAnswersForPage,
  getMappedQuestionResponses,
} from '../../services/answers-service';
import { createAnswerFromData } from '../../helpers/answers';
import { getProductId } from '../../helpers/product';
export const getSkippedQuestionAnswer = (
  pages: StepPage[],
  currentPage: number,
  currentStep: number
) => {
  const question = pages[currentPage]?.entity[0]; // entity is an array of questions? but I think there's always only one, and instead there's just an array of entities in a page
  const skippedAnswer = question
    ? createAnswerFromData({
        question_id: question.id,
        semantic_id: question.semantic_id,
        is_core_question: question.is_core_question,
        product_id: getProductId(),
        values: '',
        isSkipped: true,
        stepNumber: currentStep,
        pageNumber: currentPage,
      })
    : null;
  return skippedAnswer;
};

export const getAnswersForSaving = ({
  isSkippedQuestion,
  pages,
  currentPage,
  currentStep,
  shouldHideEntity, // _for now_ pass this in until we can tease out what's going on
}: {
  isSkippedQuestion: boolean;
  pages: StepPage[];
  currentPage: number;
  currentStep: number;
  shouldHideEntity?: (entity: PageEntity) => boolean;
}): (Answer | null)[] => {
  const inititalAnswers = isSkippedQuestion
    ? [getSkippedQuestionAnswer(pages, currentPage, currentStep)]
    : getAnswersForPage(currentStep, currentPage) ?? [];

  // use passed in hide check to filter answers
  let filteredAnswers = inititalAnswers;
  const pageEntities = pages[currentPage]?.entity;
  if (shouldHideEntity && pageEntities) {
    const entityIdsToHide = pageEntities
      .filter(shouldHideEntity)
      .map(({ id }) => id);
    filteredAnswers = inititalAnswers.filter(
      ans => ans === null || !entityIdsToHide.includes(ans.question_id)
    );
  }

  // get all mapped question answers
  const mappedQuestionResponses = filteredAnswers.flatMap(answer =>
    answer ? getMappedQuestionResponses(answer.question_id) : []
  );

  // fix up questions with mapped responses or add to answers
  mappedQuestionResponses.forEach(mappedQuestionResponse => {
    const index = filteredAnswers.findIndex(
      answer =>
        answer && answer.question_id === mappedQuestionResponse.question_id
    );
    if (index > -1) {
      filteredAnswers[index] = mappedQuestionResponse;
    } else {
      filteredAnswers.push(mappedQuestionResponse);
    }
  });

  return filteredAnswers;
};
