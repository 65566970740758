import { getProductDisplayName } from '../../helpers/product';

export const MONTH = 'Month';
export const DATE = 'Date';
export const YEAR = 'Year';
export const MONTH_PLACEHOLDER = 'MM';
export const DAY_PLACEHOLDER = 'DD';
export const YEAR_PLACEHOLDER = 'YYYY';
export const currentDate = new Date();
export const currentYear = currentDate.getFullYear();
export const INVALID_MONTH = 'Invalid month, value must be between 1 and 12';
export const INVALID_DAY = 'Invalid date, value must be between 1 and 31';
export const INVALID_YEAR = `Invalid year, value must be between 1900 and ${currentYear}`;
export const INVALID_OTHER = 'Please enter a valid date';
export const INVALID_AGE = `You must be 18 years or older sign up for ${getProductDisplayName()}`;
