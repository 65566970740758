import { FunctionComponent, KeyboardEventHandler, SyntheticEvent } from 'react';
import { themedCheckboxErrorStyling, themedCheckboxStyling } from './style';

// https://reactjs.org/docs/accessibility.html -- reference for ARIA accessibility pass later

type CheckInputProps = {
  className?: string;
  tabIndex?: number;
  id: string;
  name: string;
  isChecked: boolean;
  onChange: (event?: SyntheticEvent) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
};

export const CheckboxInput: FunctionComponent<CheckInputProps> = ({
  className,
  tabIndex,
  id,
  name,
  isChecked,
  onChange,
  onKeyDown,
  required,
  disabled,
  error,
}) => {
  return (
    <input
      type="checkbox"
      role="checkbox"
      css={error ? themedCheckboxErrorStyling : themedCheckboxStyling}
      className={className}
      tabIndex={tabIndex}
      id={id}
      name={name}
      checked={isChecked}
      onChange={onChange}
      required={required}
      disabled={disabled}
      onKeyDown={onKeyDown}
      data-testid="question-input-checkbox"
      aria-checked={isChecked}
    />
  );
};

export default CheckboxInput;
