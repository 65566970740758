import './style.scss';
import React, { Fragment } from 'react';
import InputText from '../../../input-text';
import InteractiveInputComponent from '../interactive-input';
import ConditionalRender from '../../../conditional-render';
import UserSession from '../../../../services/user-session';
import interactiveConfig from '../../../triggers/triggers-config';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as interactiveV2Actions from '../../../../actions/interactive-v2';
import InteractiveComponentV2Service from '../../../../services/interactive-component-v2';

class InteractiveTextInputComponent extends InteractiveInputComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  validateData() {
    super.validateData();
  }

  onChange(event) {
    super.onChange(event);
  }

  componentDidMount() {
    super.componentDidMount();
    if (this._isMiddleNameRenderedAfterFirstName()) {
      interactiveConfig.INPUT_FIELD_NAMES.FIRST_NAME.forEach(fieldName => {
        document
          .querySelector(`[data-input-name="${fieldName}"]`)
          .setAttribute('shorten', 'true');
      });
      interactiveConfig.INPUT_FIELD_NAMES.LAST_NAME.forEach(fieldName => {
        document
          .querySelector(`[data-input-name="${fieldName}"]`)
          .setAttribute('wide', 'true');
      });
    }

    this._triggerNotifiers();
  }

  componentDidUpdate() {
    super.componentDidUpdate();
    this._triggerNotifiers();

    if (this._isTwoStepCheckEmployeeId() && this._shouldRenderEmployeeId()) {
      setTimeout(() => {
        const input_eid = document.querySelector(
          '[data-input-name="employee_id"] input'
        );
        if (input_eid) {
          input_eid.focus();
        }
      }, 400);
    }
  }

  render() {
    const details = this._buildTextInputDetails();
    const error = !!this.props.fieldError || this.state.error;
    const errorText = this.props.fieldError || this.state.errorText;

    return (
      <Fragment>
        <ConditionalRender if={this._shouldRenderEmployeeId()}>
          <div
            className="sl-interactive--employee-id-above-help-text"
            data-disabled={this.props.isDisabled}
          >
            <ConditionalRender if={this._isTwoStepCheckEmployeeId()}>
              <span className="sl-interactive--employee-id-line" />
              <span className="sl-interactive--employee-id-check">
                &#10004;
              </span>
              <span className="sl-interactive--employee-id-line" />
            </ConditionalRender>
            <ConditionalRender
              if={this.state.additionalData.additional_instructions_text}
            >
              <p>{this.state.additionalData.additional_instructions_text}</p>
            </ConditionalRender>
          </div>
        </ConditionalRender>
        <ConditionalRender
          if={
            (!this._isTwoStepCheckEmployeeId() ||
              this._shouldRenderEmployeeId()) &&
            !this._shouldHideEmailAfterSso()
          }
        >
          <div
            className="sl-interactive--text"
            data-input-name={this.props.field_name}
            data-disabled={this.props.isDisabled}
          >
            {this.getLabelHTML()}
            <InputText
              disabled={this.props.isDisabled}
              id={this.props.id}
              name={this.props.field_name}
              type={this.props.type}
              details={details}
              maxLength={this.props.max_length}
              minLength={this.props.min_length}
              required={this._getInputIsRequired()}
              onChange={this.onChange.bind(this)}
              value={this.state.value || ''}
              readOnly={this.state.isReadOnly}
              error={error}
              errorText={errorText}
            />
            {this.getHelpText()}
          </div>
        </ConditionalRender>
      </Fragment>
    );
  }

  _isMiddleNameRenderedAfterFirstName() {
    const middleNameFieldNameIndex =
      interactiveConfig.INPUT_FIELD_NAMES.MIDDLE_NAME.indexOf(
        this.props.field_name
      );
    if (middleNameFieldNameIndex > -1) {
      const middleNameFieldName =
        interactiveConfig.INPUT_FIELD_NAMES.MIDDLE_NAME[
          middleNameFieldNameIndex
        ];
      const prevSibling = document.querySelector(
        `[data-input-name="${middleNameFieldName}"]`
      ).previousSibling;
      // eslint-disable-next-line max-len
      return (
        prevSibling &&
        interactiveConfig.INPUT_FIELD_NAMES.FIRST_NAME.indexOf(
          prevSibling.getAttribute('data-input-name')
        ) > -1
      );
    }

    return false;
  }

  _getInputIsRequired() {
    const { field_name } = this.props;
    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(field_name) > -1
    ) {
      const option = this.getOptionByField('two_step_check');

      if (option.value) {
        return false;
      }
    }

    return this.state.additionalData.required;
  }

  _isNoValidationInput() {
    const parentResult = super._isNoValidationInput();
    const isHiddenEmployeeId =
      !this._shouldRenderEmployeeId() &&
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(
        this.props.field_name
      ) > -1;
    const isOptionalZipCode = this._isOptionalZipCode();

    return parentResult || isHiddenEmployeeId || isOptionalZipCode;
  }

  _isOptionalZipCode() {
    const { field_name } = this.props;
    if (interactiveConfig.INPUT_FIELD_NAMES.ZIP_CODE.indexOf(field_name) > -1) {
      return (
        this.props.interactiveV2Reducer.showingEmployeeId &&
        this._isTwoStepCheckEmployeeId()
      );
    }

    return false;
  }

  _isTwoStepCheckEmployeeId() {
    const { field_name } = this.props;
    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(field_name) > -1
    ) {
      const option = this.getOptionByField('two_step_check');
      return option.value;
    }

    return false;
  }

  static _getPredefinedValue(props) {
    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMAIL.indexOf(props.field_name) > -1
    ) {
      const tempData = UserSession.getTempUserData() || {};
      return (
        tempData.email ||
        InteractiveComponentV2Service.getUserValues(props.field_name) ||
        ''
      );
    } else {
      return InteractiveInputComponent._getPredefinedValue(props);
    }
  }

  _shouldHideEmailAfterSso() {
    return (
      interactiveConfig.INPUT_FIELD_NAMES.EMAIL.indexOf(this.props.field_name) >
        -1 && this.props.usedSSO
    );
  }

  _isOneStepCheckEmployeeId() {
    const { field_name } = this.props;
    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(field_name) > -1
    ) {
      const option = this.getOptionByField('two_step_check');
      return !option.value;
    }

    return false;
  }

  _shouldRenderEmployeeId() {
    const { field_name } = this.props;
    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(field_name) > -1
    ) {
      const employeeIdentifierNeeded =
        this.props.interactiveComponentError.employee_identifier_needed;
      return (
        this._isOneStepCheckEmployeeId() ||
        (this._isTwoStepCheckEmployeeId() && employeeIdentifierNeeded)
      );
    }

    return false;
  }

  _triggerNotifiers() {
    if (this._shouldRenderEmployeeId()) {
      this.props.interactiveV2Actions.notifyEmployeeIdVisible(true);

      if (this._isTwoStepCheckEmployeeId()) {
        this.props.interactiveV2Actions.notifyEmployeeIdDisableFields(true);
      }
    }

    if (this._isTwoStepCheckEmployeeId() && !this._shouldRenderEmployeeId()) {
      return this.props.interactiveV2Actions.notifyEmployeeIdVisible(false);
    }

    if (!this._isTwoStepCheckEmployeeId()) {
      this.props.interactiveV2Actions.notifyEmployeeIdDisableFields(false);
    }
  }

  _buildTextInputDetails() {
    const details = [];
    const tooltip = this.state.additionalData.tooltip || '';
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    tooltip ? details.push({ tooltip: tooltip }) : () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    tooltip ? details.push({ hasImage: true }) : () => {};

    return details;
  }
}

const mapStateToProps = state => ({
  interactiveV2Reducer: state.interactiveV2Reducer,
});
const mapDispatchToProps = dispatch => ({
  interactiveV2Actions: bindActionCreators(interactiveV2Actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractiveTextInputComponent);
