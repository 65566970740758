import { css, Theme } from '@emotion/react';
import { SLATE_GRAY } from '../../styles/colors';
import { inputSharedStyle } from '../../styles/themed.input-shared.styles';

export const selectWrapperStyle = (theme: Theme) =>
  css({
    position: 'relative',

    label: {
      paddingLeft: theme.padding.EXTRA_SMALL,
    },
  });

export const selectStyle = (theme: Theme) =>
  css(
    {
      appearance: 'none',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    inputSharedStyle(theme)
  );

export const selectDisabledStyle = css({
  pointerEvents: 'none',
  color: SLATE_GRAY,
});
