import { css, Theme } from '@emotion/react';

export const contentStyle = (theme: Theme) =>
  css({
    display: 'flex',
    alignItems: 'flex-start',
    gap: 10,
    borderRadius: theme.borders.defaultBorderRadius,
    padding: theme.tooltip.padding,
    color: theme.tooltip.color,
    backgroundColor: theme.tooltip.backgroundColor,
    boxShadow: `hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px`,
    maxWidth: '80vw',
    fontSize: theme.tooltip.fontSize,
    lineHeight: '1.35em',
  });

// sigh: https://github.com/radix-ui/primitives/blob/main/packages/react/popper/src/Popper.tsx#L324-L326
// can use emotion to target the arrow span to move it if necessary
export const arrowStyle = (theme: Theme) =>
  css({
    fill: theme.tooltip.backgroundColor,
  });
