import React, {
  createContext,
  ReactChild,
  useContext,
  useMemo,
  useState,
} from 'react';

type LoginFormContextType = {
  email: string;
  password: string;
  acceptedTcDate: Date | null;
  acceptedDocConsentDate: Date | null;
  handleEmailChange: React.Dispatch<React.SetStateAction<string>>;
  handlePasswordChange: React.Dispatch<React.SetStateAction<string>>;
  handleSetTcDate: (isChecked: boolean) => void;
  handleSetHasAcceptedDocConsent: (isChecked: boolean) => void;
};

const defaultUpdate: React.Dispatch<React.SetStateAction<string>> = () =>
  undefined;
const LoginForm = createContext<LoginFormContextType>({
  email: '',
  password: '',
  acceptedTcDate: null,
  acceptedDocConsentDate: null,
  handleEmailChange: defaultUpdate,
  handlePasswordChange: defaultUpdate,
  handleSetTcDate: () => undefined,
  handleSetHasAcceptedDocConsent: () => undefined,
});

export const useLoginFormContext = () => useContext(LoginForm);

const LoginFormProvider = ({ children }: { children: ReactChild }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptedTcDate, setAcceptedTcDate] = useState<Date | null>(null);
  const handleSetTcDate = (isChecked: boolean) => {
    if (isChecked) {
      const newAcceptDate = new Date();
      setAcceptedTcDate(newAcceptDate);
      return;
    }

    setAcceptedTcDate(null);
  };

  const [acceptedDocConsentDate, setAcceptedDocConsentDate] =
    useState<Date | null>(null);
  const handleSetHasAcceptedDocConsent = (isChecked: boolean) => {
    if (isChecked) {
      const newAcceptDate = new Date();
      setAcceptedDocConsentDate(newAcceptDate);
      return;
    }

    setAcceptedDocConsentDate(null);
  };

  const value = useMemo(
    () => ({
      email,
      password,
      acceptedTcDate,
      acceptedDocConsentDate,
      setAcceptedTcDate,
      setAcceptedDocConsentDate,
      handleEmailChange: setEmail,
      handlePasswordChange: setPassword,
      handleSetTcDate,
      handleSetHasAcceptedDocConsent,
    }),
    [email, password, acceptedTcDate, acceptedDocConsentDate]
  );

  return <LoginForm.Provider value={value}>{children}</LoginForm.Provider>;
};

const mockContextValues = ({
  email = 'test@test.test',
  password = 'testPassword',
  acceptedTcDate = new Date(),
  acceptedDocConsentDate = new Date(),
}: {
  email?: string;
  password?: string;
  acceptedTcDate?: Date | null;
  acceptedDocConsentDate?: Date | null;
}) => ({
  email,
  password,
  acceptedTcDate,
  acceptedDocConsentDate,
  handleEmailChange: jest.fn(),
  handlePasswordChange: jest.fn(),
  handleSetTcDate: jest.fn(),
  handleSetHasAcceptedDocConsent: jest.fn(),
});

export { LoginForm, LoginFormProvider, mockContextValues };
