// 'key' returns the value of the key pressed by the user - https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key
// 'code' represents a physical key on the keyboard - https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code

type KBE = React.KeyboardEvent | KeyboardEvent;

const isSpecifiedKey =
  (keycode: string) =>
  ({ code, key }: KBE) => {
    return code === keycode || key === keycode;
  };
export const isEventSpaceKey = ({ code, key }: KBE) => {
  return key === ' ' || code === 'Space' || key === 'Spacebar';
};
export const isEventEnterKey = isSpecifiedKey('Enter');
export const isEventSpaceOrEnterKey = (e: KBE) =>
  isEventSpaceKey(e) || isEventEnterKey(e);
export const isEventTabKey = isSpecifiedKey('Tab');
export const isEventArrowDownKey = isSpecifiedKey('ArrowDown');
export const isEventArrowUpKey = isSpecifiedKey('ArrowUp');
export const isEventPageUpKey = isSpecifiedKey('PageUp');
export const isEventPageDownKey = isSpecifiedKey('PageDown');
export const isEventHomeKey = isSpecifiedKey('Home');
export const isEventEndKey = isSpecifiedKey('End');
export const isEventEscapeKey = isSpecifiedKey('Escape');
