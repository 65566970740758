import React, {
  BaseSyntheticEvent,
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import InputCheckbox from '../input-checkbox';
import { useLoginFormContext } from './context';
import {
  getPrivacyPolicyUrl,
  getTermsOfServiceUrl,
  getDxByProductName,
  getSuitableUrl,
} from '../../helpers/terms-conditions';
import './style.scss';

type LoginFormProps = {
  onSubmit: () => unknown;
  errorMessage?: string;
  showTermsConditions?: boolean;
  showDoctorConsent?: boolean;
  productName?: string;
};

export const SUBMIT_BUTTON_TEXT = 'Log In';
export const EMAIL_ADDRESS_LABEL = 'Email address';
export const PASSWORD_LABEL = 'Password';

export const LoginForm: FunctionComponent<LoginFormProps> = ({
  onSubmit,
  errorMessage,
  showTermsConditions = false,
  showDoctorConsent = false,
  productName,
}) => {
  const {
    email,
    password,
    acceptedTcDate,
    acceptedDocConsentDate,
    handleEmailChange,
    handlePasswordChange,
    handleSetTcDate,
    handleSetHasAcceptedDocConsent,
  } = useLoginFormContext();
  const [isLoading, setIsLoading] = useState(false);

  const _handlePasswordChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      handlePasswordChange(event.currentTarget.value);
    },
    [handlePasswordChange]
  );

  const _handleEmailChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      handleEmailChange(event.currentTarget.value);
    },
    [handleEmailChange]
  );

  const _handleSetTcDate = useCallback(
    ({ target: { value } }: { target: { value: boolean } }) => {
      handleSetTcDate(value);
    },
    [handleSetTcDate]
  );
  const _handleSetHasAcceptedDocConsent = useCallback(
    ({ target: { value } }: { target: { value: boolean } }) => {
      handleSetHasAcceptedDocConsent(value);
    },
    [handleSetHasAcceptedDocConsent]
  );

  const isSubmitDisabled = useMemo(
    () =>
      isLoading ||
      !(password && email) ||
      (showTermsConditions && !acceptedTcDate) ||
      (showDoctorConsent && !acceptedDocConsentDate),
    [
      isLoading,
      password,
      email,
      showTermsConditions,
      acceptedTcDate,
      showDoctorConsent,
      acceptedDocConsentDate,
    ]
  );

  const _onSubmit = useCallback(
    async (event: BaseSyntheticEvent) => {
      event.preventDefault();
      if (isSubmitDisabled) {
        return;
      }
      setIsLoading(true);

      await onSubmit();

      //not async-safe
      setIsLoading(false);
    },
    [onSubmit, setIsLoading, isSubmitDisabled]
  );

  return (
    <form className="login-form" onSubmit={_onSubmit}>
      {!!errorMessage && (
        <div
          className="login-form__error"
          data-testid="login-form-error-message"
        >
          {errorMessage}
        </div>
      )}
      <label htmlFor="login-form__email">{EMAIL_ADDRESS_LABEL}</label>
      <input
        name={'email'}
        placeholder={'Enter your email'}
        type="text"
        onChange={_handleEmailChange}
        className="login-form__email-input"
        id="login-form__email"
      />
      <label htmlFor="login-form__password">{PASSWORD_LABEL}</label>
      <input
        name={'password'}
        placeholder={'Enter your password'}
        type="password"
        id="login-form__password"
        onChange={_handlePasswordChange}
      />
      {(showTermsConditions || showDoctorConsent) && (
        <div className="login-form__termsConditions">
          {showTermsConditions && (
            <div className="login-form__termsConditions__tc_item">
              <InputCheckbox
                inputId="terms_conditions"
                required
                isChecked={!!acceptedTcDate}
                onChange={_handleSetTcDate}
                className="login-form__termsConditions__tc_item__checkbox"
              />
              <label htmlFor="terms_conditions">
                <span>
                  I agree to the{' '}
                  <a href={getPrivacyPolicyUrl()}>Privacy Policy</a> and{' '}
                  <a href={getTermsOfServiceUrl()}>Terms</a>
                </span>
              </label>
            </div>
          )}
          {showDoctorConsent && (
            <div className="login-form__termsConditions__tc_item">
              <InputCheckbox
                inputId="doctor_consent"
                required
                isChecked={!!acceptedDocConsentDate}
                onChange={_handleSetHasAcceptedDocConsent}
                className="login-form__termsConditions__tc_item__checkbox"
              />
              <label htmlFor="doctor_consent">
                <span>
                  I acknowledge that if I have been previously diagnosed{' '}
                  {getDxByProductName(productName)}, I should review the{' '}
                  <a href={getSuitableUrl()}>suitability information</a> and
                  contact my doctor
                  {!!productName && ` before using ${productName}`}
                </span>
              </label>
            </div>
          )}
        </div>
      )}
      <button
        className={`login-form__submit ${isSubmitDisabled ? 'disabled' : ''}`}
        disabled={isSubmitDisabled}
        type="submit"
      >
        {SUBMIT_BUTTON_TEXT}
      </button>
    </form>
  );
};
