/* eslint-disable max-len */
import './style.scss';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import FuzzySearch from '../../components/utils/fuzzy-search';
import ConditionalRender from '../conditional-render';
import interactiveConfig from '../triggers/triggers-config';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { onChangeEmail, onChangePassword } from '../../actions/user';
import getConfig from '../../config';

const config = getConfig();

class InputText extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectionStart: 0,
      autocompleteResults: [],
      showAutocomplete: false,
      shouldValidate: false,
    };

    this.autocompleteCloseTimeout = null;
  }

  focus() {
    const { value, selectedAnswers } = this.props;

    if (
      this.props.useAutocomplete &&
      (value.length || selectedAnswers.length)
    ) {
      const searcher = new FuzzySearch(
        this.props.details[0].answers,
        ['text'],
        {
          sort: true,
        }
      );
      const results = searcher.search(value || selectedAnswers);

      this.setState({ showAutocomplete: true, autocompleteResults: results });
    }
  }

  /**
   * Intercept onChange handler to store the current caret position
   * @param {Event} ev the triggered event
   */
  onChange(ev) {
    const changeFromAutocompleteSelect =
      ev.currentTarget.className.indexOf('sl-autocomplete-suggestion-item') >
      -1;
    let errorText = '';
    let value;

    if (
      this.props.name &&
      interactiveConfig.INPUT_FIELD_NAMES.EMAIL.indexOf(this.props.name) !== -1
    ) {
      this.props.onChangeEmail(ev.target.value);
    }

    if (
      this.props.name &&
      interactiveConfig.INPUT_FIELD_NAMES.PASSWORD.indexOf(this.props.name) !==
        -1
    ) {
      this.props.onChangePassword(ev.target.value);
    }

    if (changeFromAutocompleteSelect) {
      value = ev.target.innerText;
    } else {
      value =
        ev.target.value.split(' ').join('').length === 0 ? '' : ev.target.value;
    }

    // eslint-disable-next-line max-len
    const isValidValue = this.props.useAutocomplete
      ? !!this.props.details[0].answers.find(
          a => a.text.split(' ').join('') === value.split(' ').join('')
        )
      : true;

    if (!isValidValue && this.props.useAutocomplete) {
      errorText = config.messages.errors.autocomplete_invalid_answer_provided;
    }

    this.props.onChange(value, !isValidValue);

    if (!this.props.useAutocomplete || changeFromAutocompleteSelect || !value) {
      this.setState({
        selectionStart: this.input.selectionStart,
        showAutocomplete: false,
        shouldValidate: true,
      });
    } else {
      const searcher = new FuzzySearch(
        this.props.details[0].answers,
        ['text'],
        {
          sort: true,
        }
      );
      const results = searcher.search(value);
      this.setState({
        autocompleteResults: results,
        showAutocomplete: true,
        selectionStart: this.input.selectionStart,
        errorText: errorText,
      });
    }
  }

  preventSpaces(event) {
    const char = event.which || event.keyCode;
    if (char === 32) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  mobilePopup(id, close) {
    const element = document.getElementById(`sl-tooltip-${id}`);
    close
      ? element.classList.remove('active')
      : element.classList.toggle('active');
  }

  componentDidUpdate() {
    if (this.props.useAutocomplete) {
      setTimeout(() => {
        document.removeEventListener(
          'click',
          this._autocompleteClickedOutside.bind(this)
        );
        document.addEventListener(
          'click',
          this._autocompleteClickedOutside.bind(this)
        );
      }, 50);
    }

    const $slContent = document.querySelector('.sl-content');
    if (this.state.showAutocomplete) {
      const $autoComplete = document.querySelector(
        '.sl-autocomplete-suggestions'
      );

      $slContent.style.paddingBottom = `${$autoComplete.clientHeight}px`;
    } else {
      if ($slContent) {
        $slContent.style.paddingBottom = '';
      }
    }
  }

  componentDidMount() {
    if (this.props.selectedAnswers && !this.state.shouldValidate) {
      this.setState({
        shouldValidate: true,
      });
    }
    const isValidValue = this.props.useAutocomplete
      ? !!this.props.details[0].answers.find(
          a =>
            a.text.split(' ').join('') ===
            this.props.selectedAnswers.split(' ').join('')
        )
      : true;
    if (!isValidValue) {
      if (
        !isValidValue &&
        this.props.useAutocomplete &&
        this.state.shouldValidate
      ) {
        const errorText =
          config.messages.errors.autocomplete_invalid_answer_provided;
        this.setState({ errorText: errorText });
      }
      this.props.onChange(this.props.selectedAnswers, !isValidValue);
    }

    if (this.props.useAutocomplete) {
      setTimeout(() => {
        document.removeEventListener(
          'click',
          this._autocompleteClickedOutside.bind(this)
        );
        document.addEventListener(
          'click',
          this._autocompleteClickedOutside.bind(this)
        );
      }, 50);
    }
  }

  _autocompleteClickedOutside(event) {
    if (!this._isDescendant(this.container, event.target)) {
      window.clearTimeout(this.autocompleteCloseTimeout);
      this.autocompleteCloseTimeout = setTimeout(() => {
        if (this.props.useAutocomplete) {
          this.setState({
            showAutocomplete: false,
            shouldValidate: true,
            autocompleteResults: [],
          });
        }
      }, 200);
    }
  }

  _isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  render() {
    let placeholderText = null;
    let tooltipText = '';

    const {
      preventSpaces,
      disabled,
      value,
      className,
      required,
      inputId,
      selectedAnswers,
      wrapperClass,
      type,
      lang,
      details,
      maxLength,
      minLength,
      error,
      placeholder,
      readOnly,
    } = this.props;
    const errorText = this.state.errorText || this.props.errorText;

    let wrapperClassNames = `sl-container-text-input ${
      this.props.isChecked && !value && value !== 0
        ? 'sl-container-text-input--has-error '
        : ' '
    }`;
    details &&
      details.forEach(item => {
        if (!item.lang || item.lang === lang) {
          placeholderText = item.placeholder;
          if (item.tooltip) {
            tooltipText = item.tooltip;
            wrapperClassNames += 'sl-container-text-input--has-tooltip ';
          }
          if (item.hasImage) {
            wrapperClassNames += 'sl-tooltip-image ';
          }
        }
      });
    if (error && this.state.shouldValidate) {
      wrapperClassNames += 'sl-container-text-input--has-error ';
    }
    if (wrapperClass) {
      wrapperClassNames += wrapperClass;
    }
    return (
      <div ref={c => (this.container = c)} className={wrapperClassNames}>
        <input
          ref={c => (this.input = c)}
          type={type || 'text'}
          id={this.props.id || inputId}
          name={this.props.name || inputId}
          className={`sl-input-text ${className || ''}`}
          maxLength={maxLength || null}
          minLength={minLength || null}
          placeholder={placeholder || placeholderText}
          value={selectedAnswers || value || ''}
          required={required}
          onKeyDown={this._onKeyDownListener.bind(this, preventSpaces)}
          disabled={disabled}
          readOnly={readOnly}
          onChange={this.onChange.bind(this)}
          onFocus={this.focus.bind(this)}
        />

        <ConditionalRender if={this.state.showAutocomplete}>
          <ul
            className="sl-autocomplete-suggestions"
            ref={c => (this.autocompleteElem = c)}
            onMouseMove={this._onAutocompleteSuggestionMouseMove.bind(this)}
          >
            {this._getAutocompleteSuggestionList()}
          </ul>
        </ConditionalRender>

        {tooltipText ? (
          <div className="sl-input-tooltip" id={`sl-tooltip-${this.props.id}`}>
            <span
              className="sl-question-mark"
              onClick={this.mobilePopup.bind(this, this.props.id, false)}
            >
              ?
            </span>
            <div className="sl-tooltip-text-wrapper">
              <div
                className="sl-close-tooltip"
                onClick={this.mobilePopup.bind(this, this.props.id, true)}
              >
                x
              </div>
              <div
                className="sl-tooltip-text"
                dangerouslySetInnerHTML={{ __html: tooltipText }}
              />
            </div>
          </div>
        ) : null}

        {error && errorText ? (
          <div className="sl-input-text__error">{errorText}</div>
        ) : null}
      </div>
    );
  }

  _onKeyDownListener(preventSpaces, event) {
    if (preventSpaces) {
      this.preventSpaces(event);
    }

    if (this.props.useAutocomplete) {
      this._autocompleteInputListeners(event);
    }
  }

  _autocompleteInputListeners(event) {
    if (!this.autocompleteElem) {
      return;
    }

    const key = event.which || event.keyCode;
    const mouseHovered = this.autocompleteElem.querySelector('li.hoverMouse');
    const hoveredListItem =
      mouseHovered || this.autocompleteElem.querySelector('li.hover');
    // eslint-disable-next-line max-len
    if (mouseHovered) {
      mouseHovered.className = mouseHovered.className.replace(
        ' hoverMouse',
        ''
      );
    }
    let isChangingClass = false;

    const totalListItems = this.autocompleteElem.querySelectorAll('li').length;

    let tabIndex = hoveredListItem
      ? hoveredListItem.getAttribute('tabindex')
      : null;
    tabIndex = parseInt(tabIndex, 10);

    switch (key) {
      case 38: // Arrow up
        tabIndex =
          isFinite(tabIndex) && tabIndex > 0
            ? tabIndex - 1
            : totalListItems - 1;
        isChangingClass = true;
        event.preventDefault();
        break;
      case 40: // Arrow down
        tabIndex =
          isFinite(tabIndex) && tabIndex < totalListItems - 1
            ? tabIndex + 1
            : 0;
        isChangingClass = true;
        event.preventDefault();
        break;
      case 13: // Enter
        this.onChange({
          currentTarget: hoveredListItem,
          target: hoveredListItem,
        });
    }

    if (isChangingClass) {
      if (hoveredListItem) {
        hoveredListItem.className = hoveredListItem.className.replace(
          ' hover',
          ''
        );
      }

      const nextElem = this.autocompleteElem.querySelector(
        `li[tabindex="${tabIndex}"]`
      );
      nextElem.className = `${nextElem.className} hover`;
      nextElem.parentElement.scrollTop = nextElem.offsetTop;
    }
  }

  _onAutocompleteSuggestionMouseEnter(e) {
    if (!this.autocompleteElem.querySelector('li.hover')) {
      e.target.className += ' hoverMouse';
    }
  }

  _onAutocompleteSuggestionMouseLeave(e) {
    e.target.className = e.target.className.replace(' hoverMouse', '');
  }

  _onAutocompleteSuggestionMouseMove(e) {
    const hovered = this.autocompleteElem.querySelector('li.hover');
    // eslint-disable-next-line max-len
    if (hovered) {
      hovered.className = hovered.className.replace(' hover', '');
    }
  }

  _getAutocompleteSuggestionList() {
    if (!this.props.useAutocomplete) {
      return null;
    }
    const data = this.state.autocompleteResults;
    const maxSuggestions =
      this.props.details[0].max_visible_answers ||
      this.props.details[0].answers.length;
    let suggestions = data.slice(0, maxSuggestions);
    let list = [];
    let defaultAnswer;

    if (
      this.props.defaultAnswers.length &&
      this.props.defaultAnswers[0] !== ''
    ) {
      const defaultAnswerIndex = this.props.details[0].answers.findIndex(
        answer => answer.id === this.props.defaultAnswers[0]
      );
      defaultAnswer = this.props.details[0].answers[defaultAnswerIndex];

      const defaultAnswerIndexInSuggestions = suggestions.findIndex(
        a => a.id === defaultAnswer.id
      );
      if (defaultAnswerIndexInSuggestions >= 0) {
        suggestions.splice(defaultAnswerIndexInSuggestions, 1);
      }
    }

    list = suggestions.map((item, index) => (
      <Fragment key={index}>
        <li
          onClick={this.onChange.bind(this)}
          className="sl-autocomplete-suggestion-item"
          onMouseEnter={this._onAutocompleteSuggestionMouseEnter.bind(this)}
          onMouseLeave={this._onAutocompleteSuggestionMouseLeave.bind(this)}
          onMouseMove={this._onAutocompleteSuggestionMouseMove.bind(this)}
          tabIndex={index}
        >
          {item.text}
        </li>
      </Fragment>
    ));

    if (
      this.props.defaultAnswers.length &&
      this.props.defaultAnswers[0] !== ''
    ) {
      list.push(
        <Fragment key="default">
          <li
            onClick={this.onChange.bind(this)}
            data-default="true"
            onMouseEnter={this._onAutocompleteSuggestionMouseEnter.bind(this)}
            onMouseLeave={this._onAutocompleteSuggestionMouseLeave.bind(this)}
            className="sl-autocomplete-suggestion-item"
            tabIndex={suggestions.length}
          >
            {defaultAnswer.text}
          </li>
        </Fragment>
      );
    }

    return list;
  }
}

InputText.propTypes = {
  id: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  preventSpaces: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.any,
  details: PropTypes.any,
  lang: PropTypes.any,
  error: PropTypes.any,
  errorText: PropTypes.any,
  name: PropTypes.string,
  inputId: PropTypes.string,
  maxLength: PropTypes.number,
  selectedAnswers: PropTypes.any,
  isChecked: PropTypes.bool,
  minLength: PropTypes.number,
  useAutocomplete: PropTypes.bool.isRequired,
  defaultAnswers: PropTypes.any.isRequired,
  onChangeEmail: PropTypes.any.isRequired,
  email: PropTypes.any.isRequired,
  onChangePassword: PropTypes.any.isRequired,
  password: PropTypes.any.isRequired,
};

const mapStateToProps = state => {
  return {
    email: state.userReducer.userEmail,
    password: state.userReducer.userPassword,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangeEmail: bindActionCreators(onChangeEmail, dispatch),
    onChangePassword: bindActionCreators(onChangePassword, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InputText);
