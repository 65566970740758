import { FocusEventHandler, FunctionComponent, KeyboardEvent } from 'react';
import {
  isMappedQuestion,
  MappedQuestionContentAnswer,
  QuestionContent,
  QuestionContentAnswer,
} from '../../types/questions.types';
import QuestionGroupComponent from '../question-group-component/question-group.component';
import {
  labelTextStyle,
  radioAnswerStyle,
  wrapperStyle,
  titleStyle,
} from './style';
import { noop } from '../../props/empty';
import ErrorMessage from '../errors/error-message/error-message.component';
import { isEventEnterKey } from '../../helpers/event-helpers';
import {
  questionPostTitleStyle,
  questionPreTitleStyle,
  questionTitleStyle,
  questionTitleWrapperStyle,
} from '../question-component/question.styles';

type AnswerType = QuestionContentAnswer | MappedQuestionContentAnswer;

export type RadioInputTitleTextProps = Pick<
  QuestionContent,
  'title' | 'pre_question_text' | 'post_question_text'
>;

export type InputRadioProps = {
  id: number | string;
  items: MappedQuestionContentAnswer[] | QuestionContentAnswer[];
  selectedAnswer:
    | QuestionContentAnswer
    | MappedQuestionContentAnswer
    | null
    | undefined;
  belongsTo: string | null;
  className?: string;
  disabled?: boolean;
  isChecked: boolean;
  isMapped?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions?: any;
  onAnswer?: () => void;
  handleCheck: (selectedItem: AnswerType) => void;
  inputId: string;
  questionTitle: RadioInputTitleTextProps;
};

export const RadioInputTitleText = (props: RadioInputTitleTextProps) => {
  return (
    <div
      css={[questionTitleWrapperStyle, titleStyle]}
      id="question-input-radio-title"
    >
      {props.pre_question_text && (
        <p
          css={questionPreTitleStyle}
          dangerouslySetInnerHTML={{ __html: props.pre_question_text }}
          data-testid="pre-question-text"
        />
      )}
      <legend css={questionTitleStyle} data-testid="question-title">
        {props.title}
      </legend>
      {props.post_question_text && (
        <p
          css={questionPostTitleStyle}
          dangerouslySetInnerHTML={{ __html: props.post_question_text }}
          data-testid="post-question-text"
        />
      )}
    </div>
  );
};
export const QuestionTitleText = (props: {
  details: QuestionContent;
  htmlFor: string;
}) => {
  return (
    <div css={questionTitleWrapperStyle}>
      {props.details.pre_question_text && (
        <p
          css={questionPreTitleStyle}
          dangerouslySetInnerHTML={{ __html: props.details.pre_question_text }}
          data-testid="pre-question-text"
        />
      )}
      <div
        id={props.htmlFor}
        css={questionTitleStyle}
        data-testid="question-title"
      >
        <legend>{props.details.title}</legend>
      </div>
      {props.details.post_question_text && (
        <p
          css={questionPostTitleStyle}
          dangerouslySetInnerHTML={{ __html: props.details.post_question_text }}
          data-testid="post-question-text"
        />
      )}
    </div>
  );
};

const isAnswerSelected = (
  answer: AnswerType,
  selectedAnswer: AnswerType | null | undefined
) => {
  if (!selectedAnswer) {
    return false;
  }

  return answer.id === selectedAnswer.id && answer.text === selectedAnswer.text; // score?
};

export const ERROR_TEXT = 'Select an answer to continue';

export const RadioInput: FunctionComponent<InputRadioProps> = ({
  id,
  items,
  inputId,
  selectedAnswer,
  belongsTo,
  disabled,
  isChecked,
  isMapped,
  actions,
  onAnswer,
  handleCheck,
  className,
  questionTitle,
}) => {
  const hasNoAnswerSelected =
    selectedAnswer?.id === '-1' || selectedAnswer?.id === undefined;

  const _onSelect = (item: AnswerType) => () => {
    if (item.id !== null) {
      handleCheck(item);
    }
  };

  const _onKeyDown = (item: AnswerType) => (event: KeyboardEvent) => {
    if (isEventEnterKey(event)) {
      _onSelect(item)();
    }
  };

  const onLabelFocus: FocusEventHandler<HTMLDivElement> = e => {
    const inputs = e.currentTarget.getElementsByTagName('input');
    if (inputs.length > 0) {
      // replace with "click" if expected behaviour should be same as arrow up/down
      inputs[0].focus();
    }
  };

  return (
    <fieldset
      role="radiogroup"
      css={wrapperStyle}
      className={className}
      aria-labelledby="question-input-radio-title"
    >
      <RadioInputTitleText {...questionTitle} />
      {items.map((item, idx) => (
        <div
          key={idx + 1}
          tabIndex={0}
          css={radioAnswerStyle}
          onKeyDown={_onKeyDown(item)}
          className={isAnswerSelected(item, selectedAnswer) ? 'selected' : ''}
          onClick={_onSelect(item)}
          data-testid="question-input-radio-div"
          onFocus={onLabelFocus}
        >
          <input
            data-testid="question-input-radio"
            id={`${id}_${idx}`}
            name={inputId}
            checked={isAnswerSelected(item, selectedAnswer)}
            autoFocus={isAnswerSelected(item, selectedAnswer)}
            type="radio"
            role="radio"
            disabled={disabled}
            tabIndex={-1}
            onKeyDown={_onKeyDown(item)}
            onChange={noop}
          />
          <label htmlFor={`${id}_${idx}`} css={labelTextStyle}>
            {item.text}
          </label>
          {onAnswer && isMapped && isMappedQuestion(item) && (
            <>
              <QuestionGroupComponent
                hidden={true}
                belongsTo={`${belongsTo}-option-${idx}`}
                content={item.selected}
                actions={actions}
                onAnswer={onAnswer}
                selected={`${selectedAnswer?.id}` === `${idx}`}
              />
              <QuestionGroupComponent
                hidden={true}
                belongsTo={`${belongsTo}-option-${item.id}`}
                content={item.not_selected}
                actions={actions}
                onAnswer={onAnswer}
                selected={`${selectedAnswer?.id}` === `${idx}`}
              />
            </>
          )}
        </div>
      ))}
      {isChecked && hasNoAnswerSelected && (
        <ErrorMessage errorMessage={ERROR_TEXT} />
      )}
    </fieldset>
  );
};
