import React, { memo } from 'react';
import { buttonDefaultStyle, wrapperStyle } from './themed.input-button.style';

export type InputButtonProps = {
  onClick: (event?: React.SyntheticEvent) => void;
  value: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  loading?: boolean;
};

export const InputButton_TEST_ID = 'input-button-component';

const InputButton = ({
  onClick,
  value,
  className,
  type = 'button',
  disabled = false,
  loading = false,
}: InputButtonProps) => {
  return (
    <div css={wrapperStyle} data-testid={InputButton_TEST_ID}>
      <button
        type={type}
        onClick={onClick}
        role="button"
        disabled={disabled || loading}
        className={className}
        css={buttonDefaultStyle}
      >
        {value}
      </button>
    </div>
  );
};

export default memo(InputButton);
