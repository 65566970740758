import * as Sentry from '@sentry/react';
import { doGuessRegion } from '../../helpers/doGuessRegion';
import { forcePageRefresh } from '../../helpers/forcePageRefresh';
import { initializeRealUserMeasurements } from '../../analytics/real-user-measurements/real-user-measurments';
import { initializeLogger } from '../../analytics/logger/logger';
import { getHeap } from '../../services/analytics/utils/getHeap';
import GaService from '../../components/new-auth/services/ga';
import EventService from '../../services/event-service';
import { logError } from '../../analytics/logger/logger';

let previousCookieLevels = [];

export const getHeapIdBasedOnRegion = region => {
  if (window.big_health && window.big_health.heap_analytics_id) {
    return window.big_health.heap_analytics_id[region];
  }
  return '';
};

export const doCookieAssessment = async cookieLevel => {
  const eventServicePromise = Promise.resolve(
    EventService.cookieConsent(cookieLevel)
  );
  const region = doGuessRegion();
  // Init
  if (
    cookieLevel.includes('performance') &&
    !previousCookieLevels.includes('performance')
  ) {
    if (region === 'USA') {
      getHeap()?.load?.(getHeapIdBasedOnRegion(region));
    }
    initializeRealUserMeasurements();
    initializeLogger();
    GaService.initialize();
    if (window.SENTRY_DSN) {
      Sentry.init({ dsn: window.SENTRY_DSN });
    }
  }
  // De-init
  if (
    !cookieLevel.includes('performance') &&
    previousCookieLevels.includes('performance')
  ) {
    previousCookieLevels = cookieLevel;
    eventServicePromise
      .then(() => {
        forcePageRefresh(); // Trigger reload to unload HeapAnalytics
      })
      .catch(e => {
        logError(e);
      });
  }
  previousCookieLevels = cookieLevel;
};

/**
 * @type {UserConfig}
 */
export const pluginConfig = {
  current_lang: 'en',
  autoclear_cookies: true, // default: false
  page_scripts: true, // default: false

  // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
  // delay: 0,                               // default: 0
  // auto_language: null                     // default: null; could also be 'browser' or 'document'
  autorun: true, // default: true
  // force_consent: false,                   // default: false
  // hide_from_bots: false,                  // default: false
  // remove_cookie_tables: false             // default: false
  // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
  // cookie_expiration: 182,                 // default: 182 (days)
  // cookie_necessary_only_expiration: 182   // default: disabled
  // cookie_domain: location.hostname,       // default: current domain
  // cookie_path: '/',                       // default: root
  // cookie_same_site: 'Lax',                // default: 'Lax'
  // use_rfc_cookie: false,                  // default: false
  // revision: 0,                            // default: 0

  onFirstAction: function (user_preferences, cookie) {
    // callback triggered only once
    const analyticsEnabled = window.CC.allowedCategory('analytics');
  },

  // On load
  onAccept: function (cookie) {
    doCookieAssessment(cookie.level);
  },

  // Use UI
  onChange: function (cookie, changed_preferences) {
    doCookieAssessment(cookie.level);
  },

  languages: {
    en: {
      consent_modal: {
        title: 'We use cookies',
        description:
          'Our websites use cookies to enhance your browsing experience and to analyze performance and traffic on our site. Some are <b class="cc-font-style">necessary cookies</b> for our site to function properly. <b class="cc-font-style">Other cookies</b> are optional and help tailor your experience, including analytics and advertising. <button type="button" data-cc="c-settings" class="cc-link">Manage cookies</button>',
        primary_btn: {
          text: 'Accept other cookies',
          role: 'accept_all', // 'accept_selected' or 'accept_all'
        },
        secondary_btn: {
          text: 'Reject other cookies',
          role: 'accept_necessary', // 'settings' or 'accept_necessary'
        },
      },
      settings_modal: {
        title: 'Cookie Settings',
        save_settings_btn: 'Save settings',
        accept_all_btn: 'Accept other cookies',
        reject_all_btn: 'Reject other cookies',
        close_btn_label: 'Close',
        cookie_table_headers: [
          { col1: 'Name' },
          { col2: 'Domain' },
          { col3: 'Expiration' },
          { col4: 'Description' },
        ],
        blocks: [
          {
            description:
              'This site uses cookies mainly to enhance and analyze your website experience and for marketing purposes. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and manage your preferences. Blocking some types of cookies may impact your experience of the site and the services we are able to offer. For more details, please view our <a href="https://info.sleepio.com/privacy" class="cc-link">privacy policy</a>.',
          },
          {
            title: 'Strictly necessary cookies',
            description:
              'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.',
            toggle: {
              value: 'necessary',
              enabled: true,
              readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
            },
          },
          {
            title: 'Functional cookies',
            description:
              'These cookies enable the website to provide enhanced functionality. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.',
            toggle: {
              value: 'functional', // your cookie category
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'Targeting cookies',
            description:
              'These cookies are used to make advertising messages more relevant to you and may be set through our site by us or by our advertising partners. They may be used to build a profile of your interests and show you relevant advertising on our site or on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device.',
            toggle: {
              value: 'targeting',
              enabled: false,
              readonly: false,
            },
          },
          {
            title: 'Performance cookies',
            description:
              'These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.',
            toggle: {
              value: 'performance',
              enabled: false,
              readonly: false,
            },
          },
        ],
      },
    },
  },
};

export default pluginConfig;
