import { css } from '@emotion/react';
import { BHTheme } from '../../../types/bh-themes.types';

export const wrapperStyle = (theme: BHTheme) =>
  css({
    paddingTop: theme.padding.SMALL,
  });

export const messageStyle = (theme: BHTheme) =>
  css({
    color: theme.errors.color,
    margin: 0,
    fontSize: theme.fonts.fontSizes.SMALL,
  });
