import { guessRegion } from './utils/guessRegion';
import { cleanError } from './utils/cleanError';
import { debugGetRegion } from './utils/debug';

export type Regions =
  | 'USA' // 230821: Used for choosing US Heap instance.
  | 'Europe' // Soon: Used for choosing UK Heap instance
  | 'Other/Unknown'; // Unknown, not tracked

export const doGuessRegion = () => {
  const debugRegion = debugGetRegion();
  if (debugRegion) {
    return debugGetRegion();
  }
  try {
    const languageGuess = guessRegion.byNavigatorLanguage(navigator.language);
    const timeZoneGuess = guessRegion.byTimeZone(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    if (languageGuess === timeZoneGuess) {
      return languageGuess; // Consensus
    }
    return 'Other/Unknown'; // No consensus
  } catch (error) {
    // PRIVACY CONCERN: Cannot be allowed to leak positional data into logs
    throw cleanError(error);
  }
};
