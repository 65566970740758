const REPORT_VIEWED_STORAGE_KEY = 'sl-report-viewed-${flowId}-${userId}';
const REPORT_EMAIL_SENT = 'sent-report-${userId}';

class ReportService {
  static markReportAsEmailSent(userId) {
    const storageKey = REPORT_EMAIL_SENT.replace(/\${userId}/gi, userId);
    localStorage.setItem(storageKey, 'true');
  }

  static getReportEmailSentForUserId(userId) {
    const storageKey = REPORT_EMAIL_SENT.replace(/\${userId}/gi, userId);
    return localStorage.getItem(storageKey) === 'true';
  }

  static markReportAsViewed(flowId, userId) {
    const storageKey = REPORT_VIEWED_STORAGE_KEY.replace(
      /\${userId}/gi,
      userId
    ).replace(/\${flowId}/gi, flowId);
    localStorage.setItem(storageKey, 'true');
  }

  static getReportViewed(flowId, userId) {
    const storageKey = REPORT_VIEWED_STORAGE_KEY.replace(
      /\${userId}/gi,
      userId
    ).replace(/\${flowId}/gi, flowId);
    return localStorage.getItem(storageKey) === 'true';
  }
}

export default ReportService;
