import { UtmParameters } from '../constants/utm-parameters';
import Cookies from '../cookies';

export type UtmData = {
  [UtmParameters.UTM_CAMPAIGN]: string | null;
  [UtmParameters.UTM_MEDIUM]: string | null;
  [UtmParameters.UTM_SOURCE]: string | null;
};

/**
 * generates UTM param data from the current window.location's href. This accounts for UTM data being in the hash
 * instead of in the expected window.location.search, due to any URL rewriting we may have done
 */
export function getUtmParamsFromLocation({ href }: Location): UtmData {
  const url = new URL(href);

  // prefer search to hash. Due to URL rewriting, the search params can get munged onto the hash
  const params = url.search
    ? url.searchParams
    : new URLSearchParams(url.hash.split('?')[1]);
  return {
    [UtmParameters.UTM_CAMPAIGN]: params.get(UtmParameters.UTM_CAMPAIGN),
    [UtmParameters.UTM_MEDIUM]: params.get(UtmParameters.UTM_MEDIUM),
    [UtmParameters.UTM_SOURCE]: params.get(UtmParameters.UTM_SOURCE),
  };
}

/**
 * Converts UTM param data back into the search string typically appended to URLs
 * @param params the UTM param data
 */

export function convertUtmParamsToSearchString(params: UtmData): string {
  const resultingSearchString = Object.entries(params).reduce((acc, curr) => {
    const [key, val] = curr;

    // first
    if (acc === '' && val) {
      return `?${key}=${val}`;
    }

    // any subsequent
    if (val) {
      return `${acc}&${key}=${val}`;
    }

    return acc;
  }, '');

  return resultingSearchString;
}

/**
 * Gets the UTMsearch param string from cookie values
 */
export function getUtmSearchStringFromCookies(): string {
  const campaign = Cookies.get(UtmParameters.UTM_CAMPAIGN);
  const medium = Cookies.get(UtmParameters.UTM_MEDIUM);
  const source = Cookies.get(UtmParameters.UTM_SOURCE);

  let utm = '';
  if (campaign) {
    utm += campaign ? `${UtmParameters.UTM_CAMPAIGN}=${campaign}&` : '';
  }
  if (medium) {
    utm += medium ? `${UtmParameters.UTM_MEDIUM}=${medium}&` : '';
  }
  if (source) {
    utm += source ? `${UtmParameters.UTM_SOURCE}=${source}` : '';
  }

  return utm;
}

export const getUrlParamsWithSeparator = () =>
  getUtmSearchStringFromCookies() ? `?${getUtmSearchStringFromCookies()}` : '';

export function getRedirectParamFromLocation({ href }: Location): boolean {
  const url = new URL(href);
  const params = url.search
    ? url.searchParams
    : new URLSearchParams(url.hash.split('?')[1]);
  if (params.get('bypass') === 'true') {
    return true;
  }
  return false;
}
