import { css, Theme } from '@emotion/react';

export const progressTracker = (theme: Theme) =>
  css({
    marginTop: theme.padding.MEDIUM,
  });

export const pageWrapper = (theme: Theme) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    fontSize: theme.fonts.fontSizes.MEDIUM,

    // footer
    '> :last-child': {
      marginTop: 'auto',
    },
  });

export const nextButton = (theme: Theme) =>
  css({
    marginTop: theme.padding.EXTRA_LARGE,
  });

export const flowpageContent = (theme: Theme) =>
  css({
    padding: `0 ${theme.padding.LARGE}`,
  });

export const ttdFlowpageContent = () =>
  css({
    padding: `0 0`,
  });
