import { Validator } from '../components/utils/validator';
import getConfig from '../config';
import Cookies from '../cookies';
import FlowService, { FLOW_LINKED_TO_PLATGEN_LOGIN } from './flow-service';
import * as SignupService from './signup-service';
import { logoutUser } from '../actions/user';
import { getUtmSearchStringFromCookies } from '../helpers/utm-params';

const STORAGE_USER_DATA = 'sl-user-data';
const TEMP_STORAGE_USER_DATA = 'temporary_user_data';
const ACCOUNT_CREATED_KEY = 'sl-sent-account-created-event';
const ELIGIBILITY_DETAILS = 'sl-eligibility-details';
const EMAILED_REPORTS = 'sl-emailed-reports';

let metadata;

const UserSession = {
  getSelectedLanguage() {
    return getConfig().defaultLanguage;
  },

  logout() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return logoutUser(() => {}).then(resData => {
      return resData;
    });
  },

  async logOutAsync() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return logoutUser(() => {}).then(resData => {
      return resData;
    });
  },

  /**
   * Get the user data Object or null if it doesn't exist
   * @returns {Any|null}
   */
  getUserData() {
    const userData = JSON.parse(localStorage.getItem(STORAGE_USER_DATA));
    return !Validator.isEmpty(userData) ? userData : null;
  },

  getEmailedReport(flow_id, report_id) {
    let emailedReports = Cookies.get(EMAILED_REPORTS) || '[]';
    emailedReports = JSON.parse(decodeURIComponent(emailedReports));

    try {
      return !!emailedReports.find(report => {
        return (
          parseInt(report.flow_id, 10) === parseInt(flow_id, 10) &&
          parseInt(report.report_id, 10) === parseInt(report_id, 10)
        );
      });
    } catch (ignore) {
      return [];
    }
  },

  setTempUserData(data) {
    localStorage.setItem(TEMP_STORAGE_USER_DATA, data);
  },

  getTempUserData() {
    const userData = localStorage.getItem(TEMP_STORAGE_USER_DATA);
    return !Validator.isEmpty(userData) ? JSON.parse(userData) : null;
  },

  getClientMetadata() {
    metadata = metadata || {
      http: {
        request_headers: {
          // Page/Step View events may be fired prior to consent, so we need to
          // ensure that the user's IP Address is not set.
          // X-Forwarded-For is a required field of the event schema, so we must
          // set it to null.
          'X-Forwarded-For': null,
          'User-Agent': navigator.userAgent,
        },
      },
      client_session_id: SignupService.getUserSessionId(),
    };

    const utm = getUtmSearchStringFromCookies();

    metadata.http.request_headers.Referrer =
      window.location.host + window.location.pathname + window.location.hash;
    metadata.http.request_headers.Referrer += utm ? `?${utm}` : '';

    return metadata;
  },

  getSentAccountCreated() {
    return Boolean(localStorage.getItem(ACCOUNT_CREATED_KEY));
  },

  setSentAccountCreated() {
    localStorage.setItem(ACCOUNT_CREATED_KEY, 'true');
  },

  removeSentAccountCreated() {
    localStorage.removeItem(ACCOUNT_CREATED_KEY);
  },

  setNotNullableEligibilityDetails(details) {
    if (details) {
      localStorage.setItem(ELIGIBILITY_DETAILS, JSON.stringify(details));
    }
  },

  clearEligibilityDetails() {
    localStorage.removeItem(ELIGIBILITY_DETAILS);
  },

  getEligibilityDetails() {
    const details = JSON.parse(localStorage.getItem(ELIGIBILITY_DETAILS));
    return !Validator.isEmpty(details) ? details : {};
  },

  /**
   * Set user data in local storage, if any. Else, it removes it.
   * @param {Object} data user data from server
   */
  setUserData(data) {
    // TODO (Alex): If you want to remove the user data, there should be a another function which does this. When calling
    //  setUserData() it's expected it will always set user data, not delete it!
    if (!data.is_authenticated) {
      UserSession.removeUserData();
      return;
    }

    const userData = FlowService.isPostFlow()
      ? { user_id: data.id, signup_completed: data.signup_completed }
      : {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          email_opt_in: data.email_opt_in,
          idp_name: data.idp_name,
          idp_id: data.idp_id,
          fit_bit_token: data.fitbit_token,
          user_id: data.id,
          user_linked_to_platgen: data.linked_to_platgen || false,
          completed_flow: data.completed_flow,
          signup_completed: data.signup_completed,
          product_ids: data.product_ids,
        };

    // delete to prevent from being persisted in local storage
    delete userData.email;

    localStorage.setItem(STORAGE_USER_DATA, JSON.stringify(userData));
  },

  removeTempUserData() {
    localStorage.removeItem(TEMP_STORAGE_USER_DATA);
  },

  /** Clear locally stored user data */
  removeUserData() {
    this.removeSentAccountCreated();
    localStorage.removeItem(STORAGE_USER_DATA);
    Cookies.remove(FLOW_LINKED_TO_PLATGEN_LOGIN);
  },
};

export default UserSession;
