'use strict';
import getConfig from '../config';
const SERVER_URI = getConfig().server;

import doAjax from './do-ajax';
import Cookies from '../cookies';
import * as Sentry from '@sentry/react';

export const ACTION_TYPES = {
  SIGNUP: 'SIGNUP',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  ME: 'ME',
  RESETPASSWORD: 'RESETPASSWORD',
  FORGOTPASSWORD: 'FORGOTPASSWORD',
  USERINFO: 'USERINFO',
};
export const ENDPOINTS = {
  [ACTION_TYPES.SIGNUP]: { name: 'Signup', url: '/auth/signup/' },
  [ACTION_TYPES.LOGIN]: { name: 'Login', url: '/auth/login/' },
  [ACTION_TYPES.LOGOUT]: { name: 'Logout', url: '/auth/logout/' },
  [ACTION_TYPES.ME]: { name: 'Me', url: '/auth/me/' },
  [ACTION_TYPES.RESETPASSWORD]: {
    name: 'ResetPassword',
    url: '/auth/reset_password/',
  },
  [ACTION_TYPES.FORGOTPASSWORD]: {
    name: 'ForgotPassword',
    url: '/auth/forgot_password/',
  },
  [ACTION_TYPES.USERINFO]: { name: 'UserInfo', url: '/auth/update_user_info/' },
};

class UserApiActions {
  /**
   * Get url for signup/login
   *
   * @param {String} entity entity for which the request is made
   * @param {String} action API Actions
   * @param {String} [redirectUri] redirect URL
   * @returns {Promise}
   */
  static getUrl({ action, redirectUri }) {
    const theAction =
      ENDPOINTS[
        Object.keys(ENDPOINTS).find(
          e => e.toLowerCase() === action.toLowerCase()
        )
      ];
    const url = redirectUri
      ? theAction.url.concat(`?next=${redirectUri}`)
      : theAction.url;
    return SERVER_URI + url;
  }

  /**
   * Make a GET request
   *
   * @param {String} entity entity for which the request is made
   * @param {String} [method] (optional) If none provided GET is used
   * @param {String} [query] Query string
   * @param {Function} [callback] Function to be run after the server responds
   * @returns {Promise}
   */
  static get({ action, query, callback }) {
    const request = {
      ...constructEndpointRequest({ action, method: 'get' }),
      query,
    };
    return this.makeRequest({ request, callback });
  }

  /**
   * @typedef Post
   * @param {String} action action for which the request is made
   * @param {Object} data The data to be inserted. In case of {@link METHODS.UPDATE}
   * this object has to be in the form: { entity_id, attributes }
   * @param {Function} [callback] Function to be run after the server responds
   */

  /**
   * Make a POST JSON request
   * @param {Post}
   * @returns {Promise}
   */
  static post({ action, redirectUri, data, callback }) {
    const request = {
      ...constructEndpointRequest({
        action,
        redirectUri,
        method: 'post',
        data,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    return this.makeRequest({ request, callback });
  }

  /**
   * Make a generic request
   *
   * @param {Object} request Request to be made. Must be of the form: {method, url, query [optional]}
   * @param {Function} [callback] Function to be run after the server responds
   * @returns {Promise}
   */
  static async makeRequest({ request, callback }) {
    let headers = request.headers || {};

    // Don't set the body if it's a GET request as it will crash on Microsoft Edge
    const params = {
      headers,
      method: request.method || 'GET',
      credentials: request.credentials || 'same-origin',
    };
    if (params.method !== 'GET') {
      params.body = request.body || null;
    }
    if (request.query) {
      request.url += `?${request.query}`;
    }

    const serverUri = request.serverUri || SERVER_URI;

    const uuidBefore = Cookies.get('sl-user-uuid');
    const sessionIdBefore = Cookies.get('sl-session-id');

    const response = await doAjax(serverUri + request.url, params);

    const uuidAfter = Cookies.get('sl-user-uuid');
    const sessionIdAfter = Cookies.get('sl-session-id');

    Sentry.configureScope(scope => {
      scope.setTag('BEFORE_UUID ', `${uuidBefore}`);
      scope.setTag('BEFORE_SESSIONID ', `${sessionIdBefore}`);
      scope.setTag('AFTER_UUID ', `${uuidAfter}`);
      scope.setTag('AFTER_SESSIONID ', `${sessionIdAfter}`);
    });

    // If we have a response, return it
    if (response) {
      if (callback) {
        callback({ response });
      }

      return response;
    }

    // Else, go to the error page
    history.push(`/${window.product_name}/error`);
  }
}

/**
 * Helper method that constructs the endpoint for user microservices
 * @param {String} action action (one of {@link ENDPOINTS} names)
 * @param {String} method method name (one of {@link METHODS})
 * @param {Object} [data] Only required for CREATE and UPDATE
 * @returns {{url: string, method: string}}
 */
function constructEndpointRequest({ action, redirectUri, method, data }) {
  const theAction =
    ENDPOINTS[
      Object.keys(ENDPOINTS).find(e => e.toLowerCase() === action.toLowerCase())
    ];
  const restMethod = method.toLowerCase().indexOf('get') === 0 ? 'GET' : 'POST';
  const url =
    restMethod === 'GET'
      ? theAction.url
      : redirectUri
      ? theAction.url.concat(`?next=${redirectUri}`)
      : theAction.url;
  return { url, method: restMethod, body: JSON.stringify(data) };
}

export default UserApiActions;
