/* GA replace tokens */
export const TOKENS = {
  FLOW_FUID: '{fuid}',
  STEP_SUID: '{suid}',
  PAGE_PUID: '{puid}',
  TITLE: '{title}',
  VALUE: '{value}',
};

/* GA event categories */
export const CATEGORIES = {
  FLOW: `Flow ${TOKENS.FLOW_FUID}`,
  STEP: `Step ${TOKENS.STEP_SUID}`,
  PAGE: `Step ${TOKENS.STEP_SUID} Page ${TOKENS.PAGE_PUID}`,
  SIGNUP: 'Signup',
  LOGIN: 'Login',
  PASSWORD: 'Password',
};

/* GA event actions */
export const ACTIONS = {
  STARTED: 'Started',
  COMPLETED: 'Completed',
  LOADED: 'Loaded',
  LOADED_QUESTION: `Loaded question: ${TOKENS.TITLE}`,
  ANSWER_QUESTION: `Answer: ${TOKENS.VALUE} for question: ${TOKENS.TITLE}`,
  FORM_SIGNUP_STARTED: 'Form Signup Started',
  FORM_SIGNUP_COMPLETED: 'Form Signup Completed',
  GOOGLE_SIGNUP_STARTED: 'Google Signup Started ',
  GOOGLE_SIGNUP_COMPLETED: 'Google Signup Completed',
  FACEBOOK_SIGNUP_STARTED: 'Facebook Signup Started',
  FACEBOOK_SIGNUP_COMPLETED: 'Facebook Signup Completed',
  VIRGINPULSE_SIGNUP_STARTED: 'Virgin-Pulse Signup Started',
  VIRGINPULSE_SIGNUP_COMPLETED: 'Virgin-Pulse Signup Completed',
  FORM_LOGIN_SUCCESSFUL: 'Form Login Successful',
  GOOGLE_LOGIN_SUCCESSFUL: 'Google Login Successful ',
  FACEBOOK_LOGIN_SUCCESSFUL: 'Facebook Login Successful',
  VIRGINPULSE_LOGIN_SUCCESSFUL: 'Virgin-Pulse Login Successful',
  LOGIN_FAILED: 'Login Failed',
  SIGNUP_FAILED: 'Signup Failed',
  RESET_PASSWORD_PAGE_LOADED: 'Reset Password Page Loaded',
  FORGOT_PASSWORD_PAGE_LOADED: 'Forgot Password Page Loaded',
  RESET_PASSWORD_SUCCESSFUL: 'Reset password successful',
  RESET_PASSWORD_UNSUCCESSFUL: 'Reset password unsuccessful',
  FORGOT_PASSWORD_SUCCESSFUL: 'Forgot password successful',
  FORGOT_PASSWORD_UNSUCCESSFUL: 'Forgot password unsuccessful',
};
