import { ClientFlow, StepPage } from '../../types/flow.types';

export const shouldShowHeaderPageTracker = (flow: ClientFlow): boolean => {
  const currentStepInFlow = flow.currentStep;

  const isSignupStep = flow.signUpStep === currentStepInFlow;
  const isReportStep = flow.reportStep === currentStepInFlow;
  const isLandingStep = flow.landingStep === currentStepInFlow;

  return !(isSignupStep || isReportStep || isLandingStep);
};

export const generateHeaderPageTrackerText = (flow: ClientFlow): string => {
  const currentStepInFlow = flow.currentStep;
  let trackerText = '';

  // Signup Step is at end of OST and before LST
  if (
    flow.signUpStep &&
    currentStepInFlow < flow.signUpStep &&
    currentStepInFlow !== flow.landingStep
  ) {
    trackerText = generateOSTTracker(flow);
  } else if (flow.signUpStep && currentStepInFlow > flow.signUpStep) {
    trackerText = generateLSTTracker(flow);
  }

  return trackerText;
};

// OST questions can be in multiple steps with multiple pages
// Most flows have all OST questions in one step, but there are outlying flows
const generateOSTTracker = (flow: ClientFlow) => {
  if (flow.flow && flow.pagesInOST && flow.signUpStep) {
    const flowSteps = [...flow.flow.steps];
    const flowStepsWithOrigIdx: {
      originalStepNumber: number;
      pages: StepPage[];
    }[] = [];

    // maintain original index on each step for later check
    flowSteps.forEach((step, index) => {
      flowStepsWithOrigIdx.push({
        ...step,
        originalStepNumber: index,
      });
    });

    // Only include Steps between Landing page step (if it exists) and Signup Step
    const firstOSTStep = flow.landingStep !== null ? 1 : 0;
    const OSTFlow = flowStepsWithOrigIdx.slice(firstOSTStep, flow.signUpStep);

    const stepsInFlowElapsed = OSTFlow.filter(step => {
      return step.originalStepNumber <= flow.currentStep;
    });

    // Find all pages currently elapsed in OST
    const pagesInFlowElapsed = stepsInFlowElapsed.flatMap(currentStep => {
      return currentStep.pages.filter((currentPage, index) => {
        // Do not include pages that have not yet been viewed
        if (
          currentStep.originalStepNumber === flow.currentStep &&
          index > flow.currentPage
        ) {
          return false;
        }

        // Some pages in OST are composed entirely of "hidden" questions that do not show to user (may be data issue), remove these
        const areAllQuestionsHidden = currentPage.entity
          .flatMap(entity => entity.is_hidden)
          .every(isHidden => isHidden);
        return !areAllQuestionsHidden;
      });
    });

    const currentPlaceInFlow = pagesInFlowElapsed.length;

    return `${currentPlaceInFlow} of ${flow.pagesInOST}`;
  }

  return '';
};

// LST questions/pages are in multiple steps, sometimes with multiple pages per step
const generateLSTTracker = (flow: ClientFlow): string => {
  if (flow.currentStep === flow.reportStep) {
    return '';
  }

  if (flow.flow && flow.signUpStep !== null && flow.pagesInLST !== null) {
    const flowSteps = [...flow.flow.steps];
    const flowStepsWithOrigIdx: {
      originalStepNumber: number;
      pages: StepPage[];
    }[] = [];

    // maintain original index on each step for later check
    flowSteps.forEach((step, index) => {
      //Edge case where report page and signup page are in the same step
      if (flow.signUpStep !== flow.reportStep) {
        // Remove report step if it exists, as it should not count in LST page numbers
        if (index + 1 === flow.reportStep) {
          return;
        }
      }

      flowStepsWithOrigIdx.push({
        ...step,
        originalStepNumber: index,
      });
    });

    // Remove all steps before and inclusive of the signup step
    const LSTFlow = flowStepsWithOrigIdx.slice(flow.signUpStep + 1);

    const stepsInFlowElapsed = LSTFlow.filter(step => {
      return step.originalStepNumber <= flow.currentStep;
    });

    // Find all pages currently elapsed in LST
    const pagesInFlowElapsed = stepsInFlowElapsed.flatMap(currentStep => {
      return currentStep.pages.filter((currentPage, index) => {
        // Do not include pages that have not yet been viewed
        if (
          currentStep.originalStepNumber === flow.currentStep &&
          index > flow.currentPage
        ) {
          return false;
        }

        return currentPage.entity.length > 0;
      });
    });

    const currentPlaceInFlow = pagesInFlowElapsed.length;

    return `${currentPlaceInFlow} of ${flow.pagesInLST}`;
  }

  return '';
};

// Build the full URL for the IDP logout
// NOTE: We currently only support Auth0 as an IDP and the idpLogoutUri is expected
// to be an Auth0 URL.
// See https://auth0.com/docs/authenticate/login/logout/redirect-users-after-logout
// and https://auth0.com/docs/api/authentication#auth0-logout
export const getFullIdpLogoutUrl = (
  idpLogoutUri: string,
  returnToPath: string
): string => {
  return `${idpLogoutUri}?returnTo=${window.location.origin}${returnToPath}`;
};
