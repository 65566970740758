import { css, Theme } from '@emotion/react';

export const phoneNumberStyle = (theme: Theme) =>
  css({
    label: {
      display: 'none',
    },
    input: {
      minWidth: 327,
      padding: 24,
      border: '1px solid #dfe1e6',
      borderRadius: theme.borders.defaultBorderRadius,
      fontSize: 16,
      boxSizing: 'border-box',

      '@media (max-width: 800px)': {
        minWidth: 300,
      },

      '&::placeholder': {
        color: '#6a717d',
        fontWeight: 400,
      },
    },
  });

export const phoneNumberErrorStyle = () =>
  css({
    paddingTop: 4,
    visibility: 'visible',
    color: '#b92d0f',
  });
