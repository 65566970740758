import Text from '../text';
import constants from '../../../config/constants';

class Password extends Text {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      error: '',
    };
  }

  isValid(isReset = false) {
    if (!this._hasValue()) {
      this.setState({ error: 'Enter your password' });
      return false;
    }

    if (isReset) {
      const regex = new RegExp(constants.regex.password);
      if (!regex.test(this.props.value)) {
        this.setState({
          error: 'Please make sure all password requirements are met',
        });
        return false;
      }
    }

    this.setState({ error: '' });
    return true;
  }

  _hasValue() {
    return this.props.value;
  }
}

export default Password;
