import React, { useEffect, useState } from 'react';
import { countryCodes } from '../country-code-select/country-codes';
import './phone-number.scss';
import classNames from 'classnames';
import { CountryCode } from '../country-code-select/country-codes.types';
const defaultPlaceholderText = 'Phone Number';

export const errorMessage = 'Please enter a valid 10-digit number';
export const phoneNumberTestId = 'phone-number-testId';

export const PhoneNumber = ({
  countryCode,
  currentValue,
  setPhoneNumber,
  isError,
  style,
}: {
  countryCode: CountryCode | undefined;
  currentValue: string;
  setPhoneNumber: (phoneNumber: string) => void;
  isError: boolean;
  style?: React.CSSProperties;
}) => {
  const [placeholderText, setPlaceholderText] = useState(
    defaultPlaceholderText
  );

  useEffect(() => {
    getPlaceholderText();
  });

  const getPlaceholderText = () => {
    countryCodes.map(countryCodeData => {
      if (countryCodeData.code === countryCode) {
        setPlaceholderText(countryCodeData.format);
      }
    });
  };

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <div data-testid={phoneNumberTestId} className="phone-number" style={style}>
      <label htmlFor="phone">enter phone number</label>
      <input
        className={classNames('phone-number__component', {
          'phone-number__component--error': isError,
        })}
        type="tel"
        name="phone"
        value={currentValue}
        placeholder={placeholderText}
        onChange={_onChange}
      />
      <div
        className={classNames('phone-number__message', {
          'phone-number__message--error': isError,
        })}
      >
        {isError && errorMessage}
      </div>
    </div>
  );
};
