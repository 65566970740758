import { css } from '@emotion/react';
import { BHTheme } from '../../types/bh-themes.types';

export const themedCheckboxStyling = (theme: BHTheme) =>
  css({
    height: 18,
    width: 18,
    minHeight: 18,
    minWidth: 18,
    cursor: 'pointer',
    border: theme.borders.defaultActiveBorder,
    borderRadius: theme.inputs.checkbox.borderRadius,
    appearance: 'none',
    position: 'relative',

    '&:checked': {
      background: theme.inputs.checkbox.background,

      '&::before': {
        content: '""',
        position: 'absolute',
        width: '25%',
        height: '60%',
        transform: 'rotate(45deg)',
        borderBottom: `3px solid ${theme.inputs.checkbox.color}`,
        borderRight: `3px solid ${theme.inputs.checkbox.color}`,
        top: 0,
        left: 4,
      },
    },
  });

export const themedCheckboxErrorStyling = (theme: BHTheme) =>
  css([
    themedCheckboxStyling(theme),
    {
      border: theme.errors.border,
    },
  ]);
