import { RootState } from '../store/store';
import { ClientFlow, CurrentFlow } from '../types/flow.types';

export const selectFlowReducer = (state: RootState): ClientFlow =>
  state.flowReducer || {};
export const selectFlowReducerFlowId = (state: RootState) =>
  selectFlowReducer(state).flow_id;
export const selectFlowReducerInitialized = (state: RootState) =>
  selectFlowReducer(state).initialized;
export const selectFlowReducerShouldDisplaySignupExistingUserInFlow = (
  state: RootState
) => selectFlowReducer(state).shouldDisplaySignupExistingUserInFlow;
export const selectFlowReducerCurrentStepPage = (state: RootState) => {
  // this should be consumed by useShallowEqualSelector
  const { currentStep, currentPage } = selectFlowReducer(state);
  return { currentStep, currentPage };
};
export const selectFlowReducerPages = (state: RootState) =>
  selectFlowReducer(state).pages || [];
export const selectFlowReducerContinueFn = (state: RootState) =>
  selectFlowReducer(state)?.continueFunction; // please kill this
export const selectFlowReducerProductId = (state: RootState) =>
  selectFlowReducer(state).product_id;
export const selectFlowReducerContinueButtonLoading = (state: RootState) =>
  !!selectFlowReducer(state).continueBtnLoading;

// currentFlow can be undefined if redux is not initialized yet
export const selectFlowReducerCurrentFlow = (
  state: RootState
): CurrentFlow | undefined => selectFlowReducer(state).flow;
export const selectFlowReducerStepsFromCurrentFlow = (state: RootState) =>
  selectFlowReducerCurrentFlow(state)?.steps || [];
export const selectFlowReducerHiddenQuestionsFromCurrentFlow = (
  state: RootState
) => selectFlowReducerCurrentFlow(state)?.hiddenQuestions || [];
export const selectFlowReducerCurrentFlowOrganizationId = (state: RootState) =>
  selectFlowReducerCurrentFlow(state)?.organization_id;
export const selectFlowReducerCurrentFlowCss = (state: RootState) =>
  selectFlowReducerCurrentFlow(state)?.css;
export const selectFlowReducerCurrentStepFromCurrentFlow = (
  state: RootState
) => {
  const steps = selectFlowReducerStepsFromCurrentFlow(state);
  const { currentStep } = selectFlowReducerCurrentStepPage(state);
  return steps[currentStep];
};
export const selectFlowReducerCurrentStepValidator = (state: RootState) => {
  const { validator } = selectFlowReducerCurrentStepFromCurrentFlow(state);
  return validator;
};

export const selectFlowReducerLandingStep = (state: RootState) =>
  selectFlowReducer(state).landingStep;
export const selectFlowReducerIsCurrentStepLandingStep = (state: RootState) => {
  const { currentStep } = selectFlowReducerCurrentStepPage(state);
  const landingStep = selectFlowReducerLandingStep(state);
  return currentStep === landingStep;
};
