import * as Sentry from '@sentry/react';

export default function ajax(url, params) {
  const method = params.method;
  const payload = params.body;

  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
      if (xhr.status < 200 || xhr.status >= 300) {
        console.error(xhr);
        let msg = `${method} on ${url} returned a ${xhr.status} status`;
        msg += `\nCheck logs of specific service cluster (where ${url} lives) for more info.`;
        Sentry.captureMessage(msg);
      }

      try {
        resolve(JSON.parse(xhr.response || '{}'));
      } catch (e) {
        Sentry.captureException(e);
        resolve({});
      }
    };

    xhr.open(method, url);
    for (let header in params.headers) {
      // eslint-disable-next-line no-prototype-builtins
      if (params.headers.hasOwnProperty(header)) {
        xhr.setRequestHeader(header, params.headers[header]);
      }
    }
    xhr.withCredentials = params.credentials === 'same-origin';
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(payload);
  });
}
