import objectAssign from 'object-assign';
import * as ACTIONS from '../constants/action-types';
import AnswersService from '../services/answers-service';
import FlowService from '../services/flow-service';
import GaService from '../services/ga-service';
import TriggerComponent from '../components/trigger-component';
import Cookies from '../cookies';
import { findNextStepPageInFlow } from '../components/utils/flow-utils';
import UserSession from '../services/user-session';
import { getUtmSearchStringFromCookies } from '../helpers/utm-params';
import {
  calculateTotalPagesInLST,
  calculateTotalPagesInOST,
  findLandingPageStep,
  findReportStep,
  findSignupStep,
  setUtmDataCookiesFromLocation,
} from './flow-reducer.utils';

const INITIAL_STATE = {
  initialized: false,
  invalidFlow: false,
  stepPageInitialized: false,
  flow: undefined,
  flow_id: null,
  product_id: null,
  currentStep: 0,
  pages: [],
  currentPage: 0,
  signUpStep: null,
  reportStep: null,
  landingStep: null,
  pagesInOST: null,
  pagesInLST: null,
  orgId: 1,
  collectUserInfo: false,
  savingAnswers: false,
  continueFunction: null,
  continueBtnLoading: false,
  shouldDisplaySignupExistingUserInFlow: false,
};

function isMovingForward(nextStep, nextPage) {
  const { currentMaxStep = 0, currentMaxPage = 0 } =
    FlowService.loadMaxProgress();
  return (
    currentMaxStep < nextStep ||
    (currentMaxStep === nextStep && currentMaxPage < nextPage)
  );
}

export default function flowReducer(state = INITIAL_STATE, action) {
  let nextPage;
  let nextStep;
  let res;
  let product_id;
  let newState;
  let localState;
  let localProgress;
  let url;
  let userData;
  let customTriggers;
  const TriggerComp = new TriggerComponent();
  let signUpStep;
  let reportStep;
  let landingStep;
  let pagesInOST;
  let pagesInLST;

  switch (action.type) {
    case ACTIONS.FLOW_INITIALIZE:
      res = action.data.res.result;
      url = new URL(window.location);

      if (action.data && !action.data.redirect) {
        setUtmDataCookiesFromLocation(window.location);
      }

      if (
        action.data &&
        action.data.fitBitData &&
        action.data.fitBitData.fitBitCode &&
        action.data.fitBitData.location
      ) {
        window.location = action.data.fitBitData.location;
        localStorage.removeItem('current-path');
        localStorage.setItem('fit_bit_code', action.data.fitBitData.fitBitCode);
        return state;
      }

      // If no flow or invalid one, redirect to BigHealth
      if (!res || typeof res === 'string' || res.steps.length === 0) {
        window.location = `/${window.product_name}/error`;
        return objectAssign({}, state, { invalidFlow: true });
      }

      // Parse the flow, add extra step, search for hidden questions
      initialFlowManipulation(res);

      userData = UserSession.getUserData();
      product_id = window.product_id;

      Cookies.set('flow_id', res.flow_id);

      if (res) {
        signUpStep = findSignupStep(res);
        reportStep = findReportStep(res);
        landingStep = findLandingPageStep(res);
        pagesInOST = calculateTotalPagesInOST(res, signUpStep, landingStep);
        pagesInLST = calculateTotalPagesInLST(res, signUpStep, reportStep);
      }

      newState = objectAssign({}, state, {
        flow: res,
        flow_id: res.flow_id,
        product_id: product_id,
        orgId: res.organization_id,
        pages: res.steps[state.currentStep].pages,
        signUpStep: signUpStep,
        reportStep: reportStep,
        landingStep: landingStep,
        pagesInOST: pagesInOST,
        pagesInLST: pagesInLST,
        initialized: false,
      });
      return newState;

    case ACTIONS.LOAD_PROGRESS:
      // If the flow was invalid don't bother loading the progress
      if (state.invalidFlow) {
        return state;
      }

      userData = UserSession.getUserData();
      // Get product id from flow data
      product_id = window.product_id;
      // If product is changed logout user
      url = new URL(window.location.href);
      // eslint-disable-next-line max-len
      if (
        userData &&
        userData.product_ids &&
        userData.product_ids.indexOf(parseInt(product_id)) === -1 &&
        url.searchParams.get('signup') !== 'true'
      ) {
        UserSession.logout().then(() => {
          window.location = window.location.pathname;
        });
        return state;
      }

      // Check if we have local progress and load it
      newState = objectAssign({}, state);
      localState = FlowService.loadLocalProgress();
      if (hasProgressForFlow(localState, newState.flow_id)) {
        newState.currentStep = localState.currentStep;
        newState.currentPage = localState.currentPage;
        newState.pages = state.flow.steps[newState.currentStep].pages;
      } else {
        // Else remove locally stored answers and progress
        FlowService.removeLocalProgress();
        AnswersService.removeAnswers();
      }

      // Set flow as initialized
      newState.initialized = true;

      // And set up a  progress
      setFlowProgress(
        newState.flow_id,
        newState.currentStep,
        newState.currentPage,
        true
      );
      return newState;

    case ACTIONS.LOAD_NEXT_PROGRESS:
      // This "function" loads your progress from localstorage, and then
      // moves you forward to the next displayable step in the flow. One use case is when you have
      // logged in or signed up, and you need to restore your progress, and then move to the next page.
      newState = objectAssign({}, state);

      localProgress = FlowService.loadLocalProgress();

      if (
        hasProgressForFlow(
          localProgress,
          newState.flow_id,
          action.data.postTestFlowId
        )
      ) {
        let next = findNextStepPageInFlow({
          currentStep: localProgress.currentStep,
          currentPage: localProgress.currentPage,
          flow: state.flow,
          answers: action.data.answers,
        });
        // eslint-disable-next-line max-len
        let nextPageEntityTypes = state.flow.steps[next.step].pages[
          next.page
        ].entity.map(entity => entity.entity_type);

        // If you are currently successfully logging in, we skip over
        // any page with 'interactive components', i.e. the login and
        // report pages, because we don't want to show them to you
        // twice.
        if (action.data.isLogin) {
          // eslint-disable-next-line max-len
          while (nextPageEntityTypes.indexOf('interactive-component') > -1) {
            next = findNextStepPageInFlow({
              currentStep: next.step,
              currentPage: next.page,
              flow: state.flow,
              answers: action.data.answers,
            });
            // eslint-disable-next-line max-len
            nextPageEntityTypes = state.flow.steps[next.step].pages[
              next.page
            ].entity.map(entity => entity.entity_type);
          }
        }
        newState.currentStep = next.step;
        newState.currentPage = next.page;
        newState.pages = state.flow.steps[newState.currentStep].pages;
      } else {
        FlowService.removeLocalProgress();
        AnswersService.removeAnswers();
      }
      newState.initialized = true;

      // if post flow and was completed, reset progress to the first page
      if (
        newState.flow.steps.length - 1 <= newState.currentStep &&
        newState.flow.steps[newState.currentStep].pages.length - 1 <=
          newState.currentPage &&
        parseInt(action.data.postTestFlowId, 10)
      ) {
        AnswersService.removeAnswers();
        newState.currentStep = 0;
        newState.currentPage = 0;
      }

      setFlowProgress(
        newState.flow_id,
        newState.currentStep,
        newState.currentPage,
        true
      );
      return newState;

    case ACTIONS.NEXT_STEP:
      customTriggers =
        state.flow.steps[state.currentStep].custom_triggers || [];
      setTimeout(() => {
        TriggerComp.fireTriggers(customTriggers);
      }, 50);

      nextStep = state.currentStep + 1;
      if (state.flow.steps[nextStep]) {
        newState = objectAssign({}, state, {
          currentStep: nextStep,
          currentPage: 0,
          pages: state.flow.steps[nextStep].pages,
        });
      } else {
        newState = state;
        if (customTriggers && customTriggers.length > 0) {
          newState.continueBtnLoading = true;
        }
      }

      if (isMovingForward(newState.currentStep)) {
        GaService.startStep(newState.currentStep);
      }
      setFlowProgress(
        newState.flow_id,
        newState.currentStep,
        newState.currentPage,
        true
      );
      return newState;

    case ACTIONS.NEXT_PAGE:
      nextPage = state.currentPage + 1;
      if (nextPage > state.pages.length - 1) {
        nextPage = state.currentPage;
      }

      if (isMovingForward(state.currentStep, nextPage)) {
        GaService.loadPage(state.currentStep, nextPage);
      }

      setFlowProgress(state.flow_id, state.currentStep, nextPage, true);

      return objectAssign({}, state, { currentPage: nextPage });

    case ACTIONS.JUMP_TO:
      if (isMovingForward(action.step, action.page)) {
        GaService.loadPage(action.step, action.page);

        if (action.trigger) {
          customTriggers =
            state.flow.steps[action.trigger.step].custom_triggers || [];
          setTimeout(() => {
            TriggerComp.fireTriggers(customTriggers);
          }, 50);
        }
      }
      setFlowProgress(
        state.flow_id,
        action.step,
        action.page,
        action.changeHash
      );
      return objectAssign({}, state, {
        currentStep: action.step,
        currentPage: action.page,
        pages: state.flow.steps[action.step].pages,
      });

    case ACTIONS.FLOW_INITIALIZED:
      return objectAssign({}, state, {
        initialized: action.data.initialized,
      });

    case ACTIONS.COLLECT_USER_INFO:
      return objectAssign({}, state, { collectUserInfo: action.value });

    case ACTIONS.SAVING_ANSWERS:
      return objectAssign({}, state, { savingAnswers: action.value });

    case ACTIONS.SET_CONTINUE_FUNCTION:
      return objectAssign({}, state, { continueFunction: action.func });

    case ACTIONS.RESTORE_CONTINUE_FUNCTION:
      return objectAssign({}, state, { continueFunction: null });

    case ACTIONS.CONTINUE_BUTTON_LOADING:
      return objectAssign({}, state, {
        continueBtnLoading: action.value,
      });
    case ACTIONS.SHOW_EXISTING_SIGNUP:
      return objectAssign({}, state, {
        shouldDisplaySignupExistingUserInFlow: action.value,
      });
    default:
      return state;
  }
}

/**
 * Updates the flow progress.
 * Updates hash navigation & sets progress locally in local storage
 *
 * @param {String} flowId flow ID
 * @param {Number} step step number
 * @param {Number} page page number
 * @param {Boolean} [changeHash] flags whether or not to change the hash
 */
function setFlowProgress(flowId, step, page, changeHash = true) {
  FlowService.setLocalProgress(flowId, step, page);
  if (!changeHash) {
    return;
  }
  // Change the hash after a short timeout - this prevents the reducer from throwing a warning
  setTimeout(() => {
    // Wow, I think I found the exact StackOverflow question that prompted this:
    // https://stackoverflow.com/questions/4106702/change-hash-without-triggering-a-hashchange-event

    // Ignore programmatic hash changes triggered by reducer when navigating
    window.location.ignoreHashChange = true;
    const utm = getUtmSearchStringFromCookies();
    window.location.hash = `#${step + 1}/${page + 1}${utm ? `?${utm}` : ''}`;
  }, 10);
}

/**
 * Checks if there is progress for a certain flow
 *
 * @param {Object} localState the locally saved state
 * @param {String} flowId flow ID
 * @param {Number} postTestFlowId the post the flow id (if any)
 * @returns {*|String|boolean}
 */
function hasProgressForFlow(localState, flowId, postTestFlowId) {
  const hasProgress =
    localState &&
    localState.flowId &&
    parseInt(localState.flowId, 10) === parseInt(flowId, 10);
  return postTestFlowId
    ? hasProgress && (localState.currentPage || localState.currentStep)
    : hasProgress;
}

/**
 * This method is used to manipulate the Flow in the desired form for the WebApp
 *
 * It adds an extra last step which is used for saving data and redirecting to CBT
 * It searches for hidden questions and removes them from the flow, while storing them separately
 *
 * @param {Object} flow the flow obj
 * @returns {Object} returns the updated flow object
 */
function initialFlowManipulation(flow) {
  const hiddenQuestions = [];

  flow.steps.forEach((step, stepIdx) => {
    step.pages = step.pages.filter((page, pageIdx) => {
      // Filter entities, and remove Questions that have is_hidden set to true
      page.entity = page.entity.filter(entity => {
        if (entity.entity_type === 'question' && entity.is_hidden) {
          hiddenQuestions.push({
            ...entity,
            step: stepIdx,
            page: pageIdx,
            defaultAnswerKey: entity.default_answer_key,
            defaultAnswerValue: entity.default_answer_value,
          });
          return false;
        }
        return true;
      });

      return true;
    });
  });
  flow.hiddenQuestions = hiddenQuestions;

  return flow;
}
