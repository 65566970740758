import { css } from '@emotion/react';
import { ERROR, LIGHT_BLUE } from '../../../styles/colors';

export const GENERAL_ERROR_STYLES = css({
  color: ERROR,
  textAlign: 'center',
  padding: '30px 0',
});

export const SKIP_QUESTION_STYLES = css`
  text-align: center;
  margin-top: 10px;
  span {
    color: ${LIGHT_BLUE};
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
`;
