export const FLOW_INITIALIZE = 'FLOW_INITIALIZE';
export const NEXT_PAGE = 'NEXT_PAGE';
export const NEXT_STEP = 'NEXT_STEP';
export const JUMP_TO = 'JUMP_TO';
export const FLOW_INITIALIZED = 'FLOW_INITIALIZED';
export const SAVE_ANSWERS = 'SAVE_ANSWERS';
export const GET_REPORT_CONTENT = 'GET_REPORT_CONTENT';
export const CLEAR_REPORT_CONTENT = 'CLEAR_REPORT_CONTENT';
export const SIGNUP_USER = 'SIGNUP_USER';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_USER = 'LOGIN_USER';
export const UNSUBSCRIBE_USER = 'UNSUBSCRIBE_USER';
export const COLLECT_USER_INFO = 'COLLECT_USER_INFO';
export const GET_ANSWERS_FOR_USER = 'GET_ANSWERS_FOR_USER';
export const GET_USER_INFO = 'GET_USER_INFO';
export const VALIDATE_VOUCHER_CODE = 'VALIDATE_VOUCHER_CODE';
export const LOAD_NEXT_PROGRESS = 'LOAD_NEXT_PROGRESS';
export const LOAD_PROGRESS = 'LOAD_PROGRESS';
export const SAVING_ANSWERS = 'SAVING_ANSWERS';
export const SET_CONTINUE_FUNCTION = 'SET_CONTINUE_FUNCTION';
export const RESTORE_CONTINUE_FUNCTION = 'RESTORE_CONTINUE_FUNCTION';
export const CONTINUE_BUTTON_LOADING = 'CONTINUE_BUTTON_LOADING';
export const URL_DETAILS = 'URL_DETAILS';
export const INTERACTIVE_V2_INPUTS_VALIDATE = 'INTERACTIVE_V2_INPUTS_VALIDATE';
export const INTERACTIVE_V2_SHOWING_EMPLOYEE_ID =
  'INTERACTIVE_V2_SHOWING_EMPLOYEE_ID';
export const INTERACTIVE_V2_DISABLE_FIELDS_EXCEPT_EMPLOYEE_ID =
  'INTERACTIVE_V2_DISABLE_FIELDS_EXCEPT_EMPLOYEE_ID';
export const INTERACTIVE_V2_ACTION_CALLBACK = 'INTERACTIVE_V2_ACTION_CALLBACK';
export const TEMP_USER_DATA = 'TEMP_USER_DATA';
export const INTERACTIVE_V2_SET_PASSED_PROP_TO_FALSE =
  'INTERACTIVE_V2_SET_PASSED_PROP_TO_FALSE';
export const SHOW_EXISTING_SIGNUP = 'SHOW_EXISTING_SIGNUP';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const SET_COUNTRY = 'SET_COUNTRY';
