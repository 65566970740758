// todo Taber - It would be great to not store these feilds in an object like this
import interactiveConfig from './triggers-config';

export const passConditions =
  interactiveConfig.REQUESTS.REQUEST_SUCCESS_CONDITION;
export const onSuccessFollowUpRequests =
  interactiveConfig.REQUESTS.ON_REQUEST_SUCCESS_FOLLOW_UP;
export const requestTypes = interactiveConfig.REQUESTS.REQUEST_TYPES;

type Action = {
  method: string;
  service: string;
};

export const getRequestTypeForActions = function (action: Action) {
  const item = requestTypes.find(i => {
    return i.method === action.method && i.service === action.service;
  });

  return item
    ? { ...item }
    : {
        method: action.method,
        service: action.service,
        type: 'ajax',
        path: '/api/service_method_proxy',
      };
};

export default requestTypes;
