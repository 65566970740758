/* eslint-disable max-len */
'use strict';

let instance = null;

class Actions {
  constructor() {
    instance = instance || this;
    return instance;
  }

  async doRequest(url, data, method, options = {}) {
    const params = {
      header: options.headers || {},
      method: method || 'GET',
      credentials: 'same-origin',
    };
    if (params.method !== 'GET') {
      params.body = JSON.stringify(data) || undefined;
    }
    const response = await fetch(url, params);
    return await response.json(); // i don't think this 'await' is doing anything but I'm too scared to touch it
  }
}

export default new Actions();
