import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import QuestionGroupComponent from '../question-group-component/question-group.component';
import ConditionalRender from '../conditional-render';

class InputSelect extends React.Component {
  onChange(ev) {
    const value = ev.target.options[ev.target.selectedIndex].value;
    this.props.onChange(value);
  }

  render() {
    const {
      disabled,
      className,
      value,
      children,
      wide,
      inputId,
      title,
      label,
      name,
      isChecked,
      answers,
      isMapped,
    } = this.props;
    let optionIdValue =
      value instanceof Array && value.length ? value[0].id : value;
    optionIdValue =
      optionIdValue instanceof Object ? optionIdValue.id : optionIdValue;
    const selectValue = optionIdValue
      ? optionIdValue
      : optionIdValue === 0
      ? 0
      : '';
    const hasError =
      (isChecked && !value && value !== 0) || this.props.hasError;
    const questionContent = this.props.details;
    const belongsTo = questionContent
      ? `${questionContent.question_id}-${questionContent.id}-${questionContent.lang}`
      : null;

    /* eslint-disable max-len */
    const cName = `sl-select ${className || ''}${
      wide ? ' sl-select--wide' : ''
    }${hasError ? 'sl-select--has-error' : ''}`;
    return (
      <div className="sl-select-form">
        <div className="sl-select-wrapper">
          {label ? (
            <label htmlFor={inputId} className={'sl-select__label'}>
              {label}
            </label>
          ) : null}

          <select
            className={cName}
            // value == null - only 2 = operator, we want to catch both null AND undefined, nothing else!
            value={selectValue}
            id={inputId}
            name={name || inputId}
            disabled={disabled}
            title={title ? title : ''}
            onChange={this.onChange.bind(this)}
          >
            {children}
          </select>
          {answers &&
            answers.map((answer, idx) => {
              return (
                <ConditionalRender key={idx} if={isMapped}>
                  <QuestionGroupComponent
                    hidden={true}
                    belongsTo={`${belongsTo}-option-${idx}`}
                    content={answer.selected}
                    actions={this.props.actions}
                    onAnswer={this.props.onAnswer}
                    selected={
                      !selectValue
                        ? null
                        : parseInt(selectValue) === parseInt(answer.id)
                    }
                  />

                  <QuestionGroupComponent
                    hidden={true}
                    belongsTo={`${belongsTo}-option-${idx}`}
                    content={answer.not_selected}
                    actions={this.props.actions}
                    onAnswer={this.props.onAnswer}
                    selected={
                      !selectValue
                        ? null
                        : parseInt(selectValue) !== parseInt(answer.id)
                    }
                  />
                </ConditionalRender>
              );
            })}
        </div>
        {this.props.error || this.props.errorText ? (
          <p className="sl-error">
            {this.props.errorText || 'Please enter a valid option.'}
          </p>
        ) : (
          ''
        )}
      </div>
    );
  }
}

InputSelect.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  title: PropTypes.any,
  name: PropTypes.any,
  disabled: PropTypes.bool,
  wide: PropTypes.bool,
  isMapped: PropTypes.bool,
  error: PropTypes.bool,
  inputId: PropTypes.string,
  errorText: PropTypes.string,
  label: PropTypes.string,
  answers: PropTypes.array,
  isChecked: PropTypes.bool,
  hasError: PropTypes.bool,
  actions: PropTypes.any,
  onAnswer: PropTypes.func,
  details: PropTypes.object,
};

export default InputSelect;
