/**
 * This wrappers for analytics (only Heap currently)
 *
 * Usage: See "Usage:" comment in event-service/index.js
 */
import { getHeap } from './utils/getHeap';

export type EventProperties = {
  flow_id: number;
  page: number;
  step: number;
};

export type UserProperties = {
  user_id: string;
  organization_id: number;
  email: string;
  [`product_id#1`]?: boolean;
  [`product_id#2`]?: boolean;
};

// WARNING: Always pair with clearEventProperties
const addEventProperties = (properties: Partial<EventProperties>) => {
  getHeap()?.addEventProperties?.(properties);
};

const clearEventProperties = () => {
  getHeap()?.clearEventProperties?.();
};

// IMPORTANT: Always pair with resetIdentity
const addUserProperties = (properties: Partial<UserProperties>) => {
  getHeap()?.addUserProperties?.(properties);
};

const resetIdentity = () => {
  getHeap()?.resetIdentity?.(); // Heap Session's timeout after 30 mins
};

export const analytics = {
  addEventProperties,
  clearEventProperties,
  addUserProperties,
  resetIdentity,
};
