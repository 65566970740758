import { Moment } from 'moment';
import InteractiveInputComponent from '../interactive-input';
import { InteractiveInputProps } from '../interactive-input/interactive-input.types';
import InteractiveComponentV2Service from '../../../../services/interactive-component-v2';
import InteractiveDate from './themed.interactive-input-date.component';

function isMomentType(dateValue: string | Moment): dateValue is Moment {
  return typeof dateValue !== 'string';
}

class InteractiveDateContainer extends InteractiveInputComponent {
  constructor(props: InteractiveInputProps) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  _onChange = (dateValue: null | string | Moment) => {
    if (dateValue === null) {
      return;
    }

    if (isMomentType(dateValue)) {
      return;
    }

    const _dateValue = new Date(dateValue);
    const year = _dateValue.getFullYear();
    const month = `0${_dateValue.getMonth() + 1}`.slice(-2);
    const date = `0${_dateValue.getDate()}`.slice(-2);
    const formattedDate = `${year}-${month}-${date}`;
    InteractiveComponentV2Service.updateUserInputField(
      this.props.field_name,
      formattedDate
    );

    super.onChange(formattedDate);
  };

  render() {
    const { label } = this.props;
    const { additionalData } = this.state;

    return (
      <InteractiveDate
        id={this.props.id}
        onChange={this._onChange}
        label={label}
        additionalData={additionalData}
      />
    );
  }
}

export default InteractiveDateContainer;
