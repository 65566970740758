import React from 'react';
import PropTypes from 'prop-types';
import {
  getUtmParamsFromLocation,
  convertUtmParamsToSearchString,
} from '../../helpers/utm-params';
import { QUESTIONS_GENERAL_ERROR } from '../../constants/errors';

class StaticComponent extends React.Component {
  constructor(props) {
    super(props);

    this.elementRef = React.createRef();
  }

  componentDidMount() {
    const staticContent = this.props.item.content[0];
    if (staticContent.js_body) {
      const node = document.createElement('script');
      // use curly braces to scope the injected javascript
      node.textContent = staticContent.js_body;
      node.setAttribute('id', `sl-custom-js-${staticContent.id}`);
      document.body.appendChild(node);
    }
  }

  componentDidUpdate() {
    const node = this.elementRef.current;

    // handle custom next buttons from node
    const customNextButtons = Array.from(
      node.querySelectorAll('[data-sl-next]')
    );
    customNextButtons.forEach(this._handleCustomNextButtonErrors.bind(this));
    customNextButtons.forEach(this._handleUtmParams.bind(this));
  }

  componentWillUnmount() {
    const staticContent = this.props.item.content[0];
    const customJs = document.getElementById(
      `sl-custom-js-${staticContent.id}`
    );
    if (customJs) {
      customJs.parentNode.removeChild(customJs);
    }
  }

  render() {
    const content = this.props.item.content[0].body;

    return (
      <div
        ref={this.elementRef}
        className="sl-static"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  }

  _getContent() {
    const div = document.createElement('div');
    div.className = 'sl-button__loading';

    let divChild;
    for (let i = 1; i < 13; i++) {
      divChild = document.createElement('div');
      divChild.className = `bar${i}`;
      div.appendChild(divChild);
    }

    return div;
  }

  _handleUtmParams(button) {
    if (button.hasAttribute('href')) {
      const params = getUtmParamsFromLocation(window.location);
      const utmSearchString = convertUtmParamsToSearchString(params);
      button.setAttribute(
        'href',
        button.getAttribute('href') + utmSearchString
      );
    }
  }

  _handleCustomNextButtonErrors(button) {
    const node = this.elementRef.current;
    const disabled =
      this.props.nextButtonDisabled &&
      !button.getAttribute('data-jump-to-step') &&
      !button.getAttribute('data-sl-scroll-to-selector');

    disabled || this.props.continueBtnLoading
      ? button.setAttribute('disabled', true)
      : button.removeAttribute('disabled');

    if (
      this.props.continueBtnLoading &&
      !document.getElementsByClassName('sl-button__loading').length
    ) {
      const buttonParent = button.parentNode;
      buttonParent.insertBefore(this._getContent(), button.nextSibling);
    } else if (!this.props.continueBtnLoading) {
      Array.from(node.querySelectorAll('div.sl-button__loading')).forEach(
        loader => {
          const loaderParent = loader.parentNode;
          if (loaderParent) {
            loaderParent.removeChild(loader);
          }
        }
      );
    }

    button.parentNode.removeEventListener(
      'click',
      this.props.validateAnswersOnPage
    );
    if (disabled) {
      button.parentNode.addEventListener(
        'click',
        this.props.validateAnswersOnPage
      );
    }

    const errorNode = document.createElement('p');
    errorNode.className = 'sl-custom-button-error sl-general-error';
    errorNode.innerText = QUESTIONS_GENERAL_ERROR;

    Array.from(node.querySelectorAll('p.sl-custom-button-error')).forEach(
      errorParagraph => {
        const paragraphParent = errorParagraph.parentNode;
        if (paragraphParent) {
          paragraphParent.removeChild(errorParagraph);
        }
      }
    );

    if (this.props.isChecked && disabled) {
      const buttonParent = button.parentNode;
      buttonParent.parentNode.insertBefore(errorNode, buttonParent.nextSibling);
    }
  }
}

StaticComponent.propTypes = {
  item: PropTypes.any.isRequired,
  nextButtonDisabled: PropTypes.bool.isRequired,
  continueBtnLoading: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool.isRequired,
  validateAnswersOnPage: PropTypes.func.isRequired,
};

export default StaticComponent;
