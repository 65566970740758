import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './style.scss';

class VirginPulseSSOComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <a
        tabIndex={0}
        id="sl-virgin-pulse-sso"
        href={'/virgin-pulse-password-reset'}
      >
        <span>Login</span>
        <span> with Virgin Pulse</span>
      </a>
    );
  }
}

VirginPulseSSOComponent.propTypes = {
  mode: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(VirginPulseSSOComponent);
