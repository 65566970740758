import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  next as nextAction,
  jumpTo as jumpToAction,
  changeContinueFunction as changeContinueFunctionAction,
  resetContinueFunction as resetContinueFunctionAction,
  setContinueButtonLoading as setContinueButtonLoadingAction,
} from '../../actions/flow';
import { EntityTypes } from '../../constants/entity-types';
import { isThemed } from '../../helpers/themes';

import StaticComponent from '../../components/static-component';
import QuestionComponent from '../../components/question-component/question.component';
import ThemedQuestionComponent from '../question-component/themed.question.component';
import ReportComponent from '../../components/report-component';
import MappedQuestionComponent from '../question-mapped-component/question-mapped.component';
import ThemedMappedQuestionComponent from '../question-component/themed.question-mapped.component';
import {
  InteractiveComponent,
  ThemedInteractiveComponent,
} from '../interactive-component-v2/interactive-component-v2.component';

// map entity-type from admin panel to ReactComponent to render
const TYPES_TO_COMPONENTS_MAP = {
  [EntityTypes.STATIC_COMPONENT]: StaticComponent,
  [EntityTypes.QUESTION]: QuestionComponent,
  [EntityTypes.QUESTION_MAPPED]: MappedQuestionComponent,
  [EntityTypes.QUESTION_MINIFIED]: QuestionComponent,
  [EntityTypes.REPORT_COMPONENT]: ReportComponent,
  [EntityTypes.INTERACTIVE_COMPONENT]: InteractiveComponent,
};

// this will update as we add themed components
const TYPES_TO_THEMED_COMPONENT_MAP = {
  [EntityTypes.STATIC_COMPONENT]: StaticComponent,
  [EntityTypes.QUESTION]: ThemedQuestionComponent,
  [EntityTypes.QUESTION_MAPPED]: ThemedMappedQuestionComponent,
  [EntityTypes.QUESTION_MINIFIED]: ThemedQuestionComponent,
  [EntityTypes.REPORT_COMPONENT]: ReportComponent,
  [EntityTypes.INTERACTIVE_COMPONENT]: ThemedInteractiveComponent,
};

// list of all properties which are required per component, based on the entity-type
// an entity-type as defined in the admin is a generic entity which can then render as multiple components and not
// all properties on the entity are needed on the ReactComponent
const COMPONENT_PROPS = {
  [EntityTypes.STATIC_COMPONENT]: [
    'item',
    'nextButtonDisabled',
    'continueBtnLoading',
    'isChecked',
    'validateAnswersOnPage',
  ],
  [EntityTypes.QUESTION]: [
    'item',
    'answers',
    'isChecked',
    'actions',
    'answerValidator',
    'onAnswer',
    'fitBitData',
    'advance',
  ],
  [EntityTypes.QUESTION_MAPPED]: [
    'item',
    'answers',
    'isChecked',
    'actions',
    'answerValidator',
    'onAnswer',
    'fitBitData',
    'advance',
  ],
  [EntityTypes.QUESTION_MINIFIED]: [
    'item',
    'isChecked',
    'actions',
    'onAnswer',
    'answers',
  ],
  [EntityTypes.INTERACTIVE_COMPONENT]: ['advance', 'item', 'onSubmit'],
  [EntityTypes.REPORT_COMPONENT]: ['item'],
};

export class FactoryComponent extends React.Component {
  render() {
    const { type, item } = this.props;
    const isQuestion = type === EntityTypes.QUESTION;
    const containsMappedQuestions = isQuestion
      ? item.contains_mapped_questions
      : false;

    const themed = isThemed();
    const componentMapToUse = themed
      ? TYPES_TO_THEMED_COMPONENT_MAP
      : TYPES_TO_COMPONENTS_MAP;

    // we do not have a 'question-mapped' entity in admin
    // a `question-mapped` react component will render if the entity is a question and it contains map questions
    const Component =
      isQuestion && containsMappedQuestions
        ? componentMapToUse[EntityTypes.QUESTION_MAPPED]
        : componentMapToUse[type];

    const componentProps = this._getComponentPropsByType(type);

    // returns the actual ReactComponent with all the necessary props passed
    return (
      <Component key={`${type}-${item?.content[0]?.id}`} {...componentProps} />
    );
  }

  _getComponentPropsByType(type) {
    const propNames = COMPONENT_PROPS[type];
    const response = {};

    propNames.forEach(prop => {
      response[prop] = this.props[prop];
    });

    return response;
  }
}

FactoryComponent.propTypes = {
  type: PropTypes.string.isRequired,
  item: PropTypes.shape({
    contains_mapped_questions: PropTypes.bool,
    content: PropTypes.array,
  }),
  fitBitData: PropTypes.shape({}),
  answers: PropTypes.array,
  checked: PropTypes.bool,
  actions: PropTypes.shape({}),
  answerValidator: PropTypes.any,
  onAnswer: PropTypes.func,
  isChecked: PropTypes.bool,
};

function mapDispatchToProps(dispatch, ownProps) {
  // prefer any dev-defined actions over ones from the ether.
  if (ownProps.actions) {
    return ownProps.actions;
  }

  return {
    actions: bindActionCreators(
      {
        next: nextAction,
        jumpTo: jumpToAction,
        changeContinueFunction: changeContinueFunctionAction,
        resetContinueFunction: resetContinueFunctionAction,
        setContinueButtonLoading: setContinueButtonLoadingAction,
      },
      dispatch
    ),
  };
}

export default connect(null, mapDispatchToProps)(FactoryComponent);
