import UserSession from './user-session';
import SingupService from './signup-service';
import { store } from '../store/store';

const INTERACTIVE_V2_USER_INPUT = 'sl-v2-user-input';
const INTERACTIVE_COMPONENT_ID = 'sl-interactive-id';

class InteractiveComponentV2Service {
  static updateUserInputField(fieldName, value) {
    const existing = InteractiveComponentV2Service.getUserInputAll();
    const fields = fieldName.split('.');
    let object = existing;

    fields.forEach((field, index) => {
      if (index < fields.length - 1) {
        object = object[field];
      }
    });

    object[fields[fields.length - 1]] = value;

    // delete to prevent from being persisted in local storage
    delete existing.email;
    delete existing.password;

    InteractiveComponentV2Service._saveUserInput(existing);
  }

  static getUserInputAll() {
    const data = localStorage.getItem(INTERACTIVE_V2_USER_INPUT) || '{}';
    const localStorageData = JSON.parse(data);

    const userInputWithEmailAndPassword = {
      ...localStorageData,
      email: store.getState().userReducer.userEmail,
      password: store.getState().userReducer.userPassword,
    };

    if (userInputWithEmailAndPassword.email.length === 0) {
      // delete to prevent from being persisted in local storage
      delete userInputWithEmailAndPassword.email;
    }
    if (userInputWithEmailAndPassword.password.length === 0) {
      // delete to prevent from being persisted in local storage
      delete userInputWithEmailAndPassword.password;
    }
    return userInputWithEmailAndPassword;
  }

  static getUserValues(fieldName) {
    if (!fieldName) {
      return null;
    }
    let existing = InteractiveComponentV2Service.getUserInputAll();
    const fields = fieldName.split('.');

    fields.forEach((field, index) => {
      if (index < fields.length - 1) {
        // eslint-disable-next-line no-prototype-builtins
        existing = existing.hasOwnProperty(field) ? existing[field] : existing;
      }
    });

    const finalPropertyName = fields[fields.length - 1];
    const found = existing[finalPropertyName];

    const userData = UserSession.getUserData() || {};
    const ssoTemporaryData = UserSession.getTempUserData() || {};

    // if the value is set or evaluates as false but the key exists in local storage (is false | empty string | etc)...
    // eslint-disable-next-line no-prototype-builtins
    if (found || existing.hasOwnProperty(finalPropertyName)) {
      // ... return what was found if defined, otherwise maybe we have something from sso
      return found !== undefined ? found : ssoTemporaryData[finalPropertyName];
      // else, value was not found at all and was never set in local storage, so try to set it from other sources:
    } else {
      // from the user data or from sso
      return userData[finalPropertyName] || ssoTemporaryData[finalPropertyName];
    }
  }

  static _saveUserInput(data) {
    localStorage.setItem(INTERACTIVE_V2_USER_INPUT, JSON.stringify(data));
  }

  static setInteractiveComponentId(id) {
    localStorage.setItem(INTERACTIVE_COMPONENT_ID, JSON.stringify(id));
  }

  static getInteractiveComponentId() {
    return JSON.parse(localStorage.getItem(INTERACTIVE_COMPONENT_ID));
  }

  static removeEmailAndPasswordFromLocalStorage() {
    const data = localStorage.getItem(INTERACTIVE_V2_USER_INPUT) || '{}';
    const localStorageData = JSON.parse(data);

    // delete to prevent from being persisted in local storage
    delete localStorageData.email;
    delete localStorageData.password;

    InteractiveComponentV2Service._saveUserInput(localStorageData);
  }
}

export default InteractiveComponentV2Service;
