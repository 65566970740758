import objectAssign from 'object-assign';
import * as ACTIONS from '../constants/action-types';

const INITIAL_STATE = {
  user: { is_authenticated: false },
  redirectUri: null,
  loginError: null,
  urlDetails: null,
  userInitialized: false,
  userPassword: '',
  userEmail: '',
  userCountry: '',
};

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIONS.SIGNUP_USER:
      if (action.data.result) {
        return objectAssign({}, state, {
          redirectUri: action.data.result.redirect_url,
        });
      }
      return state;

    case ACTIONS.LOGIN_USER:
      if (action.data.result) {
        return objectAssign({}, state, {
          user: action.data.result,
        });
      }
      return state;

    case ACTIONS.GET_USER_INFO:
      if (
        (action.data && action.data.is_authenticated) ||
        !state.userInitialized
      ) {
        return objectAssign({}, state, {
          user: action.data,
          userInitialized: true,
        });
      }
      return state;

    case ACTIONS.UNSUBSCRIBE_USER:
      if (action.data && action.data.result === true) {
        return objectAssign({}, state, {
          userUnsubscribed: true,
          unsubscribeError: false,
        });
      }

      return objectAssign({}, state, {
        userUnsubscribed: false,
        unsubscribeError: true,
      });

    case ACTIONS.LOGIN_ERROR:
      return objectAssign({}, state, { loginError: action.data });

    case ACTIONS.URL_DETAILS:
      return objectAssign({}, state, { urlDetails: action.data });

    case ACTIONS.TEMP_USER_DATA:
      return objectAssign({}, state, {
        user: action.data,
        userInitialized: true,
      });
    case ACTIONS.UPDATE_EMAIL:
      return objectAssign({}, state, {
        userEmail: action.value,
      });
    case ACTIONS.UPDATE_PASSWORD:
      return objectAssign({}, state, {
        userPassword: action.value,
      });
    case ACTIONS.SET_COUNTRY:
      return objectAssign({}, state, {
        userCountry: action.value,
      });

    default:
      return state;
  }
}
