import { FunctionComponent, useEffect, useState } from 'react';
import * as CLASSES from '../../constants/class-names';
import Header from '../../containers/header/themed.header.component';
import Footer from '../footer/footer.component';
import {
  body,
  checkboxLabel,
  checkboxWrapper,
  defaultPageStyles,
  errorMessage,
  image,
  main,
  title,
} from './nhs-signup-page.styles';
import FlowService from '../../services/flow-service';
import OrganizationService from '../../services/org-service';
import cookies from '../../cookies';
import { useAppDispatch } from '../../hooks/use-app-react-redux';
import EventService from '../../services/event-service';
import UserSession from '../../services/user-session';
import { getUserInfo } from '../../actions/user';
import CheckboxInput from '../checkbox/themed-checkbox.component';
import Loader from '../loader/themed.loader.component';
import { AdvanceFunction } from '../flowpage/flow-page.types';
import { logError } from '../../analytics/logger/logger';
import { NHSButton } from '../new-auth/components/buttons/nhs/NHSButton';

export const BE_AGE_ERROR_MESSAGE = 'User under 18';
export const BE_ELIG_ERROR_MESSAGE = 'User is not eligible';
export const GENERIC_ERROR =
  'Your account could not be created; please contact hello@sleepio.com for support.';
export const NHS_PERMISSION_ERROR =
  'You clicked “I do not agree to share this information” on NHS login. To continue with NHS login, please try again and click “I agree”. An alternative method of access will be available shortly.  To register an interest email hello@sleepio.com.';
export const AGE_REQUIREMENT_ERROR =
  'You must be 18 years or older to sign up for Sleepio.';
export const ELIG_REQUIREMENT_ERROR =
  'Sorry, it looks like Sleepio isn’t available in your area. Please check with your local NHS services for alternative options.';

type NHSSignupPagePropType = {
  advance: AdvanceFunction;
  currentPage: number;
  currentStep: number;
};
export const NHSSignupPage: FunctionComponent<NHSSignupPagePropType> = ({
  advance,
  currentPage,
  currentStep,
}) => {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<undefined | string>();
  const dispatch = useAppDispatch();

  const getRedirectURLAndRedirect = async () => {
    setError(undefined);
    const requestUrl = `/api/oauth/get_authorization_url?redirect_uri=${window.location.href}`;
    const params = {
      method: 'GET',
    };
    try {
      const response = await fetch(requestUrl, params);
      const data = await response.json();
      if (data?.error) {
        setError(GENERIC_ERROR);
        setIsLoading(false);
        return;
      }
      window.location.assign(data.url);
    } catch (exception) {
      logError(exception);
      setError(GENERIC_ERROR);
    }
  };

  const handleAuth0Response = async (code: string, state: string) => {
    const signupFields = {
      product_id: (window.product_id || 1)?.toString(),
      onboarding_flow_id: (FlowService.getFlowId() || 1)?.toString(),
      onboarding_vanity: FlowService.getCurrentFlowURL()
        .split('/')
        .slice(-1)[0],
      organization_id: (OrganizationService.getOrgId() || 1)?.toString(),
      data_storing: true.toString(),
      uuid: cookies.get('sl-user-uuid') || '',
      code,
      state,
    };

    const searchParams = new URLSearchParams(signupFields).toString();
    const requestUrl = `/api/oauth/authenticate_with_auth_code_and_sign_up?${searchParams}`;
    const params = {
      method: 'GET',
    };
    try {
      const response = await fetch(requestUrl, params);
      const data = await response.json();

      if (data?.error) {
        if (data.message === BE_AGE_ERROR_MESSAGE) {
          setError(AGE_REQUIREMENT_ERROR);
        } else if (data.message === BE_ELIG_ERROR_MESSAGE) {
          setError(ELIG_REQUIREMENT_ERROR);
        } else {
          setError(GENERIC_ERROR);
        }
        setIsLoading(false);
        return;
      }

      getUserInfo({
        cb: (responseData: { email?: string }) => {
          const userData = UserSession.getUserData();
          EventService.accountCreatedEvent({
            id: userData.id,
            uuid: userData.uuid,
            product_id: window.product_id,
            organization_id: OrganizationService.getOrgId(),
            first_name: userData.first_name,
            last_name: userData.last_name,
            idp_name: userData.idp_name,
            idp_id: userData.idp_id,
            employee_id: userData.employee_id,
            phone_number: userData.phone_number,
            email: responseData.email,
          });
          const initiatingStepAndPage = FlowService.loadLocalProgress();
          advance({ initiatingStepAndPage }, false);
        },
      })(dispatch);
    } catch (exception) {
      logError(exception);
      setError(GENERIC_ERROR);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const state = params.get('state');
    const errorParam = params.get('error');

    if (errorParam) {
      if (errorParam === 'access_denied') {
        setError(NHS_PERMISSION_ERROR);
      } else {
        setError(GENERIC_ERROR);
      }
    }
    if (code && state) {
      handleAuth0Response(code, state);
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div
      id={CLASSES.SL_FLOW}
      data-step={currentStep}
      data-page={currentPage}
      css={defaultPageStyles}
    >
      <Header />

      <div role="main" css={main}>
        <img
          css={image}
          src="https://app.sleepio.com/content/thumbs_up__2023.11.07.23.05.29.507807.png"
          alt="Person giving a thumbs up"
        />
        <h1 css={title}>You may be eligible to access Sleepio!</h1>
        <div css={body}>
          Use NHS login to check if Sleepio is available at no cost for residents 18 or over in your area.
        </div>
        <div css={checkboxWrapper}>
          <CheckboxInput
            id={'data_storing'}
            name={'data_storing'}
            isChecked={isTermsChecked}
            onChange={() => setIsTermsChecked(!isTermsChecked)}
          />
          <label htmlFor="data_storing" css={checkboxLabel}>
            I agree to the{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.sleepio.com/privacy/#healthInformation"
            >
              Privacy Policy
            </a>{' '}
            and{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.sleepio.com/terms"
            >
              Terms of Service
            </a>
          </label>
        </div>
        <div css={{ alignSelf: 'center', paddingTop: 36 }}>
          <NHSButton
            disabled={!isTermsChecked}
            testId="nhs-button"
            onClick={getRedirectURLAndRedirect}
          />
        </div>
        {error ? <div css={errorMessage}>{error}</div> : null}
      </div>

      <Footer />
    </div>
  );
};
