'use strict';

import ApiActions from '../../actions/api';
import FlowService from '../../services/flow-service';
import {
  createAnswerFromData,
  getHiddenQuestionsForPage,
  coerceServerAnswerToAnswer,
} from '../../helpers/answers';
import { QuestionValidator } from './validator';
import { EntityTypes } from '../../constants/entity-types';
import { getAnswersForPage } from '../../services/answers-service';

/* This is currently the logic for applying conditions. Finds if there are any conditions and jumps to the next step/page */
export const findConditionsAndApply = (
  entities,
  answers,
  flow,
  currentStep,
  currentPage
) => {
  // Check if we should jump to a certain question/group
  let foundCondition = null;
  entities.forEach(item => {
    !foundCondition &&
      item.conditions &&
      item.conditions.forEach(condition => {
        // Find corresponding answer for this condition
        const answer =
          answers &&
          answers.find(answer => answer.question_id === condition.from_id);

        if (answer) {
          // Check if we can find one rule that the answer applies to
          const rule = condition.rules.find(rule => {
            let multiAnswer = [];

            // is case of multi answer create an array with all answers
            if (Array.isArray(answer.values)) {
              answer.values.forEach(value => {
                multiAnswer.push(value.id.toString());
              });
            }

            const answerFound =
              (answer.values &&
                (answer.values.id || answer.values.id === 0) &&
                rule.answers.indexOf(answer.values.id.toString()) > -1) ||
              (answer.values &&
                rule.answers.some(
                  ruleAnswer => multiAnswer.indexOf(ruleAnswer) > -1
                ));
            const responseFound =
              answer.responses &&
              (answer.responses.id || answer.responses.id === 0) &&
              rule.answers.indexOf(answer.responses.id.toString()) > -1;

            return (
              rule.question.toString() === answer.question_id.toString() &&
              (answerFound || responseFound)
            );
          });
          if (rule) {
            foundCondition = condition;
            return false;
          }
        }
      });
  });

  // Should jump to foundCondition.to_id if we can find it
  if (foundCondition) {
    // First check hiddenQuestions, maybe the question to go to is hidden
    const hiddenQuestion = flow.hiddenQuestions.find(
      item => item.id === foundCondition.to_id
    );
    if (
      hiddenQuestion &&
      (hiddenQuestion.step !== currentStep ||
        hiddenQuestion.page !== currentPage)
    ) {
      return {
        isHidden: true,
        isJump: true,
        step: hiddenQuestion.step,
        page: hiddenQuestion.page,
      };
    }

    // Else, iterate one step/page at a time and search for the question to go to
    for (
      let stepIndex = currentStep;
      stepIndex < flow.steps.length;
      stepIndex++
    ) {
      const pageIdxStart = stepIndex === currentStep ? currentPage + 1 : 0;
      const stepPages = flow.steps[stepIndex].pages;
      for (
        let pageIndex = pageIdxStart;
        pageIndex < stepPages.length;
        pageIndex++
      ) {
        if (
          stepPages[pageIndex].entity.find(
            item => item.id === foundCondition.to_id
          )
        ) {
          return {
            isJump: true,
            step: stepIndex,
            page: pageIndex,
          };
        }
      }
    }
  }
  return null;
};

const getQuestionsThatMatter = (
  currentPageQuestions,
  answersForTargetStepPage,
  pageConditions
) => {
  /* 
  what does a condition apply to? if a hidden question is necessary
  thus, we want:
    - all questions for which there is no condition attached
    - all questions for which a condition applies
  */
  const questionIdsWithConditions = pageConditions.map(cond => cond.to_id);
  const questionsWithConditions = currentPageQuestions.filter(cond =>
    questionIdsWithConditions.includes(cond.id)
  );
  const conditionsWhichApply = pageConditions
    .filter(cond => checkIfConditionApplies(cond, answersForTargetStepPage))
    .map(cond => cond.to_id);
  const questionsConditionsApplyTo = questionsWithConditions.filter(question =>
    conditionsWhichApply.includes(question.id)
  );

  const questionsWithoutConditions = currentPageQuestions.filter(
    cond => !questionIdsWithConditions.includes(cond.id)
  );
  const questionsThatMatter = [
    ...questionsWithoutConditions,
    ...questionsConditionsApplyTo,
  ];
  return questionsThatMatter;
};

const areAnswersValidForStepPage = ({
  flow,
  pages,
  currentStep,
  currentPage,
  answers,
}) => {
  const currentPageQuestions = pages[currentPage].entity.filter(
    entity => entity.entity_type === EntityTypes.QUESTION
  );

  // Find all conditions in this page
  const hiddenQuestions = flow.hiddenQuestions;
  const hiddenPageQuestions = hiddenQuestions.filter(
    item => item.page === currentPage && item.step === currentStep
  );
  const pageConditions = findConditionsInSamePage(
    currentPageQuestions,
    hiddenPageQuestions
  );

  // nuance: if there's been no attempt to save this page, the answer will be in local storage _but not in the server answers_, and so this will return an empty array. That's actually desirable, since it makes the user click continue and validate the answers and save them to the server, even if all of them have answers in local storage. We can't make QuestionComponent run validation manually, so this prevents us trying to submit invalid answers later
  const localAnswersForPage = getAnswersForPage(currentStep, currentPage);
  const questionIdsForCurrent = currentPageQuestions.map(({ id }) => id);
  const answersForTargetStepPage = answers
    .filter(
      ans =>
        questionIdsForCurrent.includes(ans.question_id) &&
        (ans.responses?.length || ans.values)
    )
    .map(ans => {
      if (ans.user_id) {
        // try fetching most recent local answer for most recent result
        const localAnswer = localAnswersForPage.find(
          ({ question_id }) => ans.question_id === question_id
        );
        return localAnswer ? localAnswer : coerceServerAnswerToAnswer(ans);
      }
      return ans;
    });

  const answerValidator = flow.steps[currentStep].validator;
  const errors = QuestionValidator.validateAnswers(
    answersForTargetStepPage,
    answerValidator
  );

  // We have conditions, for conditions that are TRUE we keep the to_id question from currentPageQuestions. For ones that are false, we don't care about that question
  const applicableQuestions = getQuestionsThatMatter(
    currentPageQuestions,
    answersForTargetStepPage,
    pageConditions
  );

  // check that ALL answers on this page are answered
  // this means: every applicableQuestion has an answer AND there's no errors
  const allAnswered = applicableQuestions.every(question =>
    answersForTargetStepPage.find(ans => ans.question_id === question.id)
  );
  return errors.length === 0 && allAnswered;
};

/**
 * Finds the next step and page in flow based on conditions defined in flow and answers from current page
 * It also filters out empty pages, s.a. ones left empty from removing hidden questions
 *
 * @param {Object} paramObj the current state of the flow and the answers from the current page
 * @returns {object} returns next step, next page and if next is a jump
 */
export const findNextStepPageInFlow = ({
  currentStep,
  currentPage,
  flow,
  answers = [], // ServerAnswers does not contain step/page, is not necessarily from local storage
  shouldSaveHiddenAnswers,
}) => {
  let isJump = false;
  let pages = flow.steps[currentStep].pages;

  // Check if there is a condition to tell us to jump to a certain step
  let jumpToStep = findConditionsAndApply(
    pages[currentPage].entity,
    answers,
    flow,
    currentStep,
    currentPage
  );

  if (jumpToStep) {
    // Special edge case here: if the page to jump to is hidden, move to the next one in the flow
    // We restart from page = page - 1 in order for the findNextStepPageInFlow to find the hidden question and save it
    if (jumpToStep.isHidden) {
      let retryFromStep = jumpToStep.step;
      let retryFromPage = jumpToStep.page;
      if (jumpToStep.page > 0) {
        retryFromPage = jumpToStep.page - 1;
      } else {
        retryFromStep = jumpToStep.step - 1;
      }
      jumpToStep = findNextStepPageInFlow({
        currentStep: retryFromStep,
        currentPage: retryFromPage,
        flow,
        answers,
        shouldSaveHiddenAnswers,
      });
    }

    return { ...jumpToStep, isJump: true };
  }

  // No condition found, we should advance in the flow to the next step or page
  let foundNext = false;
  let nextPage = currentPage + 1;
  let nextStep = currentStep;

  while (!foundNext) {
    pages = flow.steps[nextStep].pages;
    if (nextPage > pages.length - 1) {
      // No more pages in step, try to advance to next step
      nextStep = nextStep + 1;
      if (nextStep > flow.steps.length - 1) {
        // We are at the end of the road in flow
        nextPage = currentPage;
        nextStep = currentStep;
      } else {
        // New step, go to first page
        nextPage = 0;
      }
    }

    // Check for empty pages (because of is_hidden questions)
    const nextPageObj = flow.steps[nextStep].pages[nextPage];
    if (!nextPageObj.entity || !nextPageObj.entity.length) {
      // If the page is empty, attempt to jump to the next one and save answers
      const hiddenPage = flow.hiddenQuestions.find(
        item => item.step === nextStep && item.page === nextPage
      );
      // Also check if the hidden page has conditions (jump multiple steps)
      const defaultAnswer = [
        createAnswerFromData({
          question_id: hiddenPage.id,
          semantic_id: hiddenPage.semantic_id,
          is_core_question: hiddenPage.is_core_question,
          product_id: hiddenPage.product_id,
          isHidden: true,
          stepNumber: hiddenPage.step,
          pageNumber: hiddenPage.page,
          values: hiddenPage.content[0].answers.find(
            item =>
              item.id.toString() === hiddenPage.defaultAnswerKey.toString()
          ),
        }),
      ];
      const foundHiddenPageCond = findConditionsAndApply(
        [hiddenPage],
        defaultAnswer,
        flow,
        nextStep,
        nextPage
      );

      // answers = answers.concat(defaultAnswer);
      // Save hidden question's answers for this page
      if (shouldSaveHiddenAnswers) {
        const hiddenAnswers = getHiddenQuestionsForPage({
          hiddenQuestions: flow.hiddenQuestions ?? [],
          answers,
          currentPage: nextPage,
          currentStep: nextStep,
        });
        answers.push(...hiddenAnswers);
      }

      // Then move to the next page
      if (foundHiddenPageCond) {
        isJump = foundHiddenPageCond.isJump;
        nextStep = foundHiddenPageCond.step;
        nextPage = foundHiddenPageCond.page;
      } else {
        isJump = true;
        nextPage++;
      }
    } else {
      foundNext = true;
    }
  }

  // validate current page. If it passes, continue. If it fails, return currentStep/Page
  // if it fails validation and we're trying to move forward, don't
  const answersValid = areAnswersValidForStepPage({
    flow,
    pages,
    currentStep,
    currentPage,
    answers,
  });
  if (!answersValid) {
    return {
      isJump: false,
      step: currentStep,
      page: currentPage,
    };
  }

  return {
    isJump,
    step: nextStep,
    page: nextPage,
  };
};

/**
 * Checks for conditions in the same page and builds an array of them
 * @param {Array} entities array of entities on the page
 * @param {Array} hiddenEntities array of hidden entities on the page
 * @returns {Array}
 */
export const findConditionsInSamePage = (entities, hiddenEntities = []) => {
  const conditionsInPage = [];
  entities.concat(hiddenEntities).forEach(item => {
    item.conditions &&
      item.conditions.forEach(condition => {
        if (entities.find(item => item.id === condition.to_id)) {
          conditionsInPage.push(condition);
        }
      });
  });

  return conditionsInPage;
};

export const redirectLoggedUser = (user_id, noRedirect) => {
  return new Promise((resolve, reject) => {
    ApiActions.get({
      entity: 'Vanity',
      method: 'build_uri_by_user_id_and_product_id',
      query: `user_id=${user_id}&product_id=${
        window.product_id
      }&linked_to_platgen=${FlowService.getPlatgenFlagFromLogin()}`,
    })
      .then(res => {
        if (res.result.uri && !noRedirect) {
          window.location = `${res.result.uri}?login=true`;
          return resolve(res.result.uri);
        }

        return resolve(res.result.uri);
      })
      .catch(e => {
        console.log('Error getting vanity url');
        reject(e);
      });
  });
};

/**
 * Checks if a condition applies
 * @param {Object} condition the condition object
 * @param {Array} answers array of answers from the current page
 * @returns {boolean}
 */
export const checkIfConditionApplies = (condition, answers) => {
  const answer =
    answers && answers.find(answer => answer.question_id === condition.from_id);
  if (answer) {
    // Check if we can find one rule that the answer applies to
    const rule = condition.rules.find(rule => {
      let multiAnswer = [];

      // is case of multi answer create an array with all answers
      if (Array.isArray(answer.values)) {
        answer.values.forEach(value => {
          multiAnswer.push(value.id.toString());
        });
      }
      return (
        rule.question.toString() === answer.question_id.toString() &&
        ((answer.values &&
          (answer.values.id || answer.values.id === 0) &&
          rule.answers.indexOf(answer.values.id.toString()) > -1) ||
          (answer.values &&
            rule.answers.some(
              ruleAnswer => multiAnswer.indexOf(ruleAnswer) > -1
            )) ||
          (answer.responses &&
            rule.answers.indexOf(answer.responses.id.toString()) > -1))
      );
    });
    return !!rule;
  }
  return false;
};
