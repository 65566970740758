import { Regions } from '../../index';

const debugSetRegion = (region = false) => {
  localStorage.setItem('big_health.debug.region', region);
};
(window || global).debugSetRegion = debugSetRegion;

const debugRemoveRegion = (region = false) => {
  localStorage.removeItem('big_health.debug.region');
};
(window || global).debugRemoveRegion = debugRemoveRegion;

export const debugGetRegion = () => {
  // TODO Only do this on dev domains
  const value = localStorage.getItem('big_health.debug.region');
  if (value === null) {
    return false;
  }
  return value;
};
