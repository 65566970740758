import ApiActions from '../actions/api';
import {
  GET_REPORT_CONTENT,
  CLEAR_REPORT_CONTENT,
} from '../constants/action-types';
import AnswersService from '../services/answers-service';
import FlowService from '../services/flow-service';

/**
 * get report contents
 * @param {String} id identifier for the report
 * @param {String} userId identifier for user
 * @param {Boolean} send_email if an email with the report needs to be send
 * @param {String} email_button_link Url for "Tailor my program" link in email body
 * @param {String} flow_id Id of current flow in order to save if this user got report email for this flow
 * @param {String} organization_id of current flow in order to use it on unsubscribe link and generate report content
 * @returns {Function}
 */
/* eslint-disable max-len */
export function getReportContentsWithUserId(
  id,
  userId,
  send_email = false,
  email_button_link = null,
  flow_id = null,
  organization_id = null
) {
  return function (dispatch) {
    let query = `user_id=${userId}&report_id=${id}&linked_to_platgen=${FlowService.getPlatgenFlag()}`;
    if (send_email) {
      query = `user_id=${userId}&report_id=${id}&send_email=true&linked_to_platgen=${FlowService.getPlatgenFlag()}`;
    }
    if (email_button_link) {
      query = `${query}&email_button_link=${email_button_link}`;
    }
    if (flow_id) {
      query = `${query}&flow_id=${flow_id}`;
    }
    if (organization_id) {
      query = `${query}&organization_id=${organization_id}`;
    }
    ApiActions.get({
      entity: 'Report',
      method: 'generate_report_with_user_id_and_report_id',
      query: query,
    }).then(res => {
      return dispatch({ type: GET_REPORT_CONTENT, res });
    });
  };
}

export function clearReport() {
  return function (dispatch) {
    return dispatch({ type: CLEAR_REPORT_CONTENT });
  };
}

export function getReportContentsWithoutUserId(id) {
  const answers = AnswersService.getAnswersAsArray();
  const answersStructuredForReport = answers.map(answer => {
    return {
      id: 0,
      question_id: answer.question_id,
      responses: answer.values.length ? answer.values : [answer.values],
      semantic_id: answer.semantic_id,
    };
  });

  return function (dispatch) {
    ApiActions.post({
      entity: 'Report',
      method: 'generate_with_id_and_answers',
      data: {
        id: id,
        for_email: false,
        answers: answersStructuredForReport,
      },
    }).then(res => {
      return dispatch({ type: GET_REPORT_CONTENT, res });
    });
  };
}
