import { convertMillisecondsToSeconds } from './time';

export const generateEligibilityForProduct = () => {
  return [
    {
      product_id: window.product_id,
      eligibility_checked_at: convertMillisecondsToSeconds(Date.now()),
    },
  ];
};
