import './input-button.style.scss';
import React, { Component } from 'react';
import ConditionalRender from '../conditional-render';
import checkmarkSvg from '../../svgs/checkmark.svg';
import { Icon } from '../icon/icon.component';

type InputButtonProps = {
  type?: 'button' | 'submit' | 'reset' | undefined;
  value: string;
  onClick: (event?: React.SyntheticEvent) => void;
  className?: string;
  iconClassName?: string;
  wide?: boolean;
  disabled?: boolean;
  dataDisabled?: boolean;
  loading?: boolean;
  showIcon?: boolean;
  iconWidth?: number;
  iconHeight?: number;
  iconSrc?: string;
  css?: any;
};

class InputButton extends Component<InputButtonProps> {
  render() {
    const {
      onClick,
      disabled,
      className,
      iconClassName,
      value,
      wide,
      loading,
      showIcon,
      iconWidth,
      iconHeight,
      iconSrc,
    } = this.props;

    return (
      <div className="sl-button-wrapper">
        <button
          type={this.props.type || 'button'}
          onClick={onClick}
          role="button"
          data-disabled={this.props.dataDisabled}
          disabled={disabled || loading}
          className={`sl-button ${className || ''} ${
            wide ? 'sl-button--wide' : ''
          }`}
        >
          {showIcon && (
            <Icon
              alt={'icon'}
              width={iconWidth || 15}
              height={iconHeight || 12}
              src={iconSrc || checkmarkSvg}
              css={{ paddingRight: 5 }}
              className={
                iconClassName
                  ? `sl-button__${iconClassName}`
                  : 'sl-button__icon'
              }
            />
          )}
          {value}
        </button>

        {/* loading spinner shows on the button if loading flag comes as true in props*/}
        <ConditionalRender if={loading}>
          <div className="sl-button__loading">
            <div className={'bar1'} />
            <div className={'bar2'} />
            <div className={'bar3'} />
            <div className={'bar4'} />
            <div className={'bar5'} />
            <div className={'bar6'} />
            <div className={'bar7'} />
            <div className={'bar8'} />
            <div className={'bar9'} />
            <div className={'bar10'} />
            <div className={'bar11'} />
            <div className={'bar12'} />
          </div>
        </ConditionalRender>
      </div>
    );
  }
}

export default InputButton;
