import getConfig from '../../config';
import moment from 'moment';
import AnswersService from '../../services/answers-service';
import { Question, QuestionContent } from '../../types/questions.types';

const USERNAME_MAX_LENGTH = 20;
const USERNAME_MIN_LENGTH = 2;

export function checkUserNameLength(value: string) {
  if (
    value &&
    (value.length > USERNAME_MAX_LENGTH ||
      value.trim().length < USERNAME_MIN_LENGTH)
  ) {
    return `Please choose a username between ${USERNAME_MIN_LENGTH} and ${USERNAME_MAX_LENGTH} characters long`;
  } else {
    return null;
  }
}

export function saveGenderOptionsIfGenderQuestion(item: Question) {
  if (item.semantic_id === 'gender') {
    const content = item.content.find(c => c.lang === 'en') as QuestionContent;
    const options = content.answers;
    const genderValuesArray: unknown[] = [];

    options.forEach((label, id) => {
      const genderValues: any = { ...label };
      genderValues.idx = id;
      genderValuesArray.push(genderValues);
    });
    AnswersService.saveGenderQuestionOptions(genderValuesArray);
  }
}

export function filterObjectProperties(values: any) {
  if (values !== null) {
    if (typeof values === 'object' && !values.length) {
      const result: any = {};
      const listOfEntries = Object.entries(values).filter(
        ([key]) => getConfig().questionAnswerValuesKeys.indexOf(key) > -1
      );
      listOfEntries.forEach(item => (result[item[0]] = item[1]));
      return result;
    }

    if (typeof values === 'object' && values.length) {
      return values.map((v: any) => filterObjectProperties(v));
    }
  }
  return values;
}

// used for time based questions and answers on the fitbit page
// i.e. options only exist in 30 min intervals for say wake-up times, but actual fitbit data may be 8:56 AM as wake-up
// time. in this case, 8:56 AM would actually be saved as 9:00 AM
export function getClosestValue(
  item: QuestionContent,
  fitBitData: any,
  type: string
) {
  let closest;
  const valuesArray: number[] = [];
  if (type === 'period') {
    item.answers.map(value => {
      valuesArray.push(parseInt(value.id as string));
    });
    closest = valuesArray.reduce(function (prev, curr) {
      return Math.abs(curr - fitBitData) < Math.abs(prev - fitBitData)
        ? curr
        : prev;
    });

    return closest;
  } else if (type === 'time') {
    const crtDayString = moment(new Date()).format('YYYY-MM-DD');
    const unixFitBitData = moment(fitBitData.value).unix() * 1000;
    item.answers.map(value => {
      const unixTime = moment(
        `${crtDayString} ${value.text}`,
        'YYYY-MM-DD h:m A'
      ).unix();
      valuesArray.push(unixTime * 1000);
    });

    closest = valuesArray.reduce(function (prev, curr) {
      return Math.abs(curr - unixFitBitData) < Math.abs(prev - unixFitBitData)
        ? curr
        : prev;
    });

    return moment(closest).format('h:mm A');
  }
  return;
}

// Special case for InputSelect for mobile safari - don't hide the "Please select" option
const ua = window.navigator.userAgent;
const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
const webkit = !!ua.match(/WebKit/i);
export const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
