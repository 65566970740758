import InteractiveV2Service from '../../services/interactive-component-v2';
import OrganizationService from '../../services/org-service';
import FlowService from '../../services/flow-service';
import { getProductHelpEmail } from '../../helpers/product';
import { generateEligibilityForProduct } from '../../helpers/eligibility';
import cookies from '../../cookies';

const MULTIPRODUCT_SIGNUP_URL = '/auth/signup/';

type MultiproductSignupRequestArgs = {
  email: string;
  password: string;
  data_storing: boolean;
  doctor_acknowledgement: boolean;
};

export const SIGNUP_PROBLEM_MESSAGE = `Something went wrong! Please contact ${getProductHelpEmail()} for support.`;
export const BACKEND_CREDENTIALS_ERROR = 'Credentials not valid.';
export const INVALID_CREDS =
  'Incorrect email address or password. Please try again';
export const getErrorMessageFromResponseMessage = (message?: string) => {
  switch (message) {
    case BACKEND_CREDENTIALS_ERROR:
      return INVALID_CREDS;
    default:
      return SIGNUP_PROBLEM_MESSAGE;
  }
};

// There is a different flow (which this is used for) if you
// are going from product1 to product2
export const makeMultiproductSignupRequest = async ({
  email,
  password,
  data_storing,
  doctor_acknowledgement,
}: MultiproductSignupRequestArgs) => {
  const response = await fetch(MULTIPRODUCT_SIGNUP_URL, {
    method: 'POST',
    credentials: 'same-origin',
    body: JSON.stringify({
      is_existing_account: true,
      email,
      password,
      product_id: window.product_id,
      onboarding_flow_id: FlowService.getFlowId(),
      onboarding_vanity: FlowService.getCurrentFlowURL()
        .split('/')
        .slice(-1)[0],
      organization_id: OrganizationService.getOrgId(),
      interactive_id: InteractiveV2Service.getInteractiveComponentId(),
      data_storing,
      eligibility_for_product: generateEligibilityForProduct(),
      doctor_acknowledgement,
      uuid: cookies.get('sl-user-uuid'),
    }),
  });
  return response;
};
