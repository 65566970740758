import UserSession from '../../services/user-session';

const enable_text_to_download_campaign = async (phone_number: string) => {
  const user_id = UserSession.getUserData()?.user_id;
  const product_id = window.product_id;
  const url = `/api/service_method_proxy/UserProductMetadata/1/text_to_download_campaign`;
  const body = {
    phone_number,
    user_id,
    product_id,
  };
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  });
};

export default enable_text_to_download_campaign;
