import { css } from '@emotion/react';
import { BHTheme } from '../../types/bh-themes.types';

export const headerStyle = (theme: BHTheme) =>
  css({
    backgroundColor: theme.header.backgroundColor,
    minHeight: '64px',
    borderBottom: theme.borders.defaultBorder,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `0 ${theme.padding.LARGE}`,
  });

export const headerLandingPageStyle = (theme: BHTheme) =>
  css([
    headerStyle(theme),
    {
      borderBottom: 'none',
    },
  ]);

export const logoStyle = () =>
  css({
    display: 'flex',
    img: {
      width: '104px', // multiple of 8px
    },
  });

export const loginStyle = (theme: BHTheme) =>
  css({
    textDecoration: 'none',
    color: theme.header.color,
    cursor: 'pointer',
  });
