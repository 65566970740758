import { css } from '@emotion/react';
import { WHITE, SLEEPIO_NAVY, INPUT_OUTLINE_GRAY } from '../../styles/colors';

export const modalStyles = () =>
  css({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: WHITE,
    zIndex: 1000,
    borderRadius: '8px',
    color: SLEEPIO_NAVY,
    width: '555px',
    padding: '1px',
    hr: {
      border: 'none',
      height: '1px',
      margin: '0px',
      backgroundColor: INPUT_OUTLINE_GRAY,
    },
    '@media (max-width: 600px)': {
      width: '100%',
      height: '100%',
      borderRadius: '0px',
    },
  });

export const overlayStyles = () =>
  css({
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, .7)',
    zIndex: 1000,
  });
