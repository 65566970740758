import React, { useCallback, useMemo } from 'react';

import {
  useAppSelector,
  useShallowEqualSelector,
} from '../../../hooks/use-app-react-redux';
import selectors from '../../../selectors';
import { MultiProductHeader } from '../../multi-product-header/multi-product-header.component';
import { ProgressBarContainer } from '../../progress-bar/progress-bar.component';
import Header from '../../../containers/header/header.container';
import FooterContainer from '../../../containers/footer';
import FlowService from '../../../services/flow-service';
import FlowPage from './flowpage.component';
import { FlowPageContainerProps, FlowPageProps } from '../flow-page.types';

import '../../../containers/page/style.scss';

const FlowPageContainer: React.FC<FlowPageContainerProps> = props => {
  const { currentStep, currentPage } = useShallowEqualSelector(
    selectors.selectFlowReducerCurrentStepPage
  );
  const shouldDisplaySignupExistingUserInFlow = useAppSelector(
    selectors.selectFlowReducerShouldDisplaySignupExistingUserInFlow
  );

  // this is run outside of memoized callback to catch any changes
  const isPostFlow = FlowService.isPostFlow();

  const pickHeaderType = useCallback(() => {
    const showDefaultHeader =
      !isPostFlow && !shouldDisplaySignupExistingUserInFlow;
    const showMultiProductHeader = shouldDisplaySignupExistingUserInFlow;
    const headerType =
      (showDefaultHeader && Header) ||
      (showMultiProductHeader && MultiProductHeader) ||
      undefined;
    return headerType;
  }, [isPostFlow, shouldDisplaySignupExistingUserInFlow]);

  const pickProgressBarType = useCallback(() => {
    const shouldUseDefaultProgressBar = !shouldDisplaySignupExistingUserInFlow;
    const progressBarType =
      (shouldUseDefaultProgressBar && ProgressBarContainer) || undefined;
    return progressBarType;
  }, [shouldDisplaySignupExistingUserInFlow]);

  const pickFooterType = useCallback(() => {
    const shouldUseDefaultFooter = !shouldDisplaySignupExistingUserInFlow;
    const footerType = (shouldUseDefaultFooter && FooterContainer) || undefined;
    return footerType;
  }, [shouldDisplaySignupExistingUserInFlow]);

  const values: FlowPageProps = useMemo(() => {
    return {
      currentPage,
      currentStep,
      shouldDisplaySignupExistingUserInFlow,
      Header: pickHeaderType(),
      ProgressBar: pickProgressBarType(),
      Footer: pickFooterType(),
    };
  }, [
    currentPage,
    currentStep,
    shouldDisplaySignupExistingUserInFlow,
    pickHeaderType,
    pickProgressBarType,
    pickFooterType,
  ]);
  // TODO: add nhs signup for daylight. See themed-flowpage.container.tsx for example
  return (
    <FlowPage
      currentPage={values.currentPage}
      currentStep={values.currentStep}
      shouldDisplaySignupExistingUserInFlow={
        values.shouldDisplaySignupExistingUserInFlow
      }
      Header={values.Header}
      ProgressBar={values.ProgressBar}
      Footer={values.Footer}
      FitBitElement={props.FitBitElement}
      content={props.content}
      isNextButtonDisabled={props.isNextButtonDisabled}
      fitBitData={props.fitBitData}
      isValidated={props.isValidated}
      answers={props.answers}
      advance={props.advance}
      onContinue={props.onContinue}
      onAnswer={props.onAnswer}
      validateAnswers={props.validateAnswers}
      isNextButtonVisible={props.isNextButtonVisible}
      showSkippableText={props.showSkippableText}
    />
  );
};

export default FlowPageContainer;
