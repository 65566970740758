import objectAssign from 'object-assign';
import * as ACTIONS from '../constants/action-types';

export const initialState = {
  report: null,
};

export default function reportReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_REPORT_CONTENT:
      if (!action.res.result) {
        return state;
      }
      return objectAssign({}, state, { report: action.res.result });
    case ACTIONS.CLEAR_REPORT_CONTENT:
      return objectAssign({}, state, { report: null });
    default:
      return state;
  }
}
