import './style.scss';
import React, { Fragment } from 'react';

import getConfig from '../../../../config';
import ConditionalRender from '../../../conditional-render';

import interactiveConfig from '../../../triggers/triggers-config';
import InteractiveComponentV2Service from '../../../../services/interactive-component-v2';
import { Validator } from '../../../utils/validator';
import { objectPropertiesEqual } from '../../../utils/objects';
import { getAdditionalData, getLocalValidators } from '../../utils/inputs';

import AnswersService from '../../../../services/answers-service';
import UserSession from '../../../../services/user-session';
import { InteractiveInputProps } from './interactive-input.types';

type InteractveInputState = {
  initializedPredefinedValue: boolean;
  value: string;
  error: boolean;
  errorText: string;
  isReadOnly?: boolean;
  additionalData: any;
  localValidators: any;
};

class InteractiveInputComponent extends React.Component<
  InteractiveInputProps,
  InteractveInputState
> {
  constructor(props: InteractiveInputProps) {
    super(props);
    this.state = {
      initializedPredefinedValue: false,
      value: '',
      error: false,
      errorText: '',
      isReadOnly: undefined,
      additionalData: getAdditionalData(props),
      localValidators: getLocalValidators(props),
    };
  }

  static getDerivedStateFromProps(
    props: InteractiveInputProps,
    state: InteractveInputState
  ) {
    const isReadOnly =
      props.usedSAML &&
      !!state.value &&
      InteractiveInputComponent._checkIfReadOnlySamlField(props);

    if (state.initializedPredefinedValue && state.isReadOnly === undefined) {
      return { isReadOnly };
    }

    return null;
  }

  shouldComponentUpdate(
    nextProps: InteractiveInputProps,
    nextState: InteractveInputState
  ) {
    return (
      !objectPropertiesEqual(nextProps, this.props) ||
      !objectPropertiesEqual(nextState, this.state)
    );
  }

  getOptionByField(fieldName: string) {
    return this.props.options.find(o => o.field === fieldName);
  }

  validateData() {
    let error = false;
    let errorText = '';

    const hasValue = () => {
      return [undefined, '', null].indexOf(this.state.value) === -1;
    };

    if (this._isNoValidationInput()) {
      return this.setState({ error, errorText });
    }

    if (this.state.additionalData.required) {
      if (
        interactiveConfig.INPUT_FIELD_NAMES.EMAIL_OPT_IN.indexOf(
          this.props.field_name
        ) > -1
      ) {
        error = error || typeof this.state.value !== 'boolean';
      } else {
        error = error || Validator.isEmpty(this.state.value);
      }

      errorText = error
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          Validator.MESSAGES.CUSTOM_ERRORS[this.props.field_name] ||
          `${this.props.label} is required`
        : errorText;
    }

    if (
      this.props.min_length &&
      this.props.max_length &&
      this.props.min_length === this.props.max_length &&
      hasValue() &&
      !error
    ) {
      error =
        error ||
        !Validator.hasMinLength(this.state.value, this.props.min_length);
      error =
        error ||
        !Validator.hasMaxLength(this.state.value, this.props.max_length);
      errorText = error
        ? `${this.props.label} must be exactly ${this.props.min_length} characters long`
        : errorText;
    }

    if (this.props.min_length && hasValue() && !error) {
      error =
        error ||
        !Validator.hasMinLength(this.state.value, this.props.min_length);
      errorText = error
        ? `${this.props.label} must be at least ${this.props.min_length} characters long`
        : errorText;
    }

    if (this.props.max_length && hasValue() && !error) {
      error =
        error ||
        !Validator.hasMaxLength(this.state.value, this.props.max_length);
      errorText = error
        ? `${this.props.label} must be between ${
            this.props.min_length || 1
          } and ${this.props.max_length} characters long`
        : errorText;
    }

    if (this.state.localValidators.length && hasValue() && !error) {
      this.state.localValidators.forEach((validator: any) => {
        // This is where we call the stringified validator string on the Validator class.
        // We also construct the error string using string interpolation. This is pretty bad.
        error =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          error || !Validator[validator].call(Validator, this.state.value);
        errorText = error
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            Validator.MESSAGES[`${validator}ErrorMessage`]
          : errorText;
      });
    }

    // eslint-disable-next-line max-len
    if (
      this.state.additionalData &&
      this.state.additionalData.regex_validator &&
      this.state.additionalData.regex_validator !== '-1' &&
      (this.state.additionalData.required || hasValue())
    ) {
      const regex = this.state.additionalData.regex_validator;
      error = error || !Validator.matchesRegex(this.state.value, regex);
      if (error) {
        // eslint-disable-next-line max-len
        errorText =
          regex === '^([a-zA-Z0-9_-]){4,}$'
            ? `${this.props.label} must be at least 4 valid characters (letters, numbers, underscore and hyphen)`
            : `${this.props.label} must be at least 6 valid characters (letters, numbers, underscore and hyphen)`;
        errorText =
          regex === '^([0-9]){8}$'
            ? `${this.props.label} must be exactly 8 numeric characters`
            : errorText;
      } else {
        errorText = '';
      }
    }

    const customError = this.getOptionByField('custom_error_text');
    if (customError && customError.value && customError !== '') {
      errorText = customError.value;
    }

    this.setState({ error, errorText });
    this.props.inputValidationChanged(this.props.field_name, error);
  }

  componentDidUpdate() {
    if (!this._isNoValidationInput() && this.state.additionalData.required) {
      interactiveConfig.INPUT_FIELD_NAMES.EMAIL_OPT_IN.indexOf(
        this.props.field_name
      ) > -1
        ? this.props.requiredInputValueStateChanged(
            [undefined, null, ''].indexOf(this.state.value) === -1
          )
        : this.props.requiredInputValueStateChanged(!!this.state.value);
    }

    if (this.props.shouldValidate && !this._isNoValidationInput()) {
      this.validateData();
    }
  }

  componentDidMount() {
    const predefinedValue = InteractiveInputComponent._getPredefinedValue(
      this.props
    );

    if (predefinedValue !== null || predefinedValue !== undefined) {
      this.onChange({
        target: {
          value: predefinedValue,
        },
      });
    }

    this.setState({
      initializedPredefinedValue: true,
    });
  }

  onChange(event: any) {
    let value;

    // Note: When onChange is triggered in <InputText>, the arguments provided (event.target.value, isDisabled)
    // This try block accounts for that scenario
    try {
      value = event.target.value;
    } catch (ignore) {
      value = event;
    }

    if (
      this.props.usedSSO &&
      interactiveConfig.INPUT_FIELD_NAMES.PASSWORD.indexOf(
        this.props.field_name
      ) > -1
    ) {
      // clear password value in case of SSO signup
      this.setState({ value: '' });
      InteractiveComponentV2Service.updateUserInputField(
        this.props.field_name,
        ''
      );
    } else {
      this.setState({ value: value });
      InteractiveComponentV2Service.updateUserInputField(
        this.props.field_name,
        value
      );
    }
  }

  getLabelHTML() {
    const ellipsis =
      interactiveConfig.INPUT_FIELD_NAMES.EMAIL_OPT_IN.indexOf(
        this.props.field_name
      ) === -1 &&
      interactiveConfig.INPUT_FIELD_NAMES.EMPLOYEE_ID.indexOf(
        this.props.field_name
      ) === -1;

    return (
      <Fragment>
        <label
          data-small-font={this.state.additionalData.use_small_font}
          className="sl-interactive--field-label"
          data-ellipsis={ellipsis}
          htmlFor={this.props.id.toString()}
        >
          {this.props.label}
          {this.state.additionalData.required ? '*' : ''}
        </label>
      </Fragment>
    );
  }

  getHelpText() {
    return (
      <ConditionalRender if={this.state.additionalData.help_text}>
        <label className="sl-interactive--help-text">
          {this.state.additionalData.help_text}
        </label>
      </ConditionalRender>
    );
  }

  static _checkIfReadOnlySamlField(props: InteractiveInputProps) {
    if (
      interactiveConfig.SAML_READONLY_INPUT_FIELD_NAMES.indexOf(
        props.field_name
      ) > -1
    ) {
      const temporaryUserData = UserSession.getTempUserData() || {};

      return (
        ['', undefined, null].indexOf(temporaryUserData[props.field_name]) ===
        -1
      );
    }

    return false;
  }

  static _getPredefinedValue(props: InteractiveInputProps) {
    if (
      interactiveConfig.INPUT_FIELD_NAMES.DATA_STORING.indexOf(
        props.field_name
      ) > -1
    ) {
      // The Data Storing checkbox needs to be cleared on every page load
      // as a requirement for GDPR. So this sets it to false.
      return false;
    }
    // eslint-disable-next-line max-len
    const dobSemantic =
      interactiveConfig.INPUT_FIELD_NAMES.DATE_OF_BIRTH.indexOf(
        props.field_name
      ) > -1
        ? getConfig().semanticIds.dateOfBirthQuestion
        : '';
    const genderSemantic =
      props.field_name === 'gender'
        ? getConfig().semanticIds.genderQuestion
        : '';
    if (dobSemantic) {
      return (
        InteractiveComponentV2Service.getUserValues(
          interactiveConfig.INPUT_FIELD_NAMES.DATE_OF_BIRTH[0]
        ) ||
        AnswersService.getAnswerBySemantic(dobSemantic) ||
        ''
      );
    }

    if (genderSemantic) {
      return (
        InteractiveComponentV2Service.getUserValues(
          interactiveConfig.INPUT_FIELD_NAMES.GENDER[0]
        ) ||
        AnswersService.getAnswerBySemantic(genderSemantic) ||
        ''
      );
    }

    return InteractiveComponentV2Service.getUserValues(props.field_name);
  }

  _isNoValidationInput() {
    return (
      interactiveConfig.NO_SUBMIT_INPUT_TYPES.indexOf(this.props.type) > -1 ||
      (interactiveConfig.INPUT_FIELD_NAMES.PASSWORD.indexOf(
        this.props.field_name
      ) > -1 &&
        this.props.usedSSO)
    );
  }
}

export default InteractiveInputComponent;
