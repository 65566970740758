import { FunctionComponent, useMemo } from 'react';
import {
  useAppSelector,
  useShallowEqualSelector,
} from '../../../hooks/use-app-react-redux';
import { FlowPageContainerProps, FlowPageProps } from '../flow-page.types';
import { ThemedFlowPage } from './themed-flowpage.component';
import selectors from '../../../selectors';
import { NHSSignupPage } from '../../nhs-signup-page/nhs-signup-page.component';

const FlowPageContainer: FunctionComponent<FlowPageContainerProps> = props => {
  const { currentStep, currentPage } = useShallowEqualSelector(
    selectors.selectFlowReducerCurrentStepPage
  );
  const shouldDisplaySignupExistingUserInFlow = useAppSelector(
    selectors.selectFlowReducerShouldDisplaySignupExistingUserInFlow
  );
  const step = useShallowEqualSelector(
    selectors.selectFlowReducerCurrentStepFromCurrentFlow
  );

  const values: FlowPageProps = useMemo(() => {
    return {
      currentPage,
      currentStep,
      shouldDisplaySignupExistingUserInFlow,
    };
  }, [currentPage, currentStep, shouldDisplaySignupExistingUserInFlow]);
  let componentToRender = (
    <ThemedFlowPage
      currentPage={values.currentPage}
      currentStep={values.currentStep}
      shouldDisplaySignupExistingUserInFlow={
        values.shouldDisplaySignupExistingUserInFlow
      }
      FitBitElement={props.FitBitElement}
      content={props.content}
      isNextButtonDisabled={props.isNextButtonDisabled}
      fitBitData={props.fitBitData}
      isValidated={props.isValidated}
      answers={props.answers}
      advance={props.advance}
      onContinue={props.onContinue}
      onAnswer={props.onAnswer}
      validateAnswers={props.validateAnswers}
      isNextButtonVisible={props.isNextButtonVisible}
      showSkippableText={props.showSkippableText}
    />
  );
  switch (step.flex_view_type) {
    case 'nhs-auth0':
      componentToRender = (
        <NHSSignupPage
          currentPage={values.currentPage}
          currentStep={values.currentStep}
          advance={props.advance}
        />
      );
  }
  return componentToRender;
};

export default FlowPageContainer;
