import React from 'react';
import FlowService from '../../services/flow-service';

class SocialSignupError extends React.Component {
  constructor(props) {
    super(props);

    const link = FlowService.getCurrentFlowURL();
    window.location.href = link || '/';
  }
}

export default SocialSignupError;
