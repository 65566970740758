import { FunctionComponent, useCallback } from 'react';
import ConditionalRender from '../conditional-render';
import { showExistingSignup } from '../../actions/flow';
import { shouldShowSignupWithExistingUser } from '../../components/interactive-component-v2/interactive-component-v2.helper';
import { InteractiveComponentItem } from '../../components/interactive-component-v2/interactive-component-v2.types';
import { useAppDispatch } from '../../hooks/use-app-react-redux';

import './multi-account-sign-in.style.scss';
import {
  getOppositeProductName,
  getProductDisplayName,
} from '../../helpers/product';
import { isEventSpaceOrEnterKey } from '../../helpers/event-helpers';

type MultiAccountSignInProps = {
  item: InteractiveComponentItem;
};

export const MultiAccountSignIn: FunctionComponent<MultiAccountSignInProps> = ({
  item,
}) => {
  const dispatch = useAppDispatch();

  const _onKeyDown = useCallback(
    event => {
      if (isEventSpaceOrEnterKey(event)) {
        dispatch(showExistingSignup(true));
      }
    },
    [dispatch]
  );

  return (
    <ConditionalRender if={shouldShowSignupWithExistingUser(item.action[0])}>
      <p className="multi-account-sign-in">
        Already have a {getOppositeProductName(getProductDisplayName())}{' '}
        account?&nbsp;
        <a
          onKeyDown={_onKeyDown}
          tabIndex={0}
          onClick={() => dispatch(showExistingSignup(true))}
        >
          Log in here
        </a>
      </p>
    </ConditionalRender>
  );
};
