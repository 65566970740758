import {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useMemo,
  ReactChild,
} from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { showExistingSignup } from '../../actions/flow';
import FlowService from '../../services/flow-service';
import OrganizationService from '../../services/org-service';
import {
  useAppDispatch,
  useShallowEqualSelector,
} from '../../hooks/use-app-react-redux';
import { getUserInfo } from '../../actions/user';
import EventService from '../../services/event-service';
import UserSession from '../../services/user-session';
import { InteractiveComponentItem } from '../interactive-component-v2/interactive-component-v2.types';
import { LoginForm } from '../login-form/login-form.component';
import { useLoginFormContext } from '../login-form/context';
import {
  getProductDisplayName,
  getOppositeProductName,
} from '../../helpers/product';
import {
  makeMultiproductSignupRequest,
  getErrorMessageFromResponseMessage,
  SIGNUP_PROBLEM_MESSAGE,
} from './api';
import { FooterV2 } from '../../containers/footer/footer.component';
import { MultiProductHeader } from '../multi-product-header/multi-product-header.component';
import * as CLASSES from '../../constants/class-names';
import selectors from '../../selectors';
import { AdvanceFunction } from '../flowpage/flow-page.types';
import { SIGNUP_EXISTING_ACCOUNT } from '../../constants/class-names';

import './style.scss';

type SignupProps = {
  item?: InteractiveComponentItem;
  advance: AdvanceFunction;
};

export const FORGOT_YOUR_PASSWORD = 'Forgot your password?';

/**
 * This is multi-product sign-up
 *
 * SignupWithAccountComponent
 * We want to emulate it being a route change so the back button will take a user back to signup
 * We don't actually want to change the route, we want to keep the user in the flow
 * We push this same route to emulate a transition, and when we pop off we return the state to what it should be
 *
 * Once signed up we need to replicate some of the things the interactive component does
 * We need to populate user data
 * We need to fire the account created event
 * Update user data with data that could live on other interactive components
 */
export const SignupWithAccount: FunctionComponent<SignupProps> = ({
  item,
  advance,
}) => {
  const { email, password, acceptedTcDate, acceptedDocConsentDate } =
    useLoginFormContext();
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();
  const history = useHistory();

  const productName = getProductDisplayName();
  const oppositeProductName = useMemo(
    () => getOppositeProductName(productName),
    [productName]
  );
  const { currentStep, currentPage } = useShallowEqualSelector(
    selectors.selectFlowReducerCurrentStepPage
  );

  useEffect(() => {
    history.push(history.location);
    return history.listen(() => {
      if (history.action === 'POP') {
        dispatch(showExistingSignup(false));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(async () => {
    const response = await makeMultiproductSignupRequest({
      email,
      password,
      data_storing: !!acceptedTcDate,
      doctor_acknowledgement: !!acceptedDocConsentDate,
    });
    if (response.status !== 200) {
      setError(SIGNUP_PROBLEM_MESSAGE);
      return;
    }
    const jsonResult = await response.json();
    if (jsonResult && jsonResult.status !== 'success') {
      const errMessage = getErrorMessageFromResponseMessage(
        jsonResult?.message
      );
      setError(errMessage);
      return;
    }

    // success!
    // not async safe
    setError('');

    // updates redux and localstorage
    getUserInfo({
      cb: () => {
        const userData = UserSession.getUserData();
        EventService.accountCreatedEvent({
          id: userData.id,
          uuid: userData.uuid,
          product_id: window.product_id,
          organization_id: OrganizationService.getOrgId(),
          first_name: userData.first_name,
          last_name: userData.last_name,
          idp_name: userData.idp_name,
          idp_id: userData.idp_id,
          employee_id: userData.employee_id,
          phone_number: userData.phone_number,
          email,
        });
        // Account created event 1 of 2
        const initiatingStepAndPage = FlowService.loadLocalProgress();
        advance({ initiatingStepAndPage }, false);
        dispatch(showExistingSignup(false));
      },
    })(dispatch);
  }, [
    email,
    password,
    dispatch,
    item,
    advance,
    acceptedTcDate,
    acceptedDocConsentDate,
  ]);

  return (
    <FlowDomWrapper currentPage={currentPage} currentStep={currentStep}>
      <div className={`${SIGNUP_EXISTING_ACCOUNT}__body`}>
        <h1>Log In With {oppositeProductName}</h1>
        <LoginForm
          onSubmit={onSubmit}
          errorMessage={error}
          productName={productName}
          showTermsConditions
          showDoctorConsent
        />
        <a
          href="/login-component/forgot"
          className={`${SIGNUP_EXISTING_ACCOUNT}__forgot-password-link`}
        >
          {FORGOT_YOUR_PASSWORD}
        </a>
        <FooterV2 id={`${SIGNUP_EXISTING_ACCOUNT}-footer`} />
      </div>
    </FlowDomWrapper>
  );
};

// maybe extract this if we duplicate it again? Rule of threes
const FlowDomWrapper = ({
  children,
  currentStep,
  currentPage,
}: {
  children: ReactChild;
  currentStep: number;
  currentPage: number;
}) => (
  <div
    id={CLASSES.SL_FLOW}
    data-step={currentStep}
    data-page={currentPage}
    className={CLASSES.SL_FLOW}
  >
    <MultiProductHeader />
    <div className={CLASSES.SL_DYNAMIC_CONTENT}>
      {/* this is used for dynamic backgrounds*/}
    </div>
    <div
      className={classNames(
        CLASSES.MULTIPRODUCT_CONTENT,
        CLASSES.MULTIPRODUCT_BACKGROUND
      )}
      role="main"
    >
      <div
        className={classNames(
          CLASSES.MULTIPRODUCT_BACKGROUND,
          CLASSES.MULTIPRODUCT_INNER_CONTENT
        )}
      >
        {children}
      </div>
    </div>
  </div>
);
