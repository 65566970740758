import { Regions } from '../..';
import { countryCodes } from '../../constant';
// Assuming tag format:
//  - lang
//  - lang-countryCode
//  - lang-dialect
//  - lang-dialect-countryCode
// Not currently supporting 3-number region codes
export const byNavigatorLanguage = (language = ''): Regions => {
  if (language.includes('-')) {
    const maybeCountryCode = language.split('-').reverse()[0]?.toUpperCase();
    if (countryCodes.US === maybeCountryCode) {
      return 'USA';
    }
    // TODO Ensure no other tags can clash with country/region tags
    // WHEN setup routing to UK Heap instance
    if (
      countryCodes.EUROPEAN_UNION.includes(maybeCountryCode) ||
      countryCodes.GB === maybeCountryCode
    ) {
      return 'Europe';
    }
  }
  return 'Other/Unknown';
};
