import { FunctionComponent, useCallback } from 'react';
import { useAppSelector } from '../../../hooks/use-app-react-redux';
import selectors from '../../../selectors';
import { onEnterOrSpace } from '../../utils/util-functions';
import { AdvanceFunction } from '../flow-page.types';
import ThemedInputButtonComponent from '../../input-button/themed.input-button.component';

type NextButtonProps = {
  isVisible: boolean;
  isDisabled: boolean;
  showSkippableText?: string;
  advance: AdvanceFunction;
  onContinue: AdvanceFunction;
  validateAnswers: () => void;
  className?: string;
};

export const CONTINUE = 'Continue';

const NextButton: FunctionComponent<NextButtonProps> = ({
  isVisible,
  isDisabled,
  showSkippableText,
  advance,
  onContinue,
  className,
}) => {
  const continueBtnLoading = useAppSelector(
    selectors.selectFlowReducerContinueButtonLoading
  );

  const advanceSkippedQuestion = useCallback(
    event => advance(event, true),
    [advance]
  );
  const _onEnterOrSpace = useCallback(
    (event: React.KeyboardEvent) => {
      return onEnterOrSpace(event, advanceSkippedQuestion);
    },
    [advanceSkippedQuestion]
  );
  const continueWithoutSkipping = useCallback(
    (event?: React.SyntheticEvent) => {
      onContinue(event, false);
    },
    [onContinue]
  );

  return (
    <div className={className}>
      {isVisible && (
        <ThemedInputButtonComponent
          disabled={isDisabled}
          value={CONTINUE}
          loading={continueBtnLoading}
          onClick={continueWithoutSkipping}
        />
      )}
      {showSkippableText && (
        <p
          tabIndex={0}
          onKeyDown={_onEnterOrSpace}
          data-testid="skip-question-text"
        >
          <span onClick={advanceSkippedQuestion}>{showSkippableText}</span>
        </p>
      )}
    </div>
  );
};

export default NextButton;
