const BACKEND_PORT = process.env.BACKEND_PORT || '8000';

const config = {
  global: {
    // MINIMUM 8 CHARACTERS, AND AT LEAST THREE OF THE FOLLOWING:
    // - UPPERCASE LETTER
    // - LOWERCASE LETTER
    // - SYMBOL
    // - DIGIT
    passwordRegex:
      /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[-!#$%^@&*()_+|~=`{}[\]:";'<>?,./\\])|(?=.*[A-Z])(?=.*\d)(?=.*[-!#$%@^&*()_+|~=`{}[\]:";'<>?,./\\])|(?=.*[A-Z])(?=.*[a-z])(?=.*[-!#$%^@&*()_+|~=`{}[\]:";'<>?,./\\])).{8,}$/,
    suppressNextButtonForComponents: [
      'interactive-v2-component',
      'report-component',
    ],
    signupActions: [
      {
        service: 'User',
        method: 'create',
      },
      {
        service: 'User',
        method: 'check_eligibility_for_organization_and_sign_up',
      },
    ],
    retryUrls: [
      'Flow/(.*?)/find_full_flow_with_id',
      'Flow/(.*?)/find_with_id',
      'Product/(.*?)/find_with_id',
      'Vanity/(.*?)/build_uri_by_flow_id_and_organization_id',
      'User/(.*?)/send_to_cbt_with_entity_id',
      'Report/(.*?)/generate_report_with_user_id_and_report_id',
      'Answer/(.*?)/create_update_answers_bulk',
    ],
    streamTraceButtons: {
      tagNames: ['A', 'BUTTON', 'DIV', 'INPUT'],
      eventListeners: ['click'],
      requiredData: {
        'data-stream-trace-track': 'true',
      },
      sendData: ['_inner_text', 'reference'],
    },
    questionAnswerValuesKeys: ['id', 'text', 'score'],
    messages: {
      errors: {
        autocomplete_invalid_answer_provided:
          'Please select one of the available answers',
      },
    },
  },
  local: {
    server: `http://localhost:${BACKEND_PORT}`,
    returnURL: `http://localhost:${BACKEND_PORT}`,
    fitBitClientID: '',
    fitBitClientSecretCode: '',
    defaultLanguage: 'en',
    gaTrackingId: 'UA-128353932-1',
    gaTrackingDebug: true,
    semanticIds: {
      genderQuestion: ['gender_semantic_id', 'gender'],
      dateOfBirthQuestion: ['date_of_birth_semantic_id', 'date_of_birth'],
    },
  },
  dev: {
    server: '',
    fitBitClientID: '',
    fitBitClientSecretCode: '',
    defaultLanguage: 'en',
    gaTrackingId: 'UA-128353932-1',
    gaTrackingDebug: true,
    semanticIds: {
      genderQuestion: ['gender_semantic_id', 'gender'],
      dateOfBirthQuestion: ['date_of_birth_semantic_id', 'date_of_birth'],
    },
  },
  newqa: {
    server: '',
    fitBitClientID: '',
    fitBitClientSecretCode: '',
    defaultLanguage: 'en',
    gaTrackingId: '',
    gaTrackingDebug: false,
    semanticIds: {
      genderQuestion: ['gender'],
      dateOfBirthQuestion: ['date_of_birth'],
    },
  },
  prod: {
    server: '',
    fitBitClientID: '',
    fitBitClientSecretCode: '',
    defaultLanguage: 'en',
    gaTrackingId: '',
    gaTrackingDebug: false,
    semanticIds: {
      genderQuestion: ['gender'],
      dateOfBirthQuestion: ['date_of_birth'],
    },
  },
};

// Hardcoded list of actions (backend sets env vars on window)
//
export default () => {
  let _config;
  if (config[window.ENV || 'local']) {
    _config = config[window.ENV || 'local'];
  } else {
    _config = config['prod'];
  }

  _config.gaTrackingId = window['ga_id'] || _config.gaTrackingId;
  _config = Object.assign(_config, config.global);
  return _config;
};
