import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRender from '../../conditional-render';

class Submit extends React.Component {
  render() {
    const { onClick, disabled, className, value, loading } = this.props;
    const classString = `sl-new-login-button ${className || ''}`;
    return (
      <div className="sl-new-login-button-wrapper">
        <button
          type="submit"
          onClick={onClick}
          role="button"
          disabled={disabled || loading}
          className={classString}
        >
          {value}
        </button>

        <ConditionalRender if={loading}>
          <div className="sl-new-login-button__loading">
            <div className={'bar1'} />
            <div className={'bar2'} />
            <div className={'bar3'} />
            <div className={'bar4'} />
            <div className={'bar5'} />
            <div className={'bar6'} />
            <div className={'bar7'} />
            <div className={'bar8'} />
            <div className={'bar9'} />
            <div className={'bar10'} />
            <div className={'bar11'} />
            <div className={'bar12'} />
          </div>
        </ConditionalRender>
      </div>
    );
  }
}

Submit.propTypes = {
  type: PropTypes.any,
  value: PropTypes.any.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  wide: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default Submit;
