// Polyfills
import 'whatwg-fetch';
import 'raf/polyfill';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import { render } from 'react-dom';
import MainContainer from './containers/index';
import { store } from './store/store';

// instead of importing global styles here, each sub-section in the router should import the
// relevant globals for that section.Some sections will use the theming globals, others will use
// the pre-existing unthemed styles. Those will be slowly phased out until everything uses the
// same globals
import './styles/base-imports.scss';

try {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

  if (isIE11) {
    const confirmed = window.confirm(
      'This website is not intended to work with your current browser. We will ' +
        'now attempt to redirect you to Microsoft Edge. Click “OK” to be redirected to Microsoft Edge, or click ' +
        '“Cancel” to stay on your current browser.'
    );
    if (confirmed) {
      window.location = `microsoft-edge:${window.location}`;
      setTimeout(() => {
        window.location = 'https://go.microsoft.com/fwlink/?linkid=2135547';
      }, 300);
    }
  }
} catch (error) {
  console.warn(error);
}

render(<MainContainer store={store} />, document.getElementById('app'));

if (module.hot) {
  module.hot.accept('./containers/index', () => {
    const NewRoot = require('./containers/index').default;
    render(<NewRoot store={store} />, document.getElementById('app'));
  });
}
