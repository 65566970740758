import { FunctionComponent } from 'react';
import { generateHeaderPageTrackerText } from '../../containers/header/header.utils';
import { questionProgressTrackerStyle } from './style';
import { useAppSelector } from '../../hooks/use-app-react-redux';

export type QuestionProgressTrackerProps = {
  className?: string;
};

const QuestionProgressTracker: FunctionComponent<
  QuestionProgressTrackerProps
> = ({ className }) => {
  const flow = useAppSelector(state => state.flowReducer);
  const flowText = generateHeaderPageTrackerText(flow)?.split(' ');
  const current = flowText[0];
  const total = flowText[flowText.length - 1];

  if (!current) {
    return null;
  }

  return (
    <p css={questionProgressTrackerStyle} className={className}>
      Question {current}/{total}
    </p>
  );
};

export default QuestionProgressTracker;
