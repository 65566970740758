import {
  loaderContainerStyle,
  loaderGroupStyle,
  loaderStyle,
  loaderStarsStyle,
} from './style';
import starsSvg from '../../svgs/stars.svg';
import loadingMoonGif from './img/throbber-moon-transparent@2x.gif';

export const SVG_TEST_ID = 'svg-test-id';
export const LOADER_CONTAINER_ID = 'loader-container-id';
export const GIF_ALT = 'Sleepio page is loading';

export const Loader = () => (
  <div css={loaderContainerStyle} data-testid={LOADER_CONTAINER_ID}>
    <div css={loaderGroupStyle}>
      <img css={loaderStarsStyle} src={starsSvg} data-testid={SVG_TEST_ID} />
      <img css={loaderStyle} src={loadingMoonGif} alt={GIF_ALT} />
    </div>
  </div>
);

export default Loader;
