import './style.scss';
import React from 'react';
import InteractiveInputComponent from '../interactive-input';
import EmailOptIn from './email-opt-in';
import GenderOption from './gender-option';
import ConditionalRender from '../../../conditional-render';
import interactiveConfig from '../../../triggers/triggers-config';
import AnswerService from '../../../../services/answers-service';

const SYNONYMS = {
  other: 'in another way',
  'in another way': 'other',
};

const RENAMING = {
  'in another way': 'other',
};

class InteractiveRadioInputComponent extends InteractiveInputComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  onChange(event) {
    if (
      interactiveConfig.INPUT_FIELD_NAMES.GENDER.indexOf(
        this.props.field_name
      ) > -1
    ) {
      event.target.value = this._manipulateGenderOption(
        event.target.value.toLowerCase()
      );
      if (
        event.target.value === 'other' &&
        !this.state.additionalData.includeUnknown
      ) {
        return false;
      }
    }

    super.onChange(event);
  }

  validateData() {
    super.validateData();
  }

  componentDidUpdate() {
    super.componentDidUpdate();
  }

  _getOptions() {
    if (
      interactiveConfig.INPUT_FIELD_NAMES.GENDER.indexOf(
        this.props.field_name
      ) > -1
    ) {
      const genderQuestionOptions = AnswerService.getGenderQuestionOptions();
      let options = genderQuestionOptions.length
        ? genderQuestionOptions
        : interactiveConfig.DEFAULT_GENDER_OPTIONS;
      if (!this.state.additionalData.includeUnknown) {
        const indexOfUnknown = options.findIndex(o => o.id === 'other');
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        indexOfUnknown !== -1 ? options.splice(indexOfUnknown, 1) : () => {};
      }
      return options;
    }

    if (
      interactiveConfig.INPUT_FIELD_NAMES.EMAIL_OPT_IN.indexOf(
        this.props.field_name
      ) > -1
    ) {
      return interactiveConfig.DEFAULT_EMAIL_OPT_IN_OPTIONS;
    }
  }

  _manipulateGenderOption(value) {
    return RENAMING[value] ? RENAMING[value] : value;
  }

  render() {
    let options = this._getOptions();
    options = options.length ? options : this.props.items;
    const error = !!this.props.fieldError || this.state.error;
    const errorText = this.props.fieldError || this.state.errorText;
    return (
      <div
        className="sl-interactive--radio sl-container-radio-input"
        data-disabled={this.props.isDisabled}
      >
        <ConditionalRender
          if={
            interactiveConfig.INPUT_FIELD_NAMES.GENDER.indexOf(
              this.props.field_name
            ) > -1
          }
        >
          {this.getLabelHTML()}
          <GenderOption
            id={this.props.field_name}
            onChange={this.onChange.bind(this)}
            value={this.state.value}
            isDisabled={this.props.isDisabled}
            includeUnknown={this.state.additionalData.includeUnknown || false}
            error={error}
            synonyms={SYNONYMS}
            errorText={errorText}
            items={options || []}
          />
        </ConditionalRender>
        <ConditionalRender
          if={
            interactiveConfig.INPUT_FIELD_NAMES.EMAIL_OPT_IN.indexOf(
              this.props.field_name
            ) > -1
          }
        >
          {this.getLabelHTML()}
          <EmailOptIn
            id={this.props.field_name}
            onChange={this.onChange.bind(this)}
            value={this.state.value}
            isDisabled={this.props.isDisabled}
            error={error}
            errorText={errorText}
            items={options || []}
          />
          {this.getHelpText()}
        </ConditionalRender>
        {this.getHelpText()}
      </div>
    );
  }
}

// InteractiveRadioInputComponent.propTypes = InteractiveInputComponent.propTypes;

export default InteractiveRadioInputComponent;
