import classnames from 'classnames';
import { SL_FLOW, SL_LOADER } from '../../constants/class-names';
import { isThemed } from '../../helpers/themes';

import './loader.scss';
import Loader from './themed.loader.component';

export const LoaderComponent = ({
  interactive = false,
}: {
  interactive?: boolean;
}) =>
  !isThemed() ? (
    <div className={classnames(SL_FLOW, { interactive })}>
      <div className={SL_LOADER} />
    </div>
  ) : (
    <Loader />
  );

export const InteractiveLoader = () => <LoaderComponent interactive />;

export default LoaderComponent;
