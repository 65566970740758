import {
  FunctionComponent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  InputText,
  InputTextProps,
} from '../input-text/themed.input-text.component';
import FuzzySearch from '../utils/fuzzy-search';
import ErrorMessage from '../errors/error-message/error-message.component';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { autocompleteWrapper } from './style';
import {
  QuestionContent,
  QuestionContentAnswer,
} from '../../types/questions.types';
import Dropdown from '../input-dropdown/dropdown.component';
import {
  isEventArrowDownKey,
  isEventEscapeKey,
} from '../../helpers/event-helpers';

export type AutocompleteProps = Omit<InputTextProps, 'type'> & {
  id: number | string;
  defaultAnswers: string[]; // strings will be answer ids
  max_visible_answers: QuestionContent['max_visible_answers'];
  answers: QuestionContent['answers'];
};

export const AUTOCOMPLETE_LIST_TEST_ID = 'AUTOCOMPLETE_LIST_TEST_ID';
export const SELECT_VALID_ANSWER = 'Please select one of the available answers';
export const MAX_LIST_LENGTH = 5;

const AutocompleteInput: FunctionComponent<AutocompleteProps> = ({
  id,
  inputId,
  name,
  onChange,
  preventSpaces,
  disabled,
  value,
  className,
  required,
  maxLength,
  minLength,
  error,
  placeholder,
  readOnly,
  isChecked,
  defaultAnswers,
  answers,
  max_visible_answers,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const searchRef = useRef(new FuzzySearch(answers, ['text'], { sort: true }));

  const [shouldShowAutocomplete, setShouldShowAutocomplete] = useState(false);
  const [autocompleteResults, setAutocompleteResults] = useState<
    typeof answers
  >(searchRef.current.search(value));
  const [shouldShowValidationError, setShouldShowValidationError] =
    useState(false);

  const _onFocus = useCallback(() => {
    setShouldShowAutocomplete(true);
    /*
     we are doing focusing event inside the dropdown component when needed that's why
     here we are adding setTimeout function with a delay of 0 miliseconds to ensure
     that the cursor position occurs after the focus event has completed. 
     */
    setTimeout(() => {
      inputRef.current?.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length
      );
    }, 0);
  }, [setShouldShowAutocomplete]);

  const isValidValue = useCallback(
    (_value: string) => {
      return (
        !!_value.replaceAll(' ', '').length &&
        !!answers.find(({ text }) => text === _value)
      );
    },
    [answers]
  );

  const closeDropdown = useCallback(() => {
    setShouldShowAutocomplete(prev => {
      // if it was open, determine if there's an error on-close
      if (prev) {
        setShouldShowValidationError(!isValidValue(value));
      }
      return false;
    });
  }, [isValidValue, value]);
  const onCloseDropdown = useCallback(
    (event: Event) => {
      const { target } = event;
      if (inputRef?.current?.contains(target as Node)) {
        return;
      }

      closeDropdown();
    },
    [closeDropdown]
  );

  const _onChange = useCallback(
    (userEnteredValue: string, isDisabled?: boolean) => {
      // prevent starting with space
      const value = userEnteredValue.trimStart();

      // because this is autocomplete, answers _must_ be in the list
      const _isValidValue = isDisabled || isValidValue(value);

      // this is just for obvious naming
      const _isDisabled = !_isValidValue;
      onChange(value, _isDisabled);

      if (!shouldShowAutocomplete) {
        setShouldShowAutocomplete(true);
      }

      // perform AC search
      const newResults = searchRef.current.search(value);
      setAutocompleteResults(newResults);
    },
    [isValidValue, onChange, shouldShowAutocomplete]
  );

  const _onSelect = ({ text }: QuestionContentAnswer) => {
    const isValid = isValidValue(text);
    const isDisabled = !isValid;
    setShouldShowValidationError(isDisabled);
    if (text.length !== 0) {
      _onChange(text, isDisabled);
    }
    setShouldShowAutocomplete(false);
  };

  useOnClickOutside(listRef, onCloseDropdown);

  const _onKeyDown = (e: KeyboardEvent) => {
    if (isEventEscapeKey(e) && shouldShowAutocomplete) {
      closeDropdown();
    }
    if (isEventArrowDownKey(e)) {
      // expected: when the users clicks on arrow down after the search is done focus moves down to dropdown
      // focus moves to listRef - dropdown list
      listRef.current?.focus();
    }
  };

  // componentDidMount
  useEffect(() => {
    // we run this to determine validity of answer for continue button, since it hasn't
    // been determined to be valid yet or not (validation is done in this component).
    if (value) {
      _onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // consider some form of memo, but not necessary
  const maxSuggestions = max_visible_answers || MAX_LIST_LENGTH;
  const defaultAnswer = answers.find(({ id }) => id === defaultAnswers[0]);
  const suggestions = autocompleteResults
    .filter(ans => ans.id !== defaultAnswer?.id) // filter out default ans first
    .slice(0, maxSuggestions); // then take next max

  return (
    <div css={autocompleteWrapper} data-autocomplete={true}>
      <InputText
        inputId={inputId}
        name={name}
        onChange={_onChange}
        onKeyDown={_onKeyDown}
        preventSpaces={preventSpaces}
        disabled={disabled}
        value={value}
        className={className}
        required={required}
        maxLength={maxLength}
        minLength={minLength}
        error={error || shouldShowValidationError}
        placeholder={placeholder}
        readOnly={readOnly}
        isChecked={isChecked}
        onFocus={_onFocus}
        ref={inputRef}
      />
      {shouldShowAutocomplete && !!value?.length && (
        <Dropdown
          inputRef={inputRef}
          id={id}
          defaultAnswer={defaultAnswer}
          answers={suggestions}
          onSelect={_onSelect}
          ref={listRef}
          inputId={inputId}
        />
      )}
      {((error && isChecked) || shouldShowValidationError) && (
        <ErrorMessage errorMessage={SELECT_VALID_ANSWER} />
      )}
    </div>
  );
};

export default AutocompleteInput;
