import objectAssign from 'object-assign';
import * as ACTIONS from '../constants/action-types';

export const initialState = {
  isVoucherValid: null,
  isEmployeeIdValid: null,
};

export default function validationReducer(state = initialState, action) {
  let isValid;
  switch (action.type) {
    case ACTIONS.VALIDATE_VOUCHER_CODE:
      if (!action.res.result) {
        return state;
      }
      isValid =
        action.res.result.message &&
        action.res.result.message.toLowerCase().indexOf('valid') > -1;
      return objectAssign({}, state, { isVoucherValid: isValid });
    default:
      return state;
  }
}
