import './style.scss';
import { Fragment } from 'react';
import { withQuestionLogic } from '.';
import { QuestionContent } from '../../types/questions.types';
import ConditionalRender from '../conditional-render';
import InputDate from '../input-date/input-date.component';
import { InputNumber } from '../input-number/input-number.component';
import InputOptions from '../input-options';
import InputRadio from '../input-radio';
import InputSelect from '../input-select';
import InputText from '../input-text';
import { QuestionTitle } from '../question-title/question-title.component';
import generateId from '../utils/generate-id';
import { QuestionValidator } from '../utils/validator';
import { checkUserNameLength, iOSSafari } from './question.helpers';
import { QuestionProps } from './question.types';
import { QuestionTypes } from '../../constants/question-types';
import TextToDownload from '../text-to-download/text-to-download';

export const Question = ({
  isChecked,
  answerValidator,
  advance,
  item,
  answers,
  error,
  freeText,
  language,
  selectedAnswers,
  handleInputDate,
  handleInputSelect,
  handleChoiceAnswer,
  handleInputText,
  handleInputNumber,
  handleRadioSelect,
}: QuestionProps) => {
  const question = item;
  const details = question.content[0];
  const oneLine = question.type.toLowerCase().indexOf('oneline') > -1;
  // Get responses for the current language
  const responses = question.content.find(
    question => question.lang === language
  ) as QuestionContent;
  const dynamicId = generateId();
  const checkBoxAnswerType =
    question.type === 'single' ||
    question.type === 'multi' ||
    question.type === 'box' ||
    question.type === 'limit box';

  let questionErrorMessage = '';
  let hasError = false;

  let userNameLengthError = null;
  const usernameCheck = question.semantic_id.indexOf('username') > -1;
  if (usernameCheck) {
    const userNameValue = freeText || selectedAnswers;
    userNameLengthError = checkUserNameLength(userNameValue);
  }

  if (
    answerValidator &&
    answerValidator.length &&
    answers.filter(a => a.values).length
  ) {
    const errors = QuestionValidator.validateAnswers(
      answers.filter(a => a.values),
      answerValidator
    );
    errors &&
      errors.forEach(error => {
        if (error.showErrorId === responses.question_id) {
          questionErrorMessage = error.error;
        }
        if (
          error.highlightedIds.find(error => error === responses.question_id)
        ) {
          hasError = true;
        }
      });
  }

  const isFullLine = question.type === 'oneLine radio';

  return (
    <Fragment>
      <div className={`sl-question ${question.type}`}>
        <QuestionTitle
          title={details.title}
          dynamicId={dynamicId}
          post_question_text={details.post_question_text}
          pre_question_text={details.pre_question_text}
          className={`${isFullLine ? 'sl-page-title-area--full' : ''}`}
        />

        <ConditionalRender
          if={question.type === QuestionTypes.TEXT_TO_DOWNLOAD}
        >
          <TextToDownload />
        </ConditionalRender>

        <ConditionalRender
          if={question.type === 'date' || question.type === 'oneLine date'}
        >
          <div className="sl-question-answer">
            <InputDate
              key={`${question.id}${selectedAnswers}`}
              inputId={dynamicId}
              isChecked={isChecked}
              short={oneLine}
              title={question.title}
              onChange={handleInputDate}
              selectedValue={selectedAnswers}
              details={details}
            />
          </div>
        </ConditionalRender>

        <ConditionalRender
          if={question.type === 'select' || question.type === 'oneLine select'}
        >
          <div className="sl-question-answer">
            <InputSelect
              onChange={(value: string) => handleInputSelect(responses, value)}
              isChecked={isChecked}
              inputId={dynamicId}
              errorText={questionErrorMessage}
              hasError={hasError}
              details={details}
              value={selectedAnswers}
              answers={responses.answers}
            >
              {/* value == null - only 2 = operator, we want to catch both null AND undefined, nothing else! */}

              <ConditionalRender
                if={
                  selectedAnswers === null ||
                  selectedAnswers === '' ||
                  iOSSafari
                }
              >
                <option key="0" value={undefined}>
                  Please Select
                </option>
              </ConditionalRender>

              {responses.answers.map((answer, idx) => (
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                /* @ts-ignore */
                <option key={idx + 1} value={answer.id}>
                  {answer.text}
                </option>
              ))}
            </InputSelect>
          </div>
        </ConditionalRender>

        <ConditionalRender
          if={
            checkBoxAnswerType ||
            question.type === 'card' ||
            question.type === 'side-by-side'
          }
        >
          <InputOptions
            type={question.type}
            isChecked={isChecked}
            inputId={dynamicId}
            advance={advance}
            validation={question.validation}
            details={details}
            cardType={question.type === 'card'}
            selectedAnswers={selectedAnswers !== null ? selectedAnswers : []}
            onChange={handleChoiceAnswer}
          />
        </ConditionalRender>

        <ConditionalRender
          if={
            question.type === 'text' ||
            question.type === 'oneLine text' ||
            question.type === 'autocomplete'
          }
        >
          <div className="sl-question-answer">
            <InputText
              key={`${question.id}-text`}
              isChecked={isChecked}
              error={!!error || userNameLengthError || questionErrorMessage}
              inputId={dynamicId}
              errorText={error || userNameLengthError || questionErrorMessage}
              onChange={handleInputText}
              defaultAnswers={question.default_answer_keys}
              value={freeText}
              useAutocomplete={question.type === 'autocomplete'}
              selectedAnswers={
                typeof selectedAnswers === 'string' ? selectedAnswers : ''
              }
              lang={language}
              details={[details]}
            />
          </div>
        </ConditionalRender>

        <ConditionalRender
          if={question.type === 'number' || question.type === 'oneLine number'}
        >
          <div className="sl-question-answer">
            <InputNumber
              key={`${question.id}-number`}
              isChecked={isChecked}
              onChange={handleInputNumber}
              value={
                Number.isInteger(parseInt(selectedAnswers, 10))
                  ? parseInt(selectedAnswers, 10)
                  : undefined
              }
              inputId={dynamicId}
              type="number"
              lang={language}
              validation={question.validation}
              details={details}
            />
          </div>
        </ConditionalRender>

        <ConditionalRender
          if={question.type === 'radio' || question.type === 'oneLine radio'}
        >
          <div
            key={question.id}
            className={`sl-question-answer ${
              isFullLine ? 'sl-question-answer--full' : ''
            }`}
          >
            <InputRadio
              isChecked={isChecked}
              items={responses.answers}
              key={`${responses.question_id}-${selectedAnswers}`}
              id={responses.question_id}
              selectedValue={selectedAnswers}
              oneLine={oneLine}
              details={details}
              handleRadioSelect={(value: string) =>
                handleRadioSelect(responses, value)
              }
            />
          </div>
        </ConditionalRender>

        <ConditionalRender if={details.post_answer_text}>
          <div
            className="sl-page-post-answer"
            dangerouslySetInnerHTML={{ __html: details.post_answer_text }}
          />
        </ConditionalRender>
      </div>
    </Fragment>
  );
};

export default withQuestionLogic(Question);
