import './style.scss';
import { SerializedStyles, Theme } from '@emotion/react';
import { Fragment, FunctionComponent, useRef } from 'react';
import { isEmptyOrNullOrUndefined } from '../../helpers/answers';
import { inputSharedErrorStyle } from '../../styles/themed.input-shared.styles';
import { isMappedQuestion, QuestionContent } from '../../types/questions.types';
import ErrorMessageComponent from '../errors/error-message/error-message.component';
import { HandleInputSelect } from '../question-component/question.types';
import QuestionGroupComponent from '../question-group-component/question-group.component';
import { selectDisabledStyle, selectStyle, selectWrapperStyle } from './style';
import DropdownOptions from '../input-dropdown/dropdown.options.component';

export type InputSelectProps = {
  id: number | string;
  inputId?: string;
  disabled?: boolean;
  className?: string;
  value: number | string | unknown; //selectedAnswers currently is any type
  title?: string;
  label?: string;
  isChecked: boolean; // isValidated
  answers: QuestionContent['answers'];
  isMapped?: boolean;
  hasError: boolean;
  errorText?: string;
  name?: string;
  questionId?: string;
  questionContentId?: string;
  actions?: any;
  onChange: (value: string) => ReturnType<HandleInputSelect>;
  onAnswer?: () => void;
};

const InputSelect: FunctionComponent<InputSelectProps> = ({
  id,
  disabled: isDisabled = false,
  className,
  value,
  inputId,
  label,
  isChecked: isValidated,
  answers,
  isMapped,
  onChange,
  questionId,
  questionContentId,
  errorText,
  hasError,
  onAnswer,
  actions,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  const _onChange = (value: string) => {
    onChange((value ?? '').toString());
  };

  const _hasError = (isValidated && !value && value !== 0) || hasError;
  let optionIdValue =
    value instanceof Array && value.length ? value[0].id : value;
  optionIdValue =
    optionIdValue instanceof Object ? optionIdValue.id : optionIdValue;

  const selectValue = optionIdValue
    ? optionIdValue
    : optionIdValue === 0
    ? 0
    : '';

  const belongsTo =
    questionId && questionContentId
      ? `${questionId}-${questionContentId}-en`
      : null;

  const styles: (((theme: Theme) => SerializedStyles) | SerializedStyles)[] = [
    selectStyle,
  ];
  if (isDisabled) {
    styles.push(selectDisabledStyle);
  }
  if (hasError) {
    styles.push(inputSharedErrorStyle);
  }

  const matchingAnswer = answers.find(ans => ans.id === selectValue);
  return (
    <div className={className} css={selectWrapperStyle}>
      {label && <label htmlFor={inputId}>{label}</label>}
      <div ref={ref} className="dropdown-select-wrapper">
        <select
          ref={selectRef}
          id={inputId}
          css={styles}
          data-testid={'select-dropdown'}
          tabIndex={0}
          role="listbox"
          aria-haspopup="listbox"
          aria-labelledby={`${inputId}_title`}
          aria-controls={`${inputId}_dropdown`}
          value={selectValue}
          onChange={v => _onChange(v.target.value)}
        >
          <DropdownOptions
            id={id}
            key={inputId}
            inputId={inputId}
            showPleaseSelect={isEmptyOrNullOrUndefined(selectValue)} // only show if no valid value is selected
            answers={answers.filter(ans => ans.id !== null)}
            matchingAnswer={matchingAnswer}
            selectedValue={selectValue}
          />
        </select>
      </div>
      {answers &&
        answers.map((answer, idx) => {
          return (
            onAnswer &&
            isMapped &&
            isMappedQuestion(answer) && (
              <Fragment key={idx}>
                <QuestionGroupComponent
                  hidden={true}
                  belongsTo={`${belongsTo}-option-${idx}`}
                  content={answer.selected}
                  actions={actions}
                  onAnswer={onAnswer}
                  selected={
                    !selectValue
                      ? null
                      : parseInt(selectValue) === parseInt(answer.id)
                  }
                />

                <QuestionGroupComponent
                  hidden={true}
                  belongsTo={`${belongsTo}-option-${idx}`}
                  content={answer.not_selected}
                  actions={actions}
                  onAnswer={onAnswer}
                  selected={
                    !selectValue
                      ? null
                      : parseInt(selectValue) !== parseInt(answer.id)
                  }
                />
              </Fragment>
            )
          );
        })}
      {_hasError && errorText && (
        <ErrorMessageComponent errorMessage={errorText} />
      )}
    </div>
  );
};

export default InputSelect;
