export const timeZones = {
  USA: [
    'America/New_York',
    'America/Detroit',
    'America/Kentucky',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Chicago',
    'America/Menominee',
    'America/North_Dakota',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'Pacific/Honolulu',
    // Ignored Wake Island as its small and confusing
  ],
};

export const countryCodes = {
  US: 'US',
  GB: 'GB',
  EUROPEAN_UNION: [
    'BE', // Belgium
    'EL', // Greece
    'LT', // Lithuania
    'PT', // Portugal
    'BG', // Bulgaria
    'ES', // Spain
    'LU', // Luxembourg
    'RO', // Romania
    'CZ', // Czechia
    'FR', // France
    'HU', // Hungary
    'SI', // Slovenia
    'DK', // Denmark
    'HR', // Croatia
    'MT', // Malta
    'SK', // Slovakia
    'DE', // Germany
    'IT', // Italy
    'NL', // Netherlands
    'FI', // Finland
    'EE', // Estonia
    'CY', // Cyprus
    'AT', // Austria
    'SE', // Sweden
    'IE', // Ireland
    'LV', // Latvia
    'PL', // Poland
  ],
};
