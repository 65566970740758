import React from 'react';
import { countryCodes } from './country-codes';
import './country-code-select.scss';
import classNames from 'classnames';
import { CountryCode } from './country-codes.types';
import { selectStyle, selectWrapperStyle } from '../input-select/style';
import { SerializedStyles, Theme } from '@emotion/react';
import { inputSharedErrorStyle } from '../../styles/themed.input-shared.styles';
import {
  countryCodeSelectDisabledStyle,
  countryCodeSelectErrorStyle,
  countryCodeSelectStyle,
  countryCodeSelectWrapperStyle,
  countryCodeWrapperStyle,
} from './country-code';

const defaultOption = 'Country Code';

export const errorMessage = 'Please select a country code';
export const countryCodeSelectTestId = 'code-country-select-testId';
export const defaultCountry = countryCodes[0];

export default function CountryCodeSelect({
  setCountryCode,
  // per requirements, we only display US, and US is the only code
  // that currently exists in the countryCodes array
  currentSelection = defaultCountry.code,
  isError,
  style,
}: {
  setCountryCode: (countryCode: CountryCode) => void;
  currentSelection?: CountryCode;
  isError: boolean;
  style?: React.CSSProperties;
}) {
  const _onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCountryCode(event.target.value as CountryCode);
  };

  const styles: (((theme: Theme) => SerializedStyles) | SerializedStyles)[] = [
    selectStyle,
  ];
  if (isError) {
    styles.push(inputSharedErrorStyle);
  }

  return (
    <div
      data-testid={countryCodeSelectTestId}
      css={countryCodeSelectStyle}
      style={style}
    >
      <div css={selectWrapperStyle}>
        <label htmlFor="country-codes">Select your country code</label>
        <div css={countryCodeWrapperStyle}>
          <select
            id="country-codes"
            css={[
              selectStyle,
              countryCodeSelectWrapperStyle,
              !currentSelection && countryCodeSelectDisabledStyle,
              isError && countryCodeSelectErrorStyle,
            ]}
            disabled
            name="country-codes"
            onChange={_onChange}
            value={currentSelection || ''}
          >
            <option disabled value="">
              {defaultOption}
            </option>
            {countryCodes.map(countryData => (
              <option
                key={`${countryData.country}${countryData.code}`}
                value={countryData.code}
              >{`${countryData.country} (+${countryData.code})`}</option>
            ))}
          </select>
        </div>
      </div>
      <div
        className={classNames('country-code-select__message', {
          'country-code-select__message--error': isError,
        })}
      >
        {isError && errorMessage}
      </div>
    </div>
  );
}
