import { BHTheme } from '../../../../../types/bh-themes.types';
import { css } from '@emotion/react';

export const passwordHelpTextStyle = (theme: BHTheme) =>
  css({
    paddingLeft: theme.padding.SMALL,
    paddingTop: theme.padding.SMALL,
    textDecoration: 'underline',
    // Need width for tooltip arrow to display properly
    fontSize: theme.fonts.fontSizes.SMALL,
    width: '130px',
    lineHeight: '1.35em',
    color: theme.fonts.fontColors.PRIMARY,
  });
export const passwordBlockPaddingStyle = (theme: BHTheme) =>
  css({
    // Space between two password inputs
    '> :first-child': {
      paddingBottom: theme.padding.LARGE,
    },
  });
