import './style.scss';
import { useState, useEffect, FunctionComponent } from 'react';
import QuestionGroupComponent from '../question-group-component/question-group.component';
import {
  isMappedQuestion,
  MappedQuestionContentAnswer,
  QuestionContentAnswer,
} from '../../types/questions.types';
import classNames from 'classnames';

export type InputRadioProps = {
  id: number | string;
  items: MappedQuestionContentAnswer[] | QuestionContentAnswer[];
  selectedValue: QuestionContentAnswer | never[] | null;
  className?: string;
  disabled?: boolean;
  oneLine: boolean;
  details: any;
  isChecked: boolean;
  isMapped?: boolean;
  actions?: any;
  onAnswer?: () => void;
  handleRadioSelect: (selectedId: any) => void;
};

const InputRadio: FunctionComponent<InputRadioProps> = props => {
  const {
    id,
    items,
    selectedValue,
    className,
    disabled,
    oneLine,
    details,
    isChecked,
    isMapped,
    actions,
    onAnswer,
    handleRadioSelect,
  } = props;

  const [selected, setSelected] = useState<number | string | boolean>(
    // set selected to selectedValue.id if its a string, number or boolean, otherwise set it to '-1'
    !Array.isArray(selectedValue) &&
      selectedValue !== null &&
      selectedValue !== undefined &&
      selectedValue?.id !== null
      ? selectedValue.id
      : '-1'
  );

  useEffect(() => {
    setSelected(
      !Array.isArray(selectedValue) &&
        selectedValue !== null &&
        selectedValue !== undefined &&
        selectedValue?.id !== null
        ? selectedValue.id
        : '-1'
    );
  }, [selectedValue]);

  function handleCheck(selectedId: number | string | boolean) {
    setSelected(selectedId);
    handleRadioSelect(selectedId);
  }

  const specialStyle =
    oneLine && details ? { width: `${100 / items.length}%` } : {};

  const belongsTo = details
    ? `${details.question_id}-${details.id}-${details.lang}`
    : null;

  return (
    <fieldset className="sl-radio-wrapper">
      <div
        className={classNames('sl-radio-items', {
          'sl-radio-items--has-error': isChecked && selected === '-1',
        })}
      >
        {items.map((item, idx) => (
          <div key={idx} className="sl-radio" style={specialStyle}>
            <label htmlFor={`${id}_${idx}`}>
              <input
                id={`${id}_${idx}`}
                name={`${id}_${idx}`}
                checked={item.id === selected}
                autoFocus={item.id === selected}
                onChange={() => {
                  if (item.id !== null) {
                    handleCheck(item.id);
                  }
                }}
                type="radio"
                disabled={disabled || false}
                className={className}
                tabIndex={0}
              />
              <span>{item.text || item}</span>
              {onAnswer && isMapped && isMappedQuestion(item) && (
                <>
                  <QuestionGroupComponent
                    hidden={true}
                    belongsTo={`${belongsTo}-option-${idx}`}
                    content={item.selected}
                    actions={actions}
                    onAnswer={onAnswer}
                    selected={selected === '-1' ? null : selected === `${idx}`}
                  />
                  <QuestionGroupComponent
                    hidden={true}
                    belongsTo={`${belongsTo}-option-${item.id}`}
                    content={item.not_selected}
                    actions={actions}
                    onAnswer={onAnswer}
                    selected={selected === '-1' ? null : selected === `${idx}`}
                  />
                </>
              )}
            </label>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default InputRadio;
