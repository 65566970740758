import React, { useMemo } from 'react';
import {
  SL_PROGRESS_WRAPPER,
  SL_PROGRESS,
  SL_PROGRESS_DOTS,
} from '../../constants/class-names';
import {
  useAppSelector,
  useShallowEqualSelector,
} from '../../hooks/use-app-react-redux';
import selectors from '../../selectors';
import FlowService from '../../services/flow-service';

import './style.scss';

type ProgressBarProps = {
  totalPages: number;
  currentPage: number;
  stickyTop: boolean;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  totalPages,
  currentPage,
  stickyTop,
}) => {
  const percentage = useMemo(
    () => ((currentPage + 1) / totalPages) * 100,
    [totalPages, currentPage]
  );

  return (
    <div className={SL_PROGRESS_WRAPPER} data-sticky-top={stickyTop}>
      <div className={SL_PROGRESS} style={{ width: `${percentage}%` }} />
      <Dots totalPages={totalPages} currentPage={currentPage} />
    </div>
  );
};

const DotsComponent: React.FC<{ totalPages: number; currentPage: number }> = ({
  totalPages,
  currentPage,
}) => {
  return (
    <div className={SL_PROGRESS_DOTS}>
      {Array(totalPages).map((_val, i) => (
        <span key={`dot-${i}`} data-active={i <= currentPage} />
      ))}
    </div>
  );
};
const Dots = React.memo(DotsComponent);

export const ProgressBarContainer: React.FC = () => {
  const { currentPage } = useShallowEqualSelector(
    selectors.selectFlowReducerCurrentStepPage
  );
  const pages = useAppSelector(selectors.selectFlowReducerPages);
  const totalPages = pages.length;
  const stickyTop = FlowService.isPostFlow();

  return (
    <ProgressBar
      currentPage={currentPage}
      totalPages={totalPages}
      stickyTop={stickyTop}
    />
  );
};

export default ProgressBar;
