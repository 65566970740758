import { css, Theme } from '@emotion/react';
import { OFF_WHITE, SLEEPIO_NAVY } from './colors';

export const inputSharedStyle = (theme: Theme) =>
  css({
    width: '100%',
    boxSizing: 'border-box',
    margin: 0,
    background: OFF_WHITE,
    border: theme.borders.defaultBorder,
    borderRadius: theme.borders.defaultBorderRadius,
    padding: theme.padding.LARGE,
    color: SLEEPIO_NAVY,
    fontWeight: theme.fonts.fontWeights.MEDIUM,
    height: '70px',
    fontSize: theme.fonts.fontSizes.MEDIUM,

    '&: focus, &: active': {
      border: theme.borders.defaultActiveBorder,
    },

    '&::placeholder': {
      color: theme.inputs.placeholder.color,
    },
  });

// we should consider adding the math here to handle border size differences
export const inputSharedErrorStyle = (theme: Theme) =>
  css([
    inputSharedStyle(theme),
    {
      border: theme.errors.border,
    },
  ]);
