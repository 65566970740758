import * as Tooltip from '@radix-ui/react-tooltip';
import { FunctionComponent } from 'react';
import { noop } from '../../props/empty';
import { arrowStyle, contentStyle } from './styles';

export const TooltipContent: FunctionComponent<Tooltip.TooltipContentProps> = ({
  children,
  side,
  align,
}) => {
  return (
    <Tooltip.Content
      side={side}
      align={align}
      sideOffset={10}
      collisionPadding={20}
      css={contentStyle}
    >
      {children}
    </Tooltip.Content>
  );
};

export type TooltipProps = {
  delayDuration?: number;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  content: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
} & Tooltip.TooltipContentProps;

export const TooltipWithTrigger: FunctionComponent<TooltipProps> = ({
  children,
  delayDuration = 300,
  open,
  defaultOpen = false,
  onOpenChange = noop,
  content,
  side,
  align,
}) => (
  <Tooltip.Root
    delayDuration={delayDuration}
    open={open}
    defaultOpen={defaultOpen}
    onOpenChange={onOpenChange}
    data-testid="tooltip"
  >
    <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
    <TooltipContent side={side} align={align} data-testid="tooltip-text">
      {content}
      <Tooltip.Arrow css={arrowStyle} width={15} height={20} />
    </TooltipContent>
  </Tooltip.Root>
);

export const TooltipProvider = Tooltip.Provider;
