import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../store/history';
import flowReducer from './flow-reducer';
import reportReducer from './report-reducer';
import userReducer from './user-reducer';
import answerReducer from './answer-reducer';
import validationReducer from './validation-reducer';
import interactiveV2Reducer from './interactive-v2-reducer';
import appReducer from './app-reducer';

const router = connectRouter(history);
const rootReducer = combineReducers({
  router,
  flowReducer,
  reportReducer,
  userReducer,
  answerReducer,
  validationReducer,
  interactiveV2Reducer,
  appReducer,
});

export default rootReducer;
