import { css, Theme } from '@emotion/react';
import { MIDDLE_BLUE, WHITE } from '../../styles/colors';

const dropDownListWrapperStyle = (theme: Theme) =>
  css({
    borderRadius: theme.borders.defaultBorderRadius,
    border: theme.borders.defaultBorder,
    background: WHITE,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    width: '100%',
    position: 'absolute', // dropdown is pretty much always absolutely positioned in order to not affect the DOM
    zIndex: 1, // might not be necessary, but good for just being really sure
    lineHeight: 1.35,
    fontWeight: theme.fonts.fontWeights.MEDIUM,
    maxHeight: '40vh',
    overflowY: 'auto',
  });

export const dropDownItemStyle = (theme: Theme) =>
  css({
    padding: `12px ${theme.padding.LARGE}`, // figma demands 12px, it looks weird with 8 or 16
    fontSize: theme.fonts.fontSizes.MEDIUM,
    color: theme.fonts.fontColors.PRIMARY,

    // the non-standard sizes for border radius are to make the interior background color fit better with the parent padding + radius
    '&:first-of-type': {
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
    },
    '&:last-of-type': {
      borderBottomLeftRadius: '6px',
      WebkitBorderBottomRightRadius: '6px',
    },

    '&.default': {
      borderTop: theme.borders.defaultBorder,
    },

    '&.highlighted': {
      background: MIDDLE_BLUE,
    },
  });

export const listWrapper = (theme: Theme) =>
  css(dropDownListWrapperStyle(theme), {
    li: dropDownItemStyle(theme),
    overscrollBehavior: 'contain',
  });
