import './style.scss';
import React from 'react';
import InputSelect from '../input-select';
import moment, { Moment } from 'moment';
import ConditionalRender from '../conditional-render';
import {
  formatDate,
  generateDayArray,
  isUserLegalAge,
  isValidDate,
} from './input-date.utils';

type InputDateProps = {
  title?: string;
  className?: string;
  selectedValue: string | null | undefined;
  onChange: (date: Moment | string | null, isDisabled: boolean) => void;
  onClick?: () => null;
  details: unknown; // Required but never used in this component?
  disabled?: boolean;
  isChecked?: boolean;
  inputId?: string;
  error?: unknown;
  errorText?: string;
  formatting?: string;
  short: boolean;
};

type InputDateState = {
  month: string | number;
  year: string | number;
  day: string | number;
  selectedValue: string | Moment | null | undefined;
  valid: boolean;
  error: boolean | string;
  options: InputDateOptions;
};

type InputDateOptions = {
  day: number[];
  month: string[];
  year: number[];
};

type TimePeriod = 'day' | 'month' | 'year';

class InputDate extends React.Component<InputDateProps, InputDateState> {
  /*
        Input date component to render the month - day - year 3 selectors for inputting date
        If a valid date value is passed as the selected value, it will automatically pre-populate with the dropdowns
        with that date.
     */
  constructor(props: InputDateProps) {
    super(props);
    const now = moment().year() - 18;

    let month;
    let day;
    let year;

    //TODO: Refactor into helper function
    if (props.selectedValue) {
      const savedDate = moment(props.selectedValue);
      month = savedDate.format(props.short ? 'MMM' : 'MMMM');
      day = parseInt(savedDate.format('DD'));
      year = parseInt(savedDate.format('YYYY'));
    }

    // build the list of options for each dropdown
    const options = {
      year: Array.from(new Array(87), (_val, index) => now - index),
      day: month ? generateDayArray(month) : [],
      month: props.short ? moment.monthsShort() : moment.months(),
    };

    this.state = {
      month: month || '',
      year: year || '',
      day: day || '',
      selectedValue: props.selectedValue,
      valid: true,
      error: false,
      options: options,
    };
  }

  componentDidMount() {
    if (this.props.selectedValue) {
      const isValidDateBool = isValidDate(
        this.state.day,
        this.state.month,
        this.state.year
      );

      if (isValidDateBool) {
        this.setState({ error: false });
      } else {
        this.setUserError();
      }
    }
  }

  setUserError(): void {
    const formattedDate = formatDate(
      this.state.day,
      this.state.month,
      this.state.year
    );

    if (!isUserLegalAge(formattedDate)) {
      this.setState(
        {
          valid: false,
          error: 'You must be at least 18 years old to use Sleepio.',
        },
        () => {
          this.props.onChange(null, true);
        }
      );
    } else {
      this.setState(
        { valid: false, error: 'Please enter a valid date of birth.' },
        () => {
          this.props.onChange(null, true);
        }
      );
    }
  }

  handleDateUpdate(
    day: string | number,
    month: string | number,
    year: string | number
  ): void {
    if (month && day && year) {
      if (isValidDate(day, month, year)) {
        const formattedDate = formatDate(day, month, year, true);
        this.props.onChange(formattedDate, false);
      } else {
        this.setUserError();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onMonthSelect(timePeriod: string, value): void {
    const dayArray = generateDayArray(value);

    // Cast to number
    const dayAsNumber = +this.state.day;
    if (dayArray.includes(dayAsNumber)) {
      this.setState(
        prevState => ({
          ...prevState,
          [timePeriod]: value,
          valid: true,
          options: {
            ...prevState.options,
            day: dayArray,
          },
        }),
        () => {
          this.handleDateUpdate(
            this.state.day,
            this.state.month,
            this.state.year
          );
        }
      );
    } else {
      this.setState(
        prevState => ({
          ...prevState,
          [timePeriod]: value,
          day: '',
          valid: true,
          options: {
            ...prevState.options,
            day: dayArray,
          },
        }),
        () => {
          // Only null out selectedValue when year is available and valid
          // When only month/day are valid, should keep old props.selectedValue
          if (this.state.year) {
            this.props.onChange(null, true);
          }
        }
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  onDayOrYearSelect(timePeriod: string, value): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.setState({ [timePeriod]: value, valid: true }, () => {
      this.handleDateUpdate(this.state.day, this.state.month, this.state.year);
    });
  }

  // Render template for a dropdown
  getTemplate(timePeriod: TimePeriod): JSX.Element {
    const isDisabled =
      this.props.disabled || this.state.options[timePeriod].length === 0;
    const onSelectChange =
      timePeriod === 'month'
        ? this.onMonthSelect.bind(this, timePeriod)
        : this.onDayOrYearSelect.bind(this, timePeriod);

    return (
      <div
        className={`sl-date-select--col sl-date-type--${timePeriod}`}
        key={timePeriod}
      >
        <InputSelect
          onChange={onSelectChange}
          disabled={isDisabled}
          className={`sl-date-select ${
            this.props.isChecked && !this.state.options[timePeriod]
              ? 'sl-select--has-error'
              : ''
          }`}
          inputId={`select-${timePeriod}`}
          label={this.props.short ? '' : `Select ${timePeriod}`}
          value={this.state[timePeriod]}
          title={timePeriod}
        >
          <option disabled={true} value="">
            {this.props.short ? '' : 'Select '} {timePeriod}
          </option>
          {this.state.options[timePeriod].map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </InputSelect>
      </div>
    );
  }

  // Render day, month, and year dropdowns
  getDropdowns(): JSX.Element[] {
    const formatting = this.props.formatting || 'month-day-year';
    return formatting.split('-').map((timePeriod: string) => {
      return this.getTemplate(timePeriod as TimePeriod);
    });
  }

  //TODO: Investigate: This is assigned for the hidden input onChange event
  // But the input is likely readonly (this method would never be used)
  dateValueChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const savedDate = moment(new Date(event.target.value));
    const year = parseInt(savedDate.format('YYYY'));
    const month = savedDate.format(this.props.short ? 'MMM' : 'MMMM');
    const day = parseInt(savedDate.format('DD'));
    const formattedDate = formatDate(day, month, year);

    this.setState({
      year: year,
      month: month,
      day: day,
    });

    this.props.onChange(formattedDate, false);
  }

  render() {
    const { inputId } = this.props;

    return (
      <div>
        <div className="sl-date-wrapper">
          <input
            hidden={true}
            onChange={this.dateValueChange.bind(this)}
            type="date"
            id={inputId}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            value={this.state.selectedValue}
          />
          {this.getDropdowns()}
        </div>

        <ConditionalRender if={!this.state.valid || this.props.error}>
          <p className="sl-error">
            {this.state.error ||
              this.props.errorText ||
              'Please enter a valid date of birth.'}
          </p>
        </ConditionalRender>
      </div>
    );
  }
}

export default InputDate;
