import { FunctionComponent, memo } from 'react';
import UserSession from '../../services/user-session';
import { getUrlParamsWithSeparator } from '../../helpers/utm-params';
import { onEnterOrSpace } from '../../components/utils/util-functions';
import { getProductByUrl, getProductHomePage } from '../../helpers/product';

import { getFullIdpLogoutUrl } from './header.utils';
import {
  headerLandingPageStyle,
  headerStyle,
  loginStyle,
  logoStyle,
} from './themed.header.style';
import { useAppSelector } from '../../hooks/use-app-react-redux';
import { selectUserReducerIsUserAuthenticated } from '../../selectors/user-reducer';
import { selectFlowReducerIsCurrentStepLandingStep } from '../../selectors/flow-reducer';
import { isEventEnterKey, isEventSpaceKey } from '../../helpers/event-helpers';
import classNames from 'classnames';
import SleepioBlue from '../../components/logos/sleepio-blue.component';

export type HeaderProps = {
  isLoading?: boolean;
  className?: string;
};

export const LOG_IN = 'Log in';
export const LOG_OUT = 'Log out';
export const SLEEPIO_LOGO = 'Sleepio Logo';

const Header: FunctionComponent<HeaderProps> = ({
  isLoading = false,
  className,
}) => {
  const userIsAuthenticated = useAppSelector(
    selectUserReducerIsUserAuthenticated
  );
  const displayLogin = userIsAuthenticated ? !userIsAuthenticated : true;

  // Landing page header has less styles
  const isLandingStep = useAppSelector(
    selectFlowReducerIsCurrentStepLandingStep
  );

  const loginUrl = `/login-component/login?product_stream_id=${window.product_stream_id}`;
  const productName = getProductByUrl();
  const utm = getUrlParamsWithSeparator();
  const homePage = `${getProductHomePage(productName)}${utm}`;

  const logOut = () => {
    UserSession.logOutAsync().then(resData => {
      if (resData?.idp_logout_uri) {
        const idpLogoutUrl = getFullIdpLogoutUrl(
          resData.idp_logout_uri,
          '/login-component/login'
        );
        window.location.assign(idpLogoutUrl);
      } else {
        window.location.assign(window.location.pathname);
      }
    });
  };

  const onEnterOrSpaceOrCtrPlusEnter = (
    event: React.KeyboardEvent<HTMLAnchorElement>,
    url: string | URL
  ) => {
    if (isEventSpaceKey(event)) {
      event.preventDefault();
    }
    if (
      (isEventEnterKey(event) && !event.metaKey && !event.ctrlKey) ||
      isEventSpaceKey(event)
    ) {
      window.location.assign(url);
    }
  };

  return isLoading ? null : (
    <header
      className={classNames('header-element', className)}
      css={isLandingStep ? headerLandingPageStyle : headerStyle}
    >
      <a href={homePage} css={logoStyle}>
        <SleepioBlue alt={SLEEPIO_LOGO} />
      </a>

      {displayLogin && (
        <a
          css={loginStyle}
          tabIndex={0}
          href={loginUrl}
          onKeyDown={e => onEnterOrSpaceOrCtrPlusEnter(e, loginUrl)}
        >
          {LOG_IN}
        </a>
      )}

      {!displayLogin && (
        <a
          css={loginStyle}
          tabIndex={0}
          onClick={logOut}
          onKeyDown={e => onEnterOrSpace(e, logOut)}
        >
          {LOG_OUT}
        </a>
      )}
    </header>
  );
};

export default memo(Header);
