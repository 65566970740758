import './style.scss';
import React from 'react';
import InteractiveInputComponent from '../interactive-input';
import InputText from '../../../input-text';
import ConditionalRender from '../../../conditional-render';

class InteractivePasswordInputComponent extends InteractiveInputComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
    };
  }

  onChange(event) {
    super.onChange(event);
  }

  validateData() {
    if (this.props.usedSSO) {
      this.setState({ error: false, errorText: '' });
      this.props.inputValidationChanged(this.props.field_name, false);
    } else {
      super.validateData();
    }
  }

  componentDidUpdate() {
    super.componentDidUpdate();
  }

  render() {
    const error = !!this.props.fieldError || this.state.error;
    const errorText = this.props.fieldError || this.state.errorText;
    return (
      <ConditionalRender if={!this.props.usedSSO}>
        <div
          className="sl-container-text-input"
          data-disabled={this.props.isDisabled}
        >
          {this.getLabelHTML()}
          <InputText
            id={this.props.id}
            name={this.props.field_name}
            type={this.props.type}
            maxLength={this.props.max_length}
            minLength={this.props.min_length}
            loading={false}
            disabled={this.props.isDisabled}
            onChange={this.onChange.bind(this)}
            value={this.state.value || ''}
            required={this.state.additionalData.required}
            error={error}
            errorText={errorText}
          />
          {this.getHelpText()}
        </div>
      </ConditionalRender>
    );
  }
}

// InteractivePasswordInputComponent.propTypes = InteractiveInputComponent.propTypes;

export default InteractivePasswordInputComponent;
