import { css, Theme } from '@emotion/react';
import { inputSharedStyle } from '../../styles/themed.input-shared.styles';

const base = (theme: Theme) =>
  css({
    label: {
      color: theme.fonts.fontColors.LEGAL,
    },
    input: {
      ...inputSharedStyle(theme),
    },
  });

export const dateWrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export const onelineDate = (theme: Theme) =>
  css([
    base(theme),
    {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.padding.MEDIUM,
      justifyContent: 'center',
      width: '100%',

      div: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        fontSize: theme.fonts.fontSizes.MEDIUM,

        label: {
          paddingLeft: theme.padding.SMALL,
          fontSize: theme.fonts.fontSizes.SMALL,
          lineHeight: '16px',
          paddingBottom: theme.padding.SMALL,
        },

        ['&:last-of-type']: {
          flexGrow: 2,
        },
      },
      input: {
        // gets rid of number arrows
        MozAppearance: 'textfield',
        ['&::-webkit-inner-spin-button, &::-webkit-outer-spin-button']: {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '&:focus': {
          padding: `calc(${theme.padding.LARGE} - 1px)`,
        },
      },
    },
  ]);

export const errorStyle = (theme: Theme) =>
  css({
    alignSelf: 'flex-start',
    paddingLeft: theme.padding.SMALL,
  });
