// flow/page
export const SL_FLOW = 'sl-flow';
export const SL_DYNAMIC_CONTENT = 'sl-dynamic-content';
export const SL_CONTENT = 'sl-content';
export const SL_INNER_CONTENT = 'sl-inner-content';
export const SL_INTERACTIVE_BODY_CLASS = 'sl-interactive-v2-body';
export const SL_PAGE = 'sl-page';
export const SL_PAGE_ITEM = 'sl-page__item';
export const SL_PAGE_ITEM_ONELINE = 'sl-page__item-one-line'; // should this template SL_PAGE_ITEM?
export const SL_SKIP_QUESTION = 'sl-skip-question';
export const SL_GENERAL_ERROR = 'sl-general-error';

// multiproduct flow/page
export const MULTIPRODUCT_CONTENT = 'multiproduct-content';
export const MULTIPRODUCT_INNER_CONTENT = 'multiproduct-inner-content';
export const MULTIPRODUCT_BACKGROUND = 'multiproduct-background';
export const SIGNUP_EXISTING_ACCOUNT = 'signup-existing-account';

// loader
export const SL_LOADER = 'sl-loader';

// progress bar
export const SL_PROGRESS = 'sl-progress';
export const SL_PROGRESS_WRAPPER = 'sl-progress-wrapper';
export const SL_PROGRESS_DOTS = 'sl-progress-dots';

// header
export const MULTIPRODUCT_HEADER = 'multi-product-header';
export const SL_HEADER = 'sl-header';
export const HEADER_ELEMENT = 'header-element'; // hook for custom CSS targeting

// footer
export const FOOTER_ELEMENT = 'footer-element'; // hook for custom CSS targeting
