import { Fragment, FunctionComponent } from 'react';
import InputRadio from '../../../../input-radio';
import { QuestionContentAnswer } from '../../../../../types/questions.types';

export type EmailOptInProps = {
  id: string;
  items: QuestionContentAnswer[];
  onChange: (arg0: { target: { value: string | undefined } }) => void;
  error: boolean;
  errorText: string;
  value: boolean | undefined;
  isDisabled: boolean;
};

const EmailOptIn: FunctionComponent<EmailOptInProps> = ({
  id,
  items,
  value,
  isDisabled,
  error,
  errorText,
  onChange,
}) => {
  function deriveSelectedOptinObject(
    itemId: string | number | boolean | undefined
  ) {
    const selectedValue = items.find(item => item.id === itemId);
    return selectedValue || null;
  }

  const optInChanged = (value: string) => {
    onChange({ target: { value } });
  };

  return (
    <Fragment>
      <InputRadio
        id={id}
        selectedValue={deriveSelectedOptinObject(value)}
        disabled={isDisabled}
        items={items}
        handleRadioSelect={optInChanged}
        oneLine={false}
        details={undefined}
        isChecked={false}
      />
      {error && <div className="sl-input-text__error">{errorText}</div>}
    </Fragment>
  );
};

export default EmailOptIn;
