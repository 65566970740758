import { Fragment, useEffect, useState } from 'react';
import Header from '../../components/header';
import Footer from '../../../footer/footer.component';
import {
  MFAVerificationStyles,
  otpContainerStyle,
  otpInputStyle,
} from './mfa-verification.styles';
import OtpInput from 'react18-input-otp';
import Modal from '../../../modal/modal.component';
import loginWithOtp from './mfa-api';
import { getDevicePlatform } from '../../../../helpers/devicePlatform';

type MFAVerificationProps = {
  backgroundColor: string;
  product: string;
  email: string;
  product_id: number;
  otp_session_token: string;
  redirectURL: string;
  setOtpError: (otpErrorMessage: string) => void;
};

const MFAVerification = ({
  backgroundColor,
  product,
  email,
  product_id,
  otp_session_token,
  redirectURL,
  setOtpError,
}: MFAVerificationProps) => {
  const [otp, setOtp] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const handleChange = (otp: string) => setOtp(otp);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    loginWithOtp({
      otp_session_token: otp_session_token,
      email: email,
      product_id: product_id,
      otp: otp,
      device_platform: getDevicePlatform(),
    })
      .then(response => {
        if (response?.status === 200 && response?.result?.redirect) {
          window.location.href = response?.result?.redirect;
        } else if (response?.result?.status === 'error') {
          // an incorrect otp should return a 401 status
          setOtpError('Invalid verification code. Try again.');
        }
      })
      .catch(_err => {
        setOtpError('There was an issue validating your verification code.');
      });
  };

  useEffect(() => {
    otp.length === 6 ? setIsDisabled(false) : setIsDisabled(true);
  }, [otp]);

  return (
    <Fragment>
      <Header
        backgroundColor={backgroundColor}
        product={product}
        redirectURL={redirectURL}
      />
      <div css={MFAVerificationStyles(isDisabled)}>
        <p>
          Enter the verification code from your authentication app to complete
          log in
        </p>
        <form onSubmit={handleSubmit}>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            isInputNum={true}
            containerStyle={otpContainerStyle()}
            inputStyle={otpInputStyle()}
            shouldAutoFocus={true}
          />
          <input disabled={isDisabled} type="submit" value="Verify" />
        </form>
        <a href="#" onClick={() => setModalOpen(true)}>
          Need help?
        </a>
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          modalContent={'need-help-modal'}
        />
      </div>
      <Footer footerTheme={'dark'} />
    </Fragment>
  );
};

export default MFAVerification;
