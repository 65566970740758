import objectAssign from 'object-assign';
import * as ACTIONS from '../constants/action-types';

const INITIAL_STATE = {
  answers: null,
};

export default function answerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIONS.GET_ANSWERS_FOR_USER:
      return objectAssign({}, state, { answers: action.data.result });

    default:
      return state;
  }
}
