export const DAYLIGHT = 'daylight';
export const SLEEPIO = 'sleepio';
export const DAYLIGHT_PRODUCT_NAME = 'Daylight';
export const SLEEPIO_PRODUCT_NAME = 'Sleepio';
export const DAYLIGHT_HOMEPAGE = 'https://www.trydaylight.com/';
export const SLEEPIO_HOMEPAGE = 'https://www.sleepio.com/';
import {
  HELLO_SLEEPIO_EMAIL_ADDRESS,
  HELLO_DAYLIGHT_EMAIL_ADDRESS,
} from '../constants/email-addresses';

export const getProductHelpEmail = (product?: string) => {
  if (!product) {
    product = getProductByUrl();
  }

  if (product === DAYLIGHT) {
    return HELLO_DAYLIGHT_EMAIL_ADDRESS;
  }
  return HELLO_SLEEPIO_EMAIL_ADDRESS;
};

export const getProductDisplayName = (product?: string) => {
  if (!product) {
    product = getProductByUrl();
  }
  if (product === DAYLIGHT) {
    return DAYLIGHT_PRODUCT_NAME;
  }
  return SLEEPIO_PRODUCT_NAME;
};

export const getOppositeProductName = (productName: string) => {
  return productName === SLEEPIO_PRODUCT_NAME
    ? DAYLIGHT_PRODUCT_NAME
    : SLEEPIO_PRODUCT_NAME;
};

export const getProductId = (product?: string) => {
  if (!product) {
    product = getProductByUrl();
  }

  if (product === DAYLIGHT) {
    return 2;
  }
  return 1;
};

export const getProductByUrl = () => {
  return window.location.origin.includes('trydaylight') ? DAYLIGHT : SLEEPIO;
};

export const getProductHomePage = (product?: string) => {
  if (!product) {
    product = getProductByUrl();
  }
  if (product === DAYLIGHT) {
    return DAYLIGHT_HOMEPAGE;
  }
  return SLEEPIO_HOMEPAGE;
};

export const getProductIdByUrl = () => {
  const product = getProductByUrl();
  return getProductId(product);
};
