import DateInput from '../../../input-date/themed-input-date.component';
import InteractiveInputLabel from '../interactive-input/themed.interactive-input-label.component';
import { wrapper } from './style';
import { Moment } from 'moment';
import { getStoredValueByFieldName } from '../../utils/data-fetcher-utils';

type InteractiveDateProps = {
  id: number;
  label?: string;
  additionalData?: {
    required?: boolean;
  };
  onChange: (dateValue: null | string | Moment) => void;
};

const InteractiveDate = ({
  id,
  label,
  additionalData,
  onChange,
}: InteractiveDateProps) => {
  const storedDate = getStoredValueByFieldName('date_of_birth') || null;
  const additionalDataRequired = additionalData?.required;

  return (
    <div css={wrapper}>
      {label && (
        <InteractiveInputLabel
          id={id}
          text={label}
          hasInput={false}
          mode={'field-label'}
          isAdditionalDataRequired={additionalDataRequired}
        />
      )}
      <DateInput inputId={`${id}`} onChange={onChange} value={storedDate} />
    </div>
  );
};

export default InteractiveDate;
