import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import ConditionalRender from '../../conditional-render';

class Text extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if (this.props.shouldValidate) {
      this.props.onValidate(this.isValid(this.props.isReset));
    }
  }

  render() {
    return (
      <div className="input-container">
        <label htmlFor={this.props.name}>{this.props.label}</label>
        <input
          type={this.props.type || 'text'}
          id={this.props.id}
          name={this.props.name}
          data-error={this.props.error}
          maxLength={this.props.maxLength || null}
          minLength={this.props.minLength || null}
          placeholder={this.props.placeholder || ''}
          value={this.props.value || ''}
          disabled={this.props.disabled || false}
          onChange={this._onChange.bind(this)}
          className={
            this.state.error || this.props.errorText ? 'input-error' : ''
          }
        />

        <ConditionalRender if={this.state.error || this.props.errorText}>
          <div className="sl-input-text__error">
            {this.state.error || this.props.errorText}
          </div>
        </ConditionalRender>
      </div>
    );
  }

  _onChange(event) {
    this.setState({ error: '' });
    this.props.onChange(event.target.value);
  }
}

Text.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.any.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  shouldValidate: PropTypes.bool,
  onValidate: PropTypes.func.isRequired,
  isReset: PropTypes.bool,
};

export default Text;
