import { ComponentType, FC, Fragment, memo, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { AnyAction, Store } from 'redux';
import { ThemeProvider } from '@emotion/react';
import GaService from '../services/ga-service';
import ErrorPage from '../components/error-page';
import AutologinPageRedirect from '../components/autologin-redirect-page';
import FlowContainer from './flow/flow.container';
import ForgotPassword from './forgot-password/forgot-password.component';
import ResetPassword from './forgot-password/reset-password';
import GenericLoginComponent from '../components/new-auth/login';
import GenericForgotPasswordComponent from '../components/new-auth/forgot-password';
import GenericResetPasswordComponent from '../components/new-auth/reset-password';
import SocialSignupError from './page/social-signup-error';
import Unsubscribe from './unsubscribe';
import PreferenceCenter from './preference-center/preference-center.component';
import { RootState } from '../store/store';
import { history } from '../store/history';
import { CacheProvider } from '@emotion/react';
import themedGlobalStyles, {
  unthemedGlobalStyles,
} from '../styles/global-styles';

// pre-load the loader CSS. Ideally we replace oneoff loaders with LoaderComponent
// and not do this here
// I will feel comfortable removing this if I know know static content has loader classes
import '../components/loader/loader.scss';
import { getTheme, isThemed } from '../helpers/themes';
import { emotionCache, Global } from '../styles/emotion/custom-inject-global';
import InteractiveComponentV2Service from '../services/interactive-component-v2';

// Log GA page view event with current url
GaService.pageView(window.location.pathname + window.location.search);

const INDEX_ROUTE = '/';

function decodeHtml(html?: string) {
  if (!html) {
    return;
  }

  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  const txtValue = txt.value;
  txt.remove();
  return txtValue;
}

const setProductCss = () => {
  const targetStyleEl = document.querySelector('#product-css');
  if (!targetStyleEl) {
    return;
  }

  if (isThemed()) {
    targetStyleEl.innerHTML = '';
    return;
  }
  targetStyleEl.innerHTML = decodeHtml(window.product_css) || '';
};

const IndexContainer = ({ store }: { store: Store<RootState, AnyAction> }) => {
  const theme = getTheme();

  useEffect(() => {
    setProductCss();
    InteractiveComponentV2Service.removeEmailAndPasswordFromLocalStorage();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={emotionCache}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <Switch>
              <Route
                exact
                path={'/social-signup-error'}
                component={withUnthemedGlobals(SocialSignupError)}
              />
              <Route
                exact
                path={'/login-component/login'}
                component={withUnthemedGlobals(GenericLoginComponent)}
              />
              <Route
                exact
                path={'/login-component/forgot'}
                component={withUnthemedGlobals(GenericForgotPasswordComponent)}
              />
              <Route
                exact
                path={'/login-component/reset'}
                component={withUnthemedGlobals(GenericResetPasswordComponent)}
              />
              <Route
                exact
                path={'/preference-center'}
                component={withUnthemedGlobals(PreferenceCenter)}
              />
              <Route
                exact
                path={`${INDEX_ROUTE}:product/forgot-password`}
                component={withUnthemedGlobals(ForgotPassword)}
              />
              <Route
                exact
                path={`${INDEX_ROUTE}:product/reset-password`}
                component={withUnthemedGlobals(ResetPassword)}
              />
              <Route
                exact
                path={`${INDEX_ROUTE}:product/unsubscribe/:uuid`}
                component={withUnthemedGlobals(Unsubscribe)}
              />
              <Route
                exact
                path={`${INDEX_ROUTE}:product/unsubscribe/:organization_id/:uuid`}
                component={withUnthemedGlobals(Unsubscribe)}
              />
              <Route
                exact
                path={INDEX_ROUTE}
                component={withThemedGlobals(FlowContainer)}
              />
              <Route
                exact
                path={`${INDEX_ROUTE}:product/error`}
                component={withUnthemedGlobals(ErrorPage)}
              />
              <Route
                exact
                path={`${INDEX_ROUTE}:product/check-new-email`}
                component={withUnthemedGlobals(AutologinPageRedirect)}
              />
              <Route
                exact
                path={`${INDEX_ROUTE}:vanity`}
                component={withThemedGlobals(FlowContainer)}
              />
              <Route
                exact
                path={`${INDEX_ROUTE}:product/:org`}
                component={withThemedGlobals(FlowContainer)}
              />
              <Route
                exact
                path={`${INDEX_ROUTE}:product/:org/:id`}
                component={withThemedGlobals(FlowContainer)}
              />
              <Route component={withUnthemedGlobals(ErrorPage)} />
            </Switch>
          </ConnectedRouter>
        </Provider>
      </CacheProvider>
    </ThemeProvider>
  );
};

const withThemedGlobals =
  <T extends object>(Component: ComponentType<T>): FC<T> =>
  // eslint-disable-next-line react/display-name
  props => {
    const themeToUse = isThemed() ? themedGlobalStyles : unthemedGlobalStyles;
    return (
      <Fragment>
        <Global styles={themeToUse} />
        <Component {...(props as T)} />
      </Fragment>
    );
  };

const withUnthemedGlobals =
  <T extends object>(Component: ComponentType<T>): FC<T> =>
  // eslint-disable-next-line react/display-name
  props =>
    (
      <Fragment>
        <Global styles={unthemedGlobalStyles} />
        <Component {...(props as T)} />
      </Fragment>
    );

export default memo(IndexContainer);
