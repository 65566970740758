import { FunctionComponent, memo } from 'react';
import { FlowPageContainerProps, FlowPageProps } from '../flow-page.types';
import * as CLASSES from '../../../constants/class-names';
import ThemedContentComponent from './themed-content.component';
import Header from '../../../containers/header/themed.header.component';
import ProgressBar from '../../question-progress-tracker/question-progress-tracker.component';
import Footer from '../../footer/footer.component';
import NextButton from './next-button.component';
import {
  pageWrapper,
  flowpageContent,
  progressTracker,
  nextButton,
  ttdFlowpageContent,
} from './style';
import { getPaddingWithPrimaryColor } from '../../../helpers/is-primary-color';

export const ThemedFlowPage: FunctionComponent<
  FlowPageProps & FlowPageContainerProps
> = ({
  currentStep,
  currentPage,
  FitBitElement,
  content,
  isNextButtonDisabled,
  fitBitData,
  isValidated,
  answers,
  advance,
  onAnswer,
  validateAnswers,
  onContinue,
  isNextButtonVisible,
  showSkippableText,
}) => {
  const hasInjectedHeader = !!document.body.querySelector('.sl-static header');
  const isPrimaryBackgroundStep = getPaddingWithPrimaryColor(content);

  return (
    <div
      id={CLASSES.SL_FLOW}
      data-step={currentStep}
      data-page={currentPage}
      css={pageWrapper}
    >
      {!hasInjectedHeader && <Header />}

      <ProgressBar css={progressTracker} />

      {/* this is a hack to get around some data loading, fitbitelement doesn't really render */}
      {!!FitBitElement && FitBitElement}

      <div role="main">
        <div
          css={isPrimaryBackgroundStep ? ttdFlowpageContent : flowpageContent}
        >
          <ThemedContentComponent
            content={content}
            isNextButtonDisabled={isNextButtonDisabled}
            fitBitData={fitBitData}
            isValidated={isValidated}
            answers={answers}
            advance={advance}
            onAnswer={onAnswer}
            validateAnswers={validateAnswers}
          />
        </div>
        <NextButton
          isVisible={isNextButtonVisible}
          isDisabled={isNextButtonDisabled}
          showSkippableText={showSkippableText}
          advance={advance}
          onContinue={onContinue}
          validateAnswers={validateAnswers}
          css={nextButton}
        />
      </div>

      {/* conditional logic to hide/pick footer theme */}
      <Footer />
    </div>
  );
};

export default memo(ThemedFlowPage);
