import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import constants from '../../config/constants';
import ConditionalRender from '../../../conditional-render';
import { SL_HEADER, HEADER_ELEMENT } from '../../../../constants/class-names';

import './style.scss';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      loading: false,
    };
    this.logo = React.createRef();
  }

  render() {
    const isSleepio = this.props.product === 'sleepio';
    return (
      <header id={SL_HEADER} className={`flex ${HEADER_ELEMENT}`}>
        <a
          id="sl-header-logo"
          className="logo"
          href={constants.header[this.props.product].url}
        >
          <img
            className="logo-image"
            src={constants.header[this.props.product].logo}
            alt={this.props.product}
          />
        </a>
        <ConditionalRender if={this.props.variant === 'login' && isSleepio}>
          <Link id="sl-header-log-in" to={'/login-component/login'}>
            Log in
          </Link>
        </ConditionalRender>
        <ConditionalRender if={this.props.variant === 'signup' && isSleepio}>
          <a
            id="sl-header-sign-up"
            href="https://www.sleepio.com/sleepio/welcome"
          >
            Sign up
          </a>
        </ConditionalRender>
      </header>
    );
  }
}

Header.propTypes = {
  product: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['login', 'signup']).isRequired,
};

export default Header;
