import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getDevicePlatform } from '../../../helpers/devicePlatform';

import ConditionalRender from '../components/conditional-render';
import GaService from '../services/ga.js';
import Email from '../components/inputs/email';
import Password from '../components/inputs/password';
import Submit from '../components/buttons/submit';
import Header from '../components/header';
import GenericNewAuthenticationPage from '../components/page';
import constants from '../config/constants';
import MFAVerification from './mfa/mfa-verification.component';

import './style.scss';
import LoaderComponent from '../../loader';
import { logError } from '../../../analytics/logger/logger';
import Actions from '../actions/api';
import { NHSButton } from '../components/buttons/nhs/NHSButton';
import { NHSLoginError, inEnglandQuestion } from './constants';

export default class GenericLoginComponent extends GenericNewAuthenticationPage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = `Login - ${this.state.productNameCapitalized}`;
  }

  getRedirectURLAndRedirect = async event => {
    event?.preventDefault();
    const requestUrl = `/api/service_method_proxy/UserAccountAuthentication/1/get_oidc_authorization_url`;
    try {
      const { result } = await Actions.doRequest(
        requestUrl,
        { redirect_uri: window.location.href },
        'POST'
      );

      // save oidc token in local storage
      localStorage.setItem('oidcSessionToken', result.oidc_session_token);

      if (result.url) {
        window.location.assign(result.url);
      } else {
        this.setState({ nhsError: true });
      }
    } catch (e) {
      logError(e);
      this.setState({ nhsError: true });
    }
  };

  setOtpError(otpErrorMessage) {
    this.setState({
      otpError: true,
      showMFAVerificationScreen: false,
      email: '',
      password: '',
      otpErrorMessage: otpErrorMessage,
    });
  }

  // calling the action is a component specific action
  // calls specific url
  // sends google analytics events as needed when response comes back on action ajax call
  // redirects the user to the value of in the redirect uri param from the response, if that is a valid url
  async callAction(idp_name, access_token, data) {
    const next = this.urlData.get('next') || null;
    const productId = this._getProductId();

    const payload =
      idp_name === 'email'
        ? {
            email: this.state.email.trim(),
            password: this.state.password,
            product_id: productId,
            next_url: next,
            device_platform: getDevicePlatform(),
          }
        : {
            idp_name,
            access_token: (data && data['id']) || access_token,
            product_id: productId,
            next_url: next,
          };

    const url =
      idp_name === 'email'
        ? '/api/service_method_proxy/GeneralLogin/1/login_user'
        : '/api/service_method_proxy/GeneralLogin/1/login_sso';

    const response = await super.callAction(payload, url, idp_name, next);

    if (
      response &&
      response.result &&
      response.result.otp_session_token &&
      ['success', 'redirect'].indexOf(response.result.status) > -1
    ) {
      this.setState({
        showMFAVerificationScreen: true,
        otp_session_token: response.result.otp_session_token,
        otpError: false,
        submitting: false,
      });
    } else if (
      response &&
      response.result &&
      ['success', 'redirect'].indexOf(response.result.status) > -1
    ) {
      this.setState({
        pageLoading: true,
        submitting: false,
        shouldValidate: false,
        loginError: false,
      });
      GaService.initialize().then(() => GaService.login(idp_name));
      const responseRedirectURI =
        response && response.result ? response.result.redirect : '';

      // if response contains a valid value for redirect uri (https://domain...) then that is used as a location to redirect
      // the user to
      // if response does not contain a valid value for the redirect uri (not a valid URL), then whatever response
      // comes back is added at the end of the original next param that was received when first landing on the page
      // and the user is redirected there. example: next param was https://onboarding.sleepio.com and redirect uri
      // in response is "/sleepio/google" then the user will be redirected to https://onboarding.sleepio.com/sleepio/google
      // if the next param was https://onboarding.sleepio.com/sleepio/google and redirect uri in response is "https://onboarding.sleepio.com/sleepio/bnymellon"
      // then the user will be redirected to https://onboarding.sleepio.com/sleepio/bnymellon
      try {
        window.location.href = new URL(responseRedirectURI);
      } catch (ignore) {
        window.location.href = `${next}${responseRedirectURI}`;
      }
    } else {
      GaService.initialize().then(() => GaService.loginFailed(idp_name));
      this.setState({
        loginError: true,
        email: '',
        password: '',
        submitting: false,
        shouldValidate: false,
      });
    }
  }

  render() {
    const forgotPassLink = '/login-component/forgot';
    if (this.state.isAuth0Loading) {
      return <LoaderComponent />;
    }
    if (this.state.showMFAVerificationScreen) {
      return (
        <Fragment>
          <div id="modal-portal"></div>
          <MFAVerification
            backgroundColor={constants.product[this.state.product].color}
            product={this.state.product}
            email={this.state.email.trim()}
            product_id={this._getProductId()}
            otp_session_token={this.state.otp_session_token}
            redirectURL={this.urlData.get('next')}
            setOtpError={this.setOtpError.bind(this)}
          />
        </Fragment>
      );
    } else {
      return (
        <div className={`${this.state.product} login-component-container`}>
          <div
            id="full-site-loading-spinner"
            data-visible={this.state.pageLoading}
          />

          <Header
            backgroundColor={constants.product[this.state.product].color}
            product={this.state.product}
            redirectURL={this.urlData.get('next')}
            variant={'signup'}
          />
          <div id="sl-login-page">
            <form
              id="sl-login"
              name="sl-login"
              onSubmit={this.onFormAttemptSubmit.bind(this)}
            >
              <Email
                type="text"
                id="sl-login-email"
                name="sl-login-email"
                required={true}
                label="Email"
                placeholder="Email"
                shouldValidate={this.state.shouldValidate}
                disabled={this.state.submitting}
                error={
                  (this.errorFields &&
                    this.errorFields.indexOf('email') > -1) ||
                  this.state.loginError
                }
                onChange={value => this.onInputValueChange('email', value)}
                value={this.state.email}
                onValidate={this.inputValidationChanged.bind(this, 'email')}
              />

              <Password
                type="password"
                id="sl-login-password"
                name="sl-login-password"
                required={true}
                label="Password"
                placeholder="Password"
                shouldValidate={this.state.shouldValidate}
                disabled={this.state.submitting}
                error={
                  (this.errorFields &&
                    this.errorFields.indexOf('password') > -1) ||
                  this.state.loginError
                }
                onChange={value => this.onInputValueChange('password', value)}
                value={this.state.password}
                onValidate={this.inputValidationChanged.bind(this, 'password')}
              />

              <ConditionalRender if={this.state.loginError}>
                <div className="sl-login--error">
                  <p>Incorrect email address or password. Please try again.</p>
                </div>
              </ConditionalRender>

              <ConditionalRender if={this.state.otpError}>
                <div className="sl-login--error">
                  <p css={{ alignSelf: 'center' }}>
                    {this.state.otpErrorMessage}
                  </p>
                </div>
              </ConditionalRender>

              <Link className="sl-new-login--forgot" to={forgotPassLink}>
                Forgot password?
              </Link>

              <Submit
                value="Log in"
                loading={this.state.submitting}
                disabled={this.state.submitting}
              />
              {this.state.shouldShowNHS && (
                <>
                  <div className="sl-new-login--country-text">
                    <p>{inEnglandQuestion}</p>
                  </div>
                  <NHSButton
                    testId="nhs-button"
                    onClick={this.getRedirectURLAndRedirect}
                  />
                </>
              )}
              {typeof this.state.nhsError === 'boolean' && this.state.nhsError && (
                <div className="sl-login-nhs--error">
                  <p>{NHSLoginError}</p>
                </div>
              )}
            </form>
            {this.renderFooter()}
          </div>
        </div>
      );
    }
  }
}
