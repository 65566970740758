import { THEME_NAMES } from '../constants/theme-names';
import { sleepioTheme } from '../styles/themes/sleepio-theme';
import { unthemed } from '../styles/themes/unthemed';
import { QUERY_PARAMS } from '../constants/query-params';

function getThemeFromQueryParam() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(QUERY_PARAMS.BH_THEME);
}

function isValidTheme(
  theme: string | null
): theme is THEME_NAMES.SleepioTheme | THEME_NAMES.Unthemed {
  return theme === THEME_NAMES.SleepioTheme || theme === THEME_NAMES.Unthemed;
}

function getThemeName(): THEME_NAMES {
  const isThemingAllowed = window.allow_theming;

  // force theme if theme present in query param
  const chosenThemeName = getThemeFromQueryParam();
  if (
    isThemingAllowed &&
    window.ENV !== 'production' &&
    isValidTheme(chosenThemeName)
  ) {
    window.bh_theme = chosenThemeName;
    return chosenThemeName;
  }

  const noThemesProvided = !window.bh_theme;
  if (!isThemingAllowed || noThemesProvided) {
    return THEME_NAMES.Unthemed;
  }

  return THEME_NAMES[window.bh_theme];
}

export const getTheme = () => {
  const themeName = getThemeName();
  return getThemeByName(themeName);
};

export const getThemeByName = (themeName: string) => {
  switch (themeName) {
    case THEME_NAMES.SleepioTheme:
      return sleepioTheme;
    default:
      return unthemed;
  }
};

export const isThemed = () => {
  const themeName = getThemeName();
  return !!themeName && themeName !== THEME_NAMES.Unthemed;
};
