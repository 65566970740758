import React from 'react';
import PropTypes from 'prop-types';
import ApiActions from '../../actions/api';
import FlowService from '../../services/flow-service';
import { getSelectedValueForQuestion } from '../utils/question-selected-value';
import AnswersService from '../../services/answers-service';
import {
  checkUserNameLength,
  getClosestValue,
  filterObjectProperties,
  saveGenderOptionsIfGenderQuestion,
} from './question.helpers';

export const withQuestionLogic = WrappedComponent => {
  class QuestionWrapperComponent extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        freeText: '',
        percentageDone: 15,
        date: null,
        // this is never changed
        language: 'en',
        fitBit: false,
        validUsername: false,
        error: null,
        errorMessages: null,
        questionValidated: false,
        shouldCallOnAnswer: false,
        selectedAnswers: null,
        // eslint-disable-next-line max-len
        fitBitData: props.fitBitData
          ? props.fitBitData.find(
              fitBitInfo => fitBitInfo.semantic === props.item.semantic_id
            )
          : null,
      };
      this.fitBitSynced = false;
      this.checkUsernameAvailability =
        this.checkUsernameAvailability.bind(this);
      saveGenderOptionsIfGenderQuestion(props.item);
    }

    static getDerivedStateFromProps(props, state) {
      const { answers } = props;
      const question = props.item;
      const defaultAnswers = question.default_answer_keys;

      // Get responses for the current language
      const content = question.content.find(
        question => question.lang === state.language
      );
      const selectedAnswers = getSelectedValueForQuestion(
        question,
        answers,
        content,
        defaultAnswers
      );

      if (
        JSON.stringify(selectedAnswers) !==
        JSON.stringify(state.selectedAnswers)
      ) {
        return { ...state, selectedAnswers, shouldCallOnAnswer: true };
      }

      return null;
    }

    componentDidUpdate() {
      const question = this.props.item;
      const props = this.props;
      const dateOfBirthAnswer = props.answers.find(
        e => e.semantic_id === 'date_of_birth'
      );

      const fitBitData = props.fitBitData
        ? props.fitBitData.find(fit => fit.semantic === props.item.semantic_id)
        : null;
      // Map answers with fitBit data if not already done
      if (fitBitData && !this.fitBitSynced) {
        let answerValue;
        this.fitBitSynced = true;
        if (fitBitData.value !== null) {
          // map fitbit data to specific question semantic ids and values
          if (
            fitBitData.name === 'minutes_awake_at_night' ||
            fitBitData.name === 'minutes_lights_out_to_asleep'
          ) {
            const closestValue = getClosestValue(
              question.content[0],
              fitBitData.value,
              'period'
            );
            const time = parseInt(closestValue, 10);
            const hours = Math.floor(time / 60);
            const minutes = Math.round(time % 60);
            const hoursText =
              hours > 0 ? (hours > 1 ? `${hours} hours` : `${hours} hour`) : '';
            const minutesText =
              hours >= 4
                ? ''
                : hours === 0 && minutes === 0
                ? '0 mins'
                : minutes === 0
                ? ''
                : `${minutes} mins`;
            answerValue = `${hoursText} ${minutesText}`;
          } else if (
            fitBitData.name === 'time_into_bed' ||
            fitBitData.name === 'time_lights_out' ||
            fitBitData.name === 'time_wake' ||
            fitBitData.name === 'time_out_of_bed'
          ) {
            answerValue = getClosestValue(
              question.content[0],
              fitBitData,
              'time'
            );
          } else if (fitBitData.name === 'times_awake_at_night') {
            answerValue = fitBitData.value;
          }
          const theAnswer =
            answerValue || answerValue === 0
              ? question.content[0].answers.find(
                  item => item.text.trim() === answerValue.toString().trim()
                )
              : null;
          this.onAnswer(theAnswer, false, true);
        }
      }

      const { actions } = this.props;
      const usernameCheck =
        actions && question.semantic_id.indexOf('username') > -1;

      // the continue function get overridden in case of a username check
      // instead of calling next in the flow, the user name check api call is being made when clicking continue
      // if the function has been changed and the question is not a username check question, reset the continue func
      // back to normal i.e. in the case of a page after the page which contains the username check question
      if (this.continueFunctionChanged && !usernameCheck) {
        actions && actions.resetContinueFunction();
        this.continueFunctionChanged = false;
      }

      if (this.state.shouldCallOnAnswer) {
        // onAnswer actually connects to a function in the page component where answers get saved in the local storage
        this.onAnswer(this.state.selectedAnswers, !!this.state.error);
      } else if (!this.continueFunctionChanged && usernameCheck) {
        // the continue function get overridden in case of a username check
        // instead of calling next in the flow, the user name check api call is being made when clicking continue
        this.continueFunctionChanged = true;
        actions.changeContinueFunction(this.checkUsernameAvailability);
      }

      if (this.props.item.type === 'card') {
        this._manipulateContinueButton();
      }

      // Check if on date question, then if date value exists
      if (
        dateOfBirthAnswer &&
        dateOfBirthAnswer.values &&
        dateOfBirthAnswer.values !== this.state.date
      ) {
        // Set date to date prop value when available, needed to keep past InputDate values selected on refresh/browser back
        this.setState({ date: dateOfBirthAnswer.values });
      }
    }

    componentWillUnmount() {
      // this block cleans up continueFunctionChanged on navigation away
      // can probably remove this if continueFunction is ever cleaned up
      const { actions } = this.props;
      const question = this.props.item;

      const usernameCheck =
        actions && question.semantic_id.indexOf('username') > -1;
      if (this.continueFunctionChanged && usernameCheck) {
        actions && actions.resetContinueFunction();
        this.continueFunctionChanged = false;
      }
    }

    /**
     * Checks for user availability.
     * If available, restores the default continue btn behaviour.
     */
    checkUsernameAvailability() {
      const question = this.props.item;
      const { actions } = this.props;
      const usernameCheck = question.semantic_id.indexOf('username') > -1;
      const userNameValue = this.state.freeText || this.state.selectedAnswers;

      if (!userNameValue) {
        this.setState({ error: 'This field is required' });
        return;
      }
      const userNameLengthError = checkUserNameLength(userNameValue);
      if (userNameLengthError) {
        this.setState({
          error: userNameLengthError,
        });
        return;
      }

      // if username was changed  or page was refreshed check again for Availability
      if (userNameValue && usernameCheck) {
        // TODO (Alex): Api calls SHOULD NOT be made in the components
        // TODO (Alex): this could be just the API call I think, no advance or other stuff

        actions && actions.setContinueButtonLoading(true);
        AnswersService.makeInputsDisabledWhileCallLoadings();
        let entityToCheck;
        if (FlowService.getPlatgenFlag()) {
          entityToCheck = 'PlatformUser';
        } else {
          entityToCheck = 'User';
        }

        ApiActions.post(
          {
            entity: entityToCheck,
            method: 'check_community_username_exists',
            data: { community_username: userNameValue },
          },
          true
        )
          .then(res => {
            actions && actions.setContinueButtonLoading(false);
            AnswersService.removeDisabledForInputsWhenCallLoadingFinish();
            if (res && res.result === false) {
              actions && actions.resetContinueFunction();
              this.props.advance && this.props.advance();
            } else {
              this.setState({
                error:
                  'Your chosen username is already in use. Please choose another',
              });
            }
          })
          .catch(() => {
            actions && actions.setContinueButtonLoading(false);
            AnswersService.removeDisabledForInputsWhenCallLoadingFinish();
            this.setState({
              error:
                'Your chosen username is already in use. Please choose another',
            });
          });
      } else if (this.state.selectedAnswers) {
        this.props.advance && this.props.advance();
        actions && actions.resetContinueFunction();
      }
    }

    /**
     * Input text change handler
     * @param {String} value the input value
     * @param {boolean} [isDisabled] valid component
     */
    handleInputText(value, isDisabled) {
      this.setState({ freeText: value, error: isDisabled });
      this.onAnswer(value, isDisabled, true);
    }

    /**
     * Input number change handler
     * @param {Number} value the value as a number
     * @param {boolean} [isDisabled] valid component
     */
    handleInputNumber(value, isDisabled = false) {
      this.setState({ selectedAnswers: value, error: isDisabled });
      this.onAnswer(value, isDisabled, true);
    }

    /**
     * Input select change handler
     * @param {Object} item object containing the responses
     * @param {String} value currently selected value
     */
    handleInputSelect(item, value) {
      const values = item.answers.find(item => {
        if (`${item.id}` === `${value}`) {
          return item;
        }
      });
      this.onAnswer(values);
    }

    /**
     * Input date change handler
     * @param {String} value the new value as a stringified date
     * @param {boolean} [isDisabled] valid component
     */
    handleInputDate(value, isDisabled = false) {
      const date = value || this.state.date;
      this.setState({ date, error: isDisabled });
      this.onAnswer(date, isDisabled);
    }

    /**
     * Change handler for radio select
     * @param {Object} responses obj containing responses
     * @param {String} value currently selected value
     */
    handleRadioSelect(responses, value) {
      const values = responses.answers.find(item => {
        if (`${item.id}` === `${value}`) {
          return item;
        }
      });
      this.onAnswer(values);
    }

    /**
     * Triggered for Single and Multiple answers - returns the selected answers for a question
     * @param {Object} questionAnswer the selected answer obj
     * @param {Boolean} isValid if the input options has errors or not
     */
    handleChoiceAnswer(questionAnswer, isValid) {
      this.setState({ error: !isValid });
      this.onAnswer(questionAnswer, !isValid);
    }

    /**
     * Generic handler for all user answers. Calls the parent callback
     * @param {*} values values object
     * @param {boolean} [isDisabled] valid component
     * @param {boolean} [disableScroll] if auto-scroll should be disabled - used when populating fitBit data
     */
    onAnswer(values, isDisabled = false, disableScroll = false) {
      if (!(values instanceof Array) || values.length) {
        values = filterObjectProperties(values);
      }

      this.props.onAnswer(
        {
          question_id: this.props.item.id,
          semantic_id: this.props.item.semantic_id,
          is_core_question: this.props.item.is_core_question,
          product_id: window.product_id,
          values,
        },
        isDisabled,
        disableScroll
      );
      this.setState({ shouldCallOnAnswer: false });
    }

    _manipulateContinueButton() {
      const continueButtons = Array.from(
        document.querySelectorAll('.sl-button-wrapper')
      );
      continueButtons.forEach(button => {
        button.style.display = 'none';
      });
    }

    render() {
      const { isChecked, answerValidator, advance, item, answers } = this.props;
      const { error, freeText, language, selectedAnswers } = this.state;
      return (
        <WrappedComponent
          isChecked={isChecked}
          answerValidator={answerValidator}
          advance={advance}
          item={item}
          answers={answers}
          error={error}
          freeText={freeText}
          language={language}
          selectedAnswers={selectedAnswers}
          handleInputDate={this.handleInputDate.bind(this)}
          handleInputSelect={this.handleInputSelect.bind(this)}
          handleChoiceAnswer={this.handleChoiceAnswer.bind(this)}
          handleInputText={this.handleInputText.bind(this)}
          handleInputNumber={this.handleInputNumber.bind(this)}
          handleRadioSelect={this.handleRadioSelect.bind(this)}
          onAnswer={this.onAnswer.bind(this)}
          actions={this.props.actions}
        />
      );
    }
  }
  QuestionWrapperComponent.propTypes = {
    actions: PropTypes.object,
    item: PropTypes.object.isRequired,
    fitBitData: PropTypes.any,
    minified: PropTypes.bool,
    answerValidator: PropTypes.any,
    onAnswer: PropTypes.func.isRequired,
    answers: PropTypes.array.isRequired,
    advance: PropTypes.func,
    isChecked: PropTypes.bool,
  };
  return QuestionWrapperComponent;
};
