import ApiActions from '../actions/api';
import UserApiActions from '../actions/user-api';

/**
 * Get fit bit data
 * @param {jsonData} jsonData object json from fitbit
 * @param {token} token string from fitbit call
 * @param {Function} callback callback
 * @returns {function(*): Promise<any>}
 */
export function mappedFitBitData(jsonData, token, callback) {
  return function () {
    return ApiActions.post({
      entity: 'Answer',
      method: 'create_answers_from_fitbit_data',
      data: jsonData.response.sleep,
    }).then(res => {
      const data = {};
      data.fitbit_token = token;
      UserApiActions.post({
        action: 'UserInfo',
        redirectUri: '',
        data: data,
      });
      callback(res.result);
    });
  };
}
