/* eslint-disable max-len */
import './style.scss';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ConditionalRender from '../conditional-render';

class InputCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: props.isChecked || false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const isChecked = props.isChecked;
    if (isChecked !== state.isChecked) {
      return { isChecked };
    }

    return null;
  }

  onChange() {
    this.setState({ isChecked: !this.state.isChecked });
    this.props.onChange({ target: { value: !this.state.isChecked } });
  }

  render() {
    const { disabled, className, required, inputId, error, errorText } =
      this.props;

    return (
      <Fragment>
        <input
          ref={c => (this.input = c)}
          type={'checkbox'}
          id={this.props.id || inputId}
          name={this.props.name || inputId}
          className={`sl-input-checkbox ${className || ''}`}
          required={required}
          disabled={disabled}
          checked={this.state.isChecked}
          onChange={this.onChange.bind(this)}
        />

        <ConditionalRender if={error && errorText}>
          <div className="sl-input-checkbox__error">{errorText}</div>
        </ConditionalRender>
      </Fragment>
    );
  }
}

InputCheckbox.propTypes = {
  id: PropTypes.any,
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.any,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  name: PropTypes.string,
  inputId: PropTypes.string,
  isChecked: PropTypes.bool,
};

export default InputCheckbox;
