import { Fragment, useCallback, useMemo } from 'react';
import InputSelect from '../input-select/themed.input-select.component';
import DateInput from '../input-date/themed-input-date.component';
import { InputText } from '../input-text/themed.input-text.component';
import AutoComplete from '../input-autocomplete/autocomplete.component';
import { InputNumber } from '../input-number/themed.input-number.component';
import { RadioInput } from '../input-radio/themed.radio.component';
import {
  MappedQuestionContentAnswer,
  QuestionContent,
  QuestionContentAnswer,
} from '../../types/questions.types';
import { QuestionValidator } from '../utils/validator';
import { QuestionProps } from './question.types';
import { withQuestionLogic } from '.';
import { checkUserNameLength } from './question.helpers';
import {
  questionPostAnswerStyle,
  questionPostTitleStyle,
  questionPreTitleStyle,
  questionTitleStyle,
  questionWrapperStyle,
  questionTitleWrapperStyle,
  questionWrapperStyleWithoutMargin,
  textToDownloadTitleStyle,
  ttdQuestionTitleStyle,
  ttdQuestionPostTitleStyle,
  ttdQuestionTitleWrapperStyle,
} from './question.styles';
import { QuestionTypes } from '../../constants/question-types';
import { logError } from '../../analytics/logger/logger';
import MultiselectComponent from '../input-multiselect/multiselect.component';
import { useHistory } from 'react-router-dom';
import TextToDownload from '../text-to-download/themed.text-to-download';

export const QuestionTitleText = (props: {
  details: QuestionContent;
  hasInput: boolean;
  htmlFor?: string;
  questionType?: QuestionTypes;
}) => {
  const isTextToDownload =
    props.questionType === QuestionTypes.TEXT_TO_DOWNLOAD ? true : false;
  return (
    <div
      css={
        isTextToDownload
          ? ttdQuestionTitleWrapperStyle
          : questionTitleWrapperStyle
      }
    >
      {isTextToDownload && (
        <h1 css={textToDownloadTitleStyle}>
          {props.details.pre_question_text}
        </h1>
      )}
      {props.details.pre_question_text && !isTextToDownload && (
        <p
          css={questionPreTitleStyle}
          dangerouslySetInnerHTML={{
            __html: props.details.pre_question_text,
          }}
          data-testid="pre-question-text"
        />
      )}
      <div
        css={isTextToDownload ? ttdQuestionTitleStyle : questionTitleStyle}
        data-testid="question-title"
        id={`${props.htmlFor}_title`}
      >
        {props.questionType === QuestionTypes.MULTI ||
        props.questionType === QuestionTypes.DATE ? (
          <legend>{props.details.title}</legend>
        ) : (
          <label htmlFor={`${props.htmlFor}`}>{props.details.title}</label>
        )}
      </div>
      {props.details.post_question_text && (
        <p
          css={
            isTextToDownload
              ? ttdQuestionPostTitleStyle
              : questionPostTitleStyle
          }
          dangerouslySetInnerHTML={{
            __html: props.details.post_question_text,
          }}
          data-testid="post-question-text"
        />
      )}
    </div>
  );
};

export const PostAnswerText = (props: { text: string }) => {
  return (
    <p
      css={questionPostAnswerStyle}
      dangerouslySetInnerHTML={{ __html: props.text }}
      data-testid="post-answer-text"
    />
  );
};

export const Question = ({
  isChecked,
  answerValidator,
  item: question,
  answers,
  error,
  freeText,
  selectedAnswers,
  handleInputDate,
  handleInputSelect,
  handleChoiceAnswer,
  handleInputText,
  handleInputNumber,
  handleRadioSelect,
}: QuestionProps) => {
  const history = useHistory();
  const details = question.content[0];

  let questionErrorMessage = '';
  let hasError = false;

  const usernameCheck = question.semantic_id.indexOf('username') > -1;
  const userNameValue = freeText || selectedAnswers;
  const userNameLengthError = usernameCheck
    ? checkUserNameLength(userNameValue)
    : null;

  if (
    answerValidator &&
    answerValidator.length &&
    answers.filter(a => a.values).length
  ) {
    const errors = QuestionValidator.validateAnswers(
      answers.filter(a => a.values),
      answerValidator
    );
    errors &&
      errors.forEach(error => {
        if (error.showErrorId === details.question_id) {
          questionErrorMessage = error.error;
        }
        if (error.highlightedIds.find(error => error === details.question_id)) {
          hasError = true;
        }
      });
  }

  const _handleInputSelect = useCallback(
    (value: string) => {
      handleInputSelect(details, value);
    },
    [details, handleInputSelect]
  );

  let questionContent = null;

  const questionTitle = useMemo(
    () => ({
      title: details.title,
      pre_question_text: details.pre_question_text,
      post_question_text: details.post_question_text,
    }),
    [details]
  );

  let hasInput = true;
  let hasInternalTitle = false;
  switch (question.type) {
    case QuestionTypes.DATE:
    case QuestionTypes.ONELINE_DATE:
      hasInput = false;
      questionContent = (
        <DateInput
          inputId={question.semantic_id}
          onChange={handleInputDate}
          value={selectedAnswers}
        />
      );
      break;
    case QuestionTypes.SELECT:
    case QuestionTypes.ONELINE_SELECT:
      hasInput = false;
      questionContent = (
        <InputSelect
          id={details.question_id}
          onChange={_handleInputSelect}
          isChecked={isChecked}
          inputId={question.semantic_id}
          errorText={questionErrorMessage}
          hasError={hasError}
          value={selectedAnswers}
          answers={details.answers}
        />
      );
      break;
    case QuestionTypes.MULTI:
      hasInput = false;
      questionContent = (
        <MultiselectComponent
          inputId={question.semantic_id}
          isValidationChecked={isChecked}
          validation={question.validation}
          questionContent={details}
          selectedAnswers={selectedAnswers !== null ? selectedAnswers : []}
          onChange={handleChoiceAnswer}
        />
      );
      break;
    case QuestionTypes.TEXT:
    case QuestionTypes.ONELINE_TEXT:
      questionContent = (
        <InputText
          key={`${question.id}-text`}
          type={question.type}
          isChecked={isChecked}
          error={!!(error || userNameLengthError || questionErrorMessage)}
          inputId={question.semantic_id}
          errorText={error || userNameLengthError || questionErrorMessage}
          onChange={handleInputText}
          value={freeText}
          placeholder={details?.placeholder}
        />
      );
      break;
    case QuestionTypes.NUMBER:
    case QuestionTypes.ONELINE_NUMBER:
      questionContent = (
        <InputNumber
          isChecked={isChecked}
          onChange={handleInputNumber}
          value={
            Number.isInteger(parseInt(selectedAnswers, 10))
              ? parseInt(selectedAnswers, 10)
              : undefined
          }
          inputId={question.semantic_id}
          type="number"
          lang={'en'}
          validation={question.validation}
          details={details}
        />
      );
      break;
    case QuestionTypes.RADIO:
    case QuestionTypes.ONELINE_RADIO:
    case QuestionTypes.CARD:
    case QuestionTypes.SINGLE:
      hasInternalTitle = true;
      questionContent = (
        <RadioInput
          id={details.question_id}
          isChecked={isChecked}
          items={details.answers}
          questionTitle={questionTitle}
          inputId={question.semantic_id}
          key={`${details.question_id}-${selectedAnswers}`}
          selectedAnswer={selectedAnswers}
          belongsTo={`${details.question_id}-${details.id}-${details.lang}`}
          handleCheck={(
            selectedItem: QuestionContentAnswer | MappedQuestionContentAnswer
          ) => handleRadioSelect(details, selectedItem.id || '')}
        />
      );
      break;
    case QuestionTypes.SIDE_BY_SIDE:
      hasInternalTitle = true;
      questionContent = (
        <RadioInput
          id={details.question_id}
          isChecked={isChecked}
          items={details.answers}
          questionTitle={questionTitle}
          inputId={question.semantic_id}
          key={`${details.question_id}-${selectedAnswers}`}
          selectedAnswer={
            Array.isArray(selectedAnswers) // check handles null/undefined
              ? selectedAnswers[0]
              : [selectedAnswers][0]
          } // handleChoiceAnswer sets an array
          belongsTo={`${details.question_id}-${details.id}-${details.lang}`}
          handleCheck={(
            selectedItem: QuestionContentAnswer | MappedQuestionContentAnswer
          ) => handleChoiceAnswer([selectedItem], true)}
        />
      );
      break;
    case QuestionTypes.AUTOCOMPLETE:
      questionContent = (
        <AutoComplete
          id={details.question_id}
          key={`${question.id}-text`}
          isChecked={isChecked}
          error={!!error || !!userNameLengthError || !!questionErrorMessage}
          inputId={question.semantic_id}
          errorText={error || userNameLengthError || questionErrorMessage}
          onChange={handleInputText}
          defaultAnswers={question.default_answer_keys ?? []}
          value={freeText || selectedAnswers}
          answers={details.answers}
          max_visible_answers={details.max_visible_answers}
          placeholder={details.placeholder}
        />
      );
      break;
    case QuestionTypes.TEXT_TO_DOWNLOAD:
      questionContent = <TextToDownload />;
      break;
    default:
      logError(`Question of type ${question.type} not found`);
      history.push(`error`);
  }

  return (
    <Fragment>
      {hasInternalTitle ? (
        /* Question Content (contains Pre Content) */
        <div css={questionWrapperStyleWithoutMargin}>{questionContent}</div>
      ) : (
        <Fragment>
          {/* Pre Content */}
          {question.type === QuestionTypes.MULTI ||
          question.type === QuestionTypes.DATE ? (
            <fieldset>
              <QuestionTitleText
                details={details}
                hasInput={hasInput}
                htmlFor={question.semantic_id}
                questionType={question.type}
              />
            </fieldset>
          ) : (
            <QuestionTitleText
              details={details}
              hasInput={hasInput}
              htmlFor={question.semantic_id}
              questionType={question.type}
            />
          )}
          {/* Question Content*/}
          {question.semantic_id === QuestionTypes.TEXT_TO_DOWNLOAD ? (
            <div>{questionContent}</div>
          ) : (
            <div css={questionWrapperStyle}>{questionContent}</div>
          )}
        </Fragment>
      )}
      {/* Post Content */}
      {details.post_answer_text && (
        <PostAnswerText text={details.post_answer_text} />
      )}
    </Fragment>
  );
};

export default withQuestionLogic(Question);
