type iconProps = {
  width?: number;
  height?: number;
  alt: string;
  className?: string;
  src: string;
  testId?: string;
};

export const iconTestId = 'icon';

export const Icon = ({
  width,
  height,
  alt,
  className,
  testId = iconTestId,
  src,
}: iconProps) => {
  return (
    <img
      data-testid={testId}
      width={width}
      height={height}
      alt={alt}
      className={className}
      src={src}
    />
  );
};
