import classnames from 'classnames';
import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import {
  isEventArrowDownKey,
  isEventArrowUpKey,
  isEventEndKey,
  isEventHomeKey,
  isEventPageDownKey,
  isEventPageUpKey,
  isEventSpaceOrEnterKey,
  isEventTabKey,
} from '../../helpers/event-helpers';
import { isOverflownVertically } from '../../helpers/positioning';
import useEventListener from '../../hooks/use-event-listener';
import { QuestionContentAnswer } from '../../types/questions.types';

import { listWrapper } from './style';

export const PLEASE_SELECT = 'Please Select';
export const DROPDOWN_TEST_ID = 'DROPDOWN_TEST_ID';
export const HIGHLIGHTED_CLASSNAME = 'highlighted';

export type DropdownOnSelect = (answer: QuestionContentAnswer) => void;

export const emptyAnswer: QuestionContentAnswer = {
  id: null,
  text: '',
  score: null,
};

export type DropdownProps = {
  id: number | string;
  inputId?: string;
  showPleaseSelect?: boolean;
  defaultAnswer?: QuestionContentAnswer;
  answers: QuestionContentAnswer[];
  onSelect: DropdownOnSelect;
  matchingAnswer?: QuestionContentAnswer;
  // used for accessibility e.g aria-activedescendant
  setHighlightedHtmlId?: (id: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
};

const buildOptionHtmlId = (id: string | number, idx: number) =>
  `dropdown-${id}_${idx}`;

const Dropdown = forwardRef<HTMLUListElement, DropdownProps>(
  (
    {
      id,
      inputId,
      showPleaseSelect = false,
      defaultAnswer,
      answers,
      onSelect,
      setHighlightedHtmlId,
      matchingAnswer = emptyAnswer,
      inputRef,
    },
    ref
  ) => {
    const [highlightedAnswer, setHighlightedAnswer] = useState(matchingAnswer);
    const allAnswers = useMemo(() => {
      const allAnswers = [...answers];
      if (showPleaseSelect) {
        allAnswers.unshift(emptyAnswer);
      }
      if (defaultAnswer) {
        allAnswers.push(defaultAnswer);
      }

      return allAnswers;
    }, [answers, defaultAnswer, showPleaseSelect]);

    const highlightedIndex = allAnswers.findIndex(
      ans => ans === highlightedAnswer
    );

    useEffect(() => {
      const index = allAnswers.findIndex(ans => ans === highlightedAnswer);
      setHighlightedHtmlId?.(buildOptionHtmlId(id, index));
    }, [id, allAnswers, highlightedAnswer, setHighlightedHtmlId]);

    const scrollToIndex = useCallback(
      (targetIndex: number) => {
        // scroll highlighted into view if it's not
        if (typeof ref !== 'function' && ref?.current) {
          const target = ref.current.children[targetIndex];
          if (target && isOverflownVertically(ref.current, target)) {
            target.scrollIntoView(false);
          }
        }
      },
      [ref]
    );

    const _onKeyDown = useCallback(
      (e: KeyboardEvent) => {
        // handle tab logic first
        if (isEventTabKey(e)) {
          // do nothing while DD is open
          e.preventDefault();
          return;
        }

        // handle enter or space
        if (isEventSpaceOrEnterKey(e)) {
          if (highlightedAnswer) {
            onSelect(highlightedAnswer);
          }
          return;
        }

        // handle esc
        // parent handles this

        // handle arrow keys
        if (isEventArrowDownKey(e)) {
          if (highlightedIndex === -1) {
            scrollToIndex(0);
            setHighlightedAnswer(allAnswers[0]);
            return;
          }
          if (highlightedIndex === allAnswers.length - 1) {
            return;
          }
          scrollToIndex(highlightedIndex + 1);
          setHighlightedAnswer(allAnswers[highlightedIndex + 1]);
        }
        if (isEventArrowUpKey(e)) {
          if (highlightedIndex === -1) {
            scrollToIndex(0);
            setHighlightedAnswer(allAnswers[0]);
            return;
          }
          if (highlightedIndex === 0) {
            // if the user comes back to the top of the list and decides to change search text focus is moved to text input
            if (inputRef) {
              inputRef.current?.focus();
              setHighlightedAnswer(emptyAnswer);
            }
            return;
          }
          scrollToIndex(highlightedIndex - 1);
          setHighlightedAnswer(allAnswers[highlightedIndex - 1]);
        }

        // handle pageUp/Home
        if (isEventPageUpKey(e) || isEventHomeKey(e)) {
          scrollToIndex(0);
          setHighlightedAnswer(allAnswers[0]);
        }
        // handle pageDown/End
        if (isEventPageDownKey(e) || isEventEndKey(e)) {
          scrollToIndex(allAnswers.length - 1);
          setHighlightedAnswer(allAnswers[allAnswers.length - 1]);
        }
      },
      [
        allAnswers,
        highlightedAnswer,
        highlightedIndex,
        inputRef,
        onSelect,
        scrollToIndex,
        setHighlightedAnswer,
      ]
    );

    useEventListener('keydown', _onKeyDown);

    return (
      <ul
        ref={ref}
        css={listWrapper}
        id={`${inputId}_dropdown`}
        data-testid={DROPDOWN_TEST_ID}
        onLoad={() => console.log('onLoad')}
        role="list"
        tabIndex={-1}
        aria-labelledby={`${inputId}_title`}
        aria-label="Dropdown Options"
      >
        {!!showPleaseSelect && (
          <li
            key={-1}
            id={buildOptionHtmlId(id, 0)}
            onClick={() => onSelect(emptyAnswer)}
            role="option"
            aria-selected={emptyAnswer === highlightedAnswer}
            className={classnames({
              [HIGHLIGHTED_CLASSNAME]: emptyAnswer === highlightedAnswer,
            })}
            onMouseEnter={() => setHighlightedAnswer(emptyAnswer)}
          >
            {PLEASE_SELECT}
          </li>
        )}
        {answers.map((item, idx) => (
          <li
            key={item.id?.toString()}
            id={buildOptionHtmlId(id, showPleaseSelect ? 1 + idx : idx)}
            onClick={() => onSelect(item)}
            role="option"
            aria-selected={item === highlightedAnswer}
            className={classnames({
              [HIGHLIGHTED_CLASSNAME]: item === highlightedAnswer,
            })}
            onMouseEnter={() => setHighlightedAnswer(item)}
          >
            {item.text}
          </li>
        ))}
        {!!defaultAnswer && (
          <li
            key="default"
            id={buildOptionHtmlId(
              id,
              showPleaseSelect ? 1 + answers.length : answers.length
            )}
            onClick={() => onSelect(defaultAnswer)}
            role="option"
            aria-selected={defaultAnswer === highlightedAnswer}
            className={classnames({
              default: answers.length > 0,
              [HIGHLIGHTED_CLASSNAME]: defaultAnswer === highlightedAnswer,
            })}
            onMouseEnter={() => setHighlightedAnswer(defaultAnswer)}
          >
            {defaultAnswer.text}
          </li>
        )}
      </ul>
    );
  }
);
Dropdown.displayName = 'Dropdown';

export default Dropdown;
