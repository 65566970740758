import { datadogLogs } from '@datadog/browser-logs';

/*
  This utility file creates a wrapper around datadog logging.
  This exposes the common logging function in a general manner.
  This will make it easier to change loggers if we ever choose to do so in the future.
  We wrap in a try/catch and swallow any error it might throw to not break the app.
 */

export const initializeLogger = () => {
  if (window.LOG_CLIENT_TOKEN && window.LOG_SITE) {
    try {
      datadogLogs.init({
        clientToken: window.LOG_CLIENT_TOKEN,
        site: window.LOG_SITE,
        forwardErrorsToLogs: true,
        env: window.ENV,
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const logError = (errorMessage, additionalInformation) => {
  if (window.LOG_CLIENT_TOKEN && window.LOG_SITE) {
    try {
      datadogLogs.logger.error(errorMessage, additionalInformation);
    } catch (error) {
      console.log(error);
    }
  }
};
