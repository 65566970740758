import { isEventSpaceKey, isEventEnterKey } from '../../helpers/event-helpers';

/**
 * Perform a smooth scroll into view of a certain element - full js
 * @param {Node} target the element that has to be scrolled
 * @param {Number} [modifiedScrollPosition] subtract or add  a certain amount to scroll into desired position
 * @param {Node} [parent] node to scroll on. If it doesn't exists, it scrolls on window
 */
export function smoothScroll(target, modifiedScrollPosition, parent) {
  let reachedBottom = false;
  const scrollTarget = parent || window;
  const startY = scrollTarget.scrollTop;
  const maxY = scrollTarget.scrollHeight;
  const stopY = _elmYPosition(target, modifiedScrollPosition);
  const distance = stopY > startY ? stopY - startY : startY - stopY;
  let speed = 15;

  if (maxY - stopY < 100) {
    speed = 50;
  } else if (maxY - stopY < 200) {
    speed = 30;
  }
  const step = Math.round(distance / 25);
  let leapY = stopY > startY ? startY + step : startY - step;
  let timer = 0;

  const scrollToFunc = destination => {
    if (!reachedBottom) {
      scrollTarget.scrollTop = destination;
    }
    if (
      scrollTarget.scrollHeight <=
      scrollTarget.scrollTop + scrollTarget.offsetHeight
    ) {
      reachedBottom = true;
    }
  };

  if (stopY > startY) {
    for (let i = startY; i < stopY; i += step) {
      setTimeout(scrollToFunc.bind(null, leapY), timer * speed);
      leapY += step;
      if (leapY > stopY) {
        leapY = stopY;
      }
      timer++;
    }
  }
}

/**
 * Helper function for the scroll method
 * @param {Node} elem target elem to be scrolled to
 * @param {Number} [modifiedScrollPosition] subtract or add  a certain amount to scroll into desired position
 * @returns {*}
 * @private
 */
function _elmYPosition(elem, modifiedScrollPosition) {
  let y = elem.offsetTop;
  let node = elem;
  while (node.offsetParent && node.offsetParent !== document.body) {
    node = node.offsetParent;
    y += node.offsetTop;
  }
  if (modifiedScrollPosition) {
    return y + modifiedScrollPosition;
  }
  return y;
}

/**
 * Execute callback function when Enter or Space key pressed
 * @param {Object} event key pressed event
 * @param {Function} cb callback
 */
export function onEnterOrSpace(event, cb) {
  if (isEventSpaceKey(event)) {
    event.preventDefault();
  }
  if (isEventEnterKey(event) || isEventSpaceKey(event)) {
    cb();
  }
}

/**
 * Execute callback function when Escape key pressed
 * @param {Object} event key pressed event
 * @param {Function} cb callback
 */
export function onEscapeKeyDown(event, cb) {
  if (event.keyCode === 27) {
    cb();
  }
}
