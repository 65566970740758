import { isThemed } from '../../../helpers/themes';

import InteractiveTextInputComponent from '../inputs/text';
import InteractiveDateInputComponent from '../inputs/date';
import InteractivePasswordInputComponent from '../inputs/password';
import InteractiveRadioInputComponent from '../inputs/radio';
import InteractiveCheckboxInputComponent from '../inputs/checkbox';
import InteractiveButtonComponent from '../inputs/button';
import InteractiveStaticComponent from '../inputs/static-component';
import InteractiveSsoComponent from '../inputs/sso';
// Themed components
import InteractiveTextInputContainer from '../inputs/text/themed.interactive-input-text.container';
import InteractiveCheckboxContainer from '../inputs/checkbox/themed.interactive-checkbox.container';
import InteractivePasswordInputContainer from '../inputs/password/themed.password.container';
import InteractiveInputButton from '../inputs/button/themed.interactive-input-button.component';
import InteractiveDateContainer from '../inputs/date/themed.interactive-input-date.container';

const TYPES_TO_COMPONENTS_MAP = {
  text: InteractiveTextInputComponent,
  date: InteractiveDateInputComponent,
  password: InteractivePasswordInputComponent,
  radio: InteractiveRadioInputComponent,
  checkbox: InteractiveCheckboxInputComponent,
  submit: InteractiveButtonComponent,
  button: InteractiveButtonComponent,
  static: InteractiveStaticComponent,
  sso: InteractiveSsoComponent,
};

// this will update as we add themed components
const TYPES_TO_THEMED_COMPONENT_MAP = {
  text: InteractiveTextInputContainer,
  date: InteractiveDateContainer,
  password: InteractivePasswordInputContainer,
  radio: InteractiveRadioInputComponent,
  checkbox: InteractiveCheckboxContainer,
  submit: InteractiveInputButton,
  button: InteractiveInputButton,
  static: InteractiveStaticComponent,
  sso: InteractiveSsoComponent,
};

type InteractiveInputFactoryComponentProps = {
  alerts: any[];
  id: number;
  interactive_content_id: number;
  info?: string;
  type: string;
  field_name: string;
  lang: string;
  label: string;
  label_name?: string;
  display_name?: string;
  primary_key?: number;
  required?: boolean;
  default_validator?: string[];
  options: {
    can_edit: boolean;
    field: string;
    label: string;
    type: string;
    value: string | null;
  }[];
  isDisabled?: boolean;
  inputValidationChanged: (fieldName: string, hasErrors: boolean) => void;
  fieldError?: string;
  usedSSO: boolean;
  usedSAML: boolean;
  shouldValidate: boolean;
  interactiveComponentError: {
    error?: string;
  };
  allRequiredInputsHaveValue?: boolean;
  requiredInputValueStateChanged: (hasValue: boolean | undefined) => void;
  loading: boolean;
  onFormAttemptSubmit?: () => void;
  max_length?: number | null;
  min_length?: number | null;
};

const InteractiveInputFactoryComponent = (
  props: InteractiveInputFactoryComponentProps
) => {
  const COMPONENT_MAP = isThemed()
    ? TYPES_TO_THEMED_COMPONENT_MAP
    : TYPES_TO_COMPONENTS_MAP;

  const Component =
    COMPONENT_MAP[props.type as keyof typeof TYPES_TO_THEMED_COMPONENT_MAP];
  const componentProps = props;

  return Component ? <Component {...componentProps} /> : null;
};

export default InteractiveInputFactoryComponent;
