import { Regions } from '../..';
import { timeZones } from '../../constant';

export const byTimeZone = (timeZone = ''): Regions => {
  if (timeZones.USA.includes(timeZone)) {
    return 'USA';
  }
  if (timeZone.startsWith('Europe')) {
    return 'Europe';
  }
  return 'Other/Unknown';
};
