import interactiveConfig from '../../../triggers/triggers-config';

export const createLabelString = (
  label: string,
  field_name: string,
  additionalData: any
) => {
  let _label = label;

  Object.keys(additionalData).forEach(key => {
    if (key.indexOf('template_') === 0) {
      const stringToReplace = `{% ${key.replace('template_', '')} %}`;
      let valueToReplaceWith = '';

      const configFieldNames = interactiveConfig.INPUT_FIELD_NAMES;
      const fieldNamesWithTemplates = configFieldNames.DATA_STORING.concat(
        configFieldNames.CLINICAL_GOVERNANCE
      ).concat(configFieldNames.DOCTOR_ACKNOWLEDGEMENT);

      if (fieldNamesWithTemplates.indexOf(field_name) > -1) {
        const aux = `${key.replace('template_', '')}`;
        if (key.indexOf(aux) > -1) {
          valueToReplaceWith = `
                            <a target="_blank" href="${
                              additionalData[`${aux}_url`]
                            }">
                                ${additionalData[key]}
                            </a>`;
        }
      } else {
        valueToReplaceWith = additionalData[key];
      }

      _label = _label.replace(stringToReplace, valueToReplaceWith);
    }
  });

  return _label;
};
