import { css } from '@emotion/react';
import { BHTheme } from '../../types/bh-themes.types';
import { SLEEPIO_NAVY } from '../../styles/colors';

const sharedFooterStyles = (theme: BHTheme) =>
  css({
    padding: '40px',
    fontWeight: theme.fonts.fontWeights.REGULAR,
    fontSize: theme.fonts.fontSizes.SMALL,
    textAlign: 'center',
    height: 'fit-content',
    position: 'relative',
    bottom: '0',
  });

export const darkFooterStyles = (theme: BHTheme) =>
  css(
    {
      color: SLEEPIO_NAVY,
      backgroundColor: theme.backgroundColors.DARK,
    },
    sharedFooterStyles(theme)
  );

export const lightFooterStyles = (theme: BHTheme) =>
  css(
    {
      color: theme.fonts.fontColors.LEGAL,
      backgroundColor: theme.backgroundColors.LIGHT,
    },
    sharedFooterStyles(theme)
  );

export const darkFooterLinkStyles = (theme: BHTheme) =>
  css({
    color: theme.fonts.fontColors.LIGHT,
  });

export const lightFooterLinkStyles = (theme: BHTheme) =>
  css({
    color: theme.fonts.fontColors.LEGAL,
  });
