import { Fragment } from 'react';
import { saveMappedQuestionsResponses } from '../../services/answers-service';
import FactoryComponent from '../factory-component';
import { MappedQuestionSelection, Question } from '../../types/questions.types';
import { noop } from '../../props/empty';
import { useShallowEqualSelector } from '../../hooks/use-app-react-redux';
import Selectors from '../../selectors';

// this component is actually being used as part of mapped questions and makes use of the already existing answer storing
// functionality in the question component.
// as a note, mapped questions where introduced later in the project. since the question functionality was more or less
// working fine and thoroughly tested and because the saving of answers in onboarding as a whole is subject to a hardening
// ticket, this was introduced as a wrapper component between the actual mapped question and regular question in order
// to reduce the footprint of new code introduced in existing, stable code

type QuestionGroupComponentProps = {
  actions: unknown;
  content: MappedQuestionSelection[];
  hidden: boolean;
  selected: boolean | null;
  belongsTo: string;
  onAnswer: () => void;
};

type AnswerKeys = {
  [key: string]: string[];
};

const QuestionGroupComponent = ({
  content,
  hidden,
  selected,
  belongsTo,
  actions,
}: QuestionGroupComponentProps) => {
  const renderData: JSX.Element[] = [];
  const questionsToSave: Question[] = [];
  const answerKeysForQuestions: AnswerKeys = {};
  const { currentPage, currentStep } = useShallowEqualSelector(
    Selectors.selectFlowReducerCurrentStepPage
  );

  // this is super unoptimized and needs some hooks love
  content.forEach((answer, idx) => {
    const content = answer.mapped_child_question;

    questionsToSave.push(content);
    answerKeysForQuestions[content.id] = answer.answer_key;

    renderData.push(
      <FactoryComponent
        type={'question-minified'}
        item={content}
        key={idx}
        fitBitData={null}
        isChecked={false}
        answers={[]}
        actions={actions}
        onAnswer={noop}
      />
    );
  });

  if (selected) {
    saveMappedQuestionsResponses(
      belongsTo,
      questionsToSave,
      answerKeysForQuestions,
      currentPage,
      currentStep
    );
  }

  return (
    <div style={{ display: hidden ? 'none' : 'inherit' }}>
      <Fragment>{renderData}</Fragment>
    </div>
  );
};

export default QuestionGroupComponent;
