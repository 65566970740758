import { datadogRum } from '@datadog/browser-rum';

// only initialize if we have an id and token i.e. don't load it locally
export const initializeRealUserMeasurements = () => {
  if (window.RUM_APPLICATION_ID && window.RUM_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: window.RUM_APPLICATION_ID,
      clientToken: window.RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'onboarding-web-app',
      env: window.ENV || 'local',
      version: window.app_version,
      sampleRate: 100,
      trackInteractions: true,
    });
  }
};
