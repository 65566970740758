import InteractiveInputComponent from '../interactive-input';
import InputCheckbox from '../../../input-checkbox/themed.input-checkbox.component';
import InteractiveComponentV2Service from '../../../../services/interactive-component-v2';
import interactiveConfig from '../../../triggers/triggers-config';
import { createLabelString } from './themed.interactive-checkbox.utils';

class InteractiveCheckboxContainer extends InteractiveInputComponent {
  static _getPredefinedValue(props: { field_name: string }) {
    if (
      interactiveConfig.INPUT_FIELD_NAMES.CLINICAL_GOVERNANCE.indexOf(
        props.field_name
      ) > -1
    ) {
      return true;
    }

    const existingValue =
      InteractiveComponentV2Service.getUserValues(props.field_name) || '';
    return existingValue
      ? existingValue === true || existingValue === 'on'
      : false;
  }

  render() {
    const hiddenCheckbox =
      interactiveConfig.INPUT_FIELD_NAMES.CLINICAL_GOVERNANCE.indexOf(
        this.props.field_name
      ) > -1;
    const error = !!this.props.fieldError || this.state.error;
    const errorText = this.props.fieldError || this.state.errorText;
    const labelText = createLabelString(
      this.props.label,
      this.props.field_name,
      this.state.additionalData
    );
    return (
      <InputCheckbox
        hiddenCheckbox={hiddenCheckbox}
        error={error}
        errorText={errorText}
        isChecked={!!this.state.value}
        onChange={this.onChange.bind(this)}
        disabled={this.props.isDisabled}
        labelText={labelText}
        inputId={this.props.id.toString()}
      />
    );
  }
}

export default InteractiveCheckboxContainer;
