import * as Sentry from '@sentry/react';
import ApiActions from '../actions/api';
import {
  FLOW_INITIALIZE,
  FLOW_INITIALIZED,
  NEXT_STEP,
  NEXT_PAGE,
  JUMP_TO,
  SAVING_ANSWERS,
  LOAD_PROGRESS,
  LOAD_NEXT_PROGRESS,
  SET_CONTINUE_FUNCTION,
  RESTORE_CONTINUE_FUNCTION,
  CONTINUE_BUTTON_LOADING,
  COLLECT_USER_INFO,
  SHOW_EXISTING_SIGNUP,
} from '../constants/action-types';
import UserSession from '../services/user-session';
import AnswersService, {
  ANSWERS_STORAGE_KEY,
} from '../services/answers-service';
import OrganizationService from '../services/org-service';
import FlowService from '../services/flow-service';
import EventService from '../services/event-service';
import { AnyAction, Dispatch } from 'redux';
import { mapAnswersToServer } from '../helpers/answers';
import { CurrentFlow } from '../types/flow.types';
import { Answer, ServerAnswer } from '../types/answers.types';
import { RootState } from '../store/store';
import selectors from '../selectors';
import { redirectLoggedUser } from '../components/utils/flow-utils';
import { findUserProgress } from '../containers/flow/helpers';
import { ThunkDispatch } from 'redux-thunk';
import { getRedirectParamFromLocation } from '../helpers/utm-params';

type InitialFlowDetails = {
  vanity?: string;
  product?: string;
  org?: string;
  id?: string;
  redirect?: string;
  fitBitData?: {
    fitBitCode?: string;
    location?: string;
  };
  cb?: () => void;
};
/**
 * Initialize a certain flow
 * redirect: redirect flow to step and page saved in local storage
 */
export function initializeFlow({
  vanity,
  product,
  org,
  id,
  redirect,
  fitBitData,
  cb,
}: InitialFlowDetails) {
  let query = '';
  if (vanity) {
    query = `slug=${vanity}`;
  } else if (product && org) {
    query = `slug=${org}&product_slug=${product}`;
  }
  if (id) {
    query += `&flow_id=${id}`;
  }

  return function (dispatch: Dispatch) {
    if (fitBitData && fitBitData.fitBitCode && fitBitData.location) {
      localStorage.removeItem('current-path');
      localStorage.setItem('fit_bit_code', fitBitData.fitBitCode);
      window.location.href = fitBitData.location;
      return;
    }

    ApiActions.get({
      entity: 'Flow',
      method: 'find_full_flow_with_id',
      query: query,
    })
      .then(res => {
        if (!res.result) {
          Sentry.captureMessage(`Logs for SLEEP-2527 Result is missing from find full flow response
                 flow id: ${id}, vanity/org slug: ${
            org || vanity
          }, product slug: ${product}. ${navigator.appVersion}.
                 ${navigator.userAgent}. ${navigator.appName}`);
        }
        if (res.result && !res.result.organization_id) {
          Sentry.captureMessage(`Logs for SLEEP-2527 Organization Id is missing from find full flow response
                 flow id: ${id}, vanity/org slug: ${
            org || vanity
          }, product slug: ${product}.${navigator.appVersion}.
                 ${navigator.userAgent}. ${navigator.appName}`);
        }
        // why do we store this on the window
        window.organization_id =
          window.organization_id || (res.result && res.result.organization_id);
        res.result && cb && cb();

        // check if flow changed
        const flowChanged = FlowService.checkIfFlowChanged(res.result.flow_id);
        const shouldRedirect = getRedirectParamFromLocation(window.location);

        // if there is no redirect parameter and flow did change move it to the next step
        // and don't move to next step when research-signup slug because this is the only flow which doesn't contan Landing page
        if (
          flowChanged === true &&
          shouldRedirect === true &&
          res.result.slug !== 'research-signup'
        ) {
          localStorage.removeItem(ANSWERS_STORAGE_KEY);
          return (
            dispatch({
              type: FLOW_INITIALIZE,
              data: { res, redirect, fitBitData },
            }) && dispatch({ type: NEXT_STEP })
          );
        }

        return dispatch({
          type: FLOW_INITIALIZE,
          data: { res, redirect, fitBitData },
        });
      })
      .catch(err => {
        Sentry.captureMessage(
          `Error initializing flow with id ${id}, org ${org}, slug ${product}. Error: ${err.toString()}`
        );
      });
  };
}

/**
 * Set if flow is initialized
 */
export function setInitializedFlow(initialized: boolean) {
  return function (dispatch: Dispatch) {
    return dispatch({ type: FLOW_INITIALIZED, data: { initialized } });
  };
}

/**
 * Load progress flow
 */
export function loadProgress(isSignupRedirection = false) {
  return function (dispatch: Dispatch) {
    // the result of isSignupRedirection was set in redux but never used, should we keep it?
    return dispatch({ type: LOAD_PROGRESS, data: { isSignupRedirection } });
  };
}

export function redirectUserToAppropriateFlow(user_id: number) {
  return async function (dispatch: ThunkDispatch<RootState, void, AnyAction>) {
    const redirectToURI = await redirectLoggedUser(user_id, true);
    if (redirectToURI) {
      AnswersService.removeAnswers();
      window.location.href = `${redirectToURI}?login=true`;
      return;
    }

    // No answers from server, load user progress from local storage if exists
    dispatch(loadProgress());
  };
}

// todo - handle the setState at the end of this call
export function getUserProgressFromAnswers(
  answers: ServerAnswer[],
  user_id: number
) {
  return async function (
    dispatch: ThunkDispatch<RootState, void, AnyAction>,
    getState: () => RootState
  ) {
    const state = getState();
    const flowId = selectors.selectFlowReducerFlowId(state);
    const steps = selectors.selectFlowReducerStepsFromCurrentFlow(state);
    const postTestFlowId = FlowService.getParsedPostFlowIdFromCookie();

    const hasAnswersThisFlowAndProduct =
      answers.filter(
        answer =>
          answer.flow_id === flowId && answer.product_id === window.product_id
      ).length > 0;

    if (!hasAnswersThisFlowAndProduct && postTestFlowId !== flowId) {
      await dispatch(redirectUserToAppropriateFlow(user_id));
    }

    if (hasAnswersThisFlowAndProduct || postTestFlowId === flowId) {
      const localAnswersArray = AnswersService.getAnswersAsArray();
      const answersForProgress = localAnswersArray.length
        ? localAnswersArray
        : answers;
      const userProgress = findUserProgress(
        steps,
        answersForProgress,
        !!postTestFlowId
      );
      if (userProgress) {
        // Set the _current_ step and page into local storage.
        // "nextStep" and "nextPage" here is a logical fencepost error
        FlowService.setLocalProgress(
          flowId,
          userProgress.nextStep,
          userProgress.nextPage
        );
        // if post test, take out user answers so he has to answer them again. leave ids though, so they can get
        // updated in the database
        answers = postTestFlowId
          ? answers.map(a => {
              return {
                id: a.id,
                question_id: a.question_id,
              } as ServerAnswer;
            })
          : answers;
        AnswersService.saveFetchedAnswers(answers);

        // This function exists on the outermost container, the
        // page. This function triggers the Reducer to go to the
        // correct page.
        // NOTE: the second boolean here indicates that this is a
        // login flow.
        // Note that this is using ServerAnswers and not answersForProgress (IE, does not use answers from local storage)
        dispatch(loadNextProgress(answers, true, postTestFlowId));
      }
    }
  };
}

// extraction of Flow container logic
export function placeUserInFlowFromAnswers(userId: number) {
  return async function (
    dispatch: ThunkDispatch<RootState, void, AnyAction>,
    getState: () => RootState
  ) {
    const flowId = selectors.selectFlowReducerFlowId(getState());
    const organizationId = OrganizationService.getOrgId();

    // User has just logged in, so we need to get the user's progress from server
    const query = `user_id=${userId}&product_id=${window.product_id}&flow_id=${flowId}&organization_id=${organizationId}`;
    const method =
      'find_with_user_id_and_product_id_and_flow_id_and_organization_id';

    let res;
    try {
      res = await ApiActions.get({ entity: 'Answer', method, query });
      if (!res) {
        throw 'no response fetching answers';
      }
    } catch (error) {
      Sentry.captureException(`Error getting user answers: ${error}`);
      console.log('Error getting user answers');
      return;
    }

    const answers: ServerAnswer[] = res?.result;
    if (answers) {
      await dispatch(getUserProgressFromAnswers(answers, userId));
    }
    dispatch(loadProgress());
  };
}

/**
 * Load next step / page for user progress
 */
export function loadNextProgress(
  answers: Answer[] | ServerAnswer[], // BTC - findConditionsAndApply works with either .values or .responses
  isLogin: boolean,
  postTestFlowId: number
) {
  return function (dispatch: Dispatch) {
    return dispatch({
      type: LOAD_NEXT_PROGRESS,
      data: { answers, isLogin, postTestFlowId },
    });
  };
}

/**
 * Set flag for collecting user info on signup
 */
export function collectUserInfo(bool: boolean) {
  return { type: COLLECT_USER_INFO, value: bool };
}

/**
 * Set a custom continue function instead of the default one
 */
export function changeContinueFunction(func: () => void) {
  return { type: SET_CONTINUE_FUNCTION, func };
}

/**
 * Reset the continue function back to its original action
 */
export function resetContinueFunction() {
  return { type: RESTORE_CONTINUE_FUNCTION };
}

/**
 * Set loading flag for the continue button
 */
export function setContinueButtonLoading(bool: boolean) {
  return { type: CONTINUE_BUTTON_LOADING, value: bool };
}

/**
 * Moves to next page or step.
 * Saves crt page data if any, and any other previous non-saved data.
 *
 * pageOfAnswers: array of all answers from a page
 * currentStep: index of current step
 * currentPage: index of current page
 * nextStep: index of step to jump to
 * nextPage: index of page to jump to
 * flow: current flow object
 * changeHash: whether or not to update the URL hash on page/step change
 * forceSkipPages: allow the user to jump to step and page directly
 * isLastPageOfLastStep: flag to know if we are on last page of flow
 */
export function jumpTo(
  pageOfAnswers: Answer[],
  currentStep: number,
  currentPage: number,
  nextStep: number,
  nextPage: number,
  flow: CurrentFlow,
  changeHash = true,
  forceSkipPages = false,
  isLastPageOfLastStep = false
) {
  return function (dispatch: Dispatch) {
    if (forceSkipPages) {
      FlowService.setMaxProgress(nextStep, nextPage);
    }

    // purge community username if it's not saved because of https://bighealth.atlassian.net/browse/ENR-1738
    // jumpTo is used for back, so we _never want to use its value if it's not saved yet_
    AnswersService.purgeUnsavedCommunityUsernameAnswer();

    let trigger: boolean | { step: number } = false;
    // Save progress so far
    beforeNext(
      dispatch,
      pageOfAnswers,
      currentStep,
      currentPage,
      flow, // following this rabbit hole, we really only neeeeeeed the flowId for
      isLastPageOfLastStep
    );

    // Only log that the page was completed if we are actually progressing in the flow. Since this
    // method gets called if the user goes back, we have to check that the steps and pages are increasing
    if (
      nextStep > currentStep ||
      (nextStep == currentStep && nextPage > currentPage)
    ) {
      EventService.screenCompletedEvent(flow, currentStep, currentPage);
    }

    if (currentStep !== nextStep) {
      trigger = {
        step: currentStep,
      };
    }
    // Then move to corresponding page/step
    return dispatch({
      type: JUMP_TO,
      step: nextStep,
      page: nextPage,
      changeHash,
      trigger,
    });
  };
}

/**
 * Moves to next page or step.
 * Saves crt page data if any, and any other previous non-saved data.
 *
 * pageOfAnswers: array of all answers from a page
 * nextType: 'page' or 'step'
 * stepNumber: current step number
 * pageNumber: current page number
 * flow: flow
 * isLastPageOfLastStep: flag to know if we are on last page of flow
 */
export function next(
  pageOfAnswers: Answer[],
  nextType: string,
  stepNumber: number,
  pageNumber: number,
  flow: CurrentFlow,
  isLastPageOfLastStep = false
) {
  return function (dispatch: Dispatch) {
    // Save progress so far
    beforeNext(
      dispatch,
      pageOfAnswers,
      stepNumber,
      pageNumber,
      flow,
      isLastPageOfLastStep
    );
    EventService.screenCompletedEvent(flow, stepNumber, pageNumber);

    // Then move to corresponding page/step
    const type = nextType === 'page' ? NEXT_PAGE : NEXT_STEP;
    return dispatch({ type: type });
  };
}

/**
 * Helper function to save current progress before moving to next step.
 *
 * dispatch: function that triggers store changes
 * pageOfAnswers: array of all answers from a page
 * stepNumber: current step number
 * pageNumber: current page number
 * flow: current flow
 * isLastPageOfLastStep: flag to know if we are on last page of flow
 */
function beforeNext(
  dispatch: Dispatch,
  pageOfAnswers: Answer[] | undefined,
  stepNumber: number,
  pageNumber: number,
  flow: CurrentFlow,
  isLastPageOfLastStep = false
) {
  const user = UserSession.getUserData();
  if (pageOfAnswers) {
    // First, save answers from current page (if any)
    const { answersOnThisPage, hiddenQuestionAnswers } = pageOfAnswers.reduce<{
      answersOnThisPage: Answer[];
      hiddenQuestionAnswers: Answer[];
    }>(
      (acc, curr) => {
        if (curr.isHiddenQuestion) {
          acc.hiddenQuestionAnswers.push(curr);
        }
        if (!curr.isHiddenQuestion) {
          acc.answersOnThisPage.push(curr);
        }
        return acc;
      },
      { answersOnThisPage: [], hiddenQuestionAnswers: [] }
    );
    AnswersService.savePage(answersOnThisPage, stepNumber, pageNumber);

    // Then, save hidden questions answers
    hiddenQuestionAnswers.forEach(answer => {
      AnswersService.updateAnswersOnPage(
        [answer],
        answer.stepNumber,
        answer.pageNumber
      );
    });
  }

  // Check if there is unsaved data to be saved
  const allAnswers = AnswersService.getAnswersAsArray();
  const answers = allAnswers.filter(answer => !answer.saved);
  if (user && answers.length) {
    const flowId = FlowService.getFlowId();
    const organizationId = OrganizationService.getOrgId();
    const data = mapAnswersToServer(
      answers,
      user.user_id,
      flowId,
      organizationId
    ).map(answer => {
      const existingSavedAnswer = AnswersService.getFetchedAnswerByQuestionId(
        answer.question_id
      );
      if (!answer.id && existingSavedAnswer?.id) {
        answer.id = existingSavedAnswer.id;
      }
      return answer;
    });

    // Log response events for all unsaved answers once we have a user
    // AnswersService.updateSavedAnswers is what marks answers as saved, so run before that
    // any new answers will inherently be unsaved at this point
    // we also don't create responses for hidden questions
    const flowHiddenQuestions = flow.hiddenQuestions || [];
    const hiddenQuestionIds = flowHiddenQuestions.map(({ id }) => id);
    const answersThatAreNotHiddenQuestions = answers.filter(
      ans =>
        !(ans.isHiddenQuestion || hiddenQuestionIds.includes(ans.question_id)) // is marked as hidden or flow hidden questions contain it, backwards compatible HQ marking
    );
    if (answersThatAreNotHiddenQuestions.length) {
      EventService.responseCreatedEvent(
        answersThatAreNotHiddenQuestions,
        flow, // this really should just be flowId, not entire flow
        stepNumber,
        pageNumber
      );
    }

    // Save answers
    dispatch({ type: SAVING_ANSWERS, value: true });
    if (isLastPageOfLastStep) {
      dispatch({ type: CONTINUE_BUTTON_LOADING, value: true });
      AnswersService.makeInputsDisabledWhileCallLoadings();
    }
    ApiActions.post({
      entity: 'Answer',
      method: 'create_update_answers_bulk',
      data,
    })
      .then(res => {
        dispatch({ type: SAVING_ANSWERS, value: false });
        if (res?.result) {
          AnswersService.updateSavedAnswers(res.result);
        }
      })
      .catch(err => {
        Sentry.captureException(err);
      });
  }
}

export function showExistingSignup(shouldShow = false) {
  return { type: SHOW_EXISTING_SIGNUP, value: shouldShow };
}
