import { css } from '@emotion/react';
import { SLEEPIO_NAVY, SLATE_GRAY } from '../../../../styles/colors';
import { BHTheme } from '../../../../types/bh-themes.types';

export const interactiveFieldLabelStyle = (theme: BHTheme) =>
  css({
    width: '90%',
    padding: `0 0 ${theme.padding.SMALL} ${theme.padding.SMALL}`,
    color: SLEEPIO_NAVY,
    fontWeight: theme.fonts.fontWeights.REGULAR,
    fontSize: theme.fonts.fontSizes.STANDARD,
    a: {
      '&:hover': theme.links.color.hover,
      '&:visited': {
        color: theme.links.color.visited,
      },
      color: theme.links.color.defaultColor,
    },
    lineHeight: '1.35em',

    [`@media (max-width: ${theme.screenWidth.MOBILE})`]: {
      width: '70%',
    },
  });

export const interactiveHelperTextStyle = (theme: BHTheme) =>
  css({
    padding: `${theme.padding.SMALL} 0 0 ${theme.padding.MEDIUM}`,
    color: SLATE_GRAY,
    fontWeight: theme.fonts.fontWeights.MEDIUM,
    fontSize: theme.fonts.fontSizes.SMALL,
    lineHeight: '1.35em',
  });
