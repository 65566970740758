import getConfig from '../../config';

const defaultTagNames = getConfig().streamTraceButtons.tagNames;
const defaultRequiredData = getConfig().streamTraceButtons.requiredData;
// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultCallback = () => {};
const eventListeners = getConfig().streamTraceButtons.eventListeners;
const defaultSendData = getConfig().streamTraceButtons.sendData;
// eslint-disable-next-line max-len
export const globalEventHandler = function (
  callback = defaultCallback,
  flow_id,
  product_id,
  sendData = defaultSendData,
  tagNames = defaultTagNames,
  requiredData = defaultRequiredData
) {
  eventListeners.forEach(event => {
    // eslint-disable-next-line max-len
    document.removeEventListener(
      event,
      handleEventOnElement.bind(
        null,
        flow_id,
        product_id,
        sendData,
        tagNames,
        requiredData,
        callback
      )
    );
    // eslint-disable-next-line max-len
    document.addEventListener(
      event,
      handleEventOnElement.bind(
        null,
        flow_id,
        product_id,
        sendData,
        tagNames,
        requiredData,
        callback
      )
    );
  });
};

function handleEventOnElement(
  flow_id,
  product_id,
  sendData,
  tagNames,
  requiredData,
  callback,
  event
) {
  let matches = true;
  if (tagNames.indexOf(event.target.tagName) > -1) {
    Object.keys(requiredData).forEach(attribute => {
      if (
        event.target.getAttribute(`${attribute}`) !== requiredData[attribute]
      ) {
        matches = false;
      }
    });
  } else {
    matches = false;
  }

  if (matches) {
    // change from DOMStringMap to JSON
    const keyValueDataAttributes = JSON.parse(
      JSON.stringify(event.target.dataset)
    );
    const data = {};
    for (let attribute in keyValueDataAttributes) {
      // eslint-disable-next-line no-prototype-builtins
      if (keyValueDataAttributes.hasOwnProperty(attribute)) {
        const nameLowerCase = attribute
          .replace(/streamTrace/gi, '')
          .toLowerCase();
        if (sendData.indexOf(nameLowerCase) > -1) {
          data[nameLowerCase] = keyValueDataAttributes[attribute];
        }
      }
    }

    if (sendData.indexOf('_inner_text' > -1)) {
      data['display_text'] = event.target.innerText || event.target.value;
    }

    const tagNameLowerCase = event.target.tagName.toLowerCase();
    data.type = tagNameLowerCase;

    callback.call(null, data, flow_id, product_id);
  }
}
