import { Theme } from '@emotion/react';
import {
  DISABLED_GRAY,
  SALMON,
  SALMON_DARK,
  SALMON_LIGHT,
  INPUT_OUTLINE_GRAY,
  OFF_WHITE,
  SLEEPIO_NAVY,
  THEMED_ERROR_RED,
  SLATE_GRAY,
  WHITE,
  PALE_GRAY,
  THEMED_ERROR_LIGHT_RED,
} from '../colors';

import {
  Button,
  Errors,
  FontSizes,
  FontWeights,
  PaddingSizes,
  Borders,
  Links,
  FontColors,
  BackgroundColors,
  Header,
  ScreenWidths,
} from '../../types/bh-themes.types';

import {
  MOBILE_SMALL,
  MOBILE_MEDIUM,
  MOBILE_STANDARD,
  MOBILE_QUESTION_TITLE,
  MOBILE_HEADLINE,
  MOBILE_SUBTITLE,
} from '../font-sizes';
import { BOLD, MEDIUM, REGULAR } from '../font-weights';

const SCREEN_WIDTHS: ScreenWidths = {
  MOBILE: '375px',
  TABLET: '700px',
  DESKTOP: '1440px',
};

const PADDING_SIZES: PaddingSizes = {
  EXTRA_SMALL: '4px',
  SMALL: '8px',
  MEDIUM: '16px',
  LARGE: '24px',
  EXTRA_LARGE: '32px',
};

const BACKGROUND_COLORS: BackgroundColors = {
  DARK: SLEEPIO_NAVY,
  LIGHT: PALE_GRAY,
};

const FONT_SIZES: FontSizes = {
  HEADLINE: MOBILE_HEADLINE,
  TITLE: MOBILE_QUESTION_TITLE,
  SUBTITLE: MOBILE_SUBTITLE,
  STANDARD: MOBILE_STANDARD,
  SMALL: MOBILE_SMALL,
  MEDIUM: MOBILE_MEDIUM,
};

const FONT_WEIGHTS: FontWeights = {
  REGULAR: REGULAR,
  MEDIUM: MEDIUM,
  BOLD: BOLD,
};

const FONT_COLORS: FontColors = {
  PRIMARY: SLEEPIO_NAVY,
  SECONDARY: WHITE,
  LEGAL: SLATE_GRAY,
  LIGHT: WHITE,
};

const errors: Errors = {
  color: THEMED_ERROR_RED,
  borderColor: THEMED_ERROR_RED,
  borderWidth: '1.5px',
  borderRadius: 'inherit',
  border: `1.5px solid ${THEMED_ERROR_RED}`,
  backgroundColor: THEMED_ERROR_LIGHT_RED,
};

const borders: Borders = {
  defaultBorder: `1px solid ${INPUT_OUTLINE_GRAY}`,
  defaultBorderRadius: '8px',
  defaultActiveBorder: `2px solid ${SLEEPIO_NAVY}`,
};

const links: Links = {
  color: {
    defaultColor: SLEEPIO_NAVY,
    visited: SLATE_GRAY,
    hover: {
      color: SLEEPIO_NAVY,
      opacity: '0.8',
    },
  },
  defaultStyle: {
    // pop this bad boy in directly for instant link styling
    color: SLEEPIO_NAVY,
    ['&:hover']: {
      color: SLEEPIO_NAVY,
      opacity: '0.8',
      cursor: 'pointer',
    },
    ['&:visited']: {
      color: SLATE_GRAY,
    },
    textDecoration: 'underline',
  },
};

const button: Button = {
  color: {
    default: SLEEPIO_NAVY,
    disabled: WHITE,
  },
  backgroundColor: {
    active: SALMON_DARK,
    default: SALMON,
    disabled: DISABLED_GRAY,
    hover: SALMON_LIGHT,
  },
  fontSize: {
    MOBILE: MOBILE_MEDIUM,
    DESKTOP: MOBILE_STANDARD,
  },
  width: {
    MOBILE: '152px',
    DESKTOP: '184px',
    MAXWIDTH: '248px',
  },
  height: {
    MOBILE: '48px',
    DESKTOP: '56px',
  },
};

const header: Header = {
  color: SLEEPIO_NAVY,
  backgroundColor: PALE_GRAY,
};

export const baseTheme: Theme = {
  screenWidth: SCREEN_WIDTHS,
  name: 'Base Theme',
  header,
  footer: {},
  padding: PADDING_SIZES,
  backgroundColors: BACKGROUND_COLORS,
  fonts: {
    fontSizes: FONT_SIZES,
    fontWeights: FONT_WEIGHTS,
    fontColors: FONT_COLORS,
  },
  errors,
  button,
  inputs: {
    placeholder: {
      color: SLATE_GRAY,
    },
    radio: {
      color: SLEEPIO_NAVY,
      backgroundColor: OFF_WHITE,
      borderColor: INPUT_OUTLINE_GRAY,
      selectedBorderColor: SLEEPIO_NAVY,
    },
    checkbox: {
      background: SLEEPIO_NAVY,
      borderRadius: '2px',
      color: WHITE,
      textColor: SLEEPIO_NAVY,
      backgroundColor: OFF_WHITE,
    },
  },
  borders,
  links,
  tooltip: {
    backgroundColor: WHITE,
    color: SLEEPIO_NAVY,
    padding: PADDING_SIZES.LARGE,
    fontSize: FONT_SIZES.SMALL,
  },
};
