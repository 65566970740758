import './style.scss';
import React from 'react';
import PropTypes from 'prop-types';
import QuestionGroupComponent from '../question-group-component/question-group.component';
import ConditionalRender from '../conditional-render';

/* 
BTC notes:
  - selected is indices of selected answers
  - selectedAnswers is the actual selected answer objects
*/

class InputOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      selectedAnswers: [],
      errors: null,
      focusedIdx: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { type } = props;
    const selectedAnswers =
      props.selectedAnswers instanceof Array
        ? props.selectedAnswers
        : [props.selectedAnswers];
    const minimumAnswers = props.validation
      ? parseInt(props.validation.minimumAnswers)
      : 1;
    const wording = minimumAnswers === 1 ? 'answer' : 'answers';
    let selected = [...state.selected];
    let errors;
    let checks;
    // I don't think this if can ever be reached, selectedAnswers is always an array
    if (!selectedAnswers.id) {
      errors = 'You must select at least 1 answer';
    }
    if (selected.length < 1 || selected.length < minimumAnswers) {
      errors = `You must select at least ${minimumAnswers} ${wording}`;
    }
    if (selectedAnswers) {
      if (
        type === 'single' ||
        type === 'box' ||
        type === 'limit box' ||
        type === 'side-by-side'
      ) {
        checks = selectedAnswers;
        const index = props.details.answers.findIndex(
          item =>
            selectedAnswers[0] &&
            item.id === selectedAnswers[0].id &&
            item.score === selectedAnswers[0].score
        );
        selected = index > -1 ? [index] : ['none'];
      } else {
        checks = selectedAnswers;
        selected = selectedAnswers.map(item => {
          const idx = props.details.answers.findIndex(
            answer => item.id === answer.id && item.score === answer.score
          );
          if (idx === -1 && props.details.show_none_of_the_above) {
            return 'none';
          }
          return idx;
        });
      }
    }

    if (checks !== state.selectedAnswers || selected !== state.selected) {
      return { selectedAnswers: checks, selected, errors };
    }

    return null;
  }

  handleCheck(idx) {
    const questionDetails = this.props.details;
    let selected = [...this.state.selected];
    let selectedAnswers = [...this.state.selectedAnswers];
    const index = selected.indexOf(idx);
    const maximumAnswers = this.props.validation
      ? parseInt(this.props.validation.maximumAnswers)
      : 10;
    const minimumAnswers = this.props.validation
      ? parseInt(this.props.validation.minimumAnswers)
      : 1;
    let errors = null;
    const type = this.props.type;

    if (
      type === 'single' ||
      type === 'box' ||
      type === 'limit box' ||
      type === 'card' ||
      type === 'side-by-side'
    ) {
      if (idx !== 'none') {
        // const { id, score, text } = questionDetails.answers[idx];
        selectedAnswers = {
          // eslint-disable-next-line max-len
          id:
            questionDetails.answers[idx].id !== undefined
              ? questionDetails.answers[idx].id
              : questionDetails.answers[idx].key,
          score: questionDetails.answers[idx].score,
          text: questionDetails.answers[idx].text,
        };
      } else {
        selectedAnswers = this.state.selectedAnswers;
      }

      this.setState(
        {
          selected: [idx],
          errors,
          selectedAnswers: selectedAnswers,
        },
        () => {
          this.props.onChange(selectedAnswers, true);
          if (type === 'card') {
            this.props.advance();
          }
        }
      );
    } else {
      if (idx === 'none') {
        if (selected.length && selected[0] === 'none') {
          selected = [];
          selectedAnswers = [];
          const wording = minimumAnswers === 1 ? 'answer' : 'answers';
          errors = `You must select at least ${minimumAnswers} ${wording}`;
        } else {
          selected = ['none'];
          selectedAnswers = [{ id: null, score: null, text: 'none' }];
        }
      } else if (index === -1) {
        if (selected.length === maximumAnswers) {
          const wording = maximumAnswers === 1 ? 'answer' : 'answers';
          this.setState({
            errors: `You can't select more than ${maximumAnswers} ${wording}`,
          });
          return false;
        } else {
          selected = selected.concat([idx]);
          // for reasons i cannot explain, this is not working
          // const { id, score, text } = questionDetails.answers[idx];

          selectedAnswers = selectedAnswers.concat([
            {
              id: questionDetails.answers[idx].id,
              score: questionDetails.answers[idx].score,
              text: questionDetails.answers[idx].text,
            },
          ]);

          if (selected.filter(v => v !== 'none').length < minimumAnswers) {
            errors = `You must select at least ${minimumAnswers} ${
              minimumAnswers === 1 ? 'answer' : 'answers'
            }`;
          }
        }
      } else {
        if (selected.length <= minimumAnswers) {
          errors = `You must select at least ${minimumAnswers} ${
            minimumAnswers === 1 ? 'answer' : 'answers'
          }`;
          selected.splice(index, 1);
          selectedAnswers.splice(index, 1);
        } else {
          selected.splice(index, 1);
          selectedAnswers.splice(index, 1);
        }
      }

      // remove 'none' from array if other options are selected
      if (selected.indexOf('none') > -1 && selected.length > 1) {
        selected.splice(0, 1);

        if (selectedAnswers.length > 1) {
          selectedAnswers.splice(0, 1);
        }
      }

      /** is valid if 'none of the above' is selected or number of answers is >= min or <= max **/
      const isValid =
        selected.indexOf('none') !== -1
          ? true
          : maximumAnswers || minimumAnswers
          ? selected.length >= minimumAnswers &&
            selected.length <= maximumAnswers
          : true;

      this.setState({ selected, selectedAnswers, errors }, () => {
        this.props.onChange(selectedAnswers, isValid);
      });
    }
  }

  crapFocusFunction(event) {
    const idx = event.target.id.split('options-id-')[1];
    this.setState({
      focusedIdx: idx,
    });
  }

  onBlur() {
    this.setState({
      focusedIdx: null,
    });
  }

  render() {
    const type = this.props.type;
    const answerTypeBox = type === 'box' || type === 'limit box';
    const inputType =
      type === 'single' || type === 'card' ? 'radio' : 'checkbox';
    const questionContent = this.props.details;
    const belongsTo = `${questionContent.question_id}-${questionContent.id}-${questionContent.lang}`;
    const { isChecked, isMapped = false } = this.props;
    const hasOptionSelected = !!this.state.selected.length;
    const answersNumber = questionContent.show_none_of_the_above
      ? questionContent.answers.length + 1
      : questionContent.answers.length || 1;

    const list = (
      <div data-card-type={this.props.cardType}>
        <div
          className={`sl-options-wrapper ${
            answerTypeBox ? 'sl-answer-box' : ''
          }`}
        >
          {questionContent.answers.map((item, idx) => (
            <div
              key={idx}
              style={{
                minWidth: this.props.cardType
                  ? 'auto'
                  : `${100 / answersNumber}%`,
              }}
              // eslint-disable-next-line max-len
              className={`sl-option-row ${
                this.state.selected.indexOf(idx) !== -1
                  ? 'sl-option-row--checked'
                  : ''
              }`}
            >
              <label
                htmlFor={`options-id-${idx}`}
                className={`sl-option ${isChecked ? 'error' : ''}`}
                data-focused={
                  parseInt(this.state.focusedIdx) === idx ? 'true' : 'false'
                }
                style={{
                  backgroundColor: this.props.cardType ? item.card_color : '',
                }}
              >
                <ConditionalRender if={!this.props.cardType}>
                  <span>{item.text}</span>
                </ConditionalRender>

                <ConditionalRender if={this.props.cardType}>
                  <div className="card-top-text">{item.top_text}</div>
                  <img
                    className="card-image"
                    src={item.image_url}
                    alt={item.top_text}
                  />
                  <div className="card-bottom-text">{item.bottom_text}</div>
                </ConditionalRender>

                <input
                  type={inputType}
                  tabIndex="0"
                  id={`options-id-${idx}`}
                  name={`${questionContent.question_id}_${idx}`}
                  onFocus={this.crapFocusFunction.bind(this)}
                  onBlur={this.onBlur.bind(this)}
                  onChange={this.handleCheck.bind(this, idx)}
                  checked={
                    this.props.cardType
                      ? false
                      : this.state.selected.indexOf(idx) !== -1
                  }
                />

                <ConditionalRender if={isMapped}>
                  <QuestionGroupComponent
                    hidden={true}
                    belongsTo={`${belongsTo}-option-${idx}`}
                    content={item.selected}
                    actions={this.props.actions}
                    onAnswer={this.props.onAnswer}
                    selected={
                      !hasOptionSelected
                        ? null
                        : this.state.selected.indexOf(idx) !== -1
                    }
                  />

                  <QuestionGroupComponent
                    hidden={true}
                    belongsTo={`${belongsTo}-option-${idx}`}
                    content={item.not_selected}
                    actions={this.props.actions}
                    onAnswer={this.props.onAnswer}
                    selected={
                      !hasOptionSelected
                        ? null
                        : this.state.selected.indexOf(idx) === -1
                    }
                  />
                </ConditionalRender>
              </label>
            </div>
          ))}

          <ConditionalRender if={questionContent.show_none_of_the_above}>
            <div
              style={{ minWidth: `${100 / answersNumber}%` }}
              className={`sl-option-row ${
                this.state.selected.indexOf('none') !== -1
                  ? 'sl-option-row--checked'
                  : ''
              }`}
            >
              <label
                className="sl-option"
                htmlFor="options-id-none-of-the-above"
                data-focused={
                  this.state.focusedIdx === 'none-of-the-above'
                    ? 'true'
                    : 'false'
                }
              >
                <span>{questionContent.show_none_of_the_above}</span>
                <input
                  type={inputType}
                  tabIndex="0"
                  id="options-id-none-of-the-above"
                  onFocus={this.crapFocusFunction.bind(this)}
                  onBlur={this.onBlur.bind(this)}
                  onChange={this.handleCheck.bind(this, 'none')}
                  checked={this.state.selected.indexOf('none') !== -1}
                />
              </label>
            </div>
          </ConditionalRender>
        </div>

        <ConditionalRender if={type === 'limit box'}>
          <p className="sl-box-limits-text">
            <span>{questionContent.min_value}</span>
            <span>{questionContent.max_value}</span>
          </p>
        </ConditionalRender>
        <ConditionalRender
          // eslint-disable-next-line max-len
          if={
            this.props.isChecked &&
            (this.state.selectedAnswers[0] === undefined ||
              this.state.selectedAnswers.length === 0)
          }
        >
          <div className="sl-container-radio-options--error">
            {this.state.errors}
          </div>
        </ConditionalRender>
      </div>
    );

    return <div>{list}</div>;
  }
}

InputOptions.propTypes = {
  details: PropTypes.object.isRequired,
  validation: PropTypes.object,
  type: PropTypes.string,
  selectedAnswers: PropTypes.any,
  actions: PropTypes.any,
  onAnswer: PropTypes.func,
  isMapped: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  cardType: PropTypes.bool.isRequired,
  advance: PropTypes.func,
};

export default InputOptions;
