import { QuestionTypes } from '../constants/question-types';
import { SLEEPIO_LIGHT_BLUE } from '../styles/colors';
import { PageEntity } from '../types/flow.types';

export const isPrimaryColor = (semantic_id: string, index: number) => {
  if (semantic_id === QuestionTypes.TEXT_TO_DOWNLOAD && index === 0) {
    return {
      backgroundColor: SLEEPIO_LIGHT_BLUE,
    };
  }
  return null;
};

export const getPaddingWithPrimaryColor = (content: PageEntity[]) => {
  if (content && content[0].type === QuestionTypes.TEXT_TO_DOWNLOAD) {
    return true;
  }

  return false;
};
