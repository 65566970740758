import {
  getTermsOfServiceUrl,
  getPrivacyPolicyUrl,
  getSuitableUrl,
} from '../../../helpers/terms-conditions';

/**
 * Map input properties as defined in the admin panel to a more front-end-easy-to-interpret manner
 *
 * {
 *     <property_name_which_will_be_used_in_frontend>: {
 *         <field_name> - <name_of_option_from_admin>
 *         <field_name> - <name_of_option_from_admin>
 *         <field_name> - <name_of_option_from_admin>
 *         <field_name> - <name_of_option_from_admin>
 *     }
 * }
 *
 * This properties are used in each individual input type component are being set on the state as state.additionalData
 */

const PROPERTIES_MAPPING = {
  help_text: {
    zip_code: '{zip_code_field_help_text}',
    phone_number: '{phone_number_subtext}',
    password: '{password_subtext}',
    voucher_code: '{voucher_text}',
    postal_code: '{postal_code_field_help_text}',
    employee_id: '{employee_id_field_help_text}',
  },
  tooltip: {
    employee_id: '{employee_id_tooltip}',
    phone_number: '{phone_number_tooltip}',
  },
  includeUnknown: {
    gender: '{include_unknown}',
  },
  additional_instructions_text: {
    employee_id: '{employee_id_additional_instructions_text}',
  },
  regex_validator: {
    employee_id: '{employee_id_validator}',
  },
  used_for_error: {
    static_component: '{used_for_error}',
  },
  template_terms_of_service: {
    data_storing: '{terms_of_service_text}',
  },
  template_privacy_policy: {
    data_storing: '{privacy_policy_text}',
  },
  template_suitable: {
    clinical_governance: '{suitable_url_text}',
  },
  template_suitability_information: {
    doctor_acknowledgement: '{suitability_information_text}',
  },
  suitability_information_url: {
    doctor_acknowledgement: getSuitableUrl(),
  },
  terms_of_service_url: {
    data_storing: getTermsOfServiceUrl() || 'https://www.sleepio.com/terms',
  },
  privacy_policy_url: {
    data_storing: getPrivacyPolicyUrl() || 'https://www.sleepio.com/privacy',
  },
  suitable_url: {
    clinical_governance: getSuitableUrl() || 'https://sleepio.com/suitable/',
  },
  custom_error_text: {
    custom_error_text: '{custom_error_text}',
  },
};

const GLOBAL_PROPERTIES = {
  required: 'Boolean',
  use_small_font: 'Boolean',
};

/* This function performs a linear scan of all of the "options" for this input
   object, and returns the first options that has the property "validators" on
   it. For a list of all the input objects, please see
   onboarding-question-service-cluster:big_health/services/interactive_v_component/interactive_v_input.py
 */
export function getLocalValidators(properties) {
  let response = [];
  let options = properties.options || [];
  options = options instanceof Array ? options : [options];

  options.forEach(property => {
    if (property.validators) {
      response = property.validators;
    }
  });

  return response;
}

/**
 * Parses PROPERTIES_MAPPING defined above and returns the additional data for a specific input type
 * @param {Object} properties - properties mapping
 * @returns {Any}
 */
export function getAdditionalData(properties) {
  const response = {};

  Object.keys(GLOBAL_PROPERTIES).forEach(property => {
    const option = getOptionByField(properties, property);
    const castType = GLOBAL_PROPERTIES[property];
    response[property] = eval(`${castType}(${option.value})`);
  });

  Object.keys(PROPERTIES_MAPPING).forEach(property => {
    const fields = Object.keys(PROPERTIES_MAPPING[property]);
    if (fields.indexOf(properties.field_name) > -1) {
      const propertyFieldName =
        PROPERTIES_MAPPING[property][properties.field_name];
      if (propertyFieldName.indexOf('{') === 0) {
        const option = getOptionByField(
          properties,
          propertyFieldName.replace('{', '').replace('}', '')
        );
        response[property] = option.value;
      } else {
        response[property] = propertyFieldName;
      }
    }
  });
  return response;
}

function getOptionByField(properties, fieldName) {
  const options = properties.options || [];
  return options.find(o => o.field === fieldName) || {};
}
