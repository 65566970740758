// WARNING: If StreamTrace fails, the flow continues.
// This can skew data, as this failure is not considered downstream.

import ApiActions from '../../actions/api';
import * as SignupService from '../signup-service';
import UserSession from '../user-session';
import OrganizationService from '../org-service';
import * as Sentry from '@sentry/react';
import { analytics } from '../analytics';
const ACTIONS = {
  CLICKED: 'clicked',
  COMPLETED: 'completed',
  CREATED: 'created',
  NEWUUID: 'newuuid',
  STARTED: 'started',
  COOKIE_CONSENT: 'cookie_consent',
  UPDATED: 'updated',
  VIEWED: 'viewed',
};

const RESOURCE = {
  ACCOUNT: 'account',
  BUTTON: 'button',
  RESPONSE: 'response',
  SCREEN: 'screen',
  TEST: 'test',
};

const EVENTTYPE = {
  USER_ACTIVITY: 'user_activity',
  UI: 'ui',
};

const COOKIE_LEVELS = ['necessary', 'functional', 'targeting', 'performance'];
/**
 * Handles StreamTrace (and other analytics)
 *
 * "class" simply used as namespace; not due to semantic need.
 *
 * Usage: For now, best to user EventService as the wrapper for all calls to analytics
 *
 * If only single call to analytics: access like so
 * ```tsx
 * EventService.analytics.track({ type: 'Logout' })
 * ```
 *
 * If collection of calls
 * ```tsx
 * EventService.accountCreated(userData)
 * //..
 * const accountCreated = (userData) => {
 *    analytics.track(eventCreator.accountCreated(userData));
 *    analytics.identify(userData.user_uuid);
 * }
 * ```
 * WHY Ash suggests we break event code into:
 *  1. Event types
 *  2. Analytics wrapper which takes service plugins (e.g. Heap, StreamTrace)
 *  3. Event helper functions for composite analytics calls
 *
 */
class EventService {
  static analytics = analytics; // For use if no approperiate EventService helper function

  static logoutUser() {
    analytics.resetIdentity();
  }

  /**
   * @description Account created event
   *
   * @param {Object} userData - user data for event logging
   * @typedef {{
   *     email: string,
   *     first_name: string,
   *     last_name: string,
   *     idp: string,
   *     idp_id: string
   * }} Fields
   * @typedef {{
   *     fields: Fields,
   *     id: Number,
   *     organization_id: Number,
   *     product_id: Number
   * }} userData
   */
  static accountCreatedEvent(userData) {
    const localUserData = UserSession.getUserData() || {};
    userData = userData.fields
      ? userData
      : {
          fields: {
            phone_number: userData.phone_number || undefined,
            email: userData.email || undefined,
            first_name: userData.first_name || undefined,
            last_name: userData.last_name || undefined,
            idp_name: userData.idp_name || undefined,
            idp_id: userData.idp_id || undefined,
            employee_id: userData.employee_id || undefined,
          },
          id: userData.id || undefined,
          user_id: SignupService.getUserUuid(),
          product_id: userData.product_id || undefined,
          organization_id: userData.organization_id || undefined,
        };

    const body = [
      {
        action: ACTIONS.CREATED,
        body: {
          fields: {
            ...userData.fields,
          },
          meta: UserSession.getClientMetadata(),
          relations: {
            user: {
              id: localUserData.user_id || null,
            },
            company: {
              id: userData.organization_id,
            },
          },
        },
        name: `${RESOURCE.ACCOUNT}|${ACTIONS.CREATED}`,
        occurred_at: new Date().toISOString().replace(/T|Z/gi, ' ').trim(),
        resource: RESOURCE.ACCOUNT,
        type: EVENTTYPE.USER_ACTIVITY,
        product_id: window.product_id,
        user_id: SignupService.getUserUuid(),
      },
    ];
    if (!SignupService.getUserUuid()) {
      Sentry.captureMessage('Error: Sending null user uuid to RecordingAPI!');
    }
    if (!SignupService.getUserSessionId()) {
      Sentry.captureMessage('Error: Sending null session id to RecordingAPI!');
    }
    ApiActions.post({
      entity: 'RecordingAPI',
      method: 'post_events',
      data: body,
    });
    UserSession.setSentAccountCreated();
    analytics.addUserProperties({
      user_id: SignupService.getUserUuid(),
      organization_id: userData.organization_id,
      email: userData?.email || userData?.fields?.email,
      ...(window.product_id
        ? { [`product_id#${window.product_id}`]: true }
        : null),
    });
  }

  /**
   * @description Account updated event
   *
   * @param {Object} userData - user data for event logging
   * @typedef {{
   *     email: string,
   *     first_name: string,
   *     last_name: string,
   *     idp: string,
   *     idp_id: string
   * }} Fields
   * @typedef {{
   *     fields: Fields,
   *     id: Number,
   *     organization_id: Number,
   *     product_id: Number
   * }} userData
   */
  static accountUpdatedEvent(userData) {
    const localUserData = UserSession.getUserData() || {};

    userData = userData.fields
      ? userData
      : {
          fields: {
            phone_number: userData.phone_number || undefined,
            email: userData.email || undefined,
            first_name: userData.first_name || undefined,
            last_name: userData.last_name || undefined,
            idp_name: userData.idp_name || undefined,
            idp_id: userData.idp_id || undefined,
            employee_id: userData.employee_id || undefined,
          },
          id: userData.id || undefined,
          user_id: userData.uuid || undefined,
          product_id: userData.product_id || undefined,
          organization_id: userData.organization_id || undefined,
        };
    const body = [
      {
        action: ACTIONS.UPDATED,
        body: {
          fields: {
            ...userData.fields,
          },
          meta: UserSession.getClientMetadata(),
          relations: {
            user: {
              id: localUserData.user_id || null,
            },
          },
        },
        name: `${RESOURCE.ACCOUNT}|${ACTIONS.UPDATED}`,
        occurred_at: new Date().toISOString().replace(/T|Z/gi, ' ').trim(),
        resource: RESOURCE.ACCOUNT,
        type: EVENTTYPE.USER_ACTIVITY,
        product_id: window.product_id,
        user_id: SignupService.getUserUuid(),
      },
    ];
    if (!SignupService.getUserUuid()) {
      Sentry.captureMessage('Error: Sending null user uuid to RecordingAPI!');
    }
    if (!SignupService.getUserSessionId()) {
      Sentry.captureMessage('Error: Sending null session id to RecordingAPI!');
    }
    ApiActions.post({
      entity: 'RecordingAPI',
      method: 'post_events',
      data: body,
    });
    analytics.addUserProperties({
      user_id: SignupService.getUserUuid(),
      organization_id: userData.organization_id,
      email: userData?.email || userData?.fields?.email,
      ...(window.product_id
        ? { [`product_id#${window.product_id}`]: true }
        : null),
    });
  }

  /**
   * @description Response created event
   *
   * @param {Answer[]} answerData - user's answer data
   * @param {Flow} flow - flow object
   * @param {Number} stepNumber - the step on which the question resides
   * @param {Number} pageNumber - the page on which the question resides
   *
   * @typedef {{
   *     flow_id: Number
   * }} Flow
   * @typedef {{
   *     user_id: Number,
   * }} User
   */
  static responseCreatedEvent(answerData, flow, stepNumber, pageNumber) {
    let body = [];
    const localUserData = UserSession.getUserData() || {};
    if (answerData && answerData.length > 0) {
      body = answerData.map(answer => {
        const dateToUse = answer.answeredTimeInMs
          ? new Date(answer.answeredTimeInMs)
          : new Date();
        return {
          action: ACTIONS.CREATED,
          body: {
            fields: {
              value: answer.values,
            },
            meta: UserSession.getClientMetadata(),
            relations: {
              question: {
                reference: answer.semantic_id,
                is_custom: !answer.is_core_question,
                parent_reference: answer._parentQuestionId,
              },
              slide: {
                group: answer.pageNumber ?? pageNumber,
                reference: answer.stepNumber ?? stepNumber,
              },
              test: {
                reference: flow.flow_id,
              },
              user: {
                id: localUserData.user_id || null,
              },
            },
          },
          product_id: answer.product_id ? answer.product_id : window.product_id,
          name: `${RESOURCE.RESPONSE}|${ACTIONS.CREATED}|${flow.flow_id}`,
          occurred_at: dateToUse.toISOString().replace(/T|Z/gi, ' ').trim(),
          resource: RESOURCE.RESPONSE,
          type: EVENTTYPE.USER_ACTIVITY,
          user_id: SignupService.getUserUuid(),
        };
      });
    }
    if (!SignupService.getUserUuid()) {
      Sentry.captureMessage('Error: Sending null user uuid to RecordingAPI!');
    }
    if (!SignupService.getUserSessionId()) {
      Sentry.captureMessage('Error: Sending null session id to RecordingAPI!');
    }
    ApiActions.post({
      entity: 'RecordingAPI',
      method: 'post_events',
      data: body,
    });
  }
  static async cookieConsent(cookieLevels) {
    const localUserData = UserSession.getUserData() || {};

    const body = [
      {
        type: 'user_activity',
        occurred_at: new Date().toISOString().replace(/T|Z/gi, ' ').trim(),
        name: `${RESOURCE.BUTTON}|${ACTIONS.COOKIE_CONSENT}|${ACTIONS.CLICKED}`,
        user_id: SignupService.getUserUuid(),
        product_id: window.product_id,
        resource: RESOURCE.BUTTON,
        action: ACTIONS.UPDATED,
        body: {
          fields: {
            cookieLevels,
            pre_login_uuid: SignupService.getUserOldUuid(),
            post_login_uuid: SignupService.getUserUuid(),
          },
          meta: UserSession.getClientMetadata(),
          relations: {
            company: {
              id: OrganizationService.getOrgId(),
            },
            user: {
              id: localUserData.user_id || null,
            },
          },
        },
      },
    ];

    await ApiActions.post({
      entity: 'RecordingAPI',
      method: 'post_events',
      data: body,
    });
    // Note: Important to store selection for regulatory, even if we
    // throw errors due to not understanding them.
    const unknownCookieLevels = cookieLevels.filter(
      level => !COOKIE_LEVELS.includes(level)
    );
    if (unknownCookieLevels.length !== 0) {
      throw Error(
        `cookie consent levels not recognized: ${unknownCookieLevels
          .map(level => `"${level}"`)
          .join(', ')}`
      );
    }
  }

  static uuidChangedOnLoginEvent() {
    const localUserData = UserSession.getUserData() || {};

    const body = [
      {
        type: 'user_activity',
        occurred_at: new Date().toISOString().replace(/T|Z/gi, ' ').trim(),
        name: `${RESOURCE.ACCOUNT}|${ACTIONS.UPDATED}|${ACTIONS.NEWUUID}`,
        user_id: SignupService.getUserUuid(),
        product_id: window.product_id,
        resource: RESOURCE.ACCOUNT,
        action: ACTIONS.UPDATED,
        body: {
          fields: {
            pre_login_uuid: SignupService.getUserOldUuid(),
            post_login_uuid: SignupService.getUserUuid(),
          },
          meta: UserSession.getClientMetadata(),
          relations: {
            company: {
              id: OrganizationService.getOrgId(),
            },
            user: {
              id: localUserData.user_id || null,
            },
          },
        },
      },
    ];

    ApiActions.post({
      entity: 'RecordingAPI',
      method: 'post_events',
      data: body,
    });

    analytics.addUserProperties({
      user_id: SignupService.getUserUuid(),
      organization_id: OrganizationService.getOrgId(),
      ...(window.product_id
        ? { [`product_id#${window.product_id}`]: true }
        : null),
    });
  }

  /**
   * @description Test started event (when user starts a new flow)
   *
   * @param {TestData} testData - testData for test started event
   *
   * @typedef {{
   *     fields: Object,
   *     organization_id: Number,
   *     id: Number,
   *     product_id: Number
   * }} TestData
   */
  static testStartedEvent(testData) {
    const body = [
      {
        action: ACTIONS.STARTED,
        body: {
          fields: testData.fields,
          meta: UserSession.getClientMetadata(),
          relations: {
            company: {
              id: testData.organization_id,
            },
          },
        },
        name: `${RESOURCE.TEST}|${ACTIONS.STARTED}|${testData.id}`,
        occurred_at: new Date().toISOString().replace(/T|Z/gi, ' ').trim(),
        resource: RESOURCE.TEST,
        type: EVENTTYPE.USER_ACTIVITY,
        product_id: testData.product_id || window.product_id,
        user_id: SignupService.getUserUuid(),
      },
    ];
    if (!SignupService.getUserUuid()) {
      Sentry.captureMessage('Error: Sending null user uuid to RecordingAPI!');
    }
    if (!SignupService.getUserSessionId()) {
      Sentry.captureMessage('Error: Sending null session id to RecordingAPI!');
    }
    ApiActions.post({
      entity: 'RecordingAPI',
      method: 'post_events',
      data: body,
    });
  }

  /**
   * @description Test completed event (when user completes a flow)
   *
   * @param {Object} testData testData
   *
   * @typedef {{
   *     fields: Object,
   *     organization_id: Number,
   *     id: Number,
   *     product_id: Number
   * }} TestData
   */
  static testCompletedEvent(testData) {
    const localUserData = UserSession.getUserData() || {};
    const body = [
      {
        action: ACTIONS.CREATED,
        body: {
          fields: testData.fields,
          meta: UserSession.getClientMetadata(),
          relations: {
            user: {
              id: localUserData.user_id || null,
            },
          },
        },
        name: `${RESOURCE.TEST}|${ACTIONS.COMPLETED}|${testData.id}`,
        occurred_at: new Date().toISOString().replace(/T|Z/gi, ' ').trim(),
        resource: RESOURCE.TEST,
        type: EVENTTYPE.USER_ACTIVITY,
        product_id: testData.product_id || window.product_id,
        user_id: SignupService.getUserUuid(),
      },
    ];
    ApiActions.post({
      entity: 'RecordingAPI',
      method: 'post_events',
      data: body,
    });
  }

  static eventHandlerOnElementEvent(dataAttributes, flow_id, product_id) {
    const localUserData = UserSession.getUserData() || {};
    const body = [
      {
        action: ACTIONS.CLICKED,
        name: `${RESOURCE.BUTTON}|${ACTIONS.CLICKED}`,
        occurred_at: new Date().toISOString().replace(/T|Z/gi, ' ').trim(),
        user_id: SignupService.getUserUuid(),
        product_id: product_id || window.product_id,
        resource: 'button',
        type: EVENTTYPE.USER_ACTIVITY,
        body: {
          fields: {
            ...dataAttributes,
          },
          meta: UserSession.getClientMetadata(),
          relations: {
            user: {
              id: localUserData.user_id || null,
            },
            flow: {
              id: flow_id,
            },
          },
        },
      },
    ];
    ApiActions.post({
      entity: 'RecordingAPI',
      method: 'post_events',
      data: body,
    });
  }

  /**
   * @description Creates a screen viewed event. Indicates that a user loaded and viewed a page.
   *
   * @param {Flow} flow - The onboarding flow the user is in
   * @param {Number} stepNumber - The step index number of the view of the flow
   * @param {Number} pageNumber - the page index number of the view of the flow
   *
   * @typedef {{
   *     steps: Array,
   *     css: String,
   *     flow_id: Number,
   *     organization_id: Number,
   *     linked_to_platgen: Boolean,
   *     organization_name: String,
   *     slug: String
   * }} Flow
   */
  static screenViewedEvent(flow, stepNumber, pageNumber) {
    EventService._screenEvent(ACTIONS.VIEWED, flow, stepNumber, pageNumber);
  }

  /**
   * @description Creates a screen completed event. Indicates that a user completed filling out
   * a page of questions and attempted to move to the next page.
   *
   * @param {Flow} flow - The onboarding flow the user is in
   * @param {Number} stepNumber - The step index number of the view of the flow
   * @param {Number} pageNumber - the page index number of the view of the flow
   *
   * @typedef {{
   *     steps: Array,
   *     css: String,
   *     flow_id: Number,
   *     organization_id: Number,
   *     linked_to_platgen: Boolean,
   *     organization_name: String,
   *     slug: String
   * }} Flow
   */
  static screenCompletedEvent(flow, stepNumber, pageNumber) {
    EventService._screenEvent(ACTIONS.COMPLETED, flow, stepNumber, pageNumber);
  }

  /**
   * @description Helper method for emitting screen events.
   *
   * @param {String} actionType - The type of action being done on the screen, either viewed or completed
   * @param {Flow} flow - The onboarding flow the user is going through
   * @param {Number} stepNumber - The step index number of the view of the flow
   * @param {Number} pageNumber - the page index number of the view of the flow
   *
   * @typedef {{
   *     steps: Array,
   *     css: String,
   *     flow_id: Number,
   *     organization_id: Number,
   *     linked_to_platgen: Boolean,
   *     organization_name: String,
   *     slug: String
   * }} Flow
   */
  static _screenEvent(actionType, flow, stepNumber, pageNumber) {
    const localUserData = UserSession.getUserData() || {};

    // Add 1 to the step and page number to get the sequence number. The step and page numbers
    // are the indices in their respective arrays. The sequence number is the "order" in which
    // they come in the flow.
    const stepSequenceNumber = stepNumber + 1;
    const pageSequenceNumber = pageNumber + 1;
    const flowContentEntities = EventService._extractContentEntities(
      flow,
      stepNumber,
      pageNumber
    );

    const body = [
      {
        action: actionType,
        body: {
          fields: {
            step_sequence_number: stepSequenceNumber,
            page_sequence_number: pageSequenceNumber,
            content_entities: flowContentEntities,
          },
          relations: {
            user: {
              id: localUserData.user_id || null,
            },
            flow: {
              id: flow.flow_id,
            },
            step: {
              id: flow.steps[stepNumber].id,
            },
            company: {
              id: flow.organization_id || null,
              name: flow.organization_name || '',
            },
            vanity: {
              name: flow.slug || '',
            },
          },
          meta: UserSession.getClientMetadata(),
        },
        product_id: window.product_id,
        name: `${RESOURCE.SCREEN}|${actionType}`,
        occurred_at: new Date().toISOString().replace(/T|Z/gi, ' ').trim(),
        resource: RESOURCE.SCREEN,
        type: EVENTTYPE.UI,
        user_uuid: SignupService.getUserUuid(),
      },
    ];

    if (!SignupService.getUserUuid()) {
      Sentry.captureMessage('Error: Sending null user uuid to RecordingAPI!');
    }

    ApiActions.post({
      entity: 'RecordingAPI',
      method: 'post_events',
      data: body,
    });
  }

  /**
   * @description A helper function for extracting the content entities for a given
   *  step and page. Content entities include things like static-components, interactive
   *  components, and questions.
   *
   * @param {Flow} flow - flow that event is being created for
   * @param {Number} stepNumber - The step index number of the view of the flow
   * @param {Number} pageNumber - The page index number of the view of the flow
   *
   * @typedef {{
   *     steps: Array,
   *     css: String,
   *     flow_id: Number,
   *     organization_id: Number,
   *     linked_to_platgen: Boolean,
   *     organization_name: String,
   *     slug: String
   * }} Flow
   */
  static _extractContentEntities(flow, stepNumber, pageNumber) {
    const allPageEntities = flow.steps[stepNumber].pages[pageNumber].entity;
    let resultEntities = [];
    // There may be multiple pieces of content on a single page
    for (let index = 0; index < allPageEntities.length; index++) {
      const entityToAdd = {};
      const entity = allPageEntities[index];
      const entityContent = entity.content[0];
      const entityType = entity.entity_type;
      entityToAdd.entity_type = entityType;

      // Because content entities all store their properties a little differently,
      // we need to extract their identifying information based on their type
      switch (entityType) {
        case 'question': {
          entityToAdd.id = entity.id;
          entityToAdd.semantic_id = entity.semantic_id;
          entityToAdd.name = entity.question_name;
          break;
        }
        case 'static-component': {
          entityToAdd.id = entityContent.id;
          entityToAdd.semantic_id = entityContent.semantic_id;
          entityToAdd.name = entityContent.name;
          break;
        }
        case 'interactive-v2-component': {
          entityToAdd.id = entity.id;
          entityToAdd.semantic_id = entity.semantic_id;
          entityToAdd.name = entity.name;
          break;
        }
        case 'report-component': {
          entityToAdd.id = entityContent.report_id;
          break;
        }
        default: {
          try {
            entityToAdd.id = entity.id;
          } catch (e) {
            Sentry.captureMessage(
              `ERROR: Could not add 'id' for an entity in flow ${flow.flow_id}, step ${stepNumber}, and page ${pageNumber}. Please check that all entities have a valid entity-type.`
            );
          }
          break;
        }
      }

      // We only want to add the entity if it has a valid ID. Otherwise, we won't be
      // able to identify the content when querying data
      if ('id' in entityToAdd && 'entity_type' in entityToAdd) {
        resultEntities.push(entityToAdd);
      }
    }
    return resultEntities;
  }
}

export default EventService;
