import { css } from '@emotion/react';
import { SLEEPIO_LIGHT_BLUE } from '../../styles/colors';

export const textToDownloadStyle = () =>
  css({
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    backgroundColor: SLEEPIO_LIGHT_BLUE,
    justifyContent: 'center',
    marginTop: 10,
    alignItems: 'flex-start',
  });

export const textToDownloadCountryPhoneWrapperStyle = () =>
  css({
    display: 'flex',
    marginBottom: 0,
    flexWrap: 'wrap',
    justifyContent: 'center',
  });

export const textToDownloadSubmitStyle = () =>
  css({
    boxSizing: 'content-box',
    borderLeft: 'none',
    display: 'inline-block',
    marginLeft: -5,
    borderColor: '#ff8e75',
    minWidth: 110,
    marginTop: 7,
  });

export const textToDownloadSubmitSuccessStyle = () =>
  css({
    backgroundColor: '#008275',
    borderColor: '#ff8e75',
  });
