import React from 'react';
import Header from '../header/header.container';
import './style.scss';
import InputText from '../../components/input-text';
import InputButton from '../../components/input-button/input-button.component';
import { loginError, getUserInfo } from '../../actions/user';
import UserApiActions from '../../actions/user-api';
import { Validator } from '../../components/utils/validator';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as SignupService from '../../services/signup-service';
import FlowService from '../../services/flow-service';
import { redirectLoggedUser } from '../../components/utils/flow-utils';
import GaService from '../../services/ga-service';
import FooterContainer from '../footer';
import LoaderComponent from '../../components/loader';

class ResetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: null,
      retype: null,
      error: false,
      success: false,
      loading: false,
      initialized: false,
      passTooltip: false,
      product_slug: 'sleepio',
      userFlowRedirectURL: '',
      loginRedirectURL: '',
    };

    let product_slug = window.location.href.split('/');
    if (product_slug.length > 3 && product_slug[3]) {
      this.state.product_slug = product_slug[3];
    }
  }

  handleChangePassword() {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const token = url.searchParams.get('token');
    this.setState({ loading: true });
    if (!Validator.isValidPassword(this.state.password)) {
      this.setState({
        error: 'Please enter a valid password',
        loading: false,
        success: false,
      });
    } else if (this.state.password !== this.state.retype) {
      this.setState({
        error: 'Passwords do not match',
        loading: false,
        success: false,
      });
    } else {
      this.setState({ error: false });
      const data = { password: this.state.password, token: token };
      UserApiActions.post({
        action: 'RESETPASSWORD',
        redirectUri: null,
        data: data,
        callback: this.changePasswordCallback.bind(this),
      });
    }
  }

  componentDidMount() {
    this.props.getUserInfo({ cb: this.redirectPage.bind(this) });

    // If user tried to login and login failed, we notify login component
    this.verifyLoginError();
    // Record reset pass load page event
    GaService.resetPasswordLoadEvent();
  }

  redirectPage(userInfo) {
    const redirectLink = `${
      window.location.origin
    }${FlowService.getCurrentFlowURL()}`;
    if (userInfo.is_authenticated) {
      if (redirectLink) {
        return (window.location.pathname = redirectLink);
      }

      SignupService.removeSignupRedirect();
      redirectLoggedUser(userInfo.id);
    } else {
      this.setState({
        initialized: true,
        userFlowRedirectURL: redirectLink,
        loginRedirectURL: `${window.location.origin}/login-component/login?product_stream_id${window.product_stream_id}&next=${redirectLink}`,
      });
    }
  }

  verifyLoginError() {
    const urlParams = new URLSearchParams(this.props.location.search);
    if (urlParams.get('login')) {
      // Login failed
      this.props.loginError(urlParams.get('error'));
      this.props.history.replace('?', null);
    }
  }

  changePasswordCallback(err, res) {
    if (res && res.status === 'error') {
      GaService.resetPasswordUnsuccessful();
      this.setState({ error: res.message, loading: false, success: false });
    } else if (res) {
      GaService.resetPasswordSuccessful();
      this.setState({ success: true, loading: false, error: null });
    }
    if (err) {
      this.setState({ error: err, loading: false, success: false });
    }
  }

  onInputValueChange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  render() {
    if (!this.state.initialized) {
      return <LoaderComponent />;
    }
    return (
      <div>
        <Header displayLogin={true} onRef={ref => (this.header = ref)} />
        <div className="sl-forgot-password">
          <div className="sl-forgot-password--top-content">
            <h1>Set your new password</h1>
            <h2>
              Please enter a new password below to access your Sleepio account.
            </h2>
          </div>
          <div className="sl-forgot-password--content">
            <div className="sl-vertical-align">
              <p>New Password</p>
              <InputText
                onChange={this.onInputValueChange.bind(this, 'password')}
                placeholder={'New password'}
                type="password"
                error={this.state.error}
                value={this.state.password}
              />
            </div>
            <div className="sl-vertical-align">
              <p>Retype Password</p>
              <InputText
                onChange={this.onInputValueChange.bind(this, 'retype')}
                placeholder={'Re-type password'}
                type="password"
                error={this.state.error}
                value={this.state.retype}
              />
              <span
                className={`${
                  this.state.loading ? 'show' : ''
                } sl-forgot-password--loader`}
              />
              <InputButton
                value="Save password"
                onClick={this.handleChangePassword.bind(this)}
              />
            </div>

            {this.state.error ? (
              <div className="sl-forgot-password--error">
                {this.state.error.indexOf('Token expired') > -1 ? (
                  <span>
                    This link is now invalid. Please{' '}
                    <a
                      href={this.state.loginRedirectURL.replace(
                        'login?',
                        'forgot?'
                      )}
                    >
                      request a new link
                    </a>{' '}
                    to reset your password.
                  </span>
                ) : (
                  this.state.error
                )}
              </div>
            ) : null}

            {this.state.success ? (
              <div className="sl-forgot-password--success">
                Password changed! Click{' '}
                <a href={this.state.loginRedirectURL}>here</a> to log in.
              </div>
            ) : null}

            <div className="sl-vertical-align">
              <p>Your new password must</p>
              <ul>
                <li>Be at least 8 characters long</li>
                <li>
                  Contain at least three of the following: an uppercase letter,
                  a lowercase letter, a symbol and a number
                  <span
                    className={`sl-password-tooltip ${
                      this.state.passTooltip ? 'visible' : ''
                    }`}
                    onClick={() =>
                      this.setState({ passTooltip: !this.state.passTooltip })
                    }
                  >
                    <span className="sl-password-tooltip-text">
                      Accepted symbols are:
                      !&#34;#$%&amp;&#39;()*+,-.\/:;&lt;=&gt;?@[]^_`&#123;|&#125;~]
                    </span>
                  </span>
                </li>
              </ul>
            </div>
            <p>
              Any problems? Email us at{' '}
              <a href="mailto:hello@sleepio.com">hello@sleepio.com</a>
            </p>
          </div>
        </div>
        <FooterContainer />
      </div>
    );
  }
}

const mapStateToProps = state => ({ user: state.userReducer });

ResetPasswordContainer.propTypes = {
  loginError: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
const mapDispatchToProps = dispatch => ({
  loginError: bindActionCreators(loginError, dispatch),
  getUserInfo: bindActionCreators(getUserInfo, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordContainer)
);
