import { css } from '@emotion/react';
import { BHTheme } from '../../types/bh-themes.types';

export const defaultPageStyles = (theme: BHTheme) =>
  css({
    color: theme.fonts.fontColors.PRIMARY,
    textAlign: 'center',
    lineHeight: 1.2,
    a: theme.links.defaultStyle,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    fontSize: theme.fonts.fontSizes.MEDIUM,
    // footer
    '> :last-child': {
      marginTop: 'auto',
    },
  });
export const main = (theme: BHTheme) =>
  css({
    padding: `0 ${theme.padding.LARGE}`,
    display: 'flex',
    flexDirection: 'column',
  });
export const image = () =>
  css({
    marginTop: 68,
    height: 189,
    width: 189,
    alignSelf: 'center',
  });
export const title = (theme: BHTheme) =>
  css({
    marginTop: 34,
    fontSize: theme.fonts.fontSizes.TITLE,
  });
export const body = (theme: BHTheme) =>
  css({
    marginTop: theme.padding.MEDIUM,
    fontSize: theme.fonts.fontSizes.STANDARD,
  });
export const checkboxWrapper = (theme: BHTheme) =>
  css({
    marginTop: 52,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.fonts.fontSizes.SMALL,
  });
export const checkboxLabel = (theme: BHTheme) =>
  css({
    padding: '8px 0 8px 8px',
    fontSize: theme.fonts.fontSizes.SMALL,
    a: theme.links.defaultStyle,
    textAlign: 'left',
  });
export const button = (theme: BHTheme) =>
  css({
    marginTop: 52,
    fontSize: theme.fonts.fontSizes.STANDARD,
  });
export const errorMessage = (theme: BHTheme) =>
  css({
    marginTop: 44,
    fontSize: theme.fonts.fontSizes.SMALL,
    color: theme.errors.color,
    padding: theme.padding.SMALL,
    backgroundColor: theme.errors.backgroundColor,
    borderRadius: 4,
    border: 'solid',
    borderWidth: 1,
    alignSelf: 'center',
    display: 'flex',
    maxWidth: 400,
  });
export const contact = (theme: BHTheme) =>
  css({
    marginTop: 68,
    fontWeight: theme.fonts.fontWeights.MEDIUM,
    fontSize: theme.fonts.fontSizes.SMALL,
    a: theme.links.defaultStyle,
  });
