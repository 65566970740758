import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import {
  initializeFlow,
  loadProgress,
  redirectUserToAppropriateFlow,
  placeUserInFlowFromAnswers,
} from '../../actions/flow';
import {
  loginError,
  getUserInfo,
  urlDetails,
  tempUserData,
} from '../../actions/user';
import FlowService from '../../services/flow-service';
import UserSession from '../../services/user-session';
import GaService from '../../services/ga-service';
import { useAppSelector } from '../../hooks/use-app-react-redux';
import Selectors from '../../selectors';
import Loader from '../../components/loader';
import Page from '../page';

const IDP_NAMES = ['test', 'evive', 'rally'];

type MatchParams = {
  vanity: string;
  product: string;
  org: string;
  id: string;
};

const FlowContainer = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [areStylesInjected, setAreStylesInjected] = useState(false);

  const match = useRouteMatch<MatchParams>();
  const location = useLocation();
  const { search } = location;

  const initialized = useAppSelector(Selectors.selectFlowReducerInitialized);
  const css = useAppSelector(Selectors.selectFlowReducerCurrentFlowCss);

  const verifyUrlDetails = useCallback(() => {
    const urlParams = new URLSearchParams(search);
    const details = urlParams.get('details');
    if (details) {
      dispatch(urlDetails(details));
    }
  }, [search, dispatch]);

  const verifyTempUserData = useCallback(() => {
    if (
      window.temporary_user_data &&
      Object.keys(JSON.parse(window.temporary_user_data)).length
    ) {
      UserSession.setTempUserData(window.temporary_user_data);
    }
    const userData = UserSession.getUserData();
    if (userData?.id) {
      window.temporary_user_data = '{}';
    }

    const temp_user_data = UserSession.getTempUserData();
    if (temp_user_data) {
      dispatch(tempUserData(temp_user_data));
    }
  }, [dispatch]);

  const verifyLoginError = useCallback(() => {
    const urlParams = new URLSearchParams(search);

    if (urlParams.get('login')) {
      // Login failed
      const error = urlParams.get('error');
      Sentry.captureException(
        `Error logging in user: ${error} at location ${location.pathname}`
      );
      dispatch(loginError(error));
      // Record GA login failed event
      GaService.loginFailed(error);
    }
  }, [search, location, dispatch]);

  /**
   * Load progress for user
  It seems to be a precondition of this function that the user has just
  logged in. Note: To be verified.
  */
  const initializeProgress = useCallback(
    userInfo => {
      if (userInfo && userInfo.is_authenticated) {
        setIsLoading(true);

        dispatch(placeUserInFlowFromAnswers(userInfo.id));

        const urlParams = new URLSearchParams(search);
        if (
          urlParams.get('login') === 'true' ||
          urlParams.get('login_instead_signup') === 'true'
        ) {
          UserSession.removeTempUserData();
          // Record GA login event
          GaService.login(userInfo);
        } else {
          // User is logged
          dispatch(loadProgress());
        }
      } else {
        // User is not logged in
        const tempData = UserSession.getTempUserData();
        if (
          tempData &&
          Object.keys(tempData).length &&
          IDP_NAMES.indexOf(tempData.idp_name) === -1
        ) {
          dispatch(loadProgress(true)); // this result of this bool was set in redux as redirectAfterSignup, which was never used? should we keep the distinction here?
        } else {
          dispatch(loadProgress());
        }
      }

      setIsLoading(false); // might need to make a CB in redux action
    },
    [search, dispatch]
  );

  const checkOnboardingPlatformAfterLogin = useCallback(
    userInfo => {
      // This function is called on every refresh, so we need to be certain
      // that this user did actually log in. If this value we read from the
      // flow service is "null", that indicates this user did not do a login.
      const loginPlatgenFlag = FlowService.getPlatgenFlagFromLogin();

      // If the user did not do a login, they are not on the wrong OnboardingPlatform.
      const wrongOnboardingPlatform =
        loginPlatgenFlag != null &&
        loginPlatgenFlag !== FlowService.getPlatgenFlag();
      wrongOnboardingPlatform
        ? dispatch(redirectUserToAppropriateFlow(userInfo.id))
        : initializeProgress(userInfo);
    },
    [initializeProgress, dispatch]
  );

  // componentDidMount
  useEffect(() => {
    const { vanity, product, org, id } = match.params;

    // TODO (Alex): this should go through dispatch, like verifyLoginError() and verifyUrlDetails() below
    // If it's a redirect from FitBit Login save current path
    const urlParams = new URLSearchParams(window.location.search); // useLocation?
    const fitBitData = {
      fitBitCode: urlParams.get('code') || '',
      location: localStorage['current-path'] || '',
    };

    // If user tried to login and login failed, we notify login component
    verifyLoginError();
    verifyUrlDetails();
    verifyTempUserData();

    const getUserInfoCallback = () => {
      dispatch(
        getUserInfo({
          cb: checkOnboardingPlatformAfterLogin,
        })
      );
    };

    // Initialize user progress in flow
    // BTC note: this ia thunk that calls a thunk that calls a thunk. All data in those thunks can come from redux.
    if (vanity || product || (fitBitData.fitBitCode && fitBitData.location)) {
      // TODO (Alex): fitbit data should not be passed here when initializing the flow. Instead, it should go through reducer
      dispatch(
        initializeFlow({
          vanity,
          product,
          org,
          id,
          fitBitData,
          cb: getUserInfoCallback,
        })
      );
      return;
    }

    // not having any of these is an error state, redirect to error page
    console.error('Missing necessary information: ', {
      vanity,
      product,
      fitBitData,
    });
    window.location.href = `${product}/error`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only runs once, componentDidMount replacement, do not add deps

  const isInLoadingState = !initialized || isLoading;

  if (!isInLoadingState && !areStylesInjected && css) {
    setAreStylesInjected(true);
    injectStyle(css);
  }

  // still loading, no page data to render yet
  if (isInLoadingState) {
    // @TODO: ENR-2491 theme this
    return <Loader />;
  }

  // handle page container (ie navigation functions)
  return <Page />;
};

/**
 * Injects custom styles in the document head
 */
function injectStyle(css: string) {
  const head = document.head || document.getElementsByTagName('head')[0];
  const style = document.createElement('style');
  style.type = 'text/css';
  style.appendChild(document.createTextNode(css));
  head.appendChild(style);
}

export default React.memo(FlowContainer);
