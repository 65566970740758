import { FunctionComponent } from 'react';
import { shouldShowSignupWithExistingUser } from '../interactive-component-v2.helper';
import { InteractiveComponentItem } from '../interactive-component-v2.types';
import { MultiAccountSignIn } from '../../multi-account-sign-in/multi-account-sign-in.component';
import {
  defaultInteractiveStyles,
  interactiveErrorStyle,
  interactiveFormWrapperStyle,
  interactiveInputWrapperStyle,
  interactiveSubTitleStyle,
  interactiveTitleStyle,
  interactiveWrapperStyle,
} from './interactive-content.styles';
import ErrorMessageComponent from '../../errors/error-message/error-message.component';

export type InteractiveContentProps = {
  item: InteractiveComponentItem;
  name?: string;
  headerText?: string;
  dataCvsStyle?: boolean;
  inputComponents: React.ReactElement[];
  fallbackAlerts: { message: string }[];
  componentAlerts: React.ReactElement[];
  componentError: { error?: string };
  errorFields: any;
  onShowExistingSignupClick: () => void;
};

type InteractiveErrorProps = {
  error: string;
  shouldShowSignupWithExistingUser: boolean;
  onShowExistingSignupClick: () => void;
};

const InteractiveError: FunctionComponent<InteractiveErrorProps> = ({
  error,
  shouldShowSignupWithExistingUser,
  onShowExistingSignupClick,
}) => {
  return (
    <div css={interactiveErrorStyle}>
      <ErrorMessageComponent errorMessage={`${error} `} />
      {shouldShowSignupWithExistingUser && (
        <span>
          If you already have a Sleepio or Daylight account,{' '}
          <a
            css={interactiveErrorStyle}
            data-testid="username-error-login"
            onClick={onShowExistingSignupClick}
          >
            log in here.
          </a>
        </span>
      )}
    </div>
  );
};

export const ThemedInteractiveContent: FunctionComponent<
  InteractiveContentProps
> = ({
  fallbackAlerts,
  name,
  headerText,
  inputComponents,
  componentAlerts,
  componentError,
  onShowExistingSignupClick,
  item,
}) => {
  const fallbackAlertTexts = fallbackAlerts.map(
    (alert: { message: string }, index: number) => {
      return (
        <ErrorMessageComponent
          errorMessage={alert.message}
          key={`${alert.message}-${index}`}
        />
      );
    }
  );
  return (
    <div css={interactiveWrapperStyle} data-testid={'interactive-component'}>
      <h1 css={interactiveTitleStyle}>{name}</h1>
      {headerText && (
        <div
          css={interactiveSubTitleStyle}
          dangerouslySetInnerHTML={{ __html: headerText || '' }}
        />
      )}
      <form css={interactiveFormWrapperStyle} noValidate>
        <div css={defaultInteractiveStyles}>
          <MultiAccountSignIn item={item} />
        </div>
        {inputComponents.map(inputComponent => (
          <div css={interactiveInputWrapperStyle} key={inputComponent.key}>
            {inputComponent}
          </div>
        ))}
        <div css={defaultInteractiveStyles}>
          {!!fallbackAlertTexts.length && fallbackAlertTexts}
          {!componentAlerts.length && componentError.error && (
            <InteractiveError
              error={componentError.error}
              shouldShowSignupWithExistingUser={shouldShowSignupWithExistingUser(
                item.action[0]
              )}
              onShowExistingSignupClick={onShowExistingSignupClick}
            />
          )}
        </div>
      </form>
    </div>
  );
};
