import { Theme } from '@emotion/react';
import { css } from '@emotion/react';

export const wrapperStyle = () =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  });

export const titleStyle = (theme: Theme) =>
  css({
    marginBottom: theme.padding.EXTRA_LARGE,
  });

export const radioAnswerStyle = (theme: Theme) =>
  css({
    display: 'block',
    border: theme.borders.defaultBorder,
    borderRadius: theme.borders.defaultBorderRadius,
    backgroundColor: theme.inputs.radio.backgroundColor,
    color: theme.inputs.radio.color,
    padding: theme.padding.LARGE,
    cursor: 'pointer',

    ['&:hover, *:hover']: {
      cursor: 'pointer',
    },

    '&.selected': {
      border: theme.borders.defaultActiveBorder,
      padding: `calc(${theme.padding.LARGE} - 1px)`,
    },

    ['span']: {
      verticalAlign: 'middle',
    },

    ["input[type='radio']"]: {
      appearance: 'none',
      backgroundColor: theme.inputs.radio.backgroundColor,
      margin: '0',
      width: '20px',
      height: '20px',
      border: `2px solid ${theme.inputs.radio.color}`,
      borderRadius: '50%',
      display: 'inline-grid',
      placeContent: 'center',
      marginRight: '15px',
    },

    ["input[type='radio']::before"]: {
      content: '""',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      transform: 'scale(0)',
      border: `1px solid ${theme.inputs.radio.color}`,
      backgroundColor: `${theme.inputs.radio.color}`,
    },

    ["input[type='radio']:checked::before"]: {
      transform: 'scale(1)',
    },
  });

export const labelTextStyle = (theme: Theme) =>
  css({
    fontWeight: theme.fonts.fontWeights.MEDIUM,
  });
