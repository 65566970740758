import { FunctionComponent, useCallback } from 'react';
import CheckboxInput from '../checkbox/themed-checkbox.component';
import ErrorMessage from '../errors/error-message/error-message.component';
import InteractiveInputLabel from '../interactive-component-v2/inputs/interactive-input/themed.interactive-input-label.component';
import {
  errorStyles,
  inputCheckboxStyles,
  inputCheckboxStylesHidden,
} from './themed.input-checkbox.style';

export type InputCheckboxProps = {
  inputId: string;
  isChecked: boolean;
  // Note: Not actually an event handler, but a custom function made to look like one
  onChange: (event?: { target: { value: boolean } }) => void;
  labelText: string;
  id?: string;
  name?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  hiddenCheckbox?: boolean;
};

export const InputCheckbox: FunctionComponent<InputCheckboxProps> = ({
  id,
  name,
  isChecked,
  onChange,
  labelText,
  disabled,
  required,
  error,
  errorText,
  inputId,
  className,
  hiddenCheckbox,
}) => {
  const hasError = error && !!errorText;

  const onChangeFn = useCallback(() => {
    onChange({ target: { value: !isChecked } });
  }, [isChecked, onChange]);

  return (
    <div css={hiddenCheckbox ? inputCheckboxStylesHidden : inputCheckboxStyles}>
      <CheckboxInput
        id={id || inputId}
        name={name || inputId}
        isChecked={isChecked}
        onChange={onChangeFn}
        className={className}
        required={required}
        disabled={disabled}
        error={hasError}
      />
      <InteractiveInputLabel
        text={labelText}
        mode={'field-label'}
        id={id || inputId}
      />
      <div style={{ flexBasis: '100%', height: '0' }}></div>
      {hasError && <ErrorMessage errorMessage={errorText} css={errorStyles} />}
    </div>
  );
};

export default InputCheckbox;
