import * as GA from '../constants/ga';
import ReactGA from 'react-ga';
import getConfig from '../config';

export default class GaService {
  /**
   * Initialize GA global object
   */
  static initialize() {
    const gaTrackingId = getConfig().gaTrackingId;
    const gaOptions = getConfig().gaTrackingDebug
      ? {
          cookieDomain: 'auto',
          debug: true,
          testMode: true,
        }
      : null;
    ReactGA.initialize(gaTrackingId, gaOptions);
  }

  /**
   * Record page view for specified url
   * @param {String} url the url to be recorded
   */
  static pageView(url) {
    ReactGA.pageview(url);
  }

  /**
   * Record start flow event
   * @param {String} flow a unique identifier for flow
   */
  static startFlow(flow) {
    const event = {
      category: GA.CATEGORIES.FLOW.replace(GA.TOKENS.FLOW_FUID, flow),
      action: GA.ACTIONS.STARTED,
    };
    ReactGA.event(event);
  }

  /**
   * Record complete flow event
   * @param {String} flow a unique identifier for flow
   */
  static completeFlow(flow) {
    const event = {
      category: GA.CATEGORIES.FLOW.replace(GA.TOKENS.FLOW_FUID, flow),
      action: GA.ACTIONS.COMPLETED,
    };
    ReactGA.event(event);
  }

  /**
   * Record start step event
   * @param {String} step a unique identifier for the step
   */
  static startStep(step) {
    const event = {
      category: GA.CATEGORIES.STEP.replace(GA.TOKENS.STEP_SUID, step),
      action: GA.ACTIONS.STARTED,
    };
    ReactGA.event(event);
  }

  /**
   * Record complete step event
   * @param {String} step a unique identifier for the step
   */
  static completeStep(step) {
    const event = {
      category: GA.CATEGORIES.STEP.replace(GA.TOKENS.STEP_SUID, step),
      action: GA.ACTIONS.COMPLETED,
    };
    ReactGA.event(event);
  }

  /**
   * Record load page event
   * @param {String|Number} step a unique identifier for the step
   * @param {String} page a unique identifier for the page
   */
  static loadPage(step, page) {
    const event = {
      category: GA.CATEGORIES.PAGE.replace(GA.TOKENS.STEP_SUID, step).replace(
        GA.TOKENS.PAGE_PUID,
        page
      ),
      action: GA.ACTIONS.LOADED,
    };
    ReactGA.event(event);
  }

  /**
   * Record complete page event
   * @param {String} step a unique identifier for the step
   * @param {String} page a unique identifier for the page
   */
  static completePage(step, page) {
    const event = {
      category: GA.CATEGORIES.PAGE.replace(GA.TOKENS.STEP_SUID, step).replace(
        GA.TOKENS.PAGE_PUID,
        page
      ),
      action: GA.ACTIONS.COMPLETED,
    };
    ReactGA.event(event);
  }

  /**
   * Record load question event
   * @param {String} step a unique identifier for the step
   * @param {String} page a unique identifier for the page
   * @param {String} question a unique identifier for the question
   */
  static loadQuestion(step, page, question) {
    const event = {
      category: GA.CATEGORIES.PAGE.replace(GA.TOKENS.STEP_SUID, step).replace(
        GA.TOKENS.PAGE_PUID,
        page
      ),
      action: GA.ACTIONS.LOADED_QUESTION.replace(GA.TOKENS.TITLE, question),
    };
    ReactGA.event(event);
  }

  /**
   * Record answer question event
   * @param {String} step a unique identifier for the step
   * @param {String} page a unique identifier for the page
   * @param {String} question a unique identifier for the question
   * @param {String} value the answer
   */
  static answerQuestion(step, page, question, value) {
    const event = {
      category: GA.CATEGORIES.PAGE.replace(GA.TOKENS.STEP_SUID, step).replace(
        GA.TOKENS.PAGE_PUID,
        page
      ),
      action: GA.ACTIONS.ANSWER_QUESTION.replace(
        GA.TOKENS.TITLE,
        question
      ).replace(GA.TOKENS.VALUE, value),
    };
    ReactGA.event(event);
  }

  static _getSignupAction(idp) {
    switch (idp) {
      case 'google':
        return GA.ACTIONS.GOOGLE_SIGNUP_COMPLETED;
      case 'facebook':
        return GA.ACTIONS.FACEBOOK_SIGNUP_COMPLETED;
      case 'virgin_pulse':
        return GA.ACTIONS.VIRGINPULSE_SIGNUP_COMPLETED;
      default:
        return GA.ACTIONS.FORM_SIGNUP_COMPLETED;
    }
  }

  static _getLoginAction(idp) {
    switch (idp) {
      case 'google':
        return GA.ACTIONS.GOOGLE_LOGIN_SUCCESSFUL;
      case 'facebook':
        return GA.ACTIONS.FACEBOOK_LOGIN_SUCCESSFUL;
      case 'virgin_pulse':
        return GA.ACTIONS.VIRGINPULSE_LOGIN_SUCCESSFUL;
      default:
        return GA.ACTIONS.FORM_LOGIN_SUCCESSFUL;
    }
  }

  /**
   * Record form signup started event
   * @param {Object} userInfo user info
   */
  static signUp(userInfo) {
    const { idp_name } = userInfo;
    const action = this._getSignupAction(idp_name);
    ReactGA.event({
      category: GA.CATEGORIES.SIGNUP,
      action: action,
    });
  }

  /**
   * Record successful login event
   * @param {Object} userInfo user info
   */
  static login(userInfo) {
    const { idp_name } = userInfo;
    const action = this._getLoginAction(idp_name);
    ReactGA.event({
      category: GA.CATEGORIES.LOGIN,
      action: action,
    });
  }

  /**
   * Record failed login/signup event
   * @param {String | null} error error message
   */
  static loginFailed(error) {
    let action = GA.ACTIONS.LOGIN_FAILED;
    let category = GA.CATEGORIES.LOGIN;
    if (error && error.indexOf('account') > -1) {
      action = GA.ACTIONS.SIGNUP_FAILED;
      category = GA.CATEGORIES.SIGNUP;
    }
    ReactGA.event({ category, action });
  }

  /**
   * Record signup with google started event
   */
  static signUpGoogleStarted() {
    const event = {
      category: GA.CATEGORIES.SIGNUP,
      action: GA.ACTIONS.GOOGLE_SIGNUP_STARTED,
    };
    ReactGA.event(event);
  }

  /**
   * Record signup with google started event
   */
  static signUpGoogleCompleted() {
    const event = {
      category: GA.CATEGORIES.SIGNUP,
      action: GA.ACTIONS.GOOGLE_SIGNUP_COMPLETED,
    };
    ReactGA.event(event);
  }

  /**
   * Record signup with facebook started event
   */
  static signUpFacebookStarted() {
    const event = {
      category: GA.CATEGORIES.SIGNUP,
      action: GA.ACTIONS.FACEBOOK_SIGNUP_COMPLETED,
    };
    ReactGA.event(event);
  }

  /**
   * Record signup with facebook started event
   */
  static signUpFacebookCompleted() {
    const event = {
      category: GA.CATEGORIES.SIGNUP,
      action: GA.ACTIONS.FACEBOOK_SIGNUP_COMPLETED,
    };
    ReactGA.event(event);
  }

  /**
   * Record reset password load page event
   */
  static resetPasswordLoadEvent() {
    const event = {
      category: GA.CATEGORIES.PASSWORD,
      action: GA.ACTIONS.RESET_PASSWORD_PAGE_LOADED,
    };
    ReactGA.event(event);
  }

  /**
   * Record forgot password load page event
   */
  static forgotPasswordLoadEvent() {
    const event = {
      category: GA.CATEGORIES.PASSWORD,
      action: GA.ACTIONS.FORGOT_PASSWORD_PAGE_LOADED,
    };
    ReactGA.event(event);
  }

  /**
   * Record reset password successful  event
   */
  static resetPasswordSuccessful() {
    const event = {
      category: GA.CATEGORIES.PASSWORD,
      action: GA.ACTIONS.RESET_PASSWORD_SUCCESSFUL,
    };
    ReactGA.event(event);
  }

  /**
   * Record reset password unsuccessful  event
   */
  static resetPasswordUnsuccessful() {
    const event = {
      category: GA.CATEGORIES.PASSWORD,
      action: GA.ACTIONS.RESET_PASSWORD_UNSUCCESSFUL,
    };
    ReactGA.event(event);
  }

  /**
   * Record forgot password successful  event
   */
  static forgotPasswordSuccessful() {
    const event = {
      category: GA.CATEGORIES.PASSWORD,
      action: GA.ACTIONS.FORGOT_PASSWORD_SUCCESSFUL,
    };
    ReactGA.event(event);
  }

  /**
   * Record forgot password unsuccessful  event
   */
  static forgotPasswordUnsuccessful() {
    const event = {
      category: GA.CATEGORIES.PASSWORD,
      action: GA.ACTIONS.FORGOT_PASSWORD_UNSUCCESSFUL,
    };
    ReactGA.event(event);
  }
}
