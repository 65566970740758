import moment, { Moment } from 'moment';

//TODO: Manage handling error paths as part of ENR-996 (refactoring to different date library)

export function formatDate(
  day: string | number,
  month: string | number,
  year: string | number,
  pretty = false
): string | Moment {
  const formattedYear = year;
  const formattedMonth = moment().month(month).format('MM');
  const formattedDay = moment()
    .year(year as number)
    .month(month)
    .date(day as number)
    .format('DD');
  const date = moment(`${formattedYear}-${formattedMonth}-${formattedDay}`);
  return pretty ? date.format('LLLL') : date;
}

export function isUserLegalAge(date: string | Moment): boolean {
  const age = moment().diff(date, 'years');
  const isLegalAge = age >= 18;
  return isLegalAge;
}

export function isValidDate(
  day: string | number,
  month: string | number,
  year: string | number
): boolean {
  //TODO: Refactor isValid() check and Feb edge case once Moment is removed
  const formattedDate = formatDate(day, month, year);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (formattedDate.isValid()) {
    //Handle leap day (February 29th) edge case
    if (
      year &&
      (month === 'February' || month === 'Feb') &&
      !moment([year]).isLeapYear() &&
      day >= 29
    ) {
      return false;
    }

    return isUserLegalAge(formattedDate);
  }
  return false;
}

export function generateDayArray(month: string): number[] {
  let daysInMonth = moment().month(month).daysInMonth();

  // Always show 29 days for February
  if (month === 'February' || month === 'Feb') {
    daysInMonth = 29;
  }

  return Array.from({ length: daysInMonth }, (_v, current) => current + 1);
}
