let instance = null;

class GaService {
  constructor() {
    instance = instance || this;
    this.initialized = false;
    const gaTrackingId =
      window.ga_id ||
      window.localStorage.getItem('sl-new-authentication-ga-id');
    this.script = `
            (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

            ga('create', '${gaTrackingId}', 'auto');`;

    this.loginActions = {
      google: 'Google Login Successful',
      facebook: 'Facebook Login Successful',
      virgin_pulse: 'Virgin-Pulse Login Successful',
      email: 'Form Login Successful',
    };

    return instance;
  }

  initialize() {
    return new Promise(resolve => {
      if (!this.initialized) {
        const elem = document.createElement('script');
        elem.addEventListener(
          'load',
          e => {
            resolve();
            this.initialized = true;
          },
          false
        );
        elem.innerHTML = this.script;
        document.body.appendChild(elem);

        // Different browsers have different strategies to load as fast as possible,
        // so exact load sequences may differ between them.
        // might be able to detect whether the load has already happened by checking for window.ga function
        if (typeof window.ga === 'function') {
          resolve();
          this.initialized = true;
        }
      } else {
        resolve();
      }
    });
  }

  pageView(url) {
    window.ga('send', 'pageview', url);
  }

  login(idp_name) {
    window.ga('send', 'event', 'Login', this.loginActions[idp_name]);
  }

  loginFailed(idp_name) {
    window.ga('send', 'event', 'Login', 'Login Failed');
  }

  forgotPasswordLoadEvent() {
    window.ga('send', 'event', 'Password', 'Forgot Password Page Loaded');
  }

  forgotPasswordSuccessful() {
    window.ga('send', 'event', 'Password', 'Forgot password successful');
  }

  forgotPasswordUnsuccessful() {
    window.ga('send', 'event', 'Password', 'Forgot password unsuccessful');
  }
}

export default new GaService();
