import generateId from '../utils/generate-id';
import InputSelect from '../input-select/themed.input-select.component';
import { RadioInput } from '../input-radio/themed.radio.component';
import { QuestionProps } from './question.types';
import { withQuestionLogic } from './index';
import { PostAnswerText, QuestionTitleText } from './themed.question.component';
import { QuestionTypes } from '../../constants/question-types';
import {
  questionWrapperStyle,
  questionWrapperStyleWithoutMargin,
} from './question.styles';
import { logError } from '../../analytics/logger/logger';
import MultiselectComponent from '../input-multiselect/multiselect.component';
import { useHistory } from 'react-router-dom';
import { Fragment, useCallback, useMemo } from 'react';

// @TODO: Remove the need for the mapped question component it doesn't do a whole lot different from the question component
// A mapped question component extends the question component in order to support the same radio buttons, dropdowns, etc
// with the difference being that answers in the mapped question component are not simply values, but are made of
// question-group-components which in turn are made of questions.
export const MappedQuestion = ({
  isChecked,
  item: question,
  selectedAnswers,
  handleInputSelect,
  handleChoiceAnswer,
  handleRadioSelect,
  onAnswer,
  actions,
}: QuestionProps) => {
  const history = useHistory();

  const details = question.content[0];

  const dynamicId = generateId();

  const definedSelectedAnswers =
    selectedAnswers === null || selectedAnswers === undefined
      ? []
      : selectedAnswers;

  const arrayOfAnswers =
    definedSelectedAnswers && Array.isArray(definedSelectedAnswers)
      ? definedSelectedAnswers
      : [definedSelectedAnswers];

  let questionContent = null;

  const _handleInputSelect = useCallback(
    () => handleInputSelect(details, ''),
    [handleInputSelect, details]
  );

  const questionTitle = useMemo(
    () => ({
      title: details.title,
      pre_question_text: details.pre_question_text,
      post_question_text: details.post_question_text,
    }),
    [details]
  );

  let hasInput = true;
  let hasInternalTitle = false;
  switch (question.type) {
    case QuestionTypes.SELECT:
    case QuestionTypes.ONELINE_SELECT:
      hasInput = false;
      questionContent = (
        <InputSelect
          id={details.question_id}
          onChange={_handleInputSelect}
          isChecked={isChecked}
          inputId={dynamicId}
          isMapped={true}
          onAnswer={onAnswer}
          actions={actions}
          answers={details.answers}
          value={definedSelectedAnswers}
          hasError={false} //yup, okay
        />
      );
      break;
    case QuestionTypes.MULTI:
      hasInput = false;
      questionContent = (
        <MultiselectComponent
          isMapped={true}
          inputId={question.semantic_id}
          isValidationChecked={isChecked}
          validation={question.validation}
          questionContent={details}
          onAnswer={onAnswer}
          actions={actions}
          selectedAnswers={arrayOfAnswers}
          onChange={handleChoiceAnswer}
        />
      );
      break;
    case QuestionTypes.RADIO:
    case QuestionTypes.ONELINE_RADIO:
    case QuestionTypes.SINGLE:
    case QuestionTypes.CARD:
      hasInternalTitle = true;
      questionContent = (
        <RadioInput
          isChecked={isChecked}
          items={details.answers}
          questionTitle={questionTitle}
          inputId={question.semantic_id}
          key={`${details.question_id}-${definedSelectedAnswers}`}
          id={details.question_id}
          isMapped={true}
          selectedAnswer={definedSelectedAnswers} // might need to convert this to full answers from details.answers if this is just an ID
          onAnswer={onAnswer}
          actions={actions}
          belongsTo={`${details.question_id}-${details.id}-${details.lang}`}
          handleCheck={() => handleRadioSelect(details, '')}
        />
      );
      break;
    default:
      logError(`Question of type ${question.type} not found`);
      history.push('/error');
  }

  return (
    <div>
      {hasInternalTitle ? (
        /* Question Content (contains Pre Content) */
        <div css={questionWrapperStyleWithoutMargin}>{questionContent}</div>
      ) : (
        <Fragment>
          {/* Pre Content */}
          <QuestionTitleText
            details={details}
            hasInput={hasInput}
            htmlFor={question.semantic_id}
          />
          {/* Question Content*/}
          <div css={questionWrapperStyle}>{questionContent}</div>
        </Fragment>
      )}
      <PostAnswerText
        text={details.post_answer_text}
        data-testid="post-answer-text"
      />
    </div>
  );
};

export default withQuestionLogic(MappedQuestion);
