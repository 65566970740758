import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  shallowEqual,
} from 'react-redux';
import type { RootState, AppDispatch } from '../store/store';

// https://react-redux.js.org/using-react-redux/usage-with-typescript
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useShallowEqualSelector = <RootState, TSelected>(
  selector: (state: RootState) => TSelected
) => useSelector<RootState, TSelected>(selector, shallowEqual);
