import getConfig from '../../config';
import { EntityTypes } from '../../constants/entity-types';
import { Answer, ServerAnswer } from '../../types/answers.types';
import { SignupAction } from '../../types/config.types';
import { FlowStep } from '../../types/flow.types';
import { Question } from '../../types/questions.types';

/* 
  Finds the step and page that contains the signup step of the flow.
  Iterates over the flow, and returns the step/page data of the first interactive v2
  component with a signup action.
*/

// this looks like it could be way simplified by being a flatmap
export function findSignupStepPage(steps: FlowStep[]) {
  const signupActions: SignupAction[] = getConfig().signupActions;
  let signupStep = 0;
  let signupPage = 0;

  for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
    const currentStep = steps[stepIndex];
    for (
      let pageIndex = signupPage;
      pageIndex < currentStep.pages.length;
      pageIndex++
    ) {
      const currentPage = currentStep.pages[pageIndex];
      for (
        let entityIndex = 0;
        entityIndex < currentPage.entity.length;
        entityIndex++
      ) {
        const currentEntity = currentPage.entity[entityIndex];
        if (
          currentEntity.entity_type === EntityTypes.INTERACTIVE_COMPONENT &&
          currentEntity.action?.length
        ) {
          let found = false;
          signupActions.forEach(signupAction => {
            found =
              found ||
              !!currentEntity.action?.find(a => {
                return (
                  a.service === signupAction.service &&
                  a.method === signupAction.method
                );
              });
          });

          if (found) {
            signupStep = stepIndex;
            signupPage = pageIndex;
            break;
          }
        }
      }
    }
  }

  return { signupStep, signupPage };
}

/**
 * Returns the step and page of the last answered question.
 *
 * This code expects the last answered question to be past the sign-up step,
 * which means it is only safe to call for users who have passed sign up, or
 * who are performing a post-test flow.
 */
export function findUserProgress(
  steps: FlowStep[],
  answers: Answer[] | ServerAnswer[],
  isPostTestFlow: boolean
) {
  const { signupStep, signupPage } = findSignupStepPage(steps);
  let nextStep = isPostTestFlow ? 0 : signupStep;
  let nextPage = isPostTestFlow ? 0 : signupPage;

  // Make sure ids are ascending so that we take last answers given first and check those.
  // We rely on the fact that these answers were created in
  // order of the flow originally, and therefore id should correlate with position in the flow.
  answers = answers.sort((a, b) => ((a.id ?? 0) > (b.id ?? 0) ? 1 : -1));

  // Nice little quartic search you have here.
  for (let i = answers.length - 1; 0 <= i; i--) {
    const lastAnswer = answers[i];

    for (let stepIndex = signupStep; stepIndex < steps.length; stepIndex++) {
      const currentStep = steps[stepIndex];

      const pageIndexStartCount = stepIndex === signupStep ? signupPage : 0;

      for (
        let pageIndex = pageIndexStartCount;
        pageIndex < currentStep.pages.length;
        pageIndex++
      ) {
        const currentPage = currentStep.pages[pageIndex];
        for (
          let entityIndex = 0;
          entityIndex < currentPage.entity.length;
          entityIndex++
        ) {
          const currentEntity = currentPage.entity[entityIndex];
          if (
            currentEntity.entity_type === EntityTypes.QUESTION &&
            (currentEntity as Question).content[0].question_id ===
              lastAnswer.question_id
          ) {
            nextStep = stepIndex;
            nextPage = pageIndex;
            return { nextStep, nextPage };
          }
        }
      }
    }
  }

  // return if we didn't find an answer for the steps
  return { nextStep, nextPage };
}
