import React from 'react';

import ConditionalRender from '../components/conditional-render';

import Submit from '../components/buttons/submit';
import GenericNewAuthenticationPage from '../components/page';

import Header from '../components/header';

import './style.scss';
import Password from '../components/inputs/password';
import ReactTooltip from 'react-tooltip';
import { noop } from '../../../props/empty';
import { getProductDisplayName, getProductId } from '../../../helpers/product';

export default class GenericResetPasswordComponent extends GenericNewAuthenticationPage {
  constructor(props) {
    super(props);

    this.loginLink = '/login-component/login';
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = `Reset Password - ${this.state.productNameCapitalized}`;
  }

  inputValidationChanged(fieldName, isValid) {
    const errorFields = this.errorFields || [];
    const foundAtIndex = errorFields.indexOf(fieldName);
    let passwordsNoMatch = false;

    if (!isValid && foundAtIndex === -1) {
      errorFields.push(fieldName);
    }

    if (isValid && foundAtIndex > -1) {
      errorFields.splice(foundAtIndex, 1);
    }

    if (this.state.confirmPassword !== this.state.password) {
      errorFields.indexOf('password') === -1
        ? errorFields.push('password')
        : noop;
      errorFields.indexOf('confirmPassword') === -1
        ? errorFields.push('confirmPassword')
        : noop;
      passwordsNoMatch = true;
    }

    const submitting = this.state.submitting && !errorFields.length;

    if (
      errorFields !== this.state.errorFields ||
      submitting !== this.state.submitting
    ) {
      this.errorFields = errorFields;
      this.setState({
        submitting: submitting,
        shouldValidate: false,
        passwordsNoMatch: passwordsNoMatch,
      });
    }
  }

  async callAction() {
    const url = '/api/service_method_proxy/GeneralLogin/1/reset_password';

    const payload = {
      token: window.localStorage.getItem('sl-new-authentication-reset-token'),
      password:
        this.state.password === this.state.confirmPassword
          ? this.state.confirmPassword
          : null,
    };

    const response = await super.callAction(payload, url);
    if (
      response &&
      response.result &&
      ['success'].indexOf(response.result.status) > -1
    ) {
      this.setState({
        pageLoading: true,
        submitting: false,
        shouldValidate: false,
        resetError: false,
        resetSuccess: true,
      });
    } else {
      window.localStorage.setItem(
        'sl-new-authentication-reset-password-expired',
        'true'
      );
      window.location = '/login-component/forgot';
    }
  }

  resetSuccessState() {
    this.setState({
      resetSuccess: false,
    });
  }

  loginWithNewPassword(e) {
    e.preventDefault();
    window.location = this.loginLink;
  }

  render() {
    return (
      <div className={`${this.state.product} login-component-container`}>
        <Header product={this.state.product} variant="login" />
        <div id="sl-reset-page">
          <ConditionalRender if={!this.state.resetSuccess}>
            <form
              id="sl-reset"
              name="sl-reset"
              onSubmit={this.onFormAttemptSubmit.bind(this)}
            >
              <h1>Set your new password</h1>
              <p>
                Please enter a new password below to access your{' '}
                {this.state.productNameCapitalized} account.
              </p>
              <Password
                type="password"
                id="sl-login-password"
                name="sl-login-password"
                required={true}
                label="Password"
                placeholder="New password"
                shouldValidate={this.state.shouldValidate}
                disabled={this.state.submitting}
                isReset={true}
                errorText={
                  this.state.passwordsNoMatch ? 'Passwords do not match' : ''
                }
                error={
                  (this.errorFields &&
                    this.errorFields.indexOf('password') > -1) ||
                  this.state.resetError
                }
                onChange={value => this.onInputValueChange('password', value)}
                value={this.state.password}
                onValidate={this.inputValidationChanged.bind(this, 'password')}
              />

              <Password
                type="password"
                id="sl-login-confirm-password"
                name="sl-login-confirm-password"
                required={true}
                label="Confirm Password"
                placeholder="Confirm new password"
                shouldValidate={this.state.shouldValidate}
                disabled={this.state.submitting}
                isReset={true}
                errorText={
                  this.state.passwordsNoMatch ? 'Passwords do not match' : ''
                }
                /* eslint-disable-next-line max-len */
                error={
                  (this.errorFields &&
                    this.errorFields.indexOf('confirmPassword') > -1) ||
                  this.state.resetError
                }
                onChange={value =>
                  this.onInputValueChange('confirmPassword', value)
                }
                value={this.state.confirmPassword}
                onValidate={this.inputValidationChanged.bind(
                  this,
                  'confirmPassword'
                )}
              />

              <div id="password-suggestion">
                <div className={'reset-password-hints'}>
                  Your password should contain at least 8 characters and at
                  least three of the following: an uppercase letter, a lowercase
                  letter,&nbsp;
                  <span data-tip data-for="symbol-tooltip">
                    <a>a symbol</a>
                  </span>{' '}
                  and a number.
                  <ReactTooltip
                    id="symbol-tooltip"
                    place="top"
                    type="light"
                    effect="solid"
                    className="react-tooltip"
                  >
                    {/* eslint-disable-next-line no-useless-escape, max-len */}
                    <span>
                      Accepted symbols are:{' '}
                      {'!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'}
                    </span>
                  </ReactTooltip>
                </div>
              </div>

              <ConditionalRender if={this.state.resetError}>
                <div className="sl-reset--error">
                  <p>Something went wrong. Please try again.</p>
                </div>
              </ConditionalRender>

              <Submit
                className="save-button-margin-top"
                value="Save password"
                loading={this.state.submitting}
                disabled={this.state.submitting}
              />
            </form>
          </ConditionalRender>

          <ConditionalRender if={this.state.resetSuccess}>
            <form id="sl-reset" name="sl-reset" onSubmit={this.onSubmit}>
              <h1>Success!</h1>
              <p>
                Your password has been updated. Login with your new password.
              </p>
              {getProductId() === 1 ? (
                <Submit
                  value="Login with new password"
                  onClick={this.loginWithNewPassword.bind(this)}
                  loginLink={this.loginLink}
                />
              ) : null}
            </form>
          </ConditionalRender>

          {this.renderFooter()}
        </div>
      </div>
    );
  }
}
