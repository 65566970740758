import React from 'react';
import './preference-center.style.scss';
import Header from '../header/header.container';
import FooterContainer from '../footer';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  getProductByUrl,
  getProductDisplayName,
  getProductHelpEmail,
} from '../../helpers/product';
import { InteractiveLoader as Loader } from '../../components/loader';

type PreferenceCenterState = {
  isLoading: boolean;
  isError: boolean;
};

export class PreferenceCenter extends React.Component<
  RouteComponentProps,
  PreferenceCenterState
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
    };
  }
  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const email = urlParams.get('email') || '';
    const messageMedium = urlParams.get('message_medium') || 'email';
    const messageTypeId = urlParams.get('message_type_id') || 0;
    const productId = urlParams.get('product_id') || '1';
    const url = '/api/service_method_proxy/Email/2/unsubscribe';
    const encodedEmail = email.replaceAll(' ', '+');
    fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email: encodedEmail,
        message_medium: messageMedium,
        product_id: parseInt(productId),
        ...(messageTypeId && { message_type_id: parseInt(messageTypeId) }),
      }),
    })
      .then(response => {
        if (response.status === 200) {
          return this.setState({ isLoading: false });
        }
        return this.setState({ isLoading: false, isError: true });
      })
      .catch(() => {
        return this.setState({ isLoading: false, isError: true });
      });
  }

  render() {
    const productName = getProductByUrl();

    let content = (
      <>
        <h3 className={'header-font'}>
          You are unsubscribed from {getProductDisplayName(productName)} emails.
        </h3>
        <h3 className={'header-font'}>We&apos;re sorry to see you go!</h3>
        <p className={'large-top-margin text-font'}>
          Didn&apos;t mean to unsubscribe? Contact{' '}
          <a href={`${getProductHelpEmail(productName)}`}>
            {getProductHelpEmail(productName)}
          </a>{' '}
          for help.
        </p>
      </>
    );
    if (this.state.isError) {
      content = (
        <>
          <h3 className={'header-font'}>
            Whoops! We weren’t able to unsubscribe your account.{' '}
            <a href={window.location.href}>Click here</a> to try again.
          </h3>
          <p className={'large-top-margin text-font'}>
            Still not working? Contact{' '}
            <a href={`${getProductHelpEmail(productName)}`}>
              {getProductHelpEmail(productName)}
            </a>{' '}
            for help.
          </p>
        </>
      );
    }
    if (this.state.isLoading) {
      content = <Loader />;
    }

    return (
      <>
        <div className={`${productName} preference-container`}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <Header />
          <div className={`horizontal-padding`}>
            <div className={'flex column center w-100 header-margin'}>
              {content}
            </div>
          </div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <FooterContainer />
        </div>
      </>
    );
  }
}

export default withRouter(PreferenceCenter);
