import React, { forwardRef } from 'react';
import { UPDATE_EMAIL, UPDATE_PASSWORD } from '../../constants/action-types';
import { isEventSpaceKey } from '../../helpers/event-helpers';
import { useAppDispatch } from '../../hooks/use-app-react-redux';
import interactiveConfig from '../triggers/triggers-config';
import {
  inputSharedErrorStyle,
  inputSharedStyle,
} from '../../styles/themed.input-shared.styles';
import ErrorMessage from '../errors/error-message/error-message.component';

export type InputTextProps = {
  inputId?: string;
  name?: string;
  onChange: (value: string, isDisabled?: boolean) => void;
  onFocus?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<Element>) => void;
  preventSpaces?: boolean;
  disabled?: boolean;
  value: string;
  className?: string;
  required?: boolean;
  type?: string;
  maxLength?: number;
  minLength?: number;
  error: boolean;
  errorText?: string;
  placeholder?: string;
  readOnly?: boolean;
  isChecked?: boolean;
};

// eslint-disable-next-line react/display-name
export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      inputId,
      name,
      onChange,
      onFocus,
      onKeyDown,
      preventSpaces,
      disabled,
      value,
      className,
      required,
      type = 'text',
      maxLength,
      minLength,
      error,
      errorText,
      placeholder = '',
      readOnly,
      isChecked,
    },
    ref
  ) => {
    const showError = (isChecked && !value) || !!error;
    const dispatch = useAppDispatch();

    const inputTextOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const eventValue = event?.target?.value;

      if (
        name &&
        interactiveConfig.INPUT_FIELD_NAMES.EMAIL.indexOf(name) !== -1
      ) {
        dispatch({ type: UPDATE_EMAIL, value: eventValue.trimStart() });
      }

      if (
        name &&
        interactiveConfig.INPUT_FIELD_NAMES.PASSWORD.indexOf(name) !== -1
      ) {
        dispatch({ type: UPDATE_PASSWORD, value: eventValue.trimStart() });
      }

      // prevent starting with space
      onChange(eventValue.trimStart(), false);
    };

    // If preventSpaces is true, prevent user from entering empty spaces
    const onKeyDownListener = (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (preventSpaces && isEventSpaceKey(event)) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (onKeyDown) {
        onKeyDown(event);
      }
    };

    return (
      <div>
        <input
          id={`${inputId}`}
          name={name}
          type={type}
          css={showError ? inputSharedErrorStyle : inputSharedStyle}
          className={className}
          maxLength={maxLength}
          minLength={minLength}
          placeholder={placeholder}
          value={value ?? ''}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          onChange={inputTextOnChange}
          onKeyDown={onKeyDownListener}
          onFocus={onFocus}
          ref={ref}
          data-testid="question-input-text"
        />

        {error && !!errorText && <ErrorMessage errorMessage={errorText} />}
      </div>
    );
  }
);
