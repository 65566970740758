//TODO: Determine final structure of these interfaces as we build out types more

export type PageAnswer = {
  [key: string]: Answer[];
};

export type LocalStepAnswerStorage = {
  [key: string]: PageAnswer;
};

// this interface is sources from QuestionComponent.onAnswer
// src/components/question-component/index.js
export type QuestionAnswer = {
  question_id: number;
  semantic_id: string;
  product_id: number;
  is_core_question?: boolean;
  // ExtendedAnswerValues _really_ needs to merge with QuestionContentAnswer soon
  // an actual QuestionAnswer value should never have null as a real value, because it blows up the BE.
  // do not add null to this type even if you see it in JS. We transform it to an empty string for skipped questions.
  values: ExtendedAnswerValues | ExtendedAnswerValues[] | string | number; // is array for multi
};

export interface Answer extends QuestionAnswer {
  id?: number;
  _parentQuestionId?: number;
  saved?: boolean;
  isSkipped?: boolean;
  isHiddenQuestion?: boolean;
  stepNumber: number;
  pageNumber: number;
  answeredTimeInMs: number;
}

export interface ServerAnswer {
  question_id: number;
  semantic_id: string;
  product_id: number;
  id: number;
  flow_id: number | null;
  user_id: number;
  organization_id: number | string | undefined;
  responses:
    | ExtendedAnswerValues
    | ExtendedAnswerValues[]
    | string
    // | string[] // I don't think this is possible any more
    | number;
  display_name?: string;
  primary_key?: number;
}

export const instanceOfExtendedAnswerValues = (
  object: string | number | ExtendedAnswerValues | ExtendedAnswerValues[]
): object is ExtendedAnswerValues => {
  return (
    typeof object !== 'string' &&
    typeof object !== 'number' &&
    ('id' in object || 'score' in object || 'text' in object)
  );
};

// can be consolidated with QuestionContentAnswer?
export interface ExtendedAnswerValues {
  id?: number | string | null;
  score?: number | string | null;
  text?: string;
}
