import { css } from '@emotion/react';
import {
  DESKTOP_QUESTION_MAX_WIDTH,
  DESKTOP_TITLE_MAX_WIDTH,
} from '../../../styles/breakpoints';
import { BHTheme } from '../../../types/bh-themes.types';

export const defaultInteractiveStyles = (theme: BHTheme) =>
  css({
    color: theme.fonts.fontColors.PRIMARY,
    textAlign: 'center',
    marginTop: theme.padding.LARGE,
    fontSize: theme.fonts.fontSizes.SMALL,
    a: theme.links.defaultStyle,
  });

export const interactiveWrapperStyle = (theme: BHTheme) =>
  css(defaultInteractiveStyles(theme), {
    margin: `${theme.padding.EXTRA_LARGE} auto 0`,
  });

export const interactiveTitleStyle = (theme: BHTheme) =>
  css({
    fontSize: theme.fonts.fontSizes.HEADLINE,
    maxWidth: DESKTOP_TITLE_MAX_WIDTH,
    lineHeight: '1.35em',
    margin: 'auto',
    color: theme.fonts.fontColors.PRIMARY,
  });

export const interactiveSubTitleStyle = (theme: BHTheme) =>
  css({
    fontSize: theme.fonts.fontSizes.SUBTITLE,
    maxWidth: DESKTOP_QUESTION_MAX_WIDTH,
    margin: 'auto',
    marginTop: theme.padding.MEDIUM,
  });

export const interactiveFormWrapperStyle = () =>
  css({
    maxWidth: DESKTOP_QUESTION_MAX_WIDTH,
    margin: 'auto',
    textAlign: 'left',
  });

export const interactiveInputWrapperStyle = (theme: BHTheme) =>
  css({
    marginTop: theme.padding.LARGE,
  });

export const interactiveErrorStyle = (theme: BHTheme) =>
  css({
    color: theme.errors.color,
    a: {
      color: theme.errors.color,
    },
  });
