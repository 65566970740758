import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { withRouter } from 'react-router-dom';
import { onEnterOrSpace } from '../../../components/utils/util-functions';
import Cookies from '../../../cookies';

class GoogleSSOComponent extends React.Component {
  constructor(props) {
    super(props);
    this.path = `${this.props.location.pathname}`;
    this.signUp = this.props.mode === 'signup' ? '?signup=true' : '';
    Cookies.set('product_id', window.product_id);
  }

  login() {
    if (this.props.onClick) {
      this.props.onClick();
    }
    try {
      // eslint-disable-next-line max-len
      window.location.href = window['OAUTH_URLS']['google'].concat(
        `?next=${this.path}${this.signUp}&product_id=${window.product_id}`
      );
    } catch (ignore) {
      // Running on local dev
      // eslint-disable-next-line max-len
      window.location.href = `http://localhost:8000/login/google-oauth2/?next=${this.path}${this.signUp}&product_id=${window.product_id}`;
    }
  }

  render() {
    return (
      <a
        tabIndex={0}
        id="sl-google-sso"
        onClick={this.login.bind(this)}
        onKeyDown={e => onEnterOrSpace(e, this.login.bind(this))}
      >
        <span>{this.props.mode === 'signup' ? 'Sign up' : 'Login'}</span>
        <span> with Google</span>
      </a>
    );
  }
}

GoogleSSOComponent.propTypes = {
  mode: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(GoogleSSOComponent);
