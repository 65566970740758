import { css } from '@emotion/react';
import { DAYLIGHT_TEXT_COLOR, SLATE_GRAY, WHITE } from '../../styles/colors';
import { BHTheme } from '../../types/bh-themes.types';

export const multiProductContainerStyle = () =>
  css({
    height: '100%',
  });

export const multiProductContentStyle = () =>
  css({
    height: 'calc(100% - 150px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    paddingTop: '80px',
    backgroundColor: WHITE,
  });

export const multiProductInnerContentStyle = (theme: BHTheme) =>
  css({
    margin: '0 auto',
    padding: `0 ${theme.padding.LARGE}`,
    width: '600px',
    maxWidth: '100%',
    boxSizing: 'border-box',
    height: '100%',
  });

export const multiProductFooterStyle = (theme: BHTheme) =>
  css({
    color: SLATE_GRAY,
    fontSize: theme.fonts.fontSizes.SMALL,
    padding: '80px 0 40px 0',
    textAlign: 'center',
    width: '100%',
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'center',
  });

export const multiProductBodyStyle = (theme: BHTheme) =>
  css({
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    color: DAYLIGHT_TEXT_COLOR,
    height: '100%',

    h1: {
      paddingBottom: theme.padding.EXTRA_LARGE,
      fontWeight: theme.fonts.fontWeights.BOLD,
      fontSize: theme.fonts.fontSizes.TITLE,
    },
  });

export const multiProductLinkStyle = (theme: BHTheme) =>
  css({
    textDecoration: 'none',
    textAlign: 'center',
    color: DAYLIGHT_TEXT_COLOR,
    fontSize: theme.fonts.fontSizes.STANDARD,
  });
