import classnames from 'classnames';
import { Fragment, FunctionComponent } from 'react';
import { QuestionContentAnswer } from '../../types/questions.types';

export const PLEASE_SELECT = 'Please Select';
export const DROPDOWN_TEST_ID = 'DROPDOWN_TEST_ID';
export const HIGHLIGHTED_CLASSNAME = 'highlighted';

export type DropdownOnSelect = (answer: QuestionContentAnswer) => void;

export const emptyAnswer: QuestionContentAnswer = {
  id: null,
  text: '',
  score: null,
};

export type DropdownProps = {
  id: number | string;
  inputId?: string;
  showPleaseSelect?: boolean;
  defaultAnswer?: QuestionContentAnswer;
  answers: QuestionContentAnswer[];
  //onSelect: DropdownOnSelect;
  matchingAnswer?: QuestionContentAnswer;
  // used for accessibility e.g aria-activedescendant
  setHighlightedHtmlId?: (id: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  selectedValue: string;
};

const buildOptionHtmlId = (id: string | number, idx: number) =>
  `dropdown-${id}_${idx}`;

const DropdownOptions: FunctionComponent<DropdownProps> = ({
  id,
  showPleaseSelect = false,
  defaultAnswer,
  answers,
  selectedValue,
}) => {
  return (
    <Fragment>
      {showPleaseSelect && (
        <option
          key={-1}
          id={buildOptionHtmlId(id, 0)}
          value={'-1'}
          role="option"
          label={PLEASE_SELECT}
          aria-selected={showPleaseSelect}
        >
          {PLEASE_SELECT}
        </option>
      )}
      {answers.map((item, idx) => (
        <option
          key={item.id?.toString()}
          id={buildOptionHtmlId(id, showPleaseSelect ? 1 + idx : idx)}
          value={item.id?.toString()}
          role="option"
          aria-selected={item.id === selectedValue}
          className={classnames({
            [HIGHLIGHTED_CLASSNAME]: item.id === selectedValue,
          })}
          label={item.text}
        >
          {item.text}
        </option>
      ))}
      {!!defaultAnswer && (
        <option
          key="default"
          id={buildOptionHtmlId(
            id,
            showPleaseSelect ? 1 + answers.length : answers.length
          )}
          //onClick={() => onSelect(defaultAnswer)}
          role="option"
          aria-selected={defaultAnswer.id === selectedValue}
          className={classnames({
            default: answers.length > 0,
            [HIGHLIGHTED_CLASSNAME]: defaultAnswer.id === selectedValue,
          })}
        >
          {defaultAnswer.text}
        </option>
      )}
    </Fragment>
  );
};

DropdownOptions.displayName = 'DropdownOptions';

export default DropdownOptions;
