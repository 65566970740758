import { css, Theme } from '@emotion/react';

export const countryCodeSelectStyle = () =>
  css({
    boxSizing: 'border-box',
    paddingRight: 16,
    paddingBottom: 0,

    '@media (max-width: 750px)': {
      paddingRight: 0,
      paddingBottom: 16,
    },

    label: {
      display: 'none',
    },
  });
export const countryCodeSelectWrapperStyle = (theme: Theme) =>
  css({
    borderRadius: theme.borders.defaultBorderRadius,
    border: '1px solid #dfe1e6',
    padding: 24,
    minWidth: 235,
    width: '100%',
    fontSize: 16,
    '@media (max-width: 850px)': {
      minWidth: 300,
    },
  });
export const countryCodeSelectDisabledStyle = () =>
  css({
    color: '#6a717d',
  });

export const countryCodeSelectErrorStyle = () =>
  css({
    border: '1px solid #b92d0f',
  });

export const countryCodeWrapperStyle = () =>
  css({
    position: 'relative',
    '::after': {
      content: `'\u25BC'`,
      color: 'rgb(22, 50, 88)',
      fontSize: 12,
      pointerEvents: 'none',
      position: 'absolute',
      top: '53%',
      transform: 'translateY(-50%)',
      right: 15,
    },
  });
