import './style.scss';

import React from 'react';
import Header from '../header/header.container';
import FooterContainer from '../footer';
import { unsubscribeByUuidAndProductSlug } from '../../actions/user';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SL_PAGE } from '../../constants/class-names';

class UnsubscribeContainer extends React.Component {
  componentDidMount() {
    const uuid = this.props.match.params.uuid;
    const productSlug = this.props.match.params.product;
    this.props.unsubscribeByUuidAndProductSlug(uuid, productSlug);
  }

  getMessage() {
    let productSlug = this.props.match.params.product;
    productSlug = productSlug.charAt(0).toUpperCase() + productSlug.slice(1);

    if (this.props.user.userUnsubscribed) {
      return [
        <h1 key={1}>Your subscription preferences have been updated.</h1>,
        <p key={2}>
          You can change these preferences at any time at your Account page.
        </p>,
        /* eslint-disable max-len */
        <p key={3}>
          Any questions? Contact the Sleepio Team at{' '}
          <a href="mailto:hello@sleepio.com">hello@sleepio.com</a>.
        </p>,
      ];
    }

    if (this.props.user.unsubscribeError) {
      return [
        <h1 key={1}>
          There was an error unsubscribing from {productSlug} emails!
        </h1>,
        /* eslint-disable max-len */
        <p key={2}>
          Any questions? Contact the Sleepio Team at{' '}
          <a href="mailto:hello@sleepio.com">hello@sleepio.com</a>.
        </p>,
      ];
    }

    if (
      typeof this.props.user.unsubscribeError == 'undefined' &&
      typeof this.props.user.userUnsubscribed == 'undefined'
    ) {
      return <h2>Please wait...</h2>;
    }
  }

  render() {
    return (
      <div>
        <Header displayLogin={true} />
        <div className={SL_PAGE}>{this.getMessage()}</div>
        <FooterContainer />
      </div>
    );
  }
}

UnsubscribeContainer.propTypes = {
  unsubscribeByUuidAndProductSlug: PropTypes.func.isRequired,
  match: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = state => ({ user: state.userReducer });
const mapDispatchToProps = dispatch => ({
  unsubscribeByUuidAndProductSlug: bindActionCreators(
    unsubscribeByUuidAndProductSlug,
    dispatch
  ),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnsubscribeContainer)
);
