import { css } from '@emotion/react';
import { BHTheme } from '../../types/bh-themes.types';

export const multiselectWrapper = css({
  display: 'grid',
  gap: '8px',
  gridAutoRows: '1fr',
});

export const checkRowLabel = (theme: BHTheme) =>
  css({
    display: 'flex',
    alignItems: 'center',
    fontWeight: theme.fonts.fontWeights.MEDIUM,
    lineHeight: '100%',
    fontSize: 16,
    color: theme.inputs.checkbox.textColor,
    borderRadius: theme.borders.defaultBorderRadius,
    backgroundColor: theme.inputs.checkbox.backgroundColor,
    padding: theme.padding.MEDIUM,
    cursor: 'pointer',

    span: {
      maxWidth: 240,
      marginLeft: 15,
    },
  });
