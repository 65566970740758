import BaseValidator from './index';
import Validator from './field';
import moment from 'moment';
import {
  Answer,
  instanceOfExtendedAnswerValues,
} from '../../../types/answers.types';

let instance: QuestionValidator | null = null;
const NORMALLY_INTO_BED = [
  'normally_into_bed',
  'sleep_efficiency_time_into_bed',
];
const TRY_GO_SLEEP = ['try_go_sleep', 'sleep_efficiency_time_try_to_sleep'];
const FALL_ASLEEP = [
  'fall_asleep',
  'sleep_efficiency_to_fall_asleep_total_time',
];
const NORMALLY_WAKE_LAST_TIME = [
  'normally_wake_last_time',
  'sleep_efficiency_time_final_awakening',
];
const AWAKE_DURING_NIGHT = [
  'awake_during_night',
  'sleep_efficiency_awakenings_total_time',
];
const TIME_NORMALLY_OUT_BED = [
  'time_normally_out_bed',
  'sleep_efficiency_time_get_out_of_bed',
];
const RESEARCH_EMAIL_CAPTURE = ['research_email_capture'];
const NHS_WAITING_LIST_EMAIL_CAPTURE = ['nhs_waiting_list_email_capture'];
const COMMUNITY_USERNAME = ['community_username'];

interface StepError {
  showErrorId: number;
  highlightedIds: (number | null)[];
  error: string;
}

export interface ValidatorParams {
  inToBed: Answer | undefined;
  tryGoSleep: Answer | undefined;
  fallAsleep: Answer | undefined;
  wakeUp: Answer | undefined;
  awake: Answer | undefined;
  outOfBed: Answer | undefined;
  researchEmail: Answer | undefined;
  nhsWaitingListEmail: Answer | undefined;
  communityUsername: Answer | undefined;
}

export type Validator = [keyof ValidatorParams] | [];

/* Step validators
   These validators apply to an entire step. They are configured via the onboarding admin tool.

   Especially for the fitbit page questions, where it validates times such as
   for example the fact that you cannot fall asleep before getting into bed.

*/
class QuestionValidator extends BaseValidator {
  constructor() {
    super();

    instance = instance || this;

    return instance;
  }

  /* This function validates the Answers entered by the user on a step,
    pre-submission.

       This function is called on every update of input on the front-end, and
     runs the validators referenced in the "validator" key of the Step or Page.
     These validators reference the functions and parameters below by their
     name.
     */
  validateAnswers(
    answers: Answer[],
    validations: Validator | null
  ): StepError[] {
    if (validations === null) {
      return []; // just return early
    }

    // NOTE: This is a fairly inefficient way of extracting parameters
    // and matching validations. N is small, and we only run this for
    // questions that have at least 1 validation, but this could be improved.
    const inToBed = answers.find(
      item => NORMALLY_INTO_BED.indexOf(item.semantic_id) > -1
    );
    const tryGoSleep = answers.find(
      item => TRY_GO_SLEEP.indexOf(item.semantic_id) > -1
    );
    const fallAsleep = answers.find(
      item => FALL_ASLEEP.indexOf(item.semantic_id) > -1
    );
    const wakeUp = answers.find(
      item => NORMALLY_WAKE_LAST_TIME.indexOf(item.semantic_id) > -1
    );
    const awake = answers.find(
      item => AWAKE_DURING_NIGHT.indexOf(item.semantic_id) > -1
    );
    const outOfBed = answers.find(
      item => TIME_NORMALLY_OUT_BED.indexOf(item.semantic_id) > -1
    );
    const researchEmail = answers.find(
      item => RESEARCH_EMAIL_CAPTURE.indexOf(item.semantic_id) > -1
    );
    const nhsWaitingListEmail = answers.find(
      item => NHS_WAITING_LIST_EMAIL_CAPTURE.indexOf(item.semantic_id) > -1
    );
    const communityUsername = answers.find(
      item => COMMUNITY_USERNAME.indexOf(item.semantic_id) > -1
    );

    const sleepErrors: StepError[] = [];
    const params = {
      inToBed,
      tryGoSleep,
      fallAsleep,
      wakeUp,
      awake,
      outOfBed,
      researchEmail,
      nhsWaitingListEmail,
      communityUsername,
    };
    validations.forEach(functionName => {
      const result =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this[functionName] && this[functionName].call(this, params);
      if (result) {
        sleepErrors.push(result);
      }
    });
    return sleepErrors;
  }

  // noinspection JSUnusedGlobalSymbols
  fallAsleep(params: ValidatorParams): StepError | undefined | null {
    const { inToBed, tryGoSleep } = params;
    let sleepErrors;
    if (inToBed && tryGoSleep) {
      sleepErrors = this.compareHours(
        inToBed,
        tryGoSleep,
        18,
        this.MESSAGES.MESSAGE_FITBIT_SLEEP
      );
    }
    return sleepErrors;
  }

  // noinspection JSUnusedGlobalSymbols
  outOfBed(params: ValidatorParams): StepError | undefined | null {
    const { outOfBed, wakeUp } = params;
    let sleepErrors;
    if (outOfBed && wakeUp) {
      sleepErrors = this.compareHours(
        wakeUp,
        outOfBed,
        18,
        this.MESSAGES.MESSAGE_FITBIT_WAKE
      );
    }
    return sleepErrors;
  }

  /* Performs validation of Email questions in the Consumer OST flow.*/
  _emailValidator(param: Answer | undefined): StepError | undefined {
    let sleepErrors;

    if (
      param &&
      typeof param.values === 'string' &&
      !Validator.isValidEmail(param.values)
    ) {
      sleepErrors = {
        showErrorId: param.question_id,
        highlightedIds: [param.question_id],
        error: 'Please enter a valid email',
      };
    }
    return sleepErrors;
  }

  researchEmail = (params: ValidatorParams): StepError | undefined => {
    return this._emailValidator(params.researchEmail);
  };

  nhsWaitingListEmail = (params: ValidatorParams): StepError | undefined => {
    return this._emailValidator(params.nhsWaitingListEmail);
  };

  // noinspection JSUnusedGlobalSymbols
  /* Performs validation of the CommunityUsername question.  Currently configured
    for both platform and legacy users. */
  communityUsername(params: ValidatorParams): StepError | undefined {
    const { communityUsername } = params;
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    let sleepErrors;

    if (communityUsername) {
      const isValidString =
        typeof communityUsername.values === 'string'
          ? alphanumericRegex.test(communityUsername.values)
          : false;

      if (!isValidString) {
        sleepErrors = {
          showErrorId: communityUsername.question_id,
          highlightedIds: [communityUsername.question_id],
          error: 'Your community username may only contain letters and numbers',
        };
      }
    }
    return sleepErrors;
  }

  // noinspection JSUnusedGlobalSymbols
  wakeUp(params: ValidatorParams): StepError | undefined {
    const { tryGoSleep, fallAsleep, wakeUp, awake } = params;
    let sleepErrors;

    if (tryGoSleep && fallAsleep && wakeUp) {
      // id is amount of time in minutes
      // text is a time in "XX:XX PM" format
      const tryGoSleepTime = instanceOfExtendedAnswerValues(tryGoSleep.values)
        ? tryGoSleep.values.text
        : '';
      const fallAsleepMinutes = instanceOfExtendedAnswerValues(
        fallAsleep.values
      )
        ? fallAsleep.values.id
        : '';
      const wakeUpTime = instanceOfExtendedAnswerValues(wakeUp.values)
        ? wakeUp.values.text
        : '';

      if (
        tryGoSleepTime !== '' &&
        fallAsleepMinutes !== '' &&
        wakeUpTime !== ''
      ) {
        let awakeTime = 0;
        if (awake && instanceOfExtendedAnswerValues(awake.values)) {
          awakeTime = parseInt(String(awake.values?.id)) || 0;
        }

        const trySleepDate = new Date(`01/01/2017 ${tryGoSleepTime}`);

        let fallAsleepTime = 0;
        if (
          typeof fallAsleepMinutes === 'number' ||
          typeof fallAsleepMinutes === 'string'
        ) {
          fallAsleepTime = parseInt(String(fallAsleepMinutes));
        }

        let wakeUpDate = new Date(`01/01/2017 ${wakeUpTime}`);

        if (
          moment(trySleepDate).format('A') === 'PM' &&
          moment(wakeUpDate).format('A') === 'AM'
        ) {
          wakeUpDate = new Date(`01/02/2017 ${wakeUpTime}`);
        }
        if (
          moment(trySleepDate).format('A') === moment(wakeUpDate).format('A') &&
          moment(wakeUpDate).unix() - moment(trySleepDate).unix() < 0
        ) {
          wakeUpDate = new Date(`01/02/2017 ${wakeUpTime}`);
        }
        const duration = moment.duration(
          moment(wakeUpDate).diff(moment(trySleepDate))
        );
        const totalSleepTime = Math.abs(duration.asMinutes());

        // if total time in bed is smaller than wake time in bed
        if (
          (totalSleepTime - fallAsleepTime - awakeTime) / 60 < 0 ||
          (totalSleepTime - fallAsleepTime - awakeTime) / 60 >= 18
        ) {
          sleepErrors = {
            showErrorId: wakeUp.question_id,
            highlightedIds: [
              tryGoSleep.question_id,
              fallAsleep.question_id,
              wakeUp.question_id,
              awake ? awake.question_id : null,
            ],
            error: this.MESSAGES.MESSAGE_FITBIT_ASLEEP,
          };
        }
      }
    }
    return sleepErrors;
  }

  // UTIL function for fallAsleep and outOfBed functions

  /**
   * @param firstDateTime
   * @param secondDateTime
   * @param diff {number} maxim different between hours
   * @returns {object} returns object with error data or null
   * @param {text} text error message
   */

  compareHours(
    firstDateTime: Answer,
    secondDateTime: Answer,
    diff: number,
    text: string
  ) {
    const firstDateValue = instanceOfExtendedAnswerValues(firstDateTime.values)
      ? firstDateTime.values.text
      : '';
    const secondDateValue = instanceOfExtendedAnswerValues(
      secondDateTime.values
    )
      ? secondDateTime.values.text
      : '';

    let isValid = true;

    if (firstDateValue !== '' && secondDateValue !== '') {
      const firstDate = new Date(`01/01/2017 ${firstDateValue}`);
      let secondDate = new Date(`01/01/2017 ${secondDateValue}`);

      if (
        moment(firstDate).format('A') === 'PM' &&
        moment(secondDate).format('A') === 'AM'
      ) {
        secondDate = new Date(`01/02/2017 ${secondDateValue}`);
      }
      if (
        moment(firstDate).format('A') === moment(secondDate).format('A') &&
        moment(secondDate).unix() - moment(firstDate).unix() < 0
      ) {
        secondDate = new Date(`01/02/2017 ${secondDateValue}`);
      }
      const duration = moment.duration(
        moment(firstDate).diff(moment(secondDate))
      );
      const hours = duration.asHours();
      if (Math.abs(hours) >= diff) {
        isValid = false;
      }
    }

    return isValid
      ? null
      : {
          showErrorId: secondDateTime.question_id,
          highlightedIds: [
            firstDateTime.question_id,
            secondDateTime.question_id,
          ],
          error: text,
        };
  }
}

export default new QuestionValidator();
