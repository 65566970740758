/* eslint-disable max-len */
'use strict';
import * as Sentry from '@sentry/react';
import { history } from '../store/history';
import getConfig from '../config';
import UserSession from '../services/user-session';
import doAjax from './do-ajax';
import { getDevicePlatform } from '../helpers/devicePlatform';

const config = getConfig();
const SERVER_URI = config.server;
const RETRY_URLS = config.retryUrls;
const MAX_RETRIES = 3;
const RETRY_TIMEOUT = 500;

// Map of endpoints and their respective versions
const ENDPOINTS = [
  { name: 'AccessCode', version: 1 },
  { name: 'Answer', version: 1 },
  { name: 'Eligibility', version: 1 },
  { name: 'Flow', version: 1 },
  { name: 'Organization', version: 1 },
  { name: 'PlatformUser', version: 1 },
  { name: 'Product', version: 1 },
  { name: 'RecordingAPI', version: 3 },
  { name: 'Report', version: 1 },
  { name: 'User', version: 1 },
  { name: 'Vanity', version: 1 },
  { name: 'auth', version: null },
  { name: 'PotentialUser', version: 1 },
];

// Map of endpoints by their lowercase name. The case-insensitive behaviour is
// conserved despite the smell because we are not sure if somewhere that is
// relevant in this codebase.
const ENDPOINTS_BY_NAME = ENDPOINTS.reduce(function (
  endpoints_by_name,
  endpoint
) {
  endpoints_by_name[endpoint.name.toLowerCase()] = endpoint;
  return endpoints_by_name;
},
{});

export const METHODS = {
  CREATE: 'create',
  CREATE_BULK: 'create_bulk', // { objs: [ entities array - json body ], entity_type(optional): bla }
  UPDATE: 'update', // { entity_id: 1, attributes: json body }
  GET: 'find_with_id',
  GET_ALL: 'find_all',
};

class Actions {
  /**
   * @typedef GetRequest
   * @type {object}
   * @property {String} entity entity for which the request is made
   * @property {String} [method] (optional) If none provided GET is used
   * @property {String} [query] Query string
   */

  /**
   * Make a GET request
   *
   * @param {GetRequest} custom get request
   * @param {Boolean} customTrigger is custom trigger method
   * @returns {Promise}
   */
  static get({ entity, method, query }, customTrigger = false) {
    const request = {
      ...constructEndpointRequest(
        { entity, method: method || METHODS.GET, restMethod: 'get' },
        customTrigger
      ),
      query,
    };
    return this.makeRequest({ request });
  }

  /**
   * Make a GET all request
   *
   * @param {String} entity entity for which the request is made
   * @param {String} [query] Query string
   * @returns {Promise}
   */
  static getAll({ entity, query }) {
    const request = {
      ...constructEndpointRequest(
        { entity, method: METHODS.GET_ALL, restMethod: 'get' },
        false
      ),
      query,
    };
    return this.makeRequest({ request });
  }

  /**
   * @typedef Post
   * @type {object}
   * @property {String} entity entity for which the request is made
   * @property {String} method one of {@link METHODS} or a custom method as string
   * @property {Object} data The data to be inserted. In case of {@link METHODS.UPDATE}
   * this object has to be in the form: { entity_id, attributes }
   * @property {*=} callback
   * @property {String=} serviceVersion
   */

  /**
   * Make a POST JSON request
   * @param {Post}
   * @param {Boolean} customTrigger
   * @returns {Promise}
   */
  static post(
    { entity, method, data, callback, serviceVersion },
    customTrigger = false
  ) {
    const request = {
      ...constructEndpointRequest(
        { entity, method, data, restMethod: 'post', serviceVersion },
        customTrigger
      ),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    return this.makeRequest({ request, callback });
  }

  /**
   * Make a generic request
   *
   * @param {Object} request Request to be made. Must be of the form: {method, url, query [optional]}
   * @returns {Promise}
   */
  static async makeRequest({ request, callback }) {
    let retries = 0;
    let headers = request.headers || {};
    request.url = request.url || '';

    // Don't set the body if it's a GET request as it will crash on Microsoft Edge
    const params = {
      headers,
      method: request.method || 'GET',
      credentials: request.credentials || 'same-origin',
    };
    if (params.method !== 'GET') {
      params.body = request.body || undefined;
    }

    request.url += request.query ? `?${request.query}` : '';
    const serverUri = request.serverUri || SERVER_URI;

    // Check if the request should be retried
    RETRY_URLS.forEach(retryUrl => {
      const regex = new RegExp(retryUrl);
      if (regex.test(request.url)) {
        retries = MAX_RETRIES;
        return false;
      }
    });

    const response = await retry(serverUri + request.url, params, retries);

    // If we have a response, return it
    if (response) {
      if (callback) {
        callback({ response });
      }
      return response;
    }
    // Else, go to the error page
    history.push(`/${window.product_name}/error`);
  }

  static BASE_URL = '/api/service_method_proxy';
}

/** Given a string Service name, returns the service endpoint associated with it.
 *  @param {String} name name of the service for which the request is made
 *  @returns {obj} the service endpoint metadata if found, or undefined
 */
export function serviceForName(name) {
  return ENDPOINTS_BY_NAME[name.toLowerCase()];
}

/**
 * Helper method that constructs the endpoint w.r.t the requirements of the micro-services
 * @param {String} entity entity name (one of {@link ENDPOINTS} names)
 * @param {Boolean} isCustomTrigger custom trigger call
 * @param {String} method method name (one of {@link METHODS})
 * @param {String} restMethod rest method
 * @param {Object} [data] Only required for CREATE and UPDATE
 * @param {number} serviceVersion service version
 * @returns {{url: string, method: string}}
 */
function constructEndpointRequest(
  { entity, method, restMethod, data, serviceVersion },
  isCustomTrigger
) {
  const theEntity = serviceForName(entity);
  const version = serviceVersion || theEntity.version;
  const slash_padded_version = `/${version}`;
  const url = `${Actions.BASE_URL}/${theEntity.name}${
    version ? slash_padded_version : ''
  }/${method}`;
  let body;
  // TODO (Alex): this needs refactoring
  // Check if create or update and parse the body in the required format
  if (isCustomTrigger) {
    if (method === 'send_to_cbt_with_entity_id') {
      body = JSON.stringify({ ...data, device_platform: getDevicePlatform() });
    } else {
      body = JSON.stringify(data);
    }
  } else if (method === 'create_answers_from_fitbit_data') {
    body = { fitbit_data: data };
    body = JSON.stringify(body);
  } else if (method === 'check_eligibility_for_organization') {
    body = JSON.stringify(data);
  } else if (method === 'update_user_info') {
    body = JSON.stringify(data);
  } else if (method === 'validate_code') {
    body = { code: data };
    body = JSON.stringify(body);
  } else if (method === 'post_events') {
    body = { events: data };
    body = JSON.stringify(body);
  } else if (method === 'validate' && entity === 'AccessCode') {
    body = { code: data };
    body = JSON.stringify(body);
  } else if (
    method ===
      'check_employee_with_last_name_employee_identifier_and_company' &&
    entity === 'Eligibility'
  ) {
    body = JSON.stringify(data);
  } else if (method === 'generate_with_id_and_answers') {
    body = JSON.stringify(data);
  } else if (data && restMethod !== 'get' && method !== 'delete') {
    const isUpdate = method.toLowerCase().indexOf('update') === 0;
    const isBulk = method.toLowerCase().indexOf('_bulk') > -1;
    if (isBulk) {
      body = { objs: data };
    } else {
      body = { attributes: data };
    }
    if (isUpdate) {
      body.entity_id = data.id;
    }
    body = JSON.stringify(body);
  } else if (data && restMethod === 'post' && method === 'delete') {
    body = JSON.stringify({ entity_id: data.id });
  }
  return { url, method: restMethod, body };
}

/**
 * Attempts to perform a fetch request a certain number of times
 * @param {String} url the url to fetch
 * @param {Object} params params
 * @param {Number} retries number of times the request must be attempted, if no valid answer
 * @returns {Promise<*>}
 */
async function retry(url, params, retries) {
  let response;
  if (retries === 0) {
    // If the API doesn't need to be retried, just return the response
    response = await doRequest(
      url,
      params,
      retries > 0 && retries < MAX_RETRIES
    );
    return response;
  }

  // Retry until retry limit is reached or we have a valid response
  do {
    const res = await doRequest(
      url,
      params,
      retries > 0 && retries < MAX_RETRIES
    );
    if (
      !res ||
      res.status === 500 ||
      !!res.message ||
      typeof res === 'string' ||
      typeof res.result === 'string'
    ) {
      // If failed MAX_RETRIES times create log
      if (retries === 1) {
        const payload = params.body ? `${JSON.stringify(params.body)}` : 'none';
        const user = UserSession.getUserData();
        const userDetails = user ? `${JSON.stringify(user)}` : 'Guest User';
        const apiParams = url.split('/api/');
        const currUrl = window.location.href;
        Sentry.configureScope(scope => {
          scope.setUser({ User: userDetails });
          scope.setTag('Method: ', `${params.method}`);
          scope.setTag('API: ', `${apiParams[1]}`);
          scope.setTag('Url: ', `${currUrl}`);
          scope.setTag('Error: ', `${JSON.stringify(res)}`);
          scope.setTag('Payload: ', `${payload}`);
          Sentry.captureMessage(
            `API call to ${apiParams[1].substr(
              0,
              apiParams[1].indexOf('?')
            )} failed after ${4 - retries} retries with message ${
              res ? res.message : 'response undefined'
            }`
          );
        });
      }
    } else {
      response = res;
    }
    retries--;
  } while (retries > 0 && !response);
  return response;
}

/**
 * Performs the fetch request, with an optional timeout before
 * @param {String} url the url to fetch
 * @param {Object} params params
 * @param {Boolean} [timeoutBefore] optional timeout before fetching, used between retries
 * @returns {Promise<any>}
 */

async function doRequest(url, params, timeoutBefore) {
  const dispatchRequest = async () => {
    window.outgoing_requests = window.outgoing_requests || 0;
    window.outgoing_requests = window.outgoing_requests + 1;

    const res = await doAjax(url, params);

    window.done_requests = window.done_requests || 0;
    window.done_requests++;

    return res;
  };

  if (timeoutBefore) {
    setTimeout(async () => {
      return await dispatchRequest();
    }, RETRY_TIMEOUT);
  } else {
    return await dispatchRequest();
  }
}

export default Actions;
