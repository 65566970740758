import InteractiveInputComponent from '../interactive-input';
import { InteractiveInputProps } from '../interactive-input/interactive-input.types';
import { InteractivePasswordBlock } from './password-block/password-block.component';

export type AdditionalDataProps = {
  help_text: string;
  required: boolean;
};

export type InteractivePasswordInputContainerState = {
  error: boolean;
  errorText: string;
  additionalData: AdditionalDataProps;
};

// This container serves as an intermediary to pass down inherited props/state
// from InteractiveInputComponent to the Password Block
class InteractivePasswordInputContainer extends InteractiveInputComponent {
  constructor(props: InteractiveInputProps) {
    super(props);
  }

  componentDidUpdate() {
    super.componentDidUpdate();
    this.props.inputValidationChanged('confirm-password');
  }

  render() {
    return (
      !this.props.usedSSO && (
        <InteractivePasswordBlock
          id={this.props.id}
          name={this.props.field_name}
          onChange={this.onChange.bind(this)}
          type={this.props.type}
          maxLength={this.props.max_length}
          minLength={this.props.min_length}
          disabled={this.props.isDisabled}
          value={this.state.value || ''}
          additionalData={this.state.additionalData}
          label={this.props.label}
          placeholder={this.props.label}
          errorText={this.state.errorText}
          fieldError={this.props.fieldError}
          stateError={this.state.error}
          inputValidationChanged={this.props.inputValidationChanged}
        />
      )
    );
  }
}

export default InteractivePasswordInputContainer;
