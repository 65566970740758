import { FunctionComponent, useEffect, useCallback, useState } from 'react';
import { useAppDispatch } from '../../hooks/use-app-react-redux';
import { useHistory } from 'react-router-dom';
import './style.scss';
import Header from '../header/header.container';
import FooterContainer from '../footer';
import Cookies from '../../cookies';
import InputText from '../../components/input-text';
import InputButton from '../../components/input-button/input-button.component';
import { Validator } from '../../components/utils/validator';
import UserApiActions, { ACTION_TYPES } from '../../actions/user-api';
import { loginError, getUserInfo } from '../../actions/user';
import GaService from '../../services/ga-service';
import { redirectLoggedUser } from '../../components/utils/flow-utils';
import { HELLO_SLEEPIO_EMAIL_ADDRESS } from '../../constants/email-addresses';
import LoaderComponent from '../../components/loader';

// make UserInfo type?
// type UserInfo = {}

const PLEASE_VALID_EMAIL = 'Please enter a valid email';
const DEFAULT_SLUG = 'sleepio';

const buildFlowUri = (): string | null => {
  const sl_user_flow = Cookies.get('sl-user-flow');
  const product_slug_cookie = Cookies.get('product_slug');
  const organization_slug = Cookies.get('organization_slug');

  let flow_uri: string | null = '';
  if (sl_user_flow) {
    flow_uri = sl_user_flow.replace(/^\/|\/$/g, '');
  } else if (product_slug_cookie && organization_slug) {
    flow_uri = `${product_slug_cookie}/${organization_slug}`;
  } else {
    flow_uri = null;
  }

  return flow_uri;
};

/**
 * @deprecated prefer frontend/src/components/new-auth/forgot-password/index.js
 */
const ForgotPassword: FunctionComponent = () => {
  const history = useHistory(); // TODO verify that this works the way I expect
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  // setState here not async-safe since it's used in a callback
  const resetPasswordCallback = useCallback(
    (err: any, res: any) => {
      setIsLoading(false);
      if (err) {
        setErrorMessage(err?.response?.message ?? '');
        setIsSuccess(false);
        return;
      }
      if (res?.status === 'error') {
        GaService.forgotPasswordUnsuccessful();
        setErrorMessage(res?.message);
        setIsSuccess(false);
        return;
      }
      GaService.forgotPasswordSuccessful();
      setIsSuccess(true);
      setErrorMessage('');
    },
    [setIsLoading, setErrorMessage, setIsSuccess]
  );

  const handleResetPassword = useCallback(() => {
    setIsLoading(true);

    if (!Validator.isValidEmail(email)) {
      setErrorMessage(PLEASE_VALID_EMAIL);
      setIsLoading(false);
      setIsSuccess(false);
    } else {
      setErrorMessage('');
      const product_slug = window.location.href.split('/')[3] ?? DEFAULT_SLUG; // should product_slug be used in buildFlowUri?
      const flow_uri = buildFlowUri();

      const data = { email, product_slug, flow_uri };
      UserApiActions.post({
        action: ACTION_TYPES.FORGOTPASSWORD,
        redirectUri: null,
        data,
        callback: resetPasswordCallback,
      });
    }
  }, [
    email,
    resetPasswordCallback,
    setIsLoading,
    setErrorMessage,
    setIsSuccess,
  ]);

  // TODO type UserInfo
  const redirectPage = useCallback(
    (userInfo: any) => {
      if (userInfo && userInfo.is_authenticated) {
        redirectLoggedUser(userInfo.id);
      } else {
        setIsInitialized(true);
      }
    },
    [setIsInitialized]
  );

  const verifyLoginError = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('login')) {
      // Login failed
      const error = urlParams.get('error');
      if (error) {
        dispatch(loginError(error));
      }
      history.replace('?', null);
    }
  }, [dispatch]);

  const _onEmailChange = useCallback(
    (value: string) => {
      setEmail(value);
    },
    [setEmail]
  );

  useEffect(() => {
    getUserInfo({ cb: redirectPage })(dispatch);

    // Record forgot pass load page event
    GaService.forgotPasswordLoadEvent();

    // If user tried to login and login failed, we notify login component
    verifyLoginError();
  }, []);

  if (!isInitialized) {
    return <LoaderComponent />;
  }
  return (
    <div>
      <Header />
      <div className="sl-forgot-password">
        <div className="sl-forgot-password--top-content">
          <h1>Reset your password</h1>
          <h2>
            Forgotten your password? No problem. Just enter the email you signed
            up with below and we will send you a new one.
          </h2>
        </div>
        <div className="sl-forgot-password--content">
          <label htmlFor={'recover_email'}>Email address</label>

          <div className="sl-vertical-align">
            <InputText
              preventSpaces
              onChange={_onEmailChange}
              id="recover_email"
              name="recover_email"
              placeholder={'Type your email address'}
              error={!!errorMessage}
              value={email}
              type="email"
              required={true}
              useAutocomplete={false}
              defaultAnswers={[]}
            />
            <span
              className={`${
                isLoading ? 'show' : ''
              } sl-forgot-password--loader`}
            />
            <InputButton value="Reset password" onClick={handleResetPassword} />
          </div>
          {errorMessage && (
            <div className="sl-forgot-password--error">{errorMessage}</div>
          )}
          {isSuccess && !errorMessage && (
            <div className="sl-forgot-password--success">
              Please check your email for further details. Contact{' '}
              <a href={`mailto:${HELLO_SLEEPIO_EMAIL_ADDRESS}`}>
                {HELLO_SLEEPIO_EMAIL_ADDRESS}
              </a>{' '}
              with any questions.
            </div>
          )}
        </div>
      </div>
      <FooterContainer />
    </div>
  );
};

export default ForgotPassword;
