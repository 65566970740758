import SleepioBlueSvg from '../../../svgs/logos/Sleepio_Blue.svg';

export default {
  regex: {
    // MINIMUM 8 CHARACTERS, AND AT LEAST THREE OF THE FOLLOWING:
    // - UPPERCASE LETTER
    // - LOWERCASE LETTER
    // - SYMBOL
    // - DIGIT
    password:
      /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[-!#$%^@&*()_+|~=`{}[\]:";'<>?,./\\])|(?=.*[A-Z])(?=.*\d)(?=.*[-!#$%@^&*()_+|~=`{}[\]:";'<>?,./\\])|(?=.*[A-Z])(?=.*[a-z])(?=.*[-!#$%^@&*()_+|~=`{}[\]:";'<>?,./\\])).{8,}$/,
    email:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  },
  header: {
    sleepio: {
      logo: SleepioBlueSvg,
      url: 'https://www.sleepio.com',
    },
    daylight: {
      logo: 'https://www.trydaylight.com/hs-fs/hubfs/Daylight%20logo.png',
      url: 'https://www.trydaylight.com',
    },
  },
  product: {
    sleepio: {
      color: 'transparent',
    },
    daylight: {
      color: '#FFFFFF',
    },
  },
};
