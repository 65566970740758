// TODO: After theming is goes to 100%, remove this file and replace all instances of it with '/components/footer/footer.component'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConditionalRender from '../../components/conditional-render';
import { FOOTER_ELEMENT } from '../../constants/class-names';
import { Footer } from '../../components/footer/footer.component';
import { useAppSelector } from '../../hooks/use-app-react-redux';
import { isThemed } from '../../helpers/themes';
import './style.scss';

const VerticalBar = props => {
  return (
    <span
      style={{
        height: props?.height || '100%',
        padding: props?.width || '0.5px',
        backgroundColor: props?.color || 'grey',
        margin: props?.margin || '0 0.5em',
      }}
    />
  );
};

VerticalBar.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  color: PropTypes.string,
};

export const FooterContainer = props => {
  const year = new Date().getFullYear();
  const appInfo = useAppSelector(state => state.appReducer);
  const productName = appInfo.productName
    ? appInfo.productName.charAt(0).toUpperCase() + appInfo.productName.slice(1)
    : '';
  const footerIcons = appInfo.footerLogoIcons;
  const hasFooterIcons = !!footerIcons?.length > 0;
  return isThemed() ? (
    <Footer footerTheme={'dark'} />
  ) : (
    <footer className={FOOTER_ELEMENT} data-with-icons={hasFooterIcons}>
      <div
        className={`footer-text`}
        id="footer-wrapper"
        style={{ color: '#231f20' }}
        data-product={appInfo.productName}
        data-with-icons={hasFooterIcons}
      >
        <div>&copy; {year} Big Health</div>
        <div>
          {productName} Onboarding Version {appInfo.version}
        </div>
        <div style={{ marginTop: '0.5em' }}>
          <a
            style={{ color: '#231f20' }}
            href="//trydaylight.com/privacy"
            className={FOOTER_ELEMENT}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          <VerticalBar />
          <a
            href="//trydaylight.com/terms"
            className={FOOTER_ELEMENT}
            target="_blank"
            rel="noreferrer"
            style={{ color: '#231f20' }}
          >
            Terms of Use
          </a>
          <VerticalBar />
          <a
            href="#"
            className={FOOTER_ELEMENT}
            onClick={() => window.CC.showSettings()}
            style={{ color: '#231f20' }}
          >
            Cookie preferences
          </a>
        </div>
      </div>
      <ConditionalRender if={hasFooterIcons}>
        <div id="footer-icons">
          {footerIcons?.map((src, idx) => (
            <img key={idx} src={src} alt="" />
          ))}
        </div>
      </ConditionalRender>
    </footer>
  );
};

const mapStateToProps = state => ({ app: state.appReducer });

FooterContainer.propTypes = {
  app: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(FooterContainer);
