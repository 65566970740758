import { Theme } from '@emotion/react';
import { baseTheme } from './base-theme';

type CssObject = {
  [key: string]: string | number | CssObject;
};

const deepReplaceWithInherit = (themeObj: CssObject) => {
  // themes will not have arrays
  const keys = Object.keys(themeObj);
  const newThemeObject = keys.reduce((newTheme, currentKey) => {
    const currVal = themeObj[currentKey];
    if (typeof currVal === 'object') {
      newTheme[currentKey] = deepReplaceWithInherit(currVal);
      return newTheme;
    }
    newTheme[currentKey] = 'inherit';
    return newTheme;
  }, {} as CssObject);
  return newThemeObject;
};

// this is a super weird edge case to just make all the values inside the Theme be 'inherit'
export const unthemed: Theme = {
  ...((<unknown>(
    deepReplaceWithInherit((<unknown>baseTheme) as CssObject)
  )) as Theme),
  name: 'Unthemed',
};
