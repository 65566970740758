import { Fragment } from 'react';
import { modalHeaderStyle, modalContent } from './need-help-modal.styles';

type NeedHelpModalProps = {
  onClose: () => void;
};

const NeedHelpModal = ({ onClose }: NeedHelpModalProps) => {
  return (
    <Fragment>
      <div css={modalHeaderStyle}>
        <span onClick={onClose}>&#10005;</span>
      </div>
      <hr />
      <div css={modalContent}>
        <h2>Need Help?</h2>
        <p>
          This 6 digit, one-time-password should be available in your
          authenticator app. You can email us for technical support at &nbsp;
          <a href="mailto:hello@bighealth.com">hello@bighealth.com</a>&nbsp; and
          we&apos;ll respond as soon as possible.
        </p>
      </div>
    </Fragment>
  );
};

export default NeedHelpModal;
