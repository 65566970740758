import { FunctionComponent, useState } from 'react';
import { InputText } from '../../../input-text/themed.input-text.component';
import {
  passwordErrorStyle,
  passwordInputContainerStyle,
  showHideIconStyle,
} from './themed.password.styles';
import { VisibilityIcon } from '../../../visibility-icon/visibility-icon.component';
import { onEnterOrSpace } from '../../../utils/util-functions';
import InteractiveInputLabel from '../interactive-input/themed.interactive-input-label.component';
import ErrorMessage from '../../../errors/error-message/error-message.component';

export type InteractivePasswordInputProps = {
  id: number | string;
  name: string;
  onChange: (value: string, isDisabled?: boolean) => void;
  type: string;
  maxLength: number;
  minLength: number;
  disabled?: boolean;
  value: string;
  label: string;
  error?: boolean;
  errorText?: string;
  placeholder: string;
  required: boolean;
  helperText?: string;
};

export const InteractivePasswordInput: FunctionComponent<
  InteractivePasswordInputProps
> = ({
  id,
  name,
  onChange,
  type,
  maxLength,
  minLength,
  disabled = false,
  value,
  label = '',
  error = false,
  errorText,
  placeholder,
  required,
  helperText,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const onIconClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      <InteractiveInputLabel
        id={id}
        text={label}
        mode={'field-label'}
        isAdditionalDataRequired={required}
      />
      <div css={passwordInputContainerStyle}>
        <span>
          <InputText
            inputId={id.toString()}
            name={name}
            type={showPassword ? 'text' : type}
            maxLength={maxLength}
            minLength={minLength}
            disabled={disabled}
            onChange={onChange}
            value={value || ''}
            required={required}
            error={error}
            placeholder={placeholder}
          />
          <span
            aria-label={showPassword ? 'Hide password' : 'Show password'}
            onClick={onIconClick}
            onKeyDown={e => onEnterOrSpace(e, onIconClick)}
            tabIndex={0}
            role="button"
            css={showHideIconStyle}
          >
            <VisibilityIcon shouldShowOpenEye={!showPassword} parentId={id} />
          </span>
        </span>
      </div>
      {helperText && (
        <InteractiveInputLabel id={id} text={helperText} mode={'helper-text'} />
      )}

      {error && !!errorText && (
        <ErrorMessage errorMessage={errorText} css={passwordErrorStyle} />
      )}
    </div>
  );
};
