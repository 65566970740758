import PropTypes from 'prop-types';
import { getRequestTypeForActions } from '../triggers/requests';
import { callTriggerAction } from '../../actions/custom-triggers';
import { buildActionPayload } from '../triggers/payload';
import interactiveConfig from '../triggers/triggers-config';
import { checkConditionAsync } from '../triggers/conditions';
import { asyncForEach } from '../utils/async-for-each';
import OrganizationService from '../../services/org-service';

class TriggerComponent {
  constructor() {
    this.totalCallsToMake = 0;
    this.numberOfMadeCalls = 0;
    this.triggers = [];
    this.redirectURLs = [];
  }

  async fireTriggers(triggers) {
    this.triggers = triggers;
    this.triggers.forEach(trigger => {
      this.totalCallsToMake += trigger.action_ids.length;
    });
    this.triggers.forEach(trigger => {
      asyncForEach(trigger.action_ids, async action => {
        await this.callAction(
          action,
          trigger.product_id,
          trigger.organization_id,
          trigger.action_ids.length
        );
      });
    });
  }

  triggersFinishedFiring() {
    if (this.redirectURLs.length) {
      window.location = this.redirectURLs[this.redirectURLs.length - 1];
    }
  }

  callAction(action, product_id, organization_id) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      action = JSON.parse(JSON.stringify(action));
      const parameterRenaming = interactiveConfig.REQUESTS.PARAMETER_RENAMING;
      const parameterValueChange =
        interactiveConfig.REQUESTS.PARAMETER_VALUE_CHANGE;

      if (action) {
        let entity;
        let method;

        // conditions is an array in which we define objects describing a condition
        // objects are treated as an OR condition -> conditions in object 1 must be met OR conditions in object 2
        // keys inside the object are AND condition -> all the conditions defined as keys must be met
        // i.e. {post_test: false}, {user.idp_name: email} -> either not a post test, OR the user signed up with email
        // i.e. {post_test: false, user.idp_name: email} -> not a post test AND user signed up with email

        // ... therefore, if the action has the wait_for_pending_apis flag, with add this flag to each condition object
        action.conditions = action.conditions || [];
        action.conditions = action.conditions.map(condition => {
          if (action.wait_for_pending_apis) {
            condition.has_pending_requests = false;
          }

          return condition;
        });

        // ... if action had no conditions but the flag was still set, make sure to set it now as a condition
        if (!action.conditions.length) {
          action.conditions.push({ has_pending_requests: false });
        }

        action.hidden_inputs = action.params;
        const organizationId = OrganizationService.getOrgId();

        const request = getRequestTypeForActions(action);
        const payload = buildActionPayload(
          [],
          action,
          parameterRenaming,
          parameterValueChange
        );
        const validForCurrentProduct = product_id
          ? product_id === window.product_id
          : true;
        const validForCurrentOrg = organization_id
          ? organization_id === organizationId
          : true;

        entity = request.service;
        method = request.method;

        // eslint-disable-next-line max-len
        if (
          validForCurrentProduct &&
          validForCurrentOrg &&
          (await checkConditionAsync(null, '', action.conditions, true))
        ) {
          callTriggerAction(
            entity,
            method,
            payload,
            action.service_version,
            redirectURLs => {
              if (redirectURLs) {
                this.redirectURLs.push(redirectURLs);
              }

              this.numberOfMadeCalls += 1;
              if (this.totalCallsToMake === this.numberOfMadeCalls) {
                this.triggersFinishedFiring();
              }

              resolve();
            }
          );
        }
      }
    });
  }
}

TriggerComponent.propTypes = {
  triggerActions: PropTypes.object,
};

export default TriggerComponent;
