import { FunctionComponent } from 'react';
import {
  interactiveFieldLabelStyle,
  interactiveHelperTextStyle,
} from './themed.interactive-input-label.style';

export const testId = 'interactive-input-label-container';
export const fieldLabel = 'field-label';
export const helperText = 'helper-text';

type InteractiveInputLabelProps = {
  id?: number | string;
  text: string;
  isAdditionalDataRequired?: boolean;
  mode: 'field-label' | 'helper-text';
  className?: string;
  hasInput?: boolean;
};

const InteractiveInputLabel: FunctionComponent<InteractiveInputLabelProps> = ({
  id,
  text,
  isAdditionalDataRequired,
  mode,
  className,
  hasInput = true,
}) => {
  const isFieldLabel = mode === 'field-label';
  const isHelperText = mode === 'helper-text';

  return (
    <div
      data-testid={testId}
      css={
        (isFieldLabel && interactiveFieldLabelStyle) ||
        (isHelperText && interactiveHelperTextStyle)
      }
      className={className}
    >
      <label
        htmlFor={hasInput ? id?.toString() : undefined}
        id={typeof id !== 'undefined' ? `${id.toString()}_title` : undefined}
        dangerouslySetInnerHTML={{
          __html: text + (isFieldLabel && isAdditionalDataRequired ? '*' : ''),
        }}
      />
    </div>
  );
};

export default InteractiveInputLabel;
