import React, { useEffect, useState } from 'react';
import { countryCodes } from '../country-code-select/country-codes';
import './phone-number.scss';
import { CountryCode } from '../country-code-select/country-codes.types';
import {
  inputSharedErrorStyle,
  inputSharedStyle,
} from '../../styles/themed.input-shared.styles';
import { phoneNumberErrorStyle, phoneNumberStyle } from './phone-number';
const defaultPlaceholderText = 'Phone Number';

export const errorMessage = 'Please enter a valid 10-digit number';
export const phoneNumberTestId = 'phone-number-testId';

export const PhoneNumber = ({
  countryCode,
  currentValue,
  setPhoneNumber,
  isError,
  style,
}: {
  countryCode: CountryCode | undefined;
  currentValue: string;
  setPhoneNumber: (phoneNumber: string) => void;
  isError: boolean;
  style?: React.CSSProperties;
}) => {
  const [placeholderText, setPlaceholderText] = useState(
    defaultPlaceholderText
  );

  useEffect(() => {
    getPlaceholderText();
  });

  const getPlaceholderText = () => {
    countryCodes.map(countryCodeData => {
      if (countryCodeData.code === countryCode) {
        setPlaceholderText(countryCodeData.format);
      }
    });
  };

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value);
  };

  return (
    <div data-testid={phoneNumberTestId} css={phoneNumberStyle} style={style}>
      <label htmlFor="phone">enter phone number</label>
      <input
        css={isError ? inputSharedErrorStyle : inputSharedStyle}
        id="phone"
        name="phone"
        value={currentValue}
        placeholder={placeholderText}
        onChange={_onChange}
      />
      <div css={isError && phoneNumberErrorStyle}>
        {isError && errorMessage}
      </div>
    </div>
  );
};
