import { useState } from 'react';
import CountryCodeSelect from '../country-code-select/country-code-select';
import './text-to-download.scss';
import { PhoneNumber } from '../phone-number/phone-number.component';
import UserSession from '../../services/user-session';
import InputButton from '../input-button/input-button.component';
import classNames from 'classnames';
import { CountryCode } from '../country-code-select/country-codes.types';

/* SMS consent is to be used later when we start supporting the feature */
// const smsConsentText = `It's ok to text me several tips and encouragements each week.`;

const unneededValidCharactersRegex = /[()-\s]/gi;

const tenNumericalDigitsRegex = /(^[0-9]{10}$)/;

const isValidPhoneNumber = ({ phoneNumber }: { phoneNumber: string }) => {
  if (phoneNumber) {
    // Strip out valid but not needed characters such as parentheses, dashes, spaces
    const strippedPhoneNumber = phoneNumber.replaceAll(
      unneededValidCharactersRegex,
      ''
    );
    // Ensure we have a 10 digit number
    return (
      strippedPhoneNumber && strippedPhoneNumber.match(tenNumericalDigitsRegex)
    );
  }

  return false;
};
// Note: This is only temporary until we support UK numbers
const US_COUNTRY_CODE: CountryCode = '1';

export default function TextToDownload() {
  /* SMS consent is to be used later when we start supporting the feature */
  // const [isSmsConsented, setIsSmsConsented] = useState(false);
  const [countryCode, setCountryCode] = useState<CountryCode>(US_COUNTRY_CODE);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCountryCodeError, setCountryCodeError] = useState(false);
  const [isPhoneNumberError, setPhoneNumberError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onSubmit = () => {
    if (!countryCode) {
      setCountryCodeError(true);
    }

    if (!isValidPhoneNumber({ phoneNumber })) {
      setPhoneNumberError(true);
    }

    if (isValidPhoneNumber({ phoneNumber }) && countryCode) {
      setCountryCodeError(false);
      setPhoneNumberError(false);
      setIsLoading(true);
      const user_id = UserSession.getUserData()?.user_id;
      const body = {
        phone_number: `+${countryCode}${phoneNumber.replaceAll(
          unneededValidCharactersRegex,
          ''
        )}`,
        user_id,
        /* SMS consent is to be used later when we start supporting the feature */
        // sms_marketing_consented: isSmsConsented,
        product_id: 2,
      };

      fetch(
        '/api/service_method_proxy/UserProductMetadata/1/text_to_download_campaign',
        {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      )
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response);
        })
        .then(() => {
          setIsSuccess(true);
          setIsLoading(false);
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <div className="ttd__component">
      <div className="ttd__wrapper">
        <div
          className="ttd__country-phone-wrapper"
          style={{ marginBottom: '24px' }}
        >
          <CountryCodeSelect
            currentSelection={countryCode}
            setCountryCode={setCountryCode}
            style={{ margin: '0 8px 16px 0' }}
            isError={isCountryCodeError}
          />

          <PhoneNumber
            countryCode={countryCode}
            currentValue={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            isError={isPhoneNumberError}
            style={{ margin: '0 8px 0 0' }}
          />
        </div>
        {/* SMS consent is to be used later when we start supporting the feature */}
        {/* <div
					data-testid='ttd-checkbox-consent'
					className='ttd__consent'>
					<input
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) =>
							setIsSmsConsented(
								event.target.checked
							)
						}
						checked={isSmsConsented}
						type='checkbox'
						id='sms-checkbox'
						name='sms-checkbox'
					/>
					<label htmlFor='sms-checkbox'>
						{smsConsentText}
					</label>
				</div> */}
      </div>

      <InputButton
        className={classNames('ttd__submit', {
          'ttd__submit--success': isSuccess,
        })}
        loading={isLoading}
        value={isSuccess ? 'Sent' : 'Send'}
        onClick={onSubmit}
        type="submit"
        showIcon={isSuccess}
        iconClassName="checkmark"
      />
    </div>
  );
}
