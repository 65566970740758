import { FunctionComponent } from 'react';
import { InputText } from '../../../input-text/themed.input-text.component';
import InteractiveInputLabel from '../interactive-input/themed.interactive-input-label.component';
import { wrapperStyle } from './themed.interactive-input-text.component.style';

type additionalDataProps = {
  help_text?: string;
  tooltip?: string;
  required?: boolean;
};

export type InteractiveInputTextProps = {
  fieldError?: string;
  isDisabled?: boolean;
  field_name?: string;
  id?: string;
  type?: string;
  max_length?: number;
  min_length?: number;
  additionalData?: additionalDataProps;
  value: string;
  isReadOnly?: boolean | undefined;
  error: boolean;
  errorText?: string;
  label?: string;
  onChange: (value: string, isDisabled?: boolean) => void;
  isInputRequired?: boolean;
  placeholder?: string;
};

export const InteractiveInputText: FunctionComponent<
  InteractiveInputTextProps
> = ({
  isDisabled,
  id,
  field_name,
  type,
  additionalData,
  max_length,
  min_length,
  value,
  isReadOnly,
  fieldError,
  error,
  errorText,
  label,
  onChange,
  isInputRequired,
  placeholder,
}) => {
  const helperText = additionalData?.help_text;
  const additionalDataRequired = additionalData?.required;

  return (
    <div css={wrapperStyle}>
      {!!label && (
        <InteractiveInputLabel
          id={id}
          text={label}
          isAdditionalDataRequired={additionalDataRequired}
          mode={'field-label'}
        />
      )}
      <InputText
        disabled={isDisabled}
        inputId={id}
        name={field_name}
        type={type}
        maxLength={max_length}
        minLength={min_length}
        required={isInputRequired}
        value={value || ''}
        onChange={onChange}
        readOnly={isReadOnly}
        error={!!fieldError || error}
        errorText={fieldError || errorText}
        placeholder={placeholder}
      />
      {helperText && (
        <InteractiveInputLabel text={helperText} mode={'helper-text'} />
      )}
    </div>
  );
};
