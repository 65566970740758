import React from 'react';
import classnames from 'classnames';
import { useAppSelector } from '../../../hooks/use-app-react-redux';
import selectors from '../../../selectors';
import { PageEntity } from '../../../types/flow.types';
import { EntityTypes } from '../../../constants/entity-types';
import { isOneLineQuestionType } from '../../../constants/question-types';
import FactoryComponent from '../../factory-component';
import { PageContentProps } from '../flow-page.types';
import {
  SL_INTERACTIVE_BODY_CLASS,
  SL_PAGE_ITEM,
  SL_PAGE_ITEM_ONELINE,
} from '../../../constants/class-names';
import CookieConsentComponent from '../../cookie-consent-component/cookie-consent-component';

// this exists because interactive components have custom CSS that modify the entire DOM from the body on down
export function setCustomCssForPage(entity: PageEntity) {
  if (entity.entity_type === EntityTypes.INTERACTIVE_COMPONENT) {
    document.body.classList.add(SL_INTERACTIVE_BODY_CLASS);
  } else if (document.body.classList.contains(SL_INTERACTIVE_BODY_CLASS)) {
    document.body.classList.remove(SL_INTERACTIVE_BODY_CLASS);
  }
}

/**
 * This provides a separate display FC that can be used by react during reconciliation to prevent
 * any needless re-renders of the flow components down the line. It also encapsulates what goes into making
 * a component from the Factory, for the most part. Some further refactoring of the factory may or may not eliminate
 * the need for some of the props here, ie, things like advance or onAnswer or fitBitData could come from
 * redux/thunks later and be pulled in lower in the tree when necessary, rather than passed through here.
 */
const PageContent: React.FunctionComponent<PageContentProps> = ({
  content,
  isNextButtonDisabled,
  fitBitData,
  isValidated,
  answers,
  advance,
  onAnswer,
  validateAnswers,
}) => {
  const { currentPage } = useAppSelector(
    selectors.selectFlowReducerCurrentStepPage
  );
  const validator = useAppSelector(
    selectors.selectFlowReducerCurrentStepValidator
  );
  const continueBtnLoading = useAppSelector(
    selectors.selectFlowReducerContinueButtonLoading
  );
  return (
    <React.Fragment>
      <CookieConsentComponent />
      {content.map((entity, index) => {
        setCustomCssForPage(entity);

        return (
          <div
            key={`${currentPage}-${index}`}
            data-index={index}
            data-semantic-id={entity.semantic_id}
            className={classnames(SL_PAGE_ITEM, {
              [SL_PAGE_ITEM_ONELINE]:
                entity.entity_type === EntityTypes.QUESTION &&
                isOneLineQuestionType(entity.type),
            })}
          >
            <FactoryComponent
              type={entity.entity_type}
              item={entity}
              fitBitData={fitBitData}
              isChecked={isValidated}
              answers={answers}
              advance={advance}
              answerValidator={validator}
              onAnswer={onAnswer}
              nextButtonDisabled={isNextButtonDisabled}
              continueBtnLoading={continueBtnLoading}
              validateAnswersOnPage={validateAnswers}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default React.memo(PageContent);
