import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getConfig from '../../config';
import FitBit from '../../components/sso/fitbit';
import { QuestionValidator } from '../../components/utils/validator';
import { globalEventHandler } from '../../components/utils/global-event';
import {
  findNextStepPageInFlow,
  findConditionsInSamePage,
  checkIfConditionApplies,
} from '../../components/utils/flow-utils';
import { next, jumpTo } from '../../actions/flow';
import AnswersService from '../../services/answers-service';
import * as SignupService from '../../services/signup-service';
import FlowService from '../../services/flow-service';
import EventService from '../../services/event-service';
import { SignupWithAccount } from '../../components/signup-with-account/signup-with-account.component';
import { ThemedSignupWithAccount } from '../../components/signup-with-account/themed.signup-with-account.component';
import { LoginFormProvider } from '../../components/login-form/context';
import { getAnswersForSaving } from './helpers';
import {
  createAnswerFromData,
  getHiddenQuestionsForPage,
} from '../../helpers/answers';
import { EntityTypes } from '../../constants/entity-types';
import {
  QUESTION_TYPES_TO_SHOW_SKIP,
  FITBIT_QUESTION_TO_SHOW,
} from './constants';
import {
  useAppSelector,
  useShallowEqualSelector,
} from '../../hooks/use-app-react-redux';
import Selectors from '../../selectors';
import UnthemedFlowContainer from '../../components/flowpage/unthemed/unthemed-flowpage.container';
import ThemedFlowContainer from '../../components/flowpage/themed/themed-flowpage.container';
import { MULTIPRODUCT_CONTENT, SL_CONTENT } from '../../constants/class-names';
import { isThemed } from '../../helpers/themes';
import { SET_COUNTRY } from '../../constants/action-types';

const FlowPageContainer = () => {
  // selectors
  const dispatch = useDispatch();
  const flow_id = useAppSelector(Selectors.selectFlowReducerFlowId);

  const history = useHistory();
  const userFitBitToken = useAppSelector(
    Selectors.selectUserReducerUserFitBitToken
  );
  const { currentPage, currentStep } = useShallowEqualSelector(
    Selectors.selectFlowReducerCurrentStepPage
  );
  const organization_id = useAppSelector(
    Selectors.selectFlowReducerCurrentFlowOrganizationId
  );

  const currentFlow = useAppSelector(Selectors.selectFlowReducerCurrentFlow);
  const steps = useAppSelector(Selectors.selectFlowReducerStepsFromCurrentFlow);
  const hiddenQuestions = useAppSelector(
    Selectors.selectFlowReducerHiddenQuestionsFromCurrentFlow
  );
  const pages = useAppSelector(Selectors.selectFlowReducerPages);
  const continueFunction = useAppSelector(
    Selectors.selectFlowReducerContinueFn
  );
  const product_id = useAppSelector(Selectors.selectFlowReducerProductId);
  const shouldDisplaySignupExistingUserInFlow = useAppSelector(
    Selectors.selectFlowReducerShouldDisplaySignupExistingUserInFlow
  );

  // state
  const content = useMemo(
    () => pages[currentPage]?.entity ?? [],
    [pages, currentPage]
  );
  const [answers, setAnswers] = useState(
    AnswersService.getAnswersForPage(currentStep, currentPage) || []
  );
  const [questionErrors, setQuestionErrors] = useState([]);
  const [fitBitData, setFitBitData] = useState();
  const [needsFitBitLogin, setNeedsFitBitLogin] = useState(false);
  const [pageChecked, setPageChecked] = useState(false);
  const [pageConditions, setPageConditions] = useState([]);
  const [testStartedEventTriggered, setTestStartedEventTriggered] =
    useState(false);

  const hasMountedRef = useRef(false); // used to replicate effects of componentDidUpdate (IE, only run on change, not init)
  const isLastPageOfLastStep =
    currentStep >= steps.length - 1 && currentPage >= pages.length - 1;
  const isLastPageOfLastStepOnPostFlow =
    isLastPageOfLastStep && FlowService.isPostFlow();

  const hasCustomCTA = useCallback(() => {
    return !!content.find(item => {
      // Try to find a static component in this page that has a 'data-sl-next' attr
      if (item.entity_type === EntityTypes.STATIC_COMPONENT) {
        return (
          item.content[0].body &&
          item.content[0].body.indexOf('data-sl-next') > -1
        );
      }
      return false;
    });
  }, [content]);

  const isFlowThemed = isThemed();

  /**
   * Triggered by child components when an answer is registered on a question
   * @param {Number} idx index of current item w.r.t. all the items from the page
   * @param {Object} data representing the answer payload for a certain question
   * @param {boolean} [hasErrors] valid component
   */
  // BTC - ALL answers _picked by a user_ are set through this fn, is a terrible yet currently the best place to pull lots of data about the flow that subcomponents don't know about. Known caveat that hidden answers don't go through this.
  const setAnswer = useCallback(
    (data, hasErrors) => {
      // TODO: (Alex) The whole answer - question - input types functionality is buggy: answers are being sent back and forth
      // in multiple ways - not consistent - which is very error prone. This should be standardized for all input types and
      // refactored.

      // this munges known step/page into "data" returned by answer components that don't have this immediately available
      // BTC TODO: long term this should come from redux or a context
      const answerPlusMoreData = createAnswerFromData({
        ...data,
        stepNumber: currentStep,
        pageNumber: currentPage,
      });
      AnswersService.updateAnswersOnPage(
        [answerPlusMoreData],
        currentStep,
        currentPage
      );

      setAnswers((prevAnswers = []) => {
        const existingAnswers = [...prevAnswers];
        const existingQuestionIndex = existingAnswers.findIndex(
          a => a.question_id === answerPlusMoreData.question_id
        );
        if (existingQuestionIndex >= 0) {
          const { id } = existingAnswers[existingQuestionIndex];
          existingAnswers[existingQuestionIndex] = {
            ...answerPlusMoreData,
            id,
          };
        } else {
          existingAnswers.push(answerPlusMoreData);
        }

        return existingAnswers;
      });

      setQuestionErrors((prevQuestionErrors = []) => {
        /*
        questionErrors shape:
          [
            {
              "question_id": 199,
              "hasErrors": false
            },
            {
              "question_id": 5,
              "hasErrors": false
            }
          ]
        */
        const existingErrors = [...prevQuestionErrors];

        const existingErrorsIndex = existingErrors.findIndex(
          a => a.question_id === answerPlusMoreData.question_id
        );
        if (existingErrorsIndex >= 0) {
          existingErrors[existingErrorsIndex].hasErrors = hasErrors;
        } else {
          existingErrors.push({
            question_id: answerPlusMoreData.question_id,
            hasErrors,
          });
        }

        return existingErrors;
      });
    },
    [currentStep, currentPage, setAnswers, setQuestionErrors]
  );

  const _onHashChange = ev => {
    // Ignore programmatic hash changes triggered by reducer when navigating
    if (window.location.ignoreHashChange) {
      window.location.ignoreHashChange = false;
      return;
    }

    // These variables come from the internals, and are offsets.
    const theStep = parseInt(currentStep, 10);
    const thePage = parseInt(currentPage, 10);

    const newHash = new URL(ev.newURL).hash;

    // These variables are from the hash, and so are indices and are offset by +1.
    const page = parseInt(newHash.split('/')[1], 10);
    let step = newHash.split('/')[0];
    step = step ? parseInt(step.split('#')[1], 10) : null;

    if (isNaN(page) || isNaN(step)) {
      return;
    }

    const targetStep = step - 1;
    const targetPage = page - 1;

    if (continueFunction) {
      // if continueFunction present, we are navigating off username without advancing (IE, back)
      // we should purge unsaved answers in this case
      // can probably remove this if continueFunction is ever cleaned up
      AnswersService.purgeUnsavedCommunityUsernameAnswer();
    }

    if (page !== thePage || step !== theStep) {
      setQuestionErrors([]);
      dispatch(
        jumpTo(
          null,
          currentStep,
          currentPage,
          targetStep,
          targetPage,
          currentFlow,
          false,
          false,
          isLastPageOfLastStep
        )
      );
    }
  };

  /** Sets initial hash and handles hash change to be able to navigate on demand */
  const handleHashChange = useCallback(() => {
    history.replace(
      `#${parseInt(currentStep, 10) + 1}/${parseInt(currentPage, 10) + 1}`,
      null
    ); // put currentStep/Page with offset +1 into hash

    // Then listen for hash changes
    setTimeout(() => {
      window.onhashchange = _onHashChange;
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    currentStep,
    currentFlow,
    setQuestionErrors,
    history,
    isLastPageOfLastStep,
    dispatch,
    continueFunction,
  ]);

  const testStartedEvent = useCallback(() => {
    if (testStartedEventTriggered || FlowService.isPostFlow()) {
      return;
    }

    try {
      const flowData = {
        fields: {
          reference: flow_id,
        },
        id: flow_id,
        user_id: SignupService.getUserUuid(),
        product_id: window.product_id,
        organization_id,
      };

      EventService.testStartedEvent(flowData);
      setTestStartedEventTriggered(true);
    } catch (err) {
      console.error('testStartedEvent: ', err);
    }
  }, [
    testStartedEventTriggered,
    flow_id,
    organization_id,
    setTestStartedEventTriggered,
  ]);

  const validateAnswersOnPage = useCallback(() => {
    setPageChecked(true);
  }, [setPageChecked]);

  const dataMapping = useCallback(
    fitBitData => setFitBitData(fitBitData),
    [setFitBitData]
  );

  const advance = useCallback(
    (event, isSkipped = false) => {
      if (event) {
        const clearAllAnswers = event.target
          ? event.target.getAttribute('data-clear-all-answers') === 'true'
          : false;
        if (clearAllAnswers) {
          AnswersService.removeAnswers();
        }

        // HACK: If the initiating Step and Page were passed in, we make
        // sure to compare them to the current step and page. We only
        // advance if they match. Otherwise, we assume that
        // this call to advance is out of date.
        if (event.initiatingStepAndPage) {
          let currentStepAndPage = FlowService.loadLocalProgress();
          if (
            event.initiatingStepAndPage.currentStep !==
              currentStepAndPage.currentStep ||
            event.initiatingStepAndPage.currentPage !==
              currentStepAndPage.currentPage
          ) {
            return;
          }
        }
      }

      if (continueFunction) {
        // KILL THIS PLZ
        return continueFunction();
      }

      if (isLastPageOfLastStep && !FlowService.isPostFlow()) {
        _testCompletedEvent();
      }

      const answersForSaving = getAnswersForSaving({
        isSkippedQuestion: isSkipped,
        pages,
        currentPage,
        currentStep,
        shouldHideEntity,
      });
      const hiddenAnswers = getHiddenQuestionsForPage({
        hiddenQuestions,
        answers: answersForSaving,
        currentPage,
        currentStep,
      });
      answersForSaving.push(...hiddenAnswers);

      const { jumpToStep, jumpToPage } = event?.currentTarget?.dataset || {};
      const nextStepPageInFlow = findNextStepPageInFlow({
        currentStep,
        currentPage,
        flow: currentFlow,
        answers: answersForSaving,
        shouldSaveHiddenAnswers: true,
      });

      if (jumpToStep || nextStepPageInFlow.isJump) {
        // if jumpToStep is truthy, we force skip. Otherwise, we don't.
        const shouldForceSkipPages = !!jumpToStep;
        const step = parseInt(jumpToStep ?? nextStepPageInFlow.step, 10);
        const page = parseInt(jumpToPage ?? nextStepPageInFlow.page, 10) || 0;
        dispatch(
          jumpTo(
            answersForSaving,
            currentStep,
            currentPage,
            step,
            page,
            currentFlow,
            true,
            shouldForceSkipPages, // forceSkipPages
            isLastPageOfLastStep
          )
        );

        // Update answers now if any exist, bypasses getDerivedStateFromProps in QuestionComponent
        // this is so that the first render of future components has the correct answers prop to match the question details
        const nextAnswers = AnswersService.getAnswersForPage(step, page) || [];
        setAnswers(nextAnswers);
        return;
      }

      dispatch(
        next(
          answersForSaving,
          currentPage === pages.length - 1 ? 'step' : 'page',
          currentStep,
          currentPage,
          currentFlow,
          isLastPageOfLastStep
        )
      );

      // Update answers now if any exist, bypasses getDerivedStateFromProps in QuestionComponent
      // this is so that the first render of future components has the correct answers prop to match the question details
      setAnswers(
        AnswersService.getAnswersForPage(
          nextStepPageInFlow.step,
          nextStepPageInFlow.page
        ) || []
      );
    },
    [
      continueFunction,
      currentFlow,
      hiddenQuestions,
      currentPage,
      currentStep,
      pages,
      shouldHideEntity,
      isLastPageOfLastStep,
      _testCompletedEvent,
      dispatch,
    ]
  );

  // This ref exists to keep track of the reference to the advance function references for re-binding
  const oldAdvance = useRef(advance);
  const bindButtons = useCallback(() => {
    // Bind custom CTA buttons to advance. These are likely not react components, which is why this is done with document selectors instead of react refs.
    const isCustom = hasCustomCTA();
    if (isCustom) {
      const buttons = Array.from(document.querySelectorAll('[data-sl-next]'));
      buttons.forEach(button => {
        // remove/addEventListener _require_ a function reference. But because of hooks, we don't have access to the old one. For this reason, we keep track of the old one in a ref. When a _new_ advance function reference happens, this function will get called. It will strip the old (memoized) advance function, with its stale references and hook up the new advance function instead
        button.removeEventListener('click', oldAdvance.current, true);
        button.addEventListener('click', advance, true);
      });
    }
    // continueFunction is added here _just_ to cover our bases. Can't really hurt.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCustomCTA, advance, continueFunction]);

  // this function really needs to become stateless
  const shouldHideEntity = useCallback(
    entity => {
      if (!pageConditions?.length) {
        return false;
      }

      const visibleAndHiddenAnswers = [...answers];
      const currentCondition = pageConditions.find(
        cond => cond.to_id === entity.id
      );
      if (!currentCondition) {
        return false;
      }
      let answerForCondition = answers.find(
        answer => answer.question_id === currentCondition.from_id
      );
      if (!answerForCondition && hiddenQuestions.length) {
        const hiddenQuestion = hiddenQuestions.find(
          item => item.id === currentCondition.from_id
        );
        if (hiddenQuestion) {
          answerForCondition = {
            question_id: hiddenQuestion.id,
            values: hiddenQuestion.content[0].answers.find(
              item =>
                item.id.toString() ===
                hiddenQuestion.defaultAnswerKey.toString()
            ),
          };
          // this appears to be so that hidden answers that are not in the answers state are accounted for
          visibleAndHiddenAnswers.push(answerForCondition);
        }
      }

      // Hide the entity if the start condition has no answer or if the answer is not the proper one
      return (
        !answerForCondition ||
        !checkIfConditionApplies(currentCondition, visibleAndHiddenAnswers)
      );
    },
    [answers, hiddenQuestions, pageConditions]
  );

  const _testCompletedEvent = useCallback(() => {
    if (FlowService.isPostFlow()) {
      return;
    }

    try {
      let flowData = {
        fields: {
          reference: flow_id,
        },
        id: flow_id,
        product_id: window.product_id,
        user_id: SignupService.getUserUuid(),
        session_id: SignupService.getUserSessionId(),
      };
      EventService.testCompletedEvent(flowData);
    } catch (err) {
      console.log('_testCompletedEvent: ', err);
    }
  }, [flow_id]);

  /** Once mounted, check for things like Custom CTAs that have to be binded and FitBit login */
  // componentDidMount
  useEffect(() => {
    const pathArray = window.location.pathname.split('/');
    SignupService.setUserFlow(`/${pathArray[1]}/${pathArray[2]}`);

    handleHashChange();

    // not sure this can ever be true at this time?
    if (hasCustomCTA()) {
      const buttons = Array.from(document.querySelectorAll('[data-sl-next]'));
      buttons.forEach(button => {
        button.addEventListener('click', advance, true);
      });

      globalEventHandler(
        EventService.eventHandlerOnElementEvent,
        flow_id,
        product_id // this should come from somewhere else
      );
    }

    const countries = document.querySelectorAll('[class^="country--"]');

    countries.forEach(country => {
      let value = country.innerHTML.toLowerCase();
      if (value === 'uk' || value === 'us') {
        dispatch({ type: SET_COUNTRY, value: value });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /** After every component update, this code binds the next action to any
   * dynamically injected Next CTA (!?)
   */
  // componentDidUpdate
  useEffect(() => {
    EventService.analytics.addEventProperties({
      flow_id,
      currentPage,
      currentStep,
    });
    if (parseInt(currentPage, 10) === 0 && parseInt(currentStep, 10) === 0) {
      testStartedEvent();
    }

    const contentElement = document.querySelector('[role="main"]');

    if (hasMountedRef.current && contentElement) {
      // Scroll to top
      contentElement.scrollIntoView();
      contentElement.scrollTop = 0;
      // Check if any of the components of the page require fitbit login
    }

    document.querySelector('body').scrollTop = 0;

    setNeedsFitBitLogin(content.find(item => item.need_fit_bit_login));
    setPageChecked(false);

    bindButtons();

    // Load any already-saved answers for page
    setAnswers(
      AnswersService.getAnswersForPage(currentStep, currentPage) || []
    );

    // Find all conditions in this page
    const hiddenPageQuestions = hiddenQuestions.filter(
      item => item.page === currentPage && item.step === currentStep
    );
    setPageConditions(findConditionsInSamePage(content, hiddenPageQuestions));

    // this also only runs on page/step changes to prevent duplicate events
    EventService.screenViewedEvent(currentFlow, currentStep, currentPage);
    // explicitly only run this block when currentStep/currentPage change, per previous componentDidUpdate logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return function clean() {
      EventService.analytics.clearEventProperties();
    };
  }, [currentPage, currentStep]);

  /* 
  These useEffects exist to update click handling of custom CTA buttons that aren't necessarily react components.
  When bindButtons re-evals (because advance re-evals), we re-bind those buttons with the new advance and then update
  our oldAdvance ref to point to the new ref. The need for this logic vanishes entirely when all we have to do is bind an action dispatch, and the dispatched thunk itself will have access to current stateful logic
  */
  useEffect(() => {
    // remove this when continueFunction is killed as a concept or advance extracted to redux
    bindButtons();
  }, [bindButtons]);
  useEffect(() => {
    oldAdvance.current = advance;
  }, [advance]);

  // used to emulate componentDidUpdate
  useEffect(() => {
    hasMountedRef.current = true;
    return () => {
      hasMountedRef.current = false; // maybe unnecessary unsub?
    };
  }, []);

  // old render start
  const answerValidator = steps[currentStep].validator;
  const numberOfGivenAnswers = AnswersService.getAnswersAsArray().length;

  document
    .getElementsByTagName('body')[0]
    .setAttribute('data-answers', `${numberOfGivenAnswers}`);

  if (isLastPageOfLastStepOnPostFlow) {
    _testCompletedEvent();
    AnswersService.removeAnswers();
    FlowService.removeLocalProgress();
  }

  // this can be condensed a bit
  let suppressNextButton = isLastPageOfLastStepOnPostFlow;
  if (content.length > 0) {
    content.forEach(item => {
      if (
        getConfig().suppressNextButtonForComponents.indexOf(item.entity_type) >
        -1
      ) {
        suppressNextButton = true;
      }
    });
  }
  const renderNextButton = !hasCustomCTA() && !suppressNextButton;

  // KILL THIS PLZ
  const onContinue = continueFunction ? continueFunction : advance;

  // Pick only visible entities to render
  const onlyVisibleEntities = useMemo(
    () => content.filter(entity => !shouldHideEntity(entity)),
    [content, shouldHideEntity]
  );

  // Check visible questions
  const visibleQuestionIds = useMemo(() => {
    // start with only visible entities and filter down further
    return onlyVisibleEntities.reduce((acc, item) => {
      if (item.entity_type !== EntityTypes.QUESTION) {
        return acc;
      }

      // not sure what this is, but it existed before.
      // it looks like we want to _show_ the question if we don't have an answer for it already in storage
      // IE the question can be answered by the user _or_ by a fitbit import
      const shouldHideFitBitField = !(
        localStorage.getItem(FITBIT_QUESTION_TO_SHOW) &&
        JSON.parse(localStorage.getItem(FITBIT_QUESTION_TO_SHOW)) !== null
      );
      if (shouldHideFitBitField) {
        acc.push(item.id);
      }
      return acc;
    }, []);
  }, [onlyVisibleEntities]);

  // entities above have _already_ run through shouldHideEntity. This means that the condition has already been checked for any answers that are in the state
  // thus, if the question the answer is tied to is not visible, the answer should be removed. Hidden Question answers are dealt with in a different way.
  useEffect(() => {
    const filterFn = _prev => {
      const _new = _prev.filter(item =>
        visibleQuestionIds.includes(item.question_id)
      );
      // if the filter doesn't change the length, return the _same reference_ to answers to prevent re-renders
      return _new.length === _prev.length ? _prev : _new;
    };

    setAnswers(filterFn);
    setQuestionErrors(filterFn);

    // visibleQuestionIds changes whenever answers changes and causes an infinite loop, which is why we want to keep referential integrity with the old answers

    // do we need to filter errors too? Probably.
  }, [visibleQuestionIds, setAnswers, setQuestionErrors]);

  // not sure why this is done here
  if (localStorage.getItem(FITBIT_QUESTION_TO_SHOW)) {
    localStorage.removeItem(FITBIT_QUESTION_TO_SHOW);
  }

  // Next button is disabled if not all the questions are answered
  // this can probably be extracted or simplified
  let nextButtonDisabled =
    questionErrors.findIndex(error => !!error.hasErrors) > -1;
  if (visibleQuestionIds.length > 0 && !nextButtonDisabled) {
    visibleQuestionIds.forEach(id => {
      if (
        answers.filter(a => {
          return (
            a.question_id === id &&
            (Object.keys(a.values || {}).length ||
              ['string', 'number'].indexOf(typeof a.values) > -1)
          );
        }).length === 0
      ) {
        nextButtonDisabled = true;
      }
      // extra validation for inputs type text/number
      answers.forEach(a => {
        if (a.question_id && a.values !== 0 && !a.values) {
          nextButtonDisabled = true;
        }
      });
    });
  }
  if (
    answerValidator &&
    answers.filter(a => a.values).length &&
    QuestionValidator.validateAnswers(
      answers.filter(a => a.values),
      answerValidator
    ).length
  ) {
    nextButtonDisabled = true;
  }

  // Check if there's only one question and can be skipped
  // this can also be condensed so reduce the number of content traversals
  let showSkip;
  // this shows skip even if the only question is hidden, which I think is incidental and unimportant
  // can probably logically replace questionsOnPage with visibleQuestionIds
  const questionsOnPage = content.filter(
    item => item.entity_type === EntityTypes.QUESTION
  );
  if (questionsOnPage.length === 1 && questionsOnPage[0]?.content?.length) {
    const questionType = questionsOnPage[0].type;
    showSkip =
      QUESTION_TYPES_TO_SHOW_SKIP.includes(questionType) &&
      questionsOnPage[0].content[0].skip_able_text;
  }

  let isTextToDownloadQuestion =
    questionsOnPage.length === 1 &&
    questionsOnPage[0]?.content?.length &&
    questionsOnPage[0].type === 'text-to-download';

  // this is a hack to speed up refactor
  const FitBitElement = useMemo(() => {
    if (needsFitBitLogin && userFitBitToken) {
      return <FitBit mapData={dataMapping} />;
    }
  }, [needsFitBitLogin, userFitBitToken, dataMapping]);

  // render, do not use hooks past here

  // this boolean + the only known interactive component === SignupWithAccount
  // would be good to get a better way of identifying it eventually
  if (shouldDisplaySignupExistingUserInFlow) {
    if (isFlowThemed) {
      return (
        <LoginFormProvider>
          <ThemedSignupWithAccount
            advance={advance}
            item={content.find(
              item => item.entity_type === 'interactive-v2-component'
            )}
          />
        </LoginFormProvider>
      );
    }

    return (
      <LoginFormProvider>
        <SignupWithAccount
          advance={advance}
          item={content.find(
            item => item.entity_type === 'interactive-v2-component'
          )}
        />
      </LoginFormProvider>
    );
  }

  if (isFlowThemed) {
    return (
      <ThemedFlowContainer
        FitBitElement={FitBitElement}
        advance={advance}
        answers={answers}
        content={onlyVisibleEntities}
        fitBitData={fitBitData}
        isNextButtonDisabled={
          isTextToDownloadQuestion ? false : nextButtonDisabled
        }
        isNextButtonVisible={renderNextButton}
        isValidated={pageChecked}
        onAnswer={setAnswer}
        onContinue={onContinue}
        showSkippableText={showSkip}
        validateAnswers={validateAnswersOnPage}
      />
    );
  }

  return (
    <UnthemedFlowContainer
      FitBitElement={FitBitElement}
      advance={advance}
      answers={answers}
      content={onlyVisibleEntities}
      fitBitData={fitBitData}
      isNextButtonDisabled={nextButtonDisabled}
      isNextButtonVisible={renderNextButton}
      isValidated={pageChecked}
      onAnswer={setAnswer}
      onContinue={onContinue}
      showSkippableText={showSkip}
      validateAnswers={validateAnswersOnPage}
    />
  );
};

export default React.memo(FlowPageContainer);
