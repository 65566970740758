import getConfig from '../../../config';
import InteractiveComponentV2Service from '../../../services/interactive-component-v2';
import interactiveConfig from '../../triggers/triggers-config';
import AnswersService from '../../../services/answers-service';

// this list will grow rapidly as new values to get from local storage are wanted
type fieldNameArgs = 'date_of_birth' | 'gender';

export function getStoredValueByFieldName(fieldName: fieldNameArgs) {
  const dobSemantic =
    fieldName === 'date_of_birth'
      ? getConfig().semanticIds.dateOfBirthQuestion
      : '';

  const genderSemantic =
    fieldName === 'gender' ? getConfig().semanticIds.genderQuestion : '';

  if (dobSemantic) {
    return (
      InteractiveComponentV2Service.getUserValues(
        interactiveConfig.INPUT_FIELD_NAMES.DATE_OF_BIRTH[0]
      ) ||
      AnswersService.getAnswerBySemantic(dobSemantic) ||
      ''
    );
  }

  if (genderSemantic) {
    return (
      InteractiveComponentV2Service.getUserValues(
        interactiveConfig.INPUT_FIELD_NAMES.GENDER[0]
      ) ||
      AnswersService.getAnswerBySemantic(genderSemantic) ||
      ''
    );
  }

  // does a hail mary to get the value but will throw type error without adding key to type list
  return InteractiveComponentV2Service.getUserValues(fieldName);
}
