import React, { FunctionComponent, useEffect, useState } from 'react';

import { InputNumberProps } from './input-number.types';
import { QuestionValidation } from '../../../src/types/questions.types';
import ErrorMessage from '../errors/error-message/error-message.component';
import {
  inputSharedErrorStyle,
  inputSharedStyle,
} from '../../styles/themed.input-shared.styles';

export const InputNumber: FunctionComponent<InputNumberProps> = ({
  lang,
  details,
  type,
  validation,
  inputId,
  value,
  isChecked,
  onChange,
}) => {
  const [isInvalidNumber, setIsInvalidNumber] = useState(false);
  const [isInvalidRange, setIsInvalidRange] = useState(false);

  // Just once on land, check if the value is in range and a number, if not, disable the Continue button
  useEffect(() => {
    if (value || value === 0) {
      onChange(
        value,
        checkInvalidRange({ value: value, validation }) ||
          checkInvalidNumber({ value: value })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check to see if the number is in range of the min and max number
  const checkInvalidRange = ({
    value,
    validation,
  }: {
    value: number;
    validation: QuestionValidation;
  }) => {
    const maxNumber =
      typeof validation?.maximumNumber === 'string'
        ? parseInt(validation?.maximumNumber, 10)
        : NaN;

    if (value) {
      const invalidRange = maxNumber < value || value < 0;

      setIsInvalidRange(invalidRange);
      return invalidRange;
    }

    setIsInvalidRange(false);
    return false;
  };

  // Only allow numbers
  const checkInvalidNumber = ({ value }: { value: number }) => {
    const isInvalid = !Number.isInteger(value);

    setIsInvalidNumber(isInvalid);
    return isInvalid;
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = parseInt(event?.target?.value, 10);

    onChange(
      target,
      checkInvalidRange({ value: target, validation }) ||
        checkInvalidNumber({ value: target })
    );
  };

  const doesLangMatch = details?.lang === lang;
  const placeholderText = doesLangMatch ? details?.placeholder : '';
  const showError =
    (isChecked && !value && value !== 0) || isInvalidNumber || isInvalidRange;

  return (
    <>
      <input
        id={inputId}
        type={type}
        css={showError ? inputSharedErrorStyle : inputSharedStyle}
        placeholder={placeholderText}
        value={value ?? ''}
        onChange={onInputChange}
        data-testid="question-number-text"
        aria-label={'Enter ' + placeholderText}
      />

      {isInvalidRange && (
        <ErrorMessage
          errorMessage={validation?.maximumNumberText?.toString()}
        />
      )}
    </>
  );
};
