import React from 'react';
import FooterContainer from '../../containers/footer';
import './style.scss';
import CookieConsentComponent from '../cookie-consent-component/cookie-consent-component';

const ErrorPage = () => {
  const email =
    window.product_id === 1 ? 'hello@sleepio.com' : 'hello@trydaylight.com';

  return (
    <div className="sl-empty-layout">
      <div className={'sl-error-page'}>
        <div className={'sl-error-page__inner'}>
          <h2>Whoops!</h2>
          <h3>It seems something has gone wrong.</h3>
          <h4>
            Please reach out to <a href={`mailto:${email}`}>{email}</a> to get
            you back up and running.
          </h4>
        </div>
      </div>
      <FooterContainer />
      <CookieConsentComponent />
    </div>
  );
};

export default ErrorPage;
