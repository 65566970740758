import { css } from '@emotion/react';
import {
  DARK_BLUE,
  ERROR,
  INTERACTIVE_V2_LIGHT_BLUE,
  PALE_GRAY,
  WHITE,
} from './colors';

const footerHeight = '110px';
const headerHeight = '90px';
const componentFontSize = '20px';
const progressWrapperHeight = '4px';
const innerContentPadding = '20px';
const innerContentWidth = '600px';
const headerHeightTablet = '70px';
const componentFontSizeTablet = '16px';
const tabletWidth = '700px';
const mobileHeight = '365px';
const mobileWidth = '375px';

const reset = `
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
select,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
`;

const innerContent = `
  margin: 0 auto;
  padding: 0 ${innerContentPadding};
  width: ${innerContentWidth};
  max-width: 100%;
  box-sizing: border-box;
`;

// these are for static components to look correct right now
const inputDefaults = `
  input,
  textarea,
  select,
  button,
  h2,
  .sl-content {
    font-size: 20px;
  }`;

export default css`
  ${reset}

  html,
  body {
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  body {
    font: 14px GT-Walsheim, Helvetica, Arial, sans-serif;
    line-height: 1.4em;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow: auto;
    background: ${PALE_GRAY};
  }

  input,
  button {
    font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
    font-weight: 500;
  }

  ${inputDefaults}

  #app {
    height: 100%;
  }
`;

export const unthemedGlobalStyles = css`
  ${reset}

  * {
    // This makes safari mobile scroll work smoothly (as in normal)
    -webkit-overflow-scrolling: touch;
  }

  html,
  body {
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  body {
    font: 14px GT-Walsheim, Helvetica, Arial, sans-serif;
    line-height: 1.4em;
    margin: 0 auto;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    font-smoothing: antialiased;
    font-weight: 500;
    color: ${WHITE};
    background: ${DARK_BLUE};
    // Scroll on content, not body
    //position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto !important;

    // Scroll on body when a modal is opened
    &.body--scrollable {
      overflow-y: auto;
      overflow-x: hidden !important;

      .sl-header,
      .sl-progress-wrapper {
        position: relative;
      }
      .sl-progress-wrapper {
        margin-top: 0;
      }
      .sl-content,
      .sl-dynamic-content {
        margin-top: -94px;
      }

      .sl-forgot-password {
        padding-top: 60px;
        min-height: calc(100vh - ${footerHeight} - ${headerHeight}) !important;
        height: auto !important;
      }

      #app,
      .sl-flow {
        &[data-step='0'] {
          .sl-content {
            margin-top: -90px;
          }
        }
      }
    }
  }

  a {
    color: #1cddff;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #b3f4ff;
    }
    &.active {
      color: #b3f4ff;
    }
  }

  h1 {
    font-size: 30px;
  }

  input,
  textarea,
  select,
  button,
  h2,
  .sl-content {
    font-size: ${componentFontSize};
  }

  select:disabled {
    opacity: 0.7;
  }

  input,
  textarea {
    &:read-only {
      background-color: ${INTERACTIVE_V2_LIGHT_BLUE};
    }
  }

  input,
  button {
    font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
    font-weight: 500;
  }

  #app,
  .sl-flow {
    height: 100%;
    -webkit-text-size-adjust: 100%;
    &[data-step='0'] {
      .sl-content {
        padding-top: 90px;
        /* Fix for IE11 landing page */
        @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
          width: 100%;
        }
      }
    }
  }

  .sl-content {
    min-height: calc(100% - ${footerHeight}) !important;
    height: auto !important;
    padding: calc(${headerHeight} + ${progressWrapperHeight}) 0 20px 0;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
  }

  _:-ms-fullscreen,
  :root .sl-content {
    min-height: 100% !important;
    margin-bottom: -${footerHeight} !important;
    padding-bottom: ${footerHeight} !important;
  }

  #app,
  .sl-flow {
    &[data-step='0'][data-page='0'] {
      .sl-content {
        padding-bottom: 0;
      }
    }
  }

  .sl-dynamic-content {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: -1;
    display: none;
  }

  .sl-error {
    color: ${ERROR};
    margin-top: 10px;
    &.sl-error--eligibility {
      margin-bottom: 20px;
    }
  }

  .sl-inner-content {
    ${innerContent}
  }

  @media screen and (max-width: ${tabletWidth}) {
    input,
    textarea,
    select,
    button,
    h2,
    .sl-content {
      font-size: ${componentFontSizeTablet};
    }

    #app,
    .sl-flow {
      &[data-step='0'] {
        .sl-content {
          padding-top: ${headerHeightTablet};
        }
      }
    }

    .sl-content {
      min-height: calc(100% - ${footerHeight}) !important;
      height: auto !important;
      padding-top: 74px;
    }
  }

  @media screen and (min-width: 2000px) {
    body {
      height: 100vh;
    }
  }

  // fix for mobile landscape inputs not reachable
  @media screen and (max-height: ${mobileHeight}) {
    .sl-content {
      padding-top: 0;
    }
  }

  .headline {
    font-size: 30px;
    @media screen and (max-width: ${mobileWidth}) {
      font-size: 24px;
    }
  }

  .flex {
    display: flex;
  }

  .row {
    flex-direction: row;
  }

  .column {
    flex-direction: column;
  }

  .space-between {
    justify-content: space-between;
  }

  .space-center {
    justify-content: center;
  }

  .self-end {
    align-self: end;
  }

  .white {
    color: white;
  }

  .center {
    text-align: center;
  }

  // These are overwriting styles in a 3rd party tooltip
  .react-tooltip {
    background-color: #ffffff !important;
    padding: 24px !important;
    border-radius: 8px !important;
    filter: drop-shadow(0px 1px 24px rgba(0, 0, 0, 0.25)) !important;
    opacity: 1 !important;
    @media screen and (max-width: ${mobileWidth}) {
      left: 0 !important;
    }
  }
`;
