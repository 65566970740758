import getConfig from '../config';
import { CurrentFlow, EntityAction } from '../types/flow.types';
import { EntityTypes } from '../constants/entity-types';
import { getUtmParamsFromLocation } from '../helpers/utm-params';
import Cookies from '../cookies';
import { UtmParameters } from '../constants/utm-parameters';

type truncatedEntity = {
  action: EntityAction[] | undefined;
  entity_type: EntityTypes | undefined;
};

// Can determine landing page step by finding 'interactive-v2-component' class in step -> page -> entity -> entity_type
// And finding specific entity.action that matches a signup action
export const findSignupStep = (flow: CurrentFlow): number | null => {
  const indexOfSignupStep = flow.steps.findIndex(step => {
    const entityinStep = step.pages
      .flatMap(page => page.entity)
      .flatMap(entity => {
        return {
          entity_type: entity.entity_type,
          action: entity.action,
        };
      });

    return isSignupStep(entityinStep);
  });

  return indexOfSignupStep !== -1 ? indexOfSignupStep : null;
};

const isSignupStep = (entity: truncatedEntity[]): boolean => {
  const signupActions = getConfig().signupActions;

  const interactiveComponentEntity = entity.filter(
    entity =>
      entity.entity_type === EntityTypes.INTERACTIVE_COMPONENT &&
      entity.action?.length
  );
  const matchesSignupActions = interactiveComponentEntity.some(entity => {
    return signupActions.some((signupAction: EntityAction) => {
      return entity.action?.find(entityAction => {
        return (
          entityAction.service === signupAction.service &&
          entityAction.method === signupAction.method
        );
      });
    });
  });
  // IDEA: Create analytics context, to make it clear this is the signup step
  //  (this is an important step in the "funnel")
  //  This is perhaps not _perfect_ signal, but probably enough
  //  for v1
  // HOW: EventService.analytics.addEventProperties({ step_signup: matchesSignupActions });
  // WHEN: Whenever
  // WHO: Whomever
  //
  return matchesSignupActions;
};

// Can determine report step by finding 'report-component' in step -> page -> entity -> entity_type
export const findReportStep = (flow: CurrentFlow): number | null => {
  const indexOfReportStep = flow.steps.findIndex(step => {
    const entityTypesinStep = step.pages
      .flatMap(page => page.entity)
      .flatMap(entity => entity.entity_type);
    const isReportStep = entityTypesinStep.includes(
      EntityTypes.REPORT_COMPONENT
    );
    return isReportStep;
  });

  return indexOfReportStep !== -1 ? indexOfReportStep : null;
};

//Landing page is first step in flow, if it exists in flow
// Can determine landing page step by finding 'landing-page' class in step -> page -> entity -> entity.content.body
export const findLandingPageStep = (flow: CurrentFlow): number | null => {
  const entityContentInStep = flow.steps[0].pages
    .flatMap(page => page.entity)
    .flatMap(entity => entity.content);
  const isLandingPageStep = entityContentInStep.some(entityContent => {
    return entityContent.body?.includes('landing-page');
  });
  return isLandingPageStep ? 0 : null;
};

export const calculateTotalPagesInOST = (
  flow: CurrentFlow,
  signUpStep: number | null,
  landingStep: number | null
): number | null => {
  if (signUpStep) {
    // Only include Steps between Landing page step (if it exists) and Signup Step
    const firstOSTStep = landingStep !== null ? 1 : 0;
    const OSTSteps = flow.steps.slice(firstOSTStep, signUpStep);

    // Some pages are composed entirely of "hidden" questions that do not show to user (may be data issue), do not count these
    const pagesInOSTNoHiddenQuestions = OSTSteps.flatMap(
      step => step.pages
    ).filter(page => {
      const areAllQuestionsHidden = page.entity
        .flatMap(entity => entity.is_hidden)
        .every(isHidden => isHidden);
      return !areAllQuestionsHidden;
    });

    return pagesInOSTNoHiddenQuestions.length > 0
      ? pagesInOSTNoHiddenQuestions.length
      : null;
  }

  return null;
};

export const calculateTotalPagesInLST = (
  flow: CurrentFlow,
  signUpStep: number | null,
  reportStep: number | null
): number | null => {
  // Some pages are composed entirely of "hidden" questions that do not show to user (may be data issue), do not count these
  if (signUpStep) {
    const flowSteps = [...flow.steps];

    //Edge case where report page and signup page are in the same step
    if (signUpStep !== reportStep) {
      // Remove report step if it exists, as it should not count in LST page numbers
      if (reportStep !== null) {
        flowSteps.splice(reportStep, 1);
      }
    }

    // Remove all steps before and inclusive of the signup step
    const LSTFlow = flowSteps.slice(signUpStep + 1);

    // Remove "empty" pages from total count (empty array for entity/content, maybe data issue)
    const pagesInFlowNoEmptyPages = LSTFlow.flatMap(step => step.pages).filter(
      currentPage => {
        return currentPage.entity.length > 0;
      }
    );

    return pagesInFlowNoEmptyPages.length > 0
      ? pagesInFlowNoEmptyPages.length
      : null;
  }

  return null;
};

export function setUtmDataCookiesFromLocation(location: Location): void {
  const utmData = getUtmParamsFromLocation(location);

  Cookies.remove(UtmParameters.UTM_SOURCE);
  Cookies.remove(UtmParameters.UTM_MEDIUM);
  Cookies.remove(UtmParameters.UTM_CAMPAIGN);

  if (
    Object.values(utmData).length &&
    Object.values(utmData).some(val => val !== null)
  ) {
    Cookies.set(UtmParameters.UTM_SOURCE, utmData[UtmParameters.UTM_SOURCE]);
    Cookies.set(UtmParameters.UTM_MEDIUM, utmData[UtmParameters.UTM_MEDIUM]);
    Cookies.set(
      UtmParameters.UTM_CAMPAIGN,
      utmData[UtmParameters.UTM_CAMPAIGN]
    );
  }
}
