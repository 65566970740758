import { FunctionComponent } from 'react';
import ConditionalRender from '../conditional-render';

type QuestionTitleProps = {
  pre_question_text?: string;
  post_question_text?: string;
  title: string;
  dynamicId: string;
  className?: string;
};

export const QuestionTitle: FunctionComponent<QuestionTitleProps> = ({
  pre_question_text,
  post_question_text,
  title,
  dynamicId,
  className = '',
}) => {
  return (
    <div className={`sl-page-title-area ${className}`}>
      <ConditionalRender if={pre_question_text}>
        <div
          className="sl-page-pre-question"
          dangerouslySetInnerHTML={{ __html: pre_question_text || '' }}
        />
      </ConditionalRender>
      <label className="sl-page-title" htmlFor={dynamicId}>
        {title}
      </label>
      <ConditionalRender if={post_question_text}>
        <div
          className="sl-page-post-question"
          dangerouslySetInnerHTML={{ __html: post_question_text || '' }}
        />
      </ConditionalRender>
    </div>
  );
};
