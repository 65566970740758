/** Mobile Sizes */
// Title Sizes
export const MOBILE_HEADLINE = '36px';
export const MOBILE_CARD_TITLE = '32px';
export const MOBILE_QUESTION_TITLE = '24px';
export const MOBILE_SUBTITLE = '20px';

// Body Sizes
export const MOBILE_STANDARD = '18px';
export const MOBILE_SMALL = '14px';
export const MOBILE_MEDIUM = '16px';

/** Desktop Sizes */
// Title Sizes

// Body Sizes
