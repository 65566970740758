import {
  Answer,
  ExtendedAnswerValues,
  LocalStepAnswerStorage,
  ServerAnswer,
} from '../types/answers.types';

export const ANSWERS_STORAGE_KEY = 'sl-answers';
export const FETCHED_ANSWERS_STORAGE_KEY = 'sl-fetched-answers';
export const MAPPED_QUESTIONS_PRE_KEY = 'sl-mapped-question-';
export const GENDER_QUESTION_OPTIONS = 'sl-gender-options';

import * as Sentry from '@sentry/react';
import { Question, QuestionContentAnswer } from '../types/questions.types';
import { createAnswerFromData, markAnswersAsSaved } from '../helpers/answers';
import { getProductId } from '../helpers/product';
import { QuestionTypes } from '../constants/question-types';

const EMPTY_DATA = [undefined, null, ''];
const COMMUNITY_USERNAME = 'community_username';

/**
 * Get Mapped Questions from local storage
 * Each mapped question is stored at top level in local storage with a key of the MAPPED_QUESTIONS_PRE_KEY prefix
 * Example full local storage key: "sl-mapped-question-472-472-en-option-0"
 * @param {number} question_id The id of the mapped question
 * @returns {Array}
 */
export const getMappedQuestionResponses = (
  question_id: number
): Answer[] | [] => {
  let data: Answer[] = [];

  for (const key in localStorage) {
    if (key.indexOf(`${MAPPED_QUESTIONS_PRE_KEY}${question_id}-`) > -1) {
      const found = JSON.parse(localStorage.getItem(key) as string);
      data = found instanceof Array ? data.concat(found) : data.concat([found]);
    }
  }
  return data;
};

/**
 * Saves the mapped question at top level local storage MAPPED_QUESTIONS_PRE_KEY
 * @param {Array} [mappedQuestionKey] Generated key for a mapped question, in format of "question_id-id-language-option-idx"
 * @param {Array} [questions] The mapped_child_question data
 * @param {Object} [answer_keys] A key: value of the mapped_child_question id: the mapped_child_question answer id
 */
export const saveMappedQuestionsResponses = (
  mappedQuestionKey: string,
  questions: Question[],
  answer_keys = {},
  currentPage: number,
  currentStep: number
): void => {
  const data: Answer[] = [];
  const parent = parseInt(mappedQuestionKey.split('-')[0], 10);
  mappedQuestionKey = MAPPED_QUESTIONS_PRE_KEY + mappedQuestionKey;

  questions.forEach(question => {
    const content = question.content.find(c => c.lang === 'en');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ans_keys = answer_keys[question.id];
    let answers: QuestionContentAnswer[] | QuestionContentAnswer = [];
    ans_keys.forEach((key: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const answer = content.answers.find(a => a.id === key);
      if (answer) {
        const { id, score, text } = answer;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        answers.push({ id, score, text });
      }
    });

    if (question.type !== QuestionTypes.MULTI && answers.length === 1) {
      answers = answers[0];
    }

    const dataAnswer = createAnswerFromData({
      is_core_question: question.is_core_question,
      product_id: getProductId(),
      question_id: question.id,
      saved: false,
      _parentQuestionId: parent,
      semantic_id: question.semantic_id,
      values: answers,
      stepNumber: currentStep,
      pageNumber: currentPage,
    });
    data.push(dataAnswer);
  });

  localStorage.setItem(mappedQuestionKey, JSON.stringify(data));
};

/**
 * Saves a page of answers to local storage
 * @param {Array} [answers] All answers for a page
 * @param {number} [stepNumber]
 * @param {number} [pageNumber]
 */
export const savePage = (
  answers: Answer[],
  stepNumber: number,
  pageNumber: number
): void => {
  answers.forEach((answer: Answer) => {
    answer.saved = answer.saved || false;
  });
  const data = getAnswers() || {};
  if (!data[stepNumber]) {
    data[stepNumber] = {};
  }
  data[stepNumber][pageNumber] = answers;
  localStorage.setItem(ANSWERS_STORAGE_KEY, JSON.stringify(data));
};

/**
 * Updates a page of answers on local storage
 * @param {Array} [answers] All answers for a page
 * @param {number} [stepNumber]
 * @param {number} [pageNumber]
 */
export const updateAnswersOnPage = (
  answers: Answer[],
  stepNumber: number,
  pageNumber: number
): void => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  answers = answers.filter(a => !EMPTY_DATA.includes(a.values));
  answers.forEach(answer => {
    answer.saved = answer.saved || false;
  });

  const data = getAnswers() || {};

  if (!data[stepNumber]) {
    data[stepNumber] = {};
  }

  const new_answers_for_question_ids = answers.map(a => a.question_id);
  data[stepNumber][pageNumber] = data[stepNumber][pageNumber] || [];

  answers.forEach(answer => {
    const existingPersistedAnswer: Answer | undefined = data[stepNumber][
      pageNumber
    ].find((a: Answer) => a.question_id === answer.question_id);
    answer.id = answer.id ?? existingPersistedAnswer?.id;
  });
  data[stepNumber][pageNumber] = data[stepNumber][pageNumber].filter(
    (a: Answer) => new_answers_for_question_ids.indexOf(a.question_id) === -1
  );

  //Note:  During testing, I was unable to force this debug logging with duplicate questions to occur
  // Currently duplicate questions are saved, this is an area to look into in the future
  const arrayOfExistingQuestionIds = data[stepNumber][pageNumber].map(
    (i: Answer) => i.question_id
  );
  const arrayOfQuestionIdsAboutToConcat = answers.map(i => i.question_id);

  // if about to push an id that already exists
  const duplicates = arrayOfExistingQuestionIds.filter(
    i => arrayOfQuestionIdsAboutToConcat.indexOf(i) > -1
  );
  if (duplicates.length) {
    // eslint-disable-next-line max-len
    Sentry.captureMessage(
      `SLEEP-1500: step ${stepNumber} | page ${pageNumber}. Duplicates: ${JSON.stringify(
        duplicates
      )}`
    );
  }
  // end debug logging

  data[stepNumber][pageNumber] = data[stepNumber][pageNumber].concat(answers);

  localStorage.setItem(ANSWERS_STORAGE_KEY, JSON.stringify(data));
};

/**
 * Updates answers that have been sent to the server, marking them as saved and updating the id
 * @param {Array} results Answer data from the server, in order to be able to map the corresponding ids
 *
 */
export const updateSavedAnswers = (results: ServerAnswer[]): void | null => {
  const data = getAnswers() || {};
  if (!results || !results.length) {
    return;
  }

  const updatedData = markAnswersAsSaved(data, results);

  localStorage.setItem(ANSWERS_STORAGE_KEY, JSON.stringify(updatedData));
};

/**
 * Get answers for the specified page
 */
export const getAnswersForPage = (
  stepNumber: number,
  pageNumber: number
): Answer[] => {
  const localData = JSON.parse(
    localStorage.getItem(ANSWERS_STORAGE_KEY) as string
  );
  if (localData) {
    return localData[stepNumber] ? localData[stepNumber][pageNumber] ?? [] : [];
  }
  return [];
};

/**
 * Get ALL answers from all steps & pages
 * @returns {Array}
 */
export const getAnswersAsArray = (): Answer[] | [] => {
  const localData =
    JSON.parse(localStorage.getItem(ANSWERS_STORAGE_KEY) as string) || {};
  let array: Answer[] = [];
  Object.keys(localData).forEach(step => {
    Object.keys(localData[step]).forEach(page => {
      array = array.concat(localData[step][page]);
    });
  });
  return array;
};

/**
 * Determine if there are any unsaved answers on a page
 * @returns {boolean}
 */
export const checkUnsavedAnswersExist = (): boolean => {
  const localData =
    JSON.parse(localStorage.getItem(ANSWERS_STORAGE_KEY) as string) || {};
  let unsaved = 0;

  Object.keys(localData).forEach(step => {
    Object.keys(localData[step]).forEach(page => {
      unsaved += localData[step][page].filter((q: Answer) => !q.saved).length;
    });
  });

  return unsaved !== 0;
};

export const purgeUnsavedAnswers = (): void => {
  const localData =
    JSON.parse(localStorage.getItem(ANSWERS_STORAGE_KEY) as string) || {};

  const newLocalData: LocalStepAnswerStorage = {};

  Object.keys(localData).forEach(step => {
    newLocalData[step] = { ...localData[step] };
    Object.keys(localData[step]).forEach(page => {
      newLocalData[step][page] = localData[step][page].filter(
        (q: Answer) => q.saved
      );
    });
  });

  localStorage.setItem(ANSWERS_STORAGE_KEY, JSON.stringify(newLocalData));
};

/**
 * This function purges community username if it's unsaved
 */
export const purgeUnsavedCommunityUsernameAnswer = (): void => {
  const localData =
    JSON.parse(localStorage.getItem(ANSWERS_STORAGE_KEY) as string) || {};

  const newLocalData: LocalStepAnswerStorage = {};

  Object.keys(localData).forEach(step => {
    newLocalData[step] = { ...localData[step] };
    Object.keys(localData[step]).forEach(page => {
      newLocalData[step][page] = localData[step][page].filter(
        (q: Answer) => q.semantic_id !== COMMUNITY_USERNAME || q.saved
      );
    });
  });

  localStorage.setItem(ANSWERS_STORAGE_KEY, JSON.stringify(newLocalData));
};

/** Get all answers from local storage
 * @returns {Object}
 */
export const getAnswers = (): LocalStepAnswerStorage => {
  return JSON.parse(localStorage.getItem(ANSWERS_STORAGE_KEY) as string) || {};
};

/** Remove all answers from local storage */
export const removeAnswers = (): void => {
  localStorage.removeItem(ANSWERS_STORAGE_KEY);
};

/**
 * Get the locally stored answer by semantic id
 * @param {*} semantic semantic_id of the answer
 * @param {Boolean} full Flag if to return full response object or just the actual value
 * @returns answer Answer item or answer value or undefined
 */
export const getAnswerBySemantic = (
  semantic: unknown,
  full = false
): ExtendedAnswerValues | Answer | undefined => {
  const semanticArray = typeof semantic === 'object' ? semantic : [semantic];

  const answers = getAnswers();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let valueFound = undefined;
  let foundResponse = undefined;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  semanticArray.forEach(semanticId => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!valueFound) {
      Object.keys(answers).map(function (key) {
        const flowAnswers = answers[key];
        Object.keys(flowAnswers).map(function (k) {
          const response = flowAnswers[k];
          if (response && response.length) {
            if (response[0].semantic_id === semanticId) {
              valueFound =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                response[0].values.text || response[0].values;
              foundResponse = response[0];
            }
          }
        });
      });
    }
  });

  return full ? foundResponse : valueFound;
};

/** Saves the gender question options to local storage
 * @param {Array} data An array of gender question options
 */
export const saveGenderQuestionOptions = (data: unknown[]): void => {
  localStorage.setItem(GENDER_QUESTION_OPTIONS, JSON.stringify(data));
};

/** Gets the gender question options
 * @returns Gender_question_options array
 */
export const getGenderQuestionOptions = (): unknown[] => {
  return (
    JSON.parse(localStorage.getItem(GENDER_QUESTION_OPTIONS) as string) || []
  );
};

/** Saves fetched answers (saved answers returned from server) to local storage
 * @param {Array} answers An array of saved answers from the api
 */
export const saveFetchedAnswers = (answers: ServerAnswer[]): void => {
  localStorage.setItem(FETCHED_ANSWERS_STORAGE_KEY, JSON.stringify(answers));
};

/** Gets a fetched answer (saved answers returned from server) by question id
 * @param {number} question_id
 * @returns answer Answer item or undefined
 * */
export const getFetchedAnswerByQuestionId = (
  question_id: number | string
): Answer | undefined => {
  const data = localStorage.getItem(FETCHED_ANSWERS_STORAGE_KEY) || '[]';
  const fetchedAnswers = JSON.parse(data) || [];

  return fetchedAnswers.find(
    (answer: Answer) =>
      parseInt(String(answer.question_id), 10) ===
      parseInt(String(question_id), 10)
  );
};

/** Adds "disabled: true" attribute to all input and select elements */
export const makeInputsDisabledWhileCallLoadings = (): void => {
  Array.from(document.querySelectorAll('input, select')).forEach(input => {
    input.setAttribute('disabled', 'true');
  });
};

/** Removes disabled attribute from all input and select elements */
export const removeDisabledForInputsWhenCallLoadingFinish = (): void => {
  Array.from(document.querySelectorAll('input, select')).forEach(input => {
    input.removeAttribute('disabled');
  });
};

export default {
  getMappedQuestionResponses,
  saveMappedQuestionsResponses,
  savePage,
  updateAnswersOnPage,
  updateSavedAnswers,
  getAnswersForPage,
  getAnswersAsArray,
  checkUnsavedAnswersExist,
  getAnswers,
  removeAnswers,
  getAnswerBySemantic,
  saveGenderQuestionOptions,
  getGenderQuestionOptions,
  saveFetchedAnswers,
  getFetchedAnswerByQuestionId,
  makeInputsDisabledWhileCallLoadings,
  removeDisabledForInputsWhenCallLoadingFinish,
  purgeUnsavedAnswers,
  purgeUnsavedCommunityUsernameAnswer,
};
