import { css, Theme } from '@emotion/react';
import { MIDDLE_BLUE, WHITE } from '../../styles/colors';

export const autocompleteWrapper = css({
  position: 'relative',
});

export const listWrapper = (theme: Theme) =>
  css({
    borderRadius: theme.borders.defaultBorderRadius,
    border: theme.borders.defaultBorder,
    background: WHITE,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 1, // might not be necessary, but good for just being really sure
    lineHeight: 1.35,
    fontWeight: theme.fonts.fontWeights.MEDIUM,

    li: {
      padding: `12px ${theme.padding.LARGE}`, // figma demands 12px
      fontSize: theme.fonts.fontSizes.MEDIUM,
      color: theme.fonts.fontColors.PRIMARY,

      // the non-standard sizes for border radius are to make the interior background color fit better with the parent padding + radius
      '&:first-of-type': {
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
      },
      '&:last-of-type': {
        borderBottomLeftRadius: '6px',
        WebkitBorderBottomRightRadius: '6px',
      },

      '&.default': {
        borderTop: theme.borders.defaultBorder,
      },

      '&:focus,&:hover': {
        background: MIDDLE_BLUE,
      },
    },
  });
