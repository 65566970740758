const ERROR_TYPES = [
  'Error',
  'InternalError',
  'RangeError',
  'ReferenceError',
  'SyntaxError',
  'TypeError',
  'URIError',
  'Warning',
];

export const cleanError = (error: unknown): Error => {
  const errorStr = `${error}`;
  const errorType = ERROR_TYPES.find(type => errorStr.startsWith(`${type}:`));
  return Error(`[Scrubbed] ${errorType || 'UnknownError'} in doGuessRegion`);
};
