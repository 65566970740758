import {
  ArrayInterpolation,
  ComponentSelector,
  CSSObject,
  FunctionInterpolation,
  Keyframes,
  SerializedStyles,
  serializeStyles,
} from '@emotion/serialize';
import createCache from '@emotion/cache';
import { useEffect } from 'react';
import { cache } from '@emotion/css';

export const emotionCache = createCache({
  key: 'emotion',
  // ensures styles are prepended to the <head> above product/injected styles
  // the result of this is that injected style tags _and_ postprocessed CSS will take precedence over emotion
  // this is both expected and desirable.
  insertionPoint:
    (document.querySelector(
      '[name="emotion-insertion-point"]'
    ) as HTMLElement) || undefined,
});

export function injectThemedGlobal(
  ...args: (
    | string
    | number
    | boolean
    | TemplateStringsArray
    | ComponentSelector
    | Keyframes
    | SerializedStyles
    | CSSObject
    | ArrayInterpolation<unknown>
    | FunctionInterpolation<unknown>
    | null
    | undefined
  )[]
) {
  // these are probably already serialized but this doesn't hurt to call
  const serialized = serializeStyles(args, cache.registered);

  if (!emotionCache.inserted[serialized.name]) {
    emotionCache.insert('', serialized, emotionCache.sheet, true);
  }
}

export function flushThemedGlobals() {
  emotionCache.sheet.flush();
  emotionCache.inserted = {};
  emotionCache.registered = {};
}

export const Global = ({ styles }: { styles: SerializedStyles }) => {
  useEffect(() => {
    injectThemedGlobal`${styles}`;

    return () => flushThemedGlobals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
