import { SLEEPIO_PRODUCT_NAME, DAYLIGHT_PRODUCT_NAME } from './product';

export const getTermsOfServiceUrl = () => window.termsOfServiceUrl || '';
export const getPrivacyPolicyUrl = () => window.privacyPolicyUrl || '';
export const getHealthInformationUrl = () =>
  `${getPrivacyPolicyUrl()}/#healthInformation`;
export const getSuitableUrl = () =>
  window.suitableUrl || window.suitabilityInformationUrl || '';

export const getDxByProductName = (productName?: string) => {
  if (productName === SLEEPIO_PRODUCT_NAME) {
    return ` with insomnia disorder`;
  }

  if (productName === DAYLIGHT_PRODUCT_NAME) {
    return ` with anxiety disorder`;
  }

  return '';
};
