import * as Sentry from '@sentry/react';
import { QuestionTypes } from '../constants/question-types';
import {
  Answer,
  ServerAnswer,
  LocalStepAnswerStorage,
  ExtendedAnswerValues,
} from '../types/answers.types';
import {
  HiddenQuestion,
  QuestionContentAnswer,
  STRING_RESPONSE_TYPES,
} from '../types/questions.types';
import { getProductId } from './product';

/**
 * Answers are manipulated in various places such as our actions, reducers, and service files
 * This file is to centralize our helpers when dealing with answer data
 */
export function mapAnswersToServer(
  answers: Answer[],
  userId: number,
  flowId: number | null,
  orgId: string | undefined
) {
  const data: ServerAnswer[] = [];
  for (let index = 0; index < answers.length; index++) {
    if (!(answers[index].saved || answers[index].isSkipped)) {
      const responseQuestion: ServerAnswer = {
        id: answers[index].id!, // this comes back with a value once it's saved to the server, but is undefined before then
        user_id: userId,
        flow_id: flowId,
        organization_id: orgId,
        product_id: window.product_id || 1,
        question_id: answers[index].question_id,
        semantic_id: answers[index].semantic_id,
        responses: answers[index].values,
      };

      if (
        data.find(item => item.question_id === responseQuestion.question_id)
      ) {
        Sentry.captureMessage(
          `SLEEP-1500: About to push the same question response again: ${responseQuestion.question_id}`
        );
      }

      data.push(responseQuestion);
    }
  }
  return data;
}

/**
 * This helper method walks through a list of answers and updates their saved status
 * NOTE: when a question is skipped we should consider the answer to have been saved
 *
 * @param localAnswers a list of our current answers
 * @param savedAnswers a list of answers that were recently saved
 */
export function markAnswersAsSaved(
  localAnswers: LocalStepAnswerStorage,
  savedAnswers: ServerAnswer[]
) {
  Object.keys(localAnswers).forEach(step => {
    Object.keys(localAnswers[step]).forEach(page => {
      localAnswers[step][page].forEach(answer => {
        const matching = savedAnswers.find(
          result => result.question_id === answer.question_id
        );
        answer.id = matching ? matching.id : answer.id;
        answer.saved = answer.saved || answer.isSkipped || !!matching;
      });
    });
  });
  return localAnswers;
}

export const createQuestionContentAnswer = (
  id?: string | null,
  score?: string | null,
  text?: string
): QuestionContentAnswer => {
  return {
    id: id ?? null,
    score: score ?? null,
    text: text ?? '',
  };
};

export const getCastValues = (
  values: number | string | QuestionContentAnswer | QuestionContentAnswer[]
): number | string | ExtendedAnswerValues | ExtendedAnswerValues[] => {
  if (!Number.isInteger(values) && !values) {
    return '';
  }

  if (Array.isArray(values)) {
    return values.length ? (values as ExtendedAnswerValues[]) : [];
  }

  if (typeof values === 'string') {
    return values as string;
  }

  return values as ExtendedAnswerValues;
};

export const coerceServerAnswerToAnswer = ({
  question_id,
  semantic_id,
  product_id,
  responses: values,
}: ServerAnswer): Answer => {
  return {
    question_id,
    semantic_id,
    product_id,
    values: values as Answer['values'],
    saved: true,
  } as Answer;
};

export const createAnswerFromData = ({
  question_id,
  semantic_id,
  product_id,
  values,
  id,
  _parentQuestionId,
  is_core_question,
  saved = false,
  isSkipped,
  isHiddenQuestion,
  stepNumber,
  pageNumber,
}: {
  question_id: number;
  semantic_id: string;
  product_id: number;
  values: QuestionContentAnswer | string | QuestionContentAnswer[];
  id?: number;
  _parentQuestionId?: number;
  is_core_question?: boolean;
  saved?: boolean;
  isSkipped?: boolean;
  isHiddenQuestion?: boolean;
  stepNumber: number;
  pageNumber: number;
}): Answer => {
  const answeredTimeInMs = Date.now();
  const castValues = getCastValues(values);
  return {
    question_id,
    semantic_id,
    product_id,
    values: castValues,
    id,
    _parentQuestionId,
    is_core_question,
    saved,
    isSkipped,
    isHiddenQuestion,
    stepNumber,
    pageNumber,
    answeredTimeInMs,
  };
};

export const getDefaultForHiddenQuestion = (question: HiddenQuestion) => {
  const defaultValue: QuestionContentAnswer = createQuestionContentAnswer();
  if (STRING_RESPONSE_TYPES.includes(question.type)) {
    return question.defaultAnswerValue ?? '';
  }

  const defaultAnswer = question.content[0].answers.find(
    item => item.id?.toString() === question.defaultAnswerKey
  );
  return defaultAnswer ?? defaultValue;
};

export const getHiddenQuestionsForPage = ({
  hiddenQuestions,
  answers,
  currentPage,
  currentStep,
}: {
  hiddenQuestions: HiddenQuestion[];
  answers: Answer[];
  currentPage: number;
  currentStep: number;
}): Answer[] => {
  const hiddenAnswers = <Answer[]>[];
  const hiddenQuestionsForPage = hiddenQuestions.filter(
    item => item.step === currentStep && item.page === currentPage
  );
  hiddenQuestionsForPage.forEach(question => {
    if (!answers.find(item => item.question_id === question.id)) {
      const defaultValue = getDefaultForHiddenQuestion(question);
      const values =
        question.type === QuestionTypes.MULTI &&
        typeof defaultValue !== 'string'
          ? [defaultValue]
          : defaultValue;
      hiddenAnswers.push(
        createAnswerFromData({
          question_id: question.id,
          semantic_id: question.semantic_id,
          is_core_question: question.is_core_question,
          product_id: getProductId(),
          values,
          stepNumber: currentStep,
          pageNumber: currentPage,
          isHiddenQuestion: true,
        })
      );
    }
  });
  return hiddenAnswers;
};

export const isEmptyOrNullOrUndefined = (val: unknown) => {
  return val === null || val === undefined || val === '';
};
