import { css } from '@emotion/react';
import { BHTheme } from '../../../../types/bh-themes.types';

export const passwordInputContainerStyle = () =>
  css({
    position: 'relative',
  });

export const showHideIconStyle = (theme: BHTheme) =>
  css({
    position: 'absolute',
    right: theme.padding.MEDIUM,
    top: '35%',
  });

export const passwordErrorStyle = (theme: BHTheme) =>
  css({
    paddingLeft: theme.padding.SMALL,
  });
