import { FunctionComponent } from 'react';
import { THEMED_ICON } from '../../styles/colors';

type VisibilityIconProps = {
  shouldShowOpenEye: boolean;
  parentId: number | string;
  width?: number;
  height?: number;
  color?: string;
};
export const VisibilityIcon: FunctionComponent<VisibilityIconProps> = ({
  shouldShowOpenEye = true,
  parentId,
  width = 24,
  height = 24,
  color = THEMED_ICON,
}) => {
  return shouldShowOpenEye ? (
    // open eye
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      id={`open-eye-${parentId}`}
    >
      <path
        d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
        fill={color}
      />
    </svg>
  ) : (
    // closed eye
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      id={`closed-eye-${parentId}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1464 4.15216C21.7161 3.55077 21.6904 2.60137 21.0891 2.03162C20.4876 1.46187 19.5382 1.48753 18.9685 2.08893L16.3672 4.83474C15.0165 4.29618 13.543 4 12 4C7 4 2.73 7.11 1 11.5C1.83468 13.6181 3.26062 15.4382 5.07455 16.7548L3.03174 18.9111C2.46199 19.5125 2.48765 20.4619 3.08905 21.0316C3.69045 21.6014 4.63985 21.5757 5.2096 20.9743L7.80664 18.233C9.10961 18.7286 10.523 19 12 19C17 19 21.27 15.89 23 11.5C22.1869 9.4368 20.8128 7.65631 19.0656 6.34853L21.1464 4.15216ZM14.2726 7.04572C13.5906 6.69684 12.8182 6.5 12 6.5C9.24 6.5 7 8.74 7 11.5C7 12.4145 7.24592 13.2719 7.67519 14.0097L9.15281 12.4499C9.05366 12.1516 9 11.8322 9 11.5C9 9.84 10.34 8.5 12 8.5C12.2752 8.5 12.5416 8.53683 12.7946 8.60585L14.2726 7.04572ZM14.9038 10.7416L16.413 9.14847C16.7876 9.84953 17 10.6501 17 11.5C17 14.26 14.76 16.5 12 16.5C11.2462 16.5 10.5312 16.3329 9.89007 16.0338L11.3998 14.4403C11.5936 14.4794 11.7944 14.5 12 14.5C13.66 14.5 15 13.16 15 11.5C15 11.2379 14.9666 10.9837 14.9038 10.7416Z"
        fill={color}
      />
    </svg>
  );
};
