import '../style.scss';
import { FunctionComponent, useRef } from 'react';
import ConditionalRender from '../../conditional-render';
import { shouldShowSignupWithExistingUser } from '../interactive-component-v2.helper';
import { InteractiveComponentItem } from '../interactive-component-v2.types';
import { MultiAccountSignIn } from '../../multi-account-sign-in/multi-account-sign-in.component';

export type InteractiveContentProps = {
  item: InteractiveComponentItem;
  name?: string;
  headerText?: string;
  dataCvsStyle?: boolean;
  inputComponents: React.ReactElement[];
  fallbackAlerts: { message: string }[];
  componentAlerts: React.ReactElement[];
  componentError: { error?: string };
  errorFields: any;
  onShowExistingSignupClick: () => void;
};

export const InteractiveContent: FunctionComponent<
  InteractiveContentProps
> = props => {
  const scrollToErrorTimeout = useRef<number>();
  if (props.errorFields && props.errorFields.length) {
    window.clearTimeout(scrollToErrorTimeout.current);

    scrollToErrorTimeout.current = window.setTimeout(() => {
      const h2 = document.querySelector('.sl-interactive-v2 h2');
      if (h2) {
        h2.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 200);
  }
  const fallbackAlertTexts = props.fallbackAlerts.map(
    (alert: { message: string }, i: number) => {
      return (
        <div key={i} className="sl-interactive-v2--error">
          {alert.message}
        </div>
      );
    }
  );
  return (
    <div className="sl-interactive-v2">
      <h1>{props.name}</h1>
      <ConditionalRender if={props.headerText}>
        <div
          className="sl-interactive-v2--header"
          data-cvs-style={props.dataCvsStyle}
        >
          <span className="sl-interactive-v2--header-image" />
          <span
            className="sl-interactive-v2--header-text"
            dangerouslySetInnerHTML={{ __html: props.headerText || '' }}
          />
        </div>
      </ConditionalRender>
      <form noValidate>
        <MultiAccountSignIn item={props.item} />
        {props.inputComponents}
        <ConditionalRender if={fallbackAlertTexts.length}>
          {fallbackAlertTexts}
        </ConditionalRender>
        <ConditionalRender
          if={!props.componentAlerts.length && props.componentError.error}
        >
          <div className="sl-interactive-v2--error">
            {`${props.componentError.error} `}
            <ConditionalRender
              if={shouldShowSignupWithExistingUser(props.item.action[0])}
            >
              If you already have a Sleepio or Daylight account,{' '}
              <a
                className="error-color"
                onClick={props.onShowExistingSignupClick}
              >
                log in here.
              </a>
            </ConditionalRender>
          </div>
        </ConditionalRender>
      </form>
    </div>
  );
};
