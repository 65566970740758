import React, { useCallback } from 'react';
import { QUESTIONS_GENERAL_ERROR } from '../../../constants/errors';
import { useAppSelector } from '../../../hooks/use-app-react-redux';
import selectors from '../../../selectors';
import InputButton from '../../input-button/input-button.component';
import { onEnterOrSpace } from '../../utils/util-functions';
import { AdvanceFunction } from '../flow-page.types';

import {
  SL_GENERAL_ERROR,
  SL_SKIP_QUESTION,
} from '../../../constants/class-names';

import {
  GENERAL_ERROR_STYLES,
  SKIP_QUESTION_STYLES,
} from './next-button.styles';

type NextButtonProps = {
  isVisible: boolean;
  isDisabled: boolean;
  isValidated: boolean; // this just describes that the page validity has been checked, but that it is in a valid/invalid state. That is controlled by isDisabled for now.
  showSkippableText?: string;
  advance: AdvanceFunction;
  onContinue: AdvanceFunction;
  validateAnswers: () => void;
};

export const CONTINUE = 'Continue';

const NextButton: React.FC<NextButtonProps> = ({
  isVisible,
  isDisabled,
  isValidated,
  showSkippableText,
  advance,
  onContinue,
  validateAnswers,
}) => {
  const continueBtnLoading = useAppSelector(
    selectors.selectFlowReducerContinueButtonLoading
  );

  const advanceSkippedQuestion = useCallback(
    event => advance(event, true),
    [advance]
  );
  const _onEnterOrSpace = useCallback(
    (event: React.KeyboardEvent) => {
      return onEnterOrSpace(event, advanceSkippedQuestion);
    },
    [advanceSkippedQuestion]
  );
  const continueWithoutSkipping = useCallback(
    (event?: React.SyntheticEvent) => {
      onContinue(event, false);
    },
    [onContinue]
  );
  const _validateIfDisabled = useCallback(() => {
    // prevents blinking of error message when advancing, some OOO needs to be ironed out
    // by cleaning up the whole "is page checked" flag being separated from the actual validation process
    // this is logically equivalent to the alternate component rendering that existed before
    if (isDisabled) {
      validateAnswers();
    }
  }, [isDisabled, validateAnswers]);

  return (
    <React.Fragment>
      {isVisible && (
        <span onClick={_validateIfDisabled}>
          <InputButton
            disabled={isDisabled}
            value={CONTINUE}
            loading={continueBtnLoading}
            onClick={continueWithoutSkipping}
          />
        </span>
      )}
      {showSkippableText && (
        <p
          onKeyDown={_onEnterOrSpace}
          tabIndex={0}
          className={SL_SKIP_QUESTION}
          css={SKIP_QUESTION_STYLES}
          data-testid="skip-question-text"
        >
          <span onClick={advanceSkippedQuestion}>
            {/* Pretty sure this default is unreachable */}
            {showSkippableText || 'Skip Question'}
          </span>
        </p>
      )}
      {isVisible && isDisabled && isValidated && (
        <p css={GENERAL_ERROR_STYLES} className={SL_GENERAL_ERROR}>
          {QUESTIONS_GENERAL_ERROR}
        </p>
      )}
    </React.Fragment>
  );
};

export default NextButton;
