import { passConditions } from './requests';
import { callFunctionAfterMs } from '../utils/call-function-after-ms';
import { buildGenericDataToCheckConditionsAgain } from './payload';

const PENDING_REQUESTS_WAIT_DELAY_MS = 500;

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const checkConditionAsync = async function (
  data,
  methodName,
  serverConditions = [],
  matchAll = false
) {
  // This function determines if a trigger is ready to run, by checking the
  // conditions set up for it.
  data = data || buildGenericDataToCheckConditionsAgain();

  const conditions =
    (methodName && passConditions[methodName]) || serverConditions;
  let onePassed = !conditions.length;
  let allPassed = true;

  conditions &&
    (await asyncForEach(conditions, async condition => {
      let passed = true;
      await asyncForEach(Object.keys(condition), async key => {
        let dataValue;
        try {
          dataValue = key.indexOf('.') > -1 ? eval(`data.${key}`) : data[key];
        } catch (ignore) {
          passed = false;
        }

        if (typeof condition[key] === 'string') {
          if (condition[key] === 'isNumber') {
            passed = passed && typeof dataValue === 'number';
          } else if (dataValue !== condition[key]) {
            passed = false;
          }
        } else if (dataValue !== condition[key]) {
          if (key === 'has_pending_requests') {
            // "Condition" represents the state of the world. If
            // condition "has_pending_requests", then we need
            // to wait for that to be false.
            let waitingForRequestsToResolve =
              !condition['has_pending_requests'];
            if (waitingForRequestsToResolve) {
              passed = await callFunctionAfterMs(
                checkConditionAsync.bind(
                  null,
                  null,
                  methodName,
                  serverConditions,
                  matchAll
                ),
                PENDING_REQUESTS_WAIT_DELAY_MS
              );
            } // Else, passed is true--we have no more
          }
        }
      });

      if (passed) {
        onePassed = true;
      } else {
        allPassed = false;
      }
    }));

  return matchAll ? allPassed : onePassed;
};

export const checkCondition = function (
  data,
  methodName,
  serverConditions = []
) {
  let checkingPendingApis = false;
  data = data || buildGenericDataToCheckConditionsAgain();

  const conditions =
    (methodName && passConditions[methodName]) || serverConditions;
  let onePassed = !conditions.length;

  conditions &&
    conditions.forEach(condition => {
      let passed = true;
      Object.keys(condition).forEach(async key => {
        let dataValue;
        try {
          dataValue = key.indexOf('.') > -1 ? eval(`data.${key}`) : data[key];
        } catch (ignore) {
          passed = false;
        }
        if (key === 'has_pending_requests') {
          checkingPendingApis = !condition[key];
        }

        if (typeof condition[key] === 'string') {
          if (condition[key] === 'isNumber') {
            passed = passed && typeof dataValue === 'number';
          } else if (dataValue !== condition[key]) {
            passed = false;
          }
        } else if (dataValue !== condition[key]) {
          passed =
            key === 'has_pending_requests'
              ? await callFunctionAfterMs(
                  checkingPendingApis.bind(null, methodName, serverConditions),
                  500
                )
              : false;
        }
      });

      if (passed) {
        onePassed = true;
      }
    });

  return onePassed;
};
