export default {
  NO_SUBMIT_INPUT_TYPES: ['submit', 'button', 'static', 'sso'],
  NO_VALIDATION_INPUT_TYPES: ['submit', 'button', 'static', 'sso'],
  BIRTHDAY_DATE_FORMAT: 'YYYY-MM-DD',
  BIRTHDAY_DATE_VALID_FORMAT: 'YYYY, MMMM D, YYYY hh:mm A',

  GENDER_OPTION_UNKNOWN_ID: 'other',
  DEFAULT_GENDER_OPTIONS: [
    { id: 'male', score: '1', text: 'Male', idx: 0 },
    { id: 'female', score: '2', text: 'Female', idx: 1 },
    { id: 'other', score: '3', text: 'In another way', idx: 2 },
  ],

  DEFAULT_EMAIL_OPT_IN_OPTIONS: [
    { id: true, idx: 0, text: 'Yes, I want to receive emails' },
    { id: false, idx: 1, text: 'No emails please' },
  ],

  INPUT_FIELD_NAMES: {
    EMPLOYEE_ID: ['employee_id'],
    BUTTON: ['button'],
    SUBMIT: ['submit'],
    STATIC: ['static'],
    DATE_OF_BIRTH: ['date_of_birth'],
    DATA_STORING: ['data_storing'],
    CLINICAL_GOVERNANCE: ['clinical_governance'],
    GENDER: ['gender'],
    DOCTOR_ACKNOWLEDGEMENT: ['doctor_acknowledgement'],
    EMAIL_OPT_IN: ['email_opt_in'],
    FIRST_NAME: ['first_name'],
    LAST_NAME: ['last_name'],
    MIDDLE_NAME: ['middle_name'],
    EMAIL: ['email'],
    PASSWORD: ['password'],
    ZIP_CODE: ['zip_code'],
    POSTAL_CODE: ['postal_code'],
    FACEBOOK: ['sso_facebook'],
    GOOGLE: ['sso_google'],
    VIRGIN_PULSE: ['sso_virgin_pulse'],
  },

  SAML_READONLY_INPUT_FIELD_NAMES: [
    'email',
    'first_name',
    'last_name',
    'employee_id',
  ],

  REQUESTS: {
    REQUEST_SUCCESS_CONDITION: {
      register_interest: [
        {
          id: 'isNumber',
        },
      ],
      check_eligibility_for_organization_and_sign_up: [
        {
          id: 'isNumber',
        },
      ],
      check_eligibility_for_organization: [
        {
          user_eligible: true,
        },
        {
          id: 'isNumber',
          eligibility_checked_at: 'isNumber',
        },
      ],
      create: [
        {
          id: 'isNumber',
        },
      ],
    },
    ON_REQUEST_SUCCESS_FOLLOW_UP: {
      register_interest: {},
      check_eligibility_for_organization: {
        User: [
          {
            version: 1,
            method: 'update_user_info',
            fields: '*',
            hidden_inputs: ['employee_id', 'product_id'],
            default_values: [],
            omitted_params: ['middle_name', 'password'],
          },
        ],
      },
      check_eligibility_for_organization_and_sign_up: {
        User: [
          {
            version: 1,
            method: 'update_user_info',
            fields: [],
            hidden_inputs: ['employee_id', 'product_id'],
            default_values: [],
            omitted_params: ['middle_name', 'password'],
          },
        ],
        EventService: [
          {
            version: 'local',
            method: 'accountCreatedEvent',
            fields: ['email'],
            hidden_inputs: [
              'uuid',
              'id',
              'product_id',
              'organization_id',
              'first_name',
              'last_name',
              'idp_name',
              'idp_id',
              'employee_id',
              'phone_number',
            ],
            default_values: [],
            omitted_params: ['password'],
          },
        ],
        GaService: [
          {
            version: 'local',
            method: 'signUp',
            fields: [],
            hidden_inputs: ['idp_name'],
            default_values: [],
            omitted_params: [],
          },
        ],
      },
      create: {
        EventService: [
          {
            version: 'local',
            method: 'accountCreatedEvent',
            fields: ['email'],
            hidden_inputs: [
              'id',
              'uuid',
              'product_id',
              'organization_id',
              'first_name',
              'last_name',
              'idp_name',
              'idp_id',
              'employee_id',
              'phone_number',
            ],
            default_values: [],
            omitted_params: ['password'],
          },
        ],
        GaService: [
          {
            version: 'local',
            method: 'signUp',
            fields: [],
            hidden_inputs: ['idp_name'],
            default_values: [],
            omitted_params: [],
          },
        ],

        // you would think it does not make sense to call update user info, since the user just got created, right?
        // well, in the case of a flow defined as eligibility v2 and then signup v2, some of the fields on eligibility
        // component are most likely not found on the create component. so, after a user is created, call the update
        // user info again which would also update those fields.
        User: [
          {
            version: 1,
            method: 'update_user_info',
            fields: '*',
            hidden_inputs: [
              'phone_number',
              'date_of_birth',
              'gender',
              'clinical_governance',
              'first_name',
              'last_name',
              'zip_code',
              'email',
              'product_id',
              'data_storing',
              'employee_id',
            ],
            default_values: [],
            omitted_params: ['middle_name', 'password'],
          },
        ],
      },
    },
    PARAMETER_RENAMING: {
      // global parameter renaming - will be applied to all methods using this config
      employee_id: 'employee_identifier',
      organization_name: 'organization',

      // specific parameter renaming - will be applied only to methods defined as keys here
      _$method_send_to_cbt_with_entity_id: {
        user_id: 'entity_id',
      },
    },
    PARAMETER_VALUE_CHANGE: {
      gender: {
        other: 'unknown',
      },
    },
    REQUEST_TYPES: [
      {
        method: 'check_eligibility_for_organization_and_sign_up',
        service: 'User',
        type: 'ajax',
        path: '/auth/check_eligibility_for_organization_and_sign_up',
      },
      {
        method: 'check_eligibility_for_organization',
        service: 'User',
        type: 'ajax',
        path: '/auth/check_eligibility_for_organization',
      },
      {
        method: 'create',
        service: 'User',
        type: 'ajax',
        path: '/auth/signup',
      },
      {
        method: 'register_interest',
        service: 'PotentialUser',
        type: 'ajax',
        path: '/auth/register_interest',
      },
    ],
  },
};
