import { FC, useState, useEffect } from 'react';
import FactoryComponent from '../../../factory-component';
import ConditionalRender from '../../../conditional-render';
import { getAdditionalData } from '../../utils/inputs';

type InteractiveStaticComponentProps = {
  alerts: { type: string }[];
  static: {
    id: number;
    body: string;
    display_name: string;
    js_body: string;
    name: string;
    primary_key: number;
    product_id: number;
    semantic_id: string;
  };
};

const InteractiveStaticComponent: FC<
  InteractiveStaticComponentProps
> = props => {
  const { alerts, static: _static } = props;
  const [visible, setVisible] = useState(true);
  const { used_for_error } = getAdditionalData(props);
  const item = { content: [_static] };
  const { semantic_id } = _static;

  useEffect(() => {
    const _alerts = !!alerts.filter(alert => alert.type === semantic_id).length;
    const _notUsedForError = !used_for_error;
    setVisible(_alerts || _notUsedForError);
  }, [alerts, semantic_id, used_for_error]);

  return (
    <ConditionalRender if={visible}>
      <FactoryComponent type="static-component" item={item} isChecked={false} />
    </ConditionalRender>
  );
};
export default InteractiveStaticComponent;
